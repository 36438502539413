/**
 * Popup for copying a shareable link
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardTeacher,
  faCog,
  faEllipsisH,
  faExclamationTriangle,
  faLink,
  faSignInAlt,
  faLock,
  faStar,
  faCalendar,
  faClock,
  faTag,
  faPlay,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  ModalType,
  getTimeInfoInET,
  ButtonInputGroup,
  SimpleDateChooser,
  Variant,
  ModalSize,
  logClientEvent,
  LogAction,
  DAY_IN_MS,
} from 'dce-reactkit';

// Import shared components
import CopyButton from '../../../shared/CopyButton';

// Import shared type
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Course ID
  courseId: number,
  // IHID of the event to share
  ihid: string,
  // Title of the link
  title: string,
  // The link to share
  shareableLink: string,
  // Handler for when the Modal is closed
  onClose: () => void,
  // If true, the current user is a learner
  isLearner: boolean,
  // If true, the current user is an admin
  isAdmin: boolean,
  // If true, the event is held in Zoom
  isHeldInZoom: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Date info for tomorrow
const tomorrow = getTimeInfoInET(new Date(Date.now() + DAY_IN_MS));
const tomorrowMonth = tomorrow.month;
const tomorrowDay = tomorrow.day;
const tomorrowYear = tomorrow.year;

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Shareable link panel
  ShareableLink = 'ShareableLink',
  // Configure breakthrough link panel
  ConfigureBreakthrough = 'ConfigureBreakthrough',
  // Loading
  Loading = 'Loading',
  // Breakthrough link panel
  Breakthrough = 'Breakthrough',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.ShareableLink,
  }
  | {
    // Current view
    view: View.ConfigureBreakthrough,
    // If true, user is customizing the date of the breakthrough
    schedulingBreakthrough: boolean,
    // Breakthrough schedule month
    startMonth: number,
    // Breakthrough schedule day
    startDay: number,
    // Breakthrough schedule year
    startYear: number,
    // Breakthrough link duration
    durationDays: number,
    // Label for the current breakthrough link
    breakthroughLabel: string,
  }
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.Breakthrough,
    // The breakthrough link
    breakthroughLink: string,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Start configuring a breakthrough link
  StartConfiguringBreakthrough = 'StartConfiguringBreakthrough',
  // Update the breakthrough schedule
  UpdateBreakthroughSchedule = 'UpdateBreakthroughSchedule',
  // Choose to schedule the breakthrough link
  ShowCustomSchedule = 'ShowCustomSchedule',
  // Update the breakthrough label
  UpdateBreakthroughLabel = 'UpdateBreakthroughLabel',
  // Update duration
  UpdateDuration = 'UpdateDuration',
  // Show loading indicator
  StartLoading = 'StartLoading',
  // Show the breakthrough link
  ShowBreakthroughLink = 'ShowBreakthroughLink',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateBreakthroughSchedule,
    // New schedule month
    startMonth: number,
    // New schedule day
    startDay: number,
    // New schedule year
    startYear: number,
  }
  | {
    // Action type
    type: ActionType.ShowCustomSchedule,
  }
  | {
    // Action type
    type: ActionType.UpdateBreakthroughLabel,
    // New label
    label: string,
  }
  | {
    // Action type
    type: ActionType.UpdateDuration,
    // New breakthrough link duration
    durationDays: number,
  }
  | {
    // Action type
    type: ActionType.ShowBreakthroughLink,
    // New breakthrough link
    link: string,
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
      | ActionType.StartConfiguringBreakthrough
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartConfiguringBreakthrough: {
      if (state.view !== View.ShareableLink) {
        return state;
      }
      return {
        ...state,
        view: View.ConfigureBreakthrough,
        schedulingBreakthrough: false,
        startMonth: tomorrowMonth,
        startDay: tomorrowDay,
        startYear: tomorrowYear,
        durationDays: 1,
        breakthroughLabel: '',
      };
    }
    case ActionType.UpdateBreakthroughSchedule: {
      if (state.view !== View.ConfigureBreakthrough) {
        return state;
      }
      return {
        ...state,
        schedulingBreakthrough: true,
        startMonth: action.startMonth,
        startDay: action.startDay,
        startYear: action.startYear,
      };
    }
    case ActionType.ShowCustomSchedule: {
      if (state.view !== View.ConfigureBreakthrough) {
        return state;
      }

      return {
        ...state,
        schedulingBreakthrough: true,
      };
    }
    case ActionType.UpdateBreakthroughLabel: {
      if (state.view !== View.ConfigureBreakthrough) {
        return state;
      }
      return {
        ...state,
        breakthroughLabel: action.label,
      };
    }
    case ActionType.UpdateDuration: {
      if (state.view !== View.ConfigureBreakthrough) {
        return state;
      }
      return {
        ...state,
        durationDays: action.durationDays,
      };
    }
    case ActionType.StartLoading: {
      return {
        view: View.Loading,
      };
    }
    case ActionType.ShowBreakthroughLink: {
      if (state.view !== View.Loading) {
        return state;
      }
      return {
        view: View.Breakthrough,
        breakthroughLink: action.link,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ShareableLinkSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    ihid,
    title,
    shareableLink,
    onClose,
    isLearner,
    isAdmin,
    isHeldInZoom,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.ShareableLink,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Get a breakthrough link
   * @author Gabe Abrams
   */
  const getBreakthroughLink = async () => {
    // Skip if not in the right view
    if (state.view !== View.ConfigureBreakthrough) {
      return;
    }

    // Destructure state
    const {
      startMonth,
      startDay,
      startYear,
      durationDays,
      breakthroughLabel,
    } = state;

    // Show loading indicator
    dispatch({
      type: ActionType.StartLoading,
    });

    // Get the link
    try {
      const newBreakthroughLink = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${ihid}/breakthroughs`,
        method: 'POST',
        params: {
          startMonth,
          startDay,
          startYear,
          durationDays,
          label: (
            (breakthroughLabel && breakthroughLabel.trim().length > 0)
              ? breakthroughLabel.trim()
              : undefined
          ),
        },
      });

      // Log create
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.ShareableLinkSubpanel,
        action: LogAction.Create,
        target: LogMetadata.Target.BreakthroughLink,
        metadata: {
          breakthroughLink: newBreakthroughLink,
        },
      });

      // Save link and move to next page
      dispatch({
        type: ActionType.ShowBreakthroughLink,
        link: newBreakthroughLink,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ------- Reusable Link Copy Box ------- */
  /*----------------------------------------*/

  // Get the current link
  let currentLink = shareableLink;
  if (state.view === View.Breakthrough) {
    currentLink = state.breakthroughLink;
  }

  // Link copy box
  const linkCopyBox = (
    <div>
      <div className="input-group">
        {/* Label */}
        <span className="input-group-text">
          {view === View.Breakthrough ? 'Breakthrough ' : 'Shareable '}
          Link
        </span>

        {/* Input Field (just for being copied) */}
        <input
          id="ShareableLinkSubpanel-link-box"
          type="text"
          className="form-control bg-light"
          value={currentLink}
          aria-label={`${view === View.Breakthrough ? 'breakthrough' : 'shareable'} link for ${title}`}
          readOnly
          style={{
            // Keep the same height as the schedule box
            height: '2.8125rem',
          }}
        />

        {/* Copy Button */}
        <div className="input-group-append">
          <CopyButton
            text={currentLink}
            item={view === View.Breakthrough ? 'breakthrough link' : 'shareable link'}
            variant={Variant.Secondary}
          />
        </div>
      </div>

      {/* Status */}
      {(view !== View.Breakthrough) && (
        <div className="text-center">
          <FontAwesomeIcon
            icon={faLock}
            className="me-2"
          />
          This link works only for members of the Canvas course.
          That&apos;s why it&apos;s
          {' '}
          <em>
            safe
          </em>
          {' '}
          and
          {' '}
          <em>
            secure
          </em>
          .
        </div>
      )}
    </div>
  );

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  /* --------- Shareable Link --------- */

  if (view === View.ShareableLink) {
    // Breakthrough notice
    let createBreakthroughInfo: React.ReactNode = null;
    if (!isLearner && isHeldInZoom) {
      // Breakthrough link can be created
      createBreakthroughInfo = (
        <div className="text-center">
          <div className="alert alert-warning d-inline-block text-dark mt-5 mb-0 pt-2 pb-2 ps-3 pe-3">
            {/* Text */}
            <div>
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="me-1"
              />
              <span className="fw-bold">
                Have a guest speaker?
              </span>
              {' '}
              Send them a breakthrough link.
            </div>

            {/* Create Breakthrough Link Button */}
            <div className="mt-1">
              <button
                type="button"
                className="btn btn-dark btn-sm"
                aria-label="create a breakthrough link (a temporary link that anyone can use to join the event, not just members of the course)"
                id="ShareableLinkSubpanel-turn-into-breakthrough-link"
                onClick={() => {
                  dispatch({
                    type: ActionType.StartConfiguringBreakthrough,
                  });
                }}
              >
                Create Breakthrough Link
              </button>
            </div>
          </div>
        </div>
      );
    }

    // Return the shareable link panel
    return (
      <Modal
        title={(
          <div>
            <FontAwesomeIcon
              icon={faLink}
              className="me-2"
            />
            {/* Small Screen View */}
            <span className="d-inline d-sm-none text-break">
              {title}
              {' '}
              Link
            </span>
            {/* Large Screen View */}
            <span className="d-none d-sm-inline text-break">
              <strong>
                {title}
              </strong>
              {' '}
              | Shareable Link
            </span>
          </div>
        )}
        onClose={onClose}
        type={ModalType.NoButtons}
      >
        {/* Box to copy link */}
        {linkCopyBox}

        {/* Read more about breakthrough links */}
        {createBreakthroughInfo}
      </Modal>
    );
  }

  /* ----- Configure Breakthrough ----- */

  if (view === View.ConfigureBreakthrough) {
    // Destructure state
    const {
      schedulingBreakthrough,
      startMonth,
      startDay,
      startYear,
      durationDays,
      breakthroughLabel,
    } = state;

    // Duration options and label customization
    const durationOptions: React.ReactNode[] = [];
    let lastUsableDay = 'never';
    if (isAdmin) {
      // Create duration options
      for (let i = 0; i < 30; i++) {
        const duration = (i + 1);
        durationOptions.push(
          <option
            key={duration}
            value={duration}
            aria-label={`choose duration ${duration} day(s)`}
          >
            {duration}
            {' '}
            day
            {duration > 1 ? 's' : ''}
          </option>,
        );
      }

      // Create description of last usable day
      const lastUsableDate = new Date(
        (new Date(`${startMonth}/${startDay}/${startYear}`)).getTime()
        + (DAY_IN_MS * (durationDays - 1)),
      );
      lastUsableDay = lastUsableDate.toLocaleDateString();
    }

    // Create body
    const body = (
      !schedulingBreakthrough
        ? ( // Initial choice
          <div className="text-center">
            {/* Title */}
            <h5>
              When should the breakthrough link be usable?
            </h5>

            {/* Buttons */}
            <div>
              <button
                type="button"
                className="btn btn-lg btn-dark me-2 mb-1"
                aria-label="choose today and tomorrow"
                id="ShareableLinkSubpanel-schedule-today-or-tomorrow"
                onClick={() => {
                  getBreakthroughLink();
                }}
              >
                <FontAwesomeIcon
                  icon={faStar}
                  className="me-2"
                />
                Today &amp; Tomorrow
              </button>
              <button
                type="button"
                className="btn btn-lg btn-secondary mb-1"
                aria-label="choose custom date"
                id="ShareableLinkSubpanel-schedule-custom-date"
                onClick={() => {
                  dispatch({
                    type: ActionType.ShowCustomSchedule,
                  });
                }}
              >
                Custom Date
              </button>
            </div>

            {/* Timezone notice */}
            <div>
              <strong>
                Note:
              </strong>
              {' '}
              all dates are in Eastern Time.
            </div>
          </div>
        )
        : ( // Custom scheduler
          <div className="text-center">
            <h5>
              When should the breakthrough link be usable?
            </h5>

            {/* Date Chooser */}
            <ButtonInputGroup
              label={(
                <>
                  <FontAwesomeIcon
                    icon={faCalendar}
                    className="me-2"
                  />
                  Usable on
                </>
              )}
              minLabelWidth="7.5rem"
            >
              <SimpleDateChooser
                name="breakthrough-schedule"
                month={startMonth}
                day={startDay}
                year={startYear}
                ariaLabel="breakthrough link schedule date"
                onChange={(newMonth, newDay, newYear) => {
                  dispatch({
                    type: ActionType.UpdateBreakthroughSchedule,
                    startMonth: newMonth,
                    startDay: newDay,
                    startYear: newYear,
                  });
                }}
                numMonthsToShow={6}
              />
            </ButtonInputGroup>

            {/* Duration Chooser (for admins only) */}
            {isAdmin && (
              <ButtonInputGroup
                label={(
                  <>
                    <FontAwesomeIcon
                      icon={faClock}
                      className="me-2"
                    />
                    Duration
                  </>
                )}
                minLabelWidth="7.5rem"
                isAdminFeature
              >
                <span className="text-start">
                  <select
                    className="custom-select d-inline-block"
                    style={{ width: 'auto' }}
                    id="ShareableLinkSubpanel-duration-chooser"
                    value={durationDays}
                    onChange={(e) => {
                      // Set the new duration
                      dispatch({
                        type: ActionType.UpdateDuration,
                        durationDays: Number.parseInt(e.target.value, 10),
                      });
                    }}
                  >
                    {durationOptions}
                  </select>
                  {' '}
                  {(durationDays > 1) && (
                    <div className="d-block d-md-inline">
                      Last usable on
                      {' '}
                      <span id="ShareableLinkSubpanel-last-usable-on-date">
                        {lastUsableDay}
                      </span>
                    </div>
                  )}
                </span>
              </ButtonInputGroup>
            )}

            {/* Label Field (for admin) */}
            {isAdmin && (
              <div className="input-group">
                <span
                  className="input-group-text bg-success progress-bar-striped text-light fw-bold border-success"
                  style={{
                    minWidth: '7.5rem',
                  }}
                >
                  <FontAwesomeIcon
                    icon={faTag}
                    className="me-2"
                  />
                  Label
                </span>
                <input
                  type="text"
                  id="ShareableLinkSubpanel-breakthrough-link-label-field"
                  className="form-control d-block border-success"
                  placeholder="optional description for organization"
                  value={breakthroughLabel}
                  onChange={(e) => {
                    dispatch({
                      type: ActionType.UpdateBreakthroughLabel,
                      label: e.target.value,
                    });
                  }}
                />
              </div>
            )}

            {/* Continue Button */}
            <div className="mt-3">
              <button
                id="ShareableLinkSubpanel-continue-to-breakthrough-button"
                type="button"
                className="btn btn-dark btn-lg"
                aria-label="get breakthrough link"
                onClick={() => {
                  getBreakthroughLink();
                }}
              >
                Continue
              </button>
            </div>
          </div>
        )
    );

    // Create modal
    return (
      <Modal
        title={(
          <div>
            <FontAwesomeIcon
              icon={faCog}
              className="me-2"
            />
            Breakthrough Link
          </div>
        )}
        onClose={onClose}
        type={ModalType.NoButtons}
      >
        {body}
      </Modal>
    );
  }

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create modal
    return (
      <Modal
        title="Working..."
        onClose={onClose}
        type={ModalType.NoButtons}
      >
        <LoadingSpinner />
      </Modal>
    );
  }

  /* ------------ View Link ----------- */

  return (
    <Modal
      title={(
        <div>
          <FontAwesomeIcon
            icon={faLink}
            className="me-2"
          />
          {/* Small Screen View */}
          <span className="d-inline d-sm-none text-break">
            {title}
            {' '}
            Breakthrough
          </span>
          {/* Large Screen View */}
          <span className="d-none d-sm-inline text-break">
            <strong>
              {title}
            </strong>
            {' '}
            | Breakthrough Link
          </span>
        </div>
      )}
      onClose={onClose}
      type={ModalType.NoButtons}
      size={ModalSize.Large}
    >
      <div>
        <div className="alert alert-danger p-2 text-dark text-center mb-3">
          <h5 className="fw-bold mb-1">
            <FontAwesomeIcon
              icon={faExclamationTriangle}
              className="me-2"
            />
            This is a Breakthrough Link!
          </h5>

          <div className="text-start">
            <div className="alert alert-light text-dark mb-0 mt-2">
              <FontAwesomeIcon
                icon={faSignInAlt}
                className="me-1"
              />
              <strong>
                Anyone
              </strong>
              {' '}
              with this link can join the event as a participant.
              Careful where you send this link!
            </div>

            <div className="alert alert-light text-dark mb-0 mt-2">
              <FontAwesomeIcon
                icon={faPlay}
                className="me-1"
              />
              <strong>
                You start the event.
              </strong>
              {' '}
              The recipient of this link cannot start the event on their own.
            </div>

            <div className="alert alert-light text-dark mb-0 mt-2">
              <FontAwesomeIcon
                icon={faChalkboardTeacher}
                className="me-1"
              />
              <strong>
                If recipient needs screen share,
              </strong>
              {' '}
              make them a co-host by hovering over their video,
              click
              {' '}
              <span className="sr-only">
                the ellipsis button
              </span>
              <span
                className="badge bg-secondary text-light"
                style={{
                  transform: 'translate(0, -0.2rem)',
                }}
              >
                <FontAwesomeIcon
                  icon={faEllipsisH}
                />
              </span>
              {' '}
              and then &quot;Make Co-Host&quot;.
            </div>
          </div>
        </div>

        {/* Box to copy link */}
        {linkCopyBox}
      </div>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ShareableLinkSubpanel;
