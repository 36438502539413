/**
 * Explanation that user has to go claim their Zoom account
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LogAction,
  logClientEvent,
} from 'dce-reactkit';

// Import shared types
import SupportEmail from './types/from-server/SupportEmail';
import LogMetadata from './types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler to call when "try again" button is clicked
  onTryAgain: () => void,
  // If included, allow the user to skip the notice
  onSkip?: () => void,
  // If included, show a cancel button
  onCancel?: () => void,
  // If true, user is a learner
  isLearner: boolean,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, user clicked to claim
  clickedToClaim: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // User clicked to claim
  ClickedToClaim = 'ClickedToClaim',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.ClickedToClaim
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ClickedToClaim: {
      return {
        ...state,
        clickedToClaim: true,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ClaimAccountNotice: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onTryAgain,
    onSkip,
    onCancel,
    isLearner,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    clickedToClaim: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    clickedToClaim,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      // Log opening
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.ClaimAccountNotice,
        action: LogAction.Open,
      });
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------ Try Again ----------- */

  if (clickedToClaim) {
    return (
      <div className="text-center">
        <h4 className="mb-0">
          Welcome back!
        </h4>
        <div className="mb-2">
          If you successfully signed in, continue.
          {onSkip && (
            <span>
              {' '}
              If you encountered an error,
              {' '}
              <button
                type="button"
                id="ClaimAccountNotice-skip-button"
                className="btn btn-link p-0 m-0 text-dark"
                aria-label="skip process of claiming account"
                onClick={onSkip}
                style={{
                  fontSize: 'inherit',
                  verticalAlign: 'baseline',
                  textDecoration: 'underline',
                }}
              >
                skip this step
              </button>
            </span>
          )}
          {!onSkip && (
            <span>
              {' '}
              If you encountered an error,
              {' '}
              email
              {' '}
              <a
                href={`mailto:${isLearner ? SupportEmail.ForStudents : SupportEmail.ForTTMs}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'underline',
                  color: 'inherit',
                }}
              >
                {
                  isLearner
                    ? SupportEmail.ForStudents
                    : SupportEmail.ForTTMs
                }
              </a>
            </span>
          )}
        </div>
        <div>
          <button
            type="button"
            id="ClaimAccountNotice-try-again-button"
            className="btn btn-dark"
            aria-label="try to continue again"
            onClick={onTryAgain}
          >
            Continue
          </button>
          {onCancel && (
            <button
              type="button"
              id="ClaimAccountNotice-cancel-button"
              className="btn btn-secondary ms-2"
              aria-label="cancel process"
              onClick={onCancel}
            >
              Cancel
            </button>
          )}
        </div>
      </div>
    );
  }

  /* ------------ Claim Now ----------- */

  return (
    <div>
      <div className="alert alert-danger m-0">
        {/* Header */}
        <h4>
          Sign In and Come Back
        </h4>

        {/* Explanation */}
        <div>
          For
          {' '}
          <strong>
            screen share
          </strong>
          {' '}
          and other important features,
          sign in to Harvard Zoom and
          {' '}
          <strong>
            return to this page
          </strong>
          .
        </div>
      </div>

      {/* Note on reclaiming */}
      <div className="mt-2 mb-4">
        Due to Zoom account updates,
        you may need to
        {' '}
        <strong>
          reclaim
        </strong>
        {' '}
        an account even
        if you had one in the past.
      </div>

      {/* Skip Button */}
      {onSkip && (
        <div className="small text-center">
          Encountered a sign in error?
          {' '}
          <button
            type="button"
            id="ClaimAccountNotice-skip-button"
            className="btn btn-link p-0 m-0 text-dark"
            aria-label="skip process of claiming account"
            onClick={onSkip}
            style={{
              fontSize: 'inherit',
              verticalAlign: 'baseline',
              textDecoration: 'underline',
            }}
          >
            Skip this step
          </button>
        </div>
      )}

      {/* Claim Button */}
      <div className="text-center mt-2">
        <a
          className="btn btn-lg btn-dark"
          href="https://harvard.zoom.us/"
          id="ClaimAccountNotice-claim-zoom-link"
          target="_blank"
          rel="noopener noreferrer"
          aria-label="go to claim Harvard Zoom account"
          onClick={() => {
            dispatch({
              type: ActionType.ClickedToClaim,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faSignInAlt}
            className="me-2"
          />
          Open Sign-In Page
        </a>
        {onCancel && (
          <button
            type="button"
            id="ClaimAccountNotice-cancel-button"
            className="btn btn-secondary btn-lg ms-2"
            aria-label="cancel process"
            onClick={onCancel}
          >
            Cancel
          </button>
        )}
      </div>
    </div>
  );
};

export default ClaimAccountNotice;
