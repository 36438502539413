/**
 * Create a time string from a date object
 * @author Gabe Abrams
 * @param date the date to format
 * @returns formatted time string
 */
const formatTime = (date: Date): string => {
  return (
    date
      // Create US date
      .toLocaleTimeString(
        'en-US',
        {
          hour: 'numeric',
          hour12: true,
          minute: 'numeric',
        },
      )
      // Remove space between time and AM/PM
      .replace(/ /g, '')
      // Make AM/PM lowercase
      .toLowerCase()
  );
};

export default formatTime;
