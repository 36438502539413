/**
 * List of allowed schools
 * @author Gabe Abrams
 */
enum School {
  // Division of Continuing Education
  DCE = 'DCE',
  // Faculty of Arts and Sciences
  FAS = 'FAS',
}

export default School;
