// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import types
import CheckInIds from '../../../types/CheckInIds';
import Occurrence from '../../../types/Occurrence';
import SeriesInfo from '../../../types/SeriesInfo';
import OccurrenceGrouperInfo from '../../../types/OccurrenceGrouperInfo';

// Import helpers
import toStringMMDDYY from '../../../helpers/toStringMMDDYY';

// Import constants
import TTM_PATH_PREFIX from '../../../constants/TTM_PATH_PREFIX';

// Simple cache, we don't have a TTL since this is on the browser
// i.e. this is expected to be short-lived, and issues can be resolved by
// reloading
const seriesListCache: Map<string, SeriesInfo[]> = new Map();

/**
 * Fetch the list of series corresponding to the event.
 * @author Benedikt Arnarsson
 * @param opts the course ID and IHID for the event
 * @returns the list of series corresponding to the event
 */
const fetchSeriesList = async (opts: CheckInIds): Promise<SeriesInfo[]> => {
  const {
    courseId,
    ihid,
  } = opts;
  // Check cache
  const cacheKey = `${courseId}-${ihid}`;
  const cacheRes = seriesListCache.get(cacheKey);
  if (cacheRes) {
    return cacheRes;
  }

  // We know that the occurrence list returned will only have
  // `grouperEnabled: true`
  const occurrenceList: (Occurrence & OccurrenceGrouperInfo)[] = (
    await visitServerEndpoint({
      path: `${TTM_PATH_PREFIX}/occurrence/series-list`,
      params: {
        courseId,
        ihid,
      },
    })
  );

  // TODO: sort by date
  const seriesInfoList: SeriesInfo[] = occurrenceList.map((occ) => {
    const { seriesId } = occ;
    const mmddyy = toStringMMDDYY(occ);
    return {
      seriesId,
      mmddyy,
    };
  });

  // Add result to cache
  seriesListCache.set(cacheKey, seriesInfoList);

  return seriesInfoList;
};

export default fetchSeriesList;
