/**
 * Mapping of full weekday name to the 1-letter code for that day
 * @author Gabe Abrams
 */
const WEEKDAY_TO_CODE = {
  Monday: 'M',
  Tuesday: 'T',
  Wednesday: 'W',
  Thursday: 'R',
  Friday: 'F',
  Saturday: 'S',
  Sunday: 'U',
};

export default WEEKDAY_TO_CODE;
