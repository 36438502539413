/**
 * Analytics panel for analytics and downloads
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import dce-reactkit
import {
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  Modal,
  ModalType,
  ModalSize,
  confirm,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import caccl
import CanvasUser from 'caccl/types/CanvasUser';

// Import shared types
import AttendanceMethod from '../../../../shared/types/from-server/stored/AttendanceLog/AttendanceMethod';
import UserRole from '../../../../shared/types/UserRole';
import UserAndCourseInfo from '../../../../shared/types/UserAndCourseInfo';
import CourseEvent from '../../../../shared/types/from-server/stored/CourseEvent';

// Import shared constants
import WEEKDAY_TO_CODE from '../../../../shared/constants/WEEKDAY_TO_CODE';

// Import shared types
import SelfEnteredAttendance from '../../../../shared/types/from-server/stored/SelfEnteredAttendance';
import LogMetadata from '../../../../shared/types/from-server/LogMetadata';

// Import other components
import AttendanceDashboard from './AttendanceDashboard';
import AttendanceLog from '../../../../shared/types/from-server/stored/AttendanceLog';

// Import other types
import AttendanceRecord from './types/AttendanceRecord';
import AttendanceLogMap from './types/AttendanceLogMap';
import AttendanceMatcher from './AttendanceMatcher';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Event to show attendance for
  event: CourseEvent,
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
  // Close the subpanel
  onClose: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Default record values for export (if data is not found)
const RECORD_DEFAULTS = {
  userName: 'Name Unknown',
  userRole: UserRole.Unknown,
  inAttendance: false,
  method: AttendanceMethod.Absent,
  groupNumber: 'N/A',
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Matching
  Matching = 'Matching',
  // Attendance dashboard
  Attendance = 'Attendance',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.Matching,
    // List of all self-entered attendance entries
    selfEnteredAttendance: SelfEnteredAttendance[],
    // Canvas users
    canvasUsers: CanvasUser[],
  }
  | {
    // Current view
    view: View.Attendance,
    // Attendance log map (date => ihid => userId => record)
    attendanceLogMap: AttendanceLogMap,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the matcher
  ShowMatcher = 'ShowMatcher',
  // Start loading
  StartLoading = 'StartLoading',
  // Finish loading
  FinishLoading = 'FinishLoading',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // List of course events
    events: CourseEvent[],
    // Attendance log map (date => ihid => userId => record)
    attendanceLogMap: AttendanceLogMap,
  }
  | {
    // Action type
    type: ActionType.ShowMatcher,
    // List of all self-entered attendance entries
    selfEnteredAttendance: SelfEnteredAttendance[],
    // Canvas users
    canvasUsers: CanvasUser[],
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FinishLoading: {
      return {
        view: View.Attendance,
        attendanceLogMap: action.attendanceLogMap,
      };
    }
    case ActionType.ShowMatcher: {
      return {
        view: View.Matching,
        selfEnteredAttendance: action.selfEnteredAttendance,
        canvasUsers: action.canvasUsers,
      };
    }
    case ActionType.StartLoading: {
      return {
        view: View.Loading,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    event,
    userAndCourseInfo,
    onClose,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Load attendance
   * @author Gabe Abrams
   */
  const loadAttendance = async () => {
    try {
      // Show the loader (it is probably already visible, but just in case)
      dispatch({
        type: ActionType.StartLoading,
      });

      // Load from server
      const [
        attendanceLogs,
        events,
      ] = await Promise.all([
        visitServerEndpoint({
          path: `/api/ttm/courses/${userAndCourseInfo.courseId}/attendance`,
          method: 'GET',
        }) as Promise<AttendanceLog[]>,
        visitServerEndpoint({
          path: `/api/ttm/courses/${userAndCourseInfo.courseId}/events`,
          method: 'GET',
        }) as Promise<CourseEvent[]>,
      ]);

      // Pre-process events
      const ihidToEvent: {
        [ihid: string]: CourseEvent,
      } = {};
      events.forEach((eventToProcess) => {
        ihidToEvent[eventToProcess.ihid] = eventToProcess;
      });

      // Sort attendance logs (earliest first)
      attendanceLogs.sort((a, b) => {
        return a.timestamp - b.timestamp;
      });

      // Keep track of user info
      const userIdToRole: {
        [userId: number]: UserRole,
      } = {};
      const userIdToName: {
        [userId: number]: string,
      } = {};

      // Process attendance logs
      const dateToEventToUserToRecord: {
        [date: string]: {
          [ihid: string]: {
            [userId: number]: AttendanceRecord
          },
        },
      } = {}; // date => ihid => userId => record
      attendanceLogs.forEach((log) => {
        const {
          userId,
          userFirstName,
          userLastName,
          ihid,
          method,
          isLearner,
          isAdmin,
          isHost,
          groupNum,
        } = log;
        const timestamp = (
          method === AttendanceMethod.Async
            // Use the timestamp of the event if async
            ? (log.eventTimestamp ?? log.timestamp)
            : log.timestamp
        );
        const userName = (
          userFirstName && userLastName
            ? `${userLastName}, ${userFirstName}`
            : null
        );

        // Get other objects
        const associatedEvent = ihidToEvent[ihid];
        const time = new Date(timestamp);

        // Skip when the event has disappeared
        if (!associatedEvent) {
          return;
        }

        // Get date key
        const dateKey = time.toLocaleDateString(
          'en-US',
          { timeZone: 'America/New_York' },
        );

        // Add record if there isn't one
        if (!dateToEventToUserToRecord[dateKey]) {
          dateToEventToUserToRecord[dateKey] = {};
        }
        if (!dateToEventToUserToRecord[dateKey][associatedEvent.ihid]) {
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid] = {};
        }
        if (!dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]) {
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId] = {
            userId,
            userName: RECORD_DEFAULTS.userName,
            userRole: RECORD_DEFAULTS.userRole,
            inAttendance: false,
            eventDate: dateKey,
            eventDayOfWeek: 'Monday', // will be overwritten later
            eventFractionalHour: 0, // part of hour, indicated by decimal
            joinTimes: [], // [{ method, description }, ...]
            eventId: associatedEvent.ihid,
            eventName: associatedEvent.name,
            eventType: associatedEvent.type,
            userWasHost: false,
            method: RECORD_DEFAULTS.method,
            groupNumber: RECORD_DEFAULTS.groupNumber,
            timestamp: 0,
          };
        }

        // Update record for this attendance entry

        // Determine the user's role
        let userRole = UserRole.Unknown;
        if (isAdmin) {
          userRole = UserRole.Admin;
        } else if (isLearner) {
          userRole = UserRole.Student;
        } else {
          userRole = UserRole.TTM;
        }
        userIdToRole[userId] = userRole;

        // Save user's name
        if (userName) {
          userIdToName[userId] = userName;
        }

        // > Add role
        if (
          // eslint-disable-next-line max-len
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId].userRole
          === RECORD_DEFAULTS.userRole
        ) {
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .userRole = (
              userRole
            );
        }
        // > Add name
        if (userName) {
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .userName = (
              userName
            );
        }
        // > Add attendance boolean
        dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
          .inAttendance = (
            true
          );
        // > Save method of attendance
        if (
          // eslint-disable-next-line max-len
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId].method
          === RECORD_DEFAULTS.method
        ) {
          // eslint-disable-next-line max-len
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId].method = (
            method
          );
        }
        // > Add whether the user was a host
        dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
          .userWasHost = (
            dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
              .userWasHost
            || isHost
          );
        // > Add group number
        if (groupNum) {
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .groupNumber = String(
              groupNum,
            );
        }
        // > Timestamp, day, time
        if (
          // eslint-disable-next-line max-len
          !dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId].timestamp
        ) {
          // Add timestamp
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .timestamp = (
              timestamp
            );

          // Add the day of the week in ET
          const weekday = time.toLocaleString(
            'en-US',
            {
              timeZone: 'America/New_York',
              weekday: 'long',
            },
          );
          const weekdayCode = WEEKDAY_TO_CODE[
            weekday as keyof typeof WEEKDAY_TO_CODE
          ];
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .eventDayOfWeek = weekdayCode;

          // Add hours
          const [hours, minutes] = (
            time
              .toLocaleTimeString(
                'en-US',
                {
                  timeZone: 'America/New_York',
                  hour12: false,
                  hour: '2-digit',
                  minute: '2-digit',
                },
              )
              .split(':')
              .map((part) => {
                return Number.parseInt(part, 10);
              })
          );
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .eventFractionalHour = hours + (minutes / 60);
        }
        // >> Add join time
        if (method === AttendanceMethod.Async) {
          // Add indication of asynchronous watch
          const description = `Watched Recording on ${time.toLocaleDateString('en-US', { timeZone: 'America/New_York' })} at ${time.toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}`;

          // Add to list
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .joinTimes.push({
              method,
              description,
            });
        } else if (method === AttendanceMethod.InPerson) {
          // Add indication of in-person join
          const description = `Joined In-Person at ${time.toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}`;

          // Add to list
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .joinTimes.push({
              method,
              description,
            });
        } else {
          const alreadyJoinedLive = (
            dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
              .joinTimes
              .some((item) => {
                return (item.method === AttendanceMethod.Live);
              })
          );

          // Add indication of live join
          const description = `${alreadyJoinedLive ? 'Re-joined' : 'Joined'} Live at ${time.toLocaleTimeString('en-US', { timeZone: 'America/New_York' })}`;

          // Add to list
          dateToEventToUserToRecord[dateKey][associatedEvent.ihid][userId]
            .joinTimes.push({
              method,
              description,
            });
        }
      });

      // Override roles and names for everyone
      Object.keys(dateToEventToUserToRecord).forEach((date) => {
        Object.keys(dateToEventToUserToRecord[date]).forEach((ihid) => {
          Object.keys(dateToEventToUserToRecord[date][ihid])
            .forEach((idString) => {
              // Parse id
              const id = Number.parseInt(idString, 10);

              // Overwrite role and name
              const knownRole = userIdToRole[id];
              const knownName = userIdToName[id];
              if (knownRole) {
                dateToEventToUserToRecord[date][ihid][id].userRole = (
                  knownRole
                );
              }
              if (knownName) {
                dateToEventToUserToRecord[date][ihid][id].userName = (
                  knownName
                );
              }
            });
        });
      });

      // Filter out archived events
      const unarchivedEvents = events.filter((unarchivedEvent) => {
        return !unarchivedEvent.archived;
      });

      // Update the state
      dispatch({
        type: ActionType.FinishLoading,
        attendanceLogMap: dateToEventToUserToRecord,
        events: unarchivedEvents,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          // Log open
          logClientEvent({
            context: LogMetadata.Context.Home,
            subcontext: LogMetadata.Context.Home.AttendanceSubpanel,
            action: LogAction.Open,
            metadata: {
              ihid: event.ihid,
            },
          });

          // Load the list of attendance to match
          const {
            selfEnteredAttendance,
            canvasUsers,
          } = await visitServerEndpoint({
            path: `/api/ttm/courses/${event.courseId}/attendance/to-match`,
            method: 'GET',
          }) as {
            selfEnteredAttendance: SelfEnteredAttendance[],
            canvasUsers: CanvasUser[],
          };

          // Show matcher if needed
          if (selfEnteredAttendance.length > 0) {
            // Ask the user if they want to match
            const confirmed = await confirm(
              'Manual Attendance Matching Required',
              'Some attendees couldn\'t be matched to people in your course because their name didn\'t match anyone in the course. You\'ll need to manually match them to people in your course.',
              {
                confirmButtonText: 'Start Matching',
                cancelButtonText: 'Skip for Now',
              },
            );
            if (confirmed) {
              // Show the matcher
              dispatch({
                type: ActionType.ShowMatcher,
                selfEnteredAttendance,
                canvasUsers,
              });

              // Halt here
              return;
            }
          }

          // Start loading attendance
          loadAttendance();
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log close
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.AttendanceSubpanel,
          action: LogAction.Close,
          metadata: {
            ihid: event.ihid,
          },
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------- Matcher ------------ */

  if (view === View.Matching) {
    // Destructure state
    const {
      selfEnteredAttendance,
      canvasUsers,
    } = state;

    // Combine self entered attendance into batches
    const batches: SelfEnteredAttendance[][] = [];

    // Keep creating batches until all self entered attendance is in a batch
    let unbatchedSelfEnteredAttendance = [...selfEnteredAttendance];
    while (unbatchedSelfEnteredAttendance.length) {
      // Create a batch based on the first self entered attendance entry
      const batch = [unbatchedSelfEnteredAttendance[0]];

      // Get info to compare to
      const {
        userFirstName,
        userLastName,
        isLearner,
        isAdmin,
      } = batch[0];

      // Keep track of items that weren't added to the batch
      const nextUnbatched: SelfEnteredAttendance[] = [];

      // Add other matching entries to the batch
      for (let i = 1; i < unbatchedSelfEnteredAttendance.length; i++) {
        const candidate = unbatchedSelfEnteredAttendance[i];
        if (
          // Same first name
          (
            candidate.userFirstName.trim().toLowerCase()
            === userFirstName.trim().toLowerCase()
          )
          // Same last name
          && (
            candidate.userLastName.trim().toLowerCase()
            === userLastName.trim().toLowerCase()
          )
          // Same learner status
          && !!candidate.isLearner === !!isLearner
          // Same admin status
          && !!candidate.isAdmin === !!isAdmin
        ) {
          // Add to batch
          batch.push(candidate);
        } else {
          // Don't add to batch, save for next time
          nextUnbatched.push(candidate);
        }
      }

      // Add the batch
      batches.push(batch);

      // Update unbatched
      unbatchedSelfEnteredAttendance = nextUnbatched;
    }

    // Return the matcher, let it take full control
    return (
      <AttendanceMatcher
        batches={batches}
        canvasUsers={canvasUsers}
        onFinish={() => {
          // Switched to loading attendance
          loadAttendance();
        }}
      />
    );
  }

  /* ------ Attendance Dashboard ------ */

  if (view === View.Attendance) {
    // Destructure state
    const {
      attendanceLogMap,
    } = state;

    // Create body
    body = (
      <AttendanceDashboard
        attendanceLogMap={attendanceLogMap}
        event={event}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* -------------------------- UI Parts -------------------------- */

  return (
    <Modal
      type={ModalType.NoButtons}
      size={ModalSize.ExtraLarge}
      title={(
        <div>
          Attendance for
          {' '}
          {event.name}
        </div>
      )}
      onClose={onClose}
    >
      <div className="AttendanceSubpanel-outer-container">
        {body}
      </div>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceSubpanel;
