// Import types
import { StudentGroupAssignment, EventStateData } from 'dce-live-grouper';

/**
 * Helper for extracting a list of pre-assignments from a previous event.
 * @author Benedikt Arnarsson
 * @param eventStateData the events data, containing the
 *   `allGroup: GroupStateMap`.
 * @returns a list of `StudentGroupAssignments` for pre-assignment use.
 */
const allGroupsToGroupAssignment = (
  eventStateData: EventStateData,
): StudentGroupAssignment[] => {
  return (
    Object.entries(eventStateData.allGroups)
      .flatMap(([groupId, groupState]) => {
        const groupNum = Number.parseInt(groupId, 10);
        return groupState.studentIds.map((studentId) => {
          return {
            studentId,
            groupNum,
          };
        });
      })
  );
};

export default allGroupsToGroupAssignment;
