/**
 * Show a list of events to recover (unarchive)
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import dce-reactkit
import {
  LoadingSpinner,
  alert,
  showFatalError,
  visitServerEndpoint,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import other components
import ArchivedEventItem from './ArchivedEventItem';

// Import shared components
import NothingHereNotice from '../../../shared/NothingHereNotice';
import CourseEvent from '../../../shared/types/from-server/stored/CourseEvent';

// Import shared types
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

// Import other types
import AdminPanelFeatureProps from '../types/AdminPanelFeatureProps';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Archived event list
  ArchivedEventList = 'ArchivedEventList',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.ArchivedEventList,
    // List of archived events
    archivedEvents: CourseEvent[],
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Start loading
  StartLoading = 'StartLoading',
  // Finish loading
  FinishLoading = 'FinishLoading',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.StartLoading,
  }
  | {
    // Action type
    type: ActionType.FinishLoading,
    // List of archived events
    archivedEvents: CourseEvent[],
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartLoading: {
      return {
        ...state,
        view: View.Loading,
      };
    }
    case ActionType.FinishLoading: {
      return {
        ...state,
        view: View.ArchivedEventList,
        archivedEvents: action.archivedEvents,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const RecoverEvent: React.FC<AdminPanelFeatureProps> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
  } = props;

  const {
    courseId,
    courseName,
  } = userAndCourseInfo;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Recover an event
   * @author Gabe Abrams
   * @param event the event to recover
   */
  const recoverEvent = async (event: CourseEvent) => {
    // Skip if not in the right view
    if (view !== View.ArchivedEventList) {
      return;
    }

    // Destructure the state
    const {
      archivedEvents,
    } = state;

    // Show the loading spinner
    dispatch({
      type: ActionType.StartLoading,
    });

    try {
      // Recover the event
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/events/${event.ihid}/recover`,
        method: 'PUT',
      });

      // Log recovery
      logClientEvent({
        context: LogMetadata.Context.AdminPanel,
        subcontext: LogMetadata.Context.AdminPanel.RecoverEvent,
        action: LogAction.Resume,
        target: LogMetadata.Target.RecoverableEvent,
        metadata: {
          event,
        },
      });

      // Update the state
      const newArchivedEvents = archivedEvents.filter((archivedEvent) => {
        return (archivedEvent.ihid !== event.ihid);
      });
      dispatch({
        type: ActionType.FinishLoading,
        archivedEvents: newArchivedEvents,
      });

      // Show the success modal
      await alert(
        'Event Recovered',
        'The event was successfully recovered.',
      );
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          // Get the list of archived events
          const archivedEvents = await visitServerEndpoint({
            path: `/api/admin/courses/${courseId}/events/archived`,
            method: 'GET',
            params: {
              allowCourseMismatch: true, // Admin panel can access all courses
            },
          });

          // Log opening
          logClientEvent({
            context: LogMetadata.Context.AdminPanel,
            subcontext: LogMetadata.Context.AdminPanel.RecoverEvent,
            action: LogAction.Open,
          });

          // Update the state
          dispatch({
            type: ActionType.FinishLoading,
            archivedEvents,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log opening
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          subcontext: LogMetadata.Context.AdminPanel.RecoverEvent,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* ----------- Event List ----------- */

  if (view === View.ArchivedEventList) {
    // Destructure the state
    const {
      archivedEvents,
    } = state;

    // Create the archived event list
    const eventItems = archivedEvents.map((event) => {
      return (
        <ArchivedEventItem
          key={event.ihid}
          event={event}
          onRecover={() => {
            recoverEvent(event);
          }}
        />
      );
    });

    // Create the body
    body = (
      <div className="alert alert-light text-dark mt-4">
        {archivedEvents.length === 0 && (
          <NothingHereNotice
            title="No deleted events"
            subtitle="This course has no deleted events right now. Please check back later."
            noMarginOnBottom
          />
        )}
        {eventItems}
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {/* Title */}
      <h2 className="text-white fw-bold">
        Recover Events
      </h2>

      {/* Current Course */}
      <p className="text-white lead">
        Current course:
        {' '}
        {courseName}
      </p>

      {/* Body */}
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default RecoverEvent;
