/**
 * Main Gather App
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import caccl
import { getStatus } from 'caccl/client';
import LaunchInfo from 'caccl/types/LaunchInfo';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHourglassEnd,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  showFatalError,
} from 'dce-reactkit';

// Import shared types
import UserAndCourseInfo from './shared/types/UserAndCourseInfo';
import School from './shared/types/from-server/School';

// Import components
import Home from './panels/Home';
import AdminPanel from './panels/AdminPanel';
import Header from './shared/Header';

// Import style
import './App.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

enum SessionExpiredNoticeType {
  // Session expired
  SessionExpired = 'SessionExpired',
}

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Session expired
  SessionExpiredNotice = 'SessionExpiredNotice',
  // Home
  HomePanel = 'HomePanel',
  // Admin Panel
  Admin = 'Admin',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.HomePanel | View.Admin,
    // User and course info
    userAndCourseInfo: UserAndCourseInfo,
  }
  | {
    // Current view
    view: View.HomePanel,
    // User and course info
    userAndCourseInfo: UserAndCourseInfo,
  }
  | {
    // Current view
    view: View.SessionExpiredNotice,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish loading
  FinishLoading = 'FinishLoading',
  // Switch to admin panel
  SwitchToAdminPanel = 'SwitchToAdminPanel',
  // Switch to home
  SwitchToHome = 'SwitchToHome',
  // Show session expired notice
  ShowSessionExpiredNotice = 'ShowSessionExpiredNotice',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // User and course info
    userAndCourseInfo: UserAndCourseInfo,
  }
  | {
    // Action type
    type: ActionType.ShowSessionExpiredNotice,
    // Error type
    errorType: SessionExpiredNoticeType,
  }
  | {
    // Action type
    type: (
      | ActionType.SwitchToAdminPanel
      | ActionType.SwitchToHome
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  // Pretty error
  if (action.type === ActionType.ShowSessionExpiredNotice) {
    return {
      view: View.SessionExpiredNotice,
    };
  }
  if (state.view === View.SessionExpiredNotice) {
    return state;
  }

  // Loading
  if (state.view === View.Loading) {
    switch (action.type) {
      case ActionType.FinishLoading: {
        return {
          ...state,
          view: View.HomePanel,
          userAndCourseInfo: action.userAndCourseInfo,
        };
      }
      default: {
        return state;
      }
    }
  }

  // Home or Admin Panel
  switch (action.type) {
    case ActionType.SwitchToAdminPanel: {
      return {
        view: View.Admin,
        userAndCourseInfo: state.userAndCourseInfo,
      };
    }
    case ActionType.SwitchToHome: {
      return {
        ...state,
        view: View.HomePanel,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const App: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Load status
        let launchInfo: LaunchInfo | undefined;
        try {
          // Get status from server
          const status = await getStatus();

          // > App wasn't launched via Canvas
          if (!status.launched) {
            // Display error to user
            return dispatch({
              type: ActionType.ShowSessionExpiredNotice,
              errorType: SessionExpiredNoticeType.SessionExpired,
            });
          }

          // Get launch info from server
          ({ launchInfo } = status);
        } catch (err) {
          // Display error to user
          return showFatalError(err);
        }

        /* ------ Ensure User in Course ----- */

        const {
          notInCourse,
          isAdmin,
          courseId,
        } = launchInfo;
        if (notInCourse && !isAdmin) {
          // Show an error message
          return showFatalError(
            'You are not enrolled in this course. Please make sure you\'re logged into Canvas with the correct account then try again.',
            'Not Enrolled in Course',
          );
        }

        /* ------- Process Launch Info ------ */

        const {
          userId,
        } = launchInfo;
        const isLearner = (launchInfo.isLearner && !isAdmin);
        const userFirstName = (
          launchInfo.userFirstName
          || 'Student'
        );
        const userLastName = (
          launchInfo.userLastName
          || ''
        );
        const school = (
          launchInfo.customParams
            ? launchInfo.customParams.school || School.DCE
            : School.DCE
        );
        const courseName = launchInfo.contextLabel;
        const termName = (
          launchInfo.customParams
            ? launchInfo.customParams.term_name || 'Unknown Term'
            : 'Unknown Term'
        );

        // Save to state
        dispatch({
          type: ActionType.FinishLoading,
          userAndCourseInfo: {
            isLearner,
            courseId,
            courseName,
            termName,
            userId,
            school,
            userFirstName,
            userLastName,
            isAdmin: !!isAdmin,
          },
        });
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  /* -------- Messages/Notices -------- */

  if (view === View.Loading || view === View.SessionExpiredNotice) {
    let body: React.ReactNode;

    // Loading
    if (view === View.Loading) {
      // Loading message
      body = (
        <div className="text-center">
          <LoadingSpinner />
        </div>
      );
    }

    // Session expired
    if (view === View.SessionExpiredNotice) {
      const canRefreshToRestartSession = (
        window.location.pathname.includes('/courses/')
      );

      // Session expired message
      body = (
        <div className="alert alert-warning d-inline-block">
          {/* Title */}
          <h3>
            <FontAwesomeIcon
              icon={faHourglassEnd}
              className="me-2"
            />
            Session Expired
          </h3>

          {/* Subtitle */}
          <p className="lead font-weight-normal m-0">
            {
              canRefreshToRestartSession
                ? 'Try refreshing the page. If that doesn\'t work, go'
                : 'Go'
            }
            {' '}
            to Canvas and reopen this page.
          </p>
        </div>
      );
    }

    // Put together
    return (
      <div>
        <Header
          userAndCourseInfo={{
            isAdmin: false,
            isLearner: false,
            userFirstName: '',
            userLastName: '',
            school: School.DCE,
            termName: '',
            courseName: '',
            userId: 0,
            courseId: 0,
          }}
        />

        <div className="App-content-below-header">
          {body}
        </div>
      </div>
    );
  }

  /* ----------- Admin Panel ---------- */

  if (view === View.Admin) {
    // Destructure state
    const {
      userAndCourseInfo,
    } = state;

    // Build body
    return (
      <AdminPanel
        userAndCourseInfo={userAndCourseInfo}
        onExit={() => {
          // Go back to home
          dispatch({
            type: ActionType.SwitchToHome,
          });
        }}
      />
    );
  }

  /* -------------- Home -------------- */

  // Destructure state
  const {
    userAndCourseInfo,
  } = state;

  // Create body
  return (
    <Home
      userAndCourseInfo={userAndCourseInfo}
      onSwitchToAdminPanel={() => {
        if (userAndCourseInfo.isAdmin) {
          dispatch({
            type: ActionType.SwitchToAdminPanel,
          });
        }
      }}
    />
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default App;
