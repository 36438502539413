// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import live grouper types
import { GrouperAlgoSettings, StudentGroupAssignment } from 'dce-live-grouper';

// Import shared helper
import genOccurrenceId from '../../../helpers/genOccurrenceId';

// Import shared constants
import TTM_PATH_PREFIX from '../../../constants/TTM_PATH_PREFIX';

// Import shared types
import CreateEventBody from '../../../types/CreateEventBody';
import OccurrenceBasicInfo from '../../../types/OccurrenceBasicInfo';

/**
 * Call the backend to post a new event in a given series,
 *   i.e. inheriting old groups.
 * @author Benedikt Arnarsson
 * @param opts object containing all arguments
 * @param opts.occInfo the basic info for the current occurrence.
 * @param opts.seriesId the ID of the series to post the new event in.
 * @param opts.grouperAlgoSettings the algorithm settings for the new event.
 * @param opts.preAssignments the pre-assignments for the new event.
 */
const postNewEventInSeries = async (
  opts: {
    occInfo: OccurrenceBasicInfo,
    seriesId: number,
    grouperAlgoSettings: GrouperAlgoSettings,
    preAssignments: StudentGroupAssignment[],
  },
): Promise<void> => {
  const {
    occInfo,
    seriesId,
    grouperAlgoSettings,
    preAssignments,
  } = opts;

  const { courseId } = occInfo;

  // Generate occurrence id
  const occId = genOccurrenceId(occInfo);

  // Get eventId by removing courseId
  const eventId = occId.replace(`${courseId}-`, '');

  // Event information
  const createEventBody: CreateEventBody = {
    courseId,
    eventId,
    preAssignments,
    ...grouperAlgoSettings,
    metadata: {
      seriesId,
    },
  };

  // Post new event
  return visitServerEndpoint({
    path: `${TTM_PATH_PREFIX}/grouper/event`,
    method: 'POST',
    params: {
      createEventBody,
    },
  });
};

export default postNewEventInSeries;
