/**
 * UI for setting up the batch creation process
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faInfoCircle,
  faLink,
  faRobot,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LogAction,
  logClientEvent,
} from 'dce-reactkit';

// Import other components
import GoogleSheetInstructionsModal from './GoogleSheetInstructionsModal';

// Import shared components
import BatchCreationFooter from '../shared/BatchCreationFooter';

// Import shared types
import LogMetadata from '../../../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Email address for the Gather robot
  robotEmail: string,
  /**
   * Function to call when done
   * @param googleSheetURL the URL of the Google Sheet to use
   * @param year the calendar year for the courses
   */
  onDone: (
    googleSheetURL: string,
    year: number,
  ) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

const now = new Date();

// Choices of years to choose from
const YEARS = [
  now.getFullYear() - 1, // Last year
  now.getFullYear(), // This year
  now.getFullYear() + 1, // Next year
];

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // The URL of the Google Sheet to use
  googleSheetURL: string,
  // The calendar year for the courses
  year: number,
  // If the Google Sheet instructions modal is visible
  googleSheetInstructionsVisible: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Update the Google Sheet URL
  UpdateGoogleSheetURL = 'UpdateGoogleSheetURL',
  // Update the calendar year
  UpdateYear = 'UpdateYear',
  // Toggle showing the Google Sheet instructions
  ToggleGoogleSheetInstructions = 'ToggleGoogleSheetInstructions',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateGoogleSheetURL,
    // The new Google Sheet URL
    googleSheetURL: string,
  }
  | {
    // Action type
    type: ActionType.UpdateYear,
    // The new year
    year: number,
  }
  | {
    // Action type
    type: (
      | ActionType.ToggleGoogleSheetInstructions
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateGoogleSheetURL: {
      return {
        ...state,
        googleSheetURL: action.googleSheetURL.trim(),
      };
    }
    case ActionType.UpdateYear: {
      return {
        ...state,
        year: Number.parseInt(String(action.year), 10),
      };
    }
    case ActionType.ToggleGoogleSheetInstructions: {
      return {
        ...state,
        googleSheetInstructionsVisible: !state.googleSheetInstructionsVisible,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BatchEventCreatorSetup: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    robotEmail,
    onDone,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    googleSheetURL: '',
    year: YEARS[1],
    googleSheetInstructionsVisible: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    googleSheetURL,
    year,
    googleSheetInstructionsVisible,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      // Log the event
      logClientEvent({
        context: LogMetadata.Context.AdminPanel,
        subcontext: LogMetadata.Context.AdminPanel.BatchEventCreator,
        action: LogAction.Open,
      });
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ---- Google Sheet Instructions --- */

  if (googleSheetInstructionsVisible) {
    // Create modal
    modal = (
      <GoogleSheetInstructionsModal
        onClose={() => {
          dispatch({
            type: ActionType.ToggleGoogleSheetInstructions,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Validate the URL
  const urlInvalid = (
    // URL must exist
    !googleSheetURL
    // URL must have some text
    || googleSheetURL.length === 0
    // URL must be a Google Sheets URL
    || !googleSheetURL.startsWith('https://docs.google.com/spreadsheets/')
    // URL cannot have spaces
    || googleSheetURL.trim().indexOf(' ') >= 0
  );

  // Create the UI
  return (
    <div key="BatchEventCreatorSetup-outer">
      {/*  Add the modal */}
      {modal}

      {/* Content */}
      <div className="text-start">
        {/* Set up spreadsheet */}
        <div className="mb-5">
          {/* Instructions */}
          <h4 className="m-0">
            1. Set Up your Sheet
          </h4>
          Make sure your spreadsheet matches our
          {' '}
          <button
            type="button"
            className="btn btn-secondary btn-sm pt-0 pb-0"
            aria-label="Open google sheet instructions"
            id="BatchEventCreatorSetup-show-google-sheet-instructions"
            onClick={() => {
              // Show the instructions
              dispatch({
                type: ActionType.ToggleGoogleSheetInstructions,
              });
            }}
          >
            Spreadsheet Guide
          </button>
        </div>

        {/* Gather Robot Instructions */}
        <div className="mb-5">
          <h4 className="m-0">
            2. Add Gather Robot
          </h4>
          <div>
            Share your sheet with the Gather robot (as an editor):
          </div>
          <div>

            <FontAwesomeIcon
              icon={faRobot}
              className="text-muted"
            />
            <span
              className="badge bg-light text-muted user-select-all"
              id="BatchEventCreatorSetup-robot-email"
            >
              {robotEmail}
            </span>
          </div>
        </div>

        {/* Spreadsheet link */}
        <div className="mb-5">
          <h4 className="m-0">
            3. Get the Sheet Link
          </h4>
          <div className="mb-2">
            Paste a link to your spreadsheet below:
          </div>
          <div className="input-group mb-5">
            <span className="input-group-text">
              <FontAwesomeIcon
                icon={faLink}
                className="me-2"
              />
              Google Sheet URL
            </span>
            <input
              type="text"
              className="form-control"
              placeholder="e.g. https://docs.google.com/spreadsheets/..."
              aria-label="link to the google sheet containing batch creator information"
              value={googleSheetURL}
              id="BatchEventCreatorSetup-google-sheet-url-field"
              onChange={(e) => {
                dispatch({
                  type: ActionType.UpdateGoogleSheetURL,
                  googleSheetURL: e.target.value,
                });
              }}
            />
          </div>
        </div>

        {/* Calendar Year */}
        <div className="mb-5">
          <h4 className="m-0">
            4. Choose a Calendar Year
          </h4>
          <div className="mb-2">
            During which calendar year
            {' '}
            <strong>
              (NOT the academic year)
            </strong>
            {' '}
            will the courses take place?
          </div>
          <div className="input-group">
            <span className="input-group-text">
              <FontAwesomeIcon
                icon={faCalendarAlt}
                className="me-2"
              />
              Calendar Year
            </span>
            <select
              className="custom-select flex-grow-1"
              value={year}
              id="BatchEventCreatorSetup-calendar-year-chooser"
              onChange={(e) => {
                dispatch({
                  type: ActionType.UpdateYear,
                  year: YEARS[e.target.selectedIndex],
                });
              }}
            >
              {/* Create a list of years for the dropdown */}
              {
                YEARS.map((yearOption) => {
                  return (
                    <option
                      key={yearOption}
                      value={yearOption}
                      id={`BatchEventCreatorSetup-calendar-year-chooser-${yearOption}`}
                    >
                      {yearOption}
                      {' '}
                      Calendar Year
                    </option>
                  );
                })
              }
            </select>
          </div>
        </div>
      </div>

      {/* Footer */}
      <BatchCreationFooter
        continueButton={(
          urlInvalid
            ? undefined
            : {
              onClick: () => {
                // Alert the parent
                onDone(googleSheetURL, year);
              },
            }
        )}
        customContent={urlInvalid && (
          <div
            className="text-danger fw-bold"
            id="BatchEventCreatorSetup-add-valid-url-message"
          >
            <FontAwesomeIcon
              icon={faInfoCircle}
              className="me-2"
            />
            To continue, add a valid Google Sheet URL.
          </div>
        )}
      />
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BatchEventCreatorSetup;
