/**
 * Popup to ask user if they want to host the meeting then directs them
 *   to the proper link
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChalkboardTeacher,
  faUserAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  ModalType,
} from 'dce-reactkit';

// Import shared types
import CourseEvent from '../../../shared/types/from-server/stored/CourseEvent';

// Import shared components
import ClaimAccountNotice from '../../../shared/ClaimAccountNotice';

// Import style
import './ChooseJoinTypeSubpanel.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler to call when user cancels the join
  onCancel: () => void,
  // Id of the course
  courseId: number,
  // The event to join
  event: CourseEvent,
  // If true, user is a learner
  isLearner: boolean,
  // Handler to call when user opts to join the webinar as a panelist
  onJoinAsPanelist: (startURL: string) => void,
  // Handler to call when user opts to join the meeting as a participant
  onJoinAsParticipant: () => void,
  /**
   * Handler to call when user opts to join the meeting as a host
   * @param startURL the url to start the meeting
   */
  onJoinAsHost: (startURL: string) => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, loading
  loading: boolean,
  // If true, showing the participant notice
  showParticipantNotice: boolean,
  // If true, showing the host notice
  showHostNotice: boolean,
  // If true, showing the "claim your account" notice
  showClaimAccountNotice: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show loading indicator
  StartLoading = 'StartLoading',
  // Stop loading indicator
  StopLoading = 'StopLoading',
  // Show participant notice
  ShowParticipantNotice = 'ShowParticipantNotice',
  // Hide participant notice
  HideParticipantNotice = 'HideParticipantNotice',
  // Show host notice
  ShowHostNotice = 'ShowHostNotice',
  // Hide host notice
  HideHostNotice = 'HideHostNotice',
  // Show claim account notice
  ShowClaimAccountNotice = 'ShowClaimAccountNotice',
  // Hide claim account notice
  HideClaimAccountNotice = 'HideClaimAccountNotice',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.StartLoading
      | ActionType.StopLoading
      | ActionType.ShowParticipantNotice
      | ActionType.HideParticipantNotice
      | ActionType.ShowHostNotice
      | ActionType.HideHostNotice
      | ActionType.ShowClaimAccountNotice
      | ActionType.HideClaimAccountNotice
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartLoading: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.StopLoading: {
      return {
        ...state,
        loading: false,
      };
    }
    case ActionType.ShowParticipantNotice: {
      return {
        ...state,
        showParticipantNotice: true,
      };
    }
    case ActionType.HideParticipantNotice: {
      return {
        ...state,
        showParticipantNotice: false,
      };
    }
    case ActionType.ShowHostNotice: {
      return {
        ...state,
        showHostNotice: true,
      };
    }
    case ActionType.HideHostNotice: {
      return {
        ...state,
        showHostNotice: false,
      };
    }
    case ActionType.ShowClaimAccountNotice: {
      return {
        ...state,
        showClaimAccountNotice: true,
        loading: false,
      };
    }
    case ActionType.HideClaimAccountNotice: {
      return {
        ...state,
        showClaimAccountNotice: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ChooseJoinTypeSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    onCancel,
    event,
    isLearner,
    onJoinAsParticipant,
    onJoinAsHost,
    onJoinAsPanelist,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: false,
    showParticipantNotice: false,
    showHostNotice: false,
    showClaimAccountNotice: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    showParticipantNotice,
    showHostNotice,
    showClaimAccountNotice,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Get the startURL for the host
   * @author Gabe Abrams
   */
  const getStartURL = async () => {
    // Start loading
    dispatch({
      type: ActionType.StartLoading,
    });

    // Get the start URL (add as alt-host if needed, log in, get URL)
    try {
      const startURL = await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events/${event.ihid}/start_url`,
        method: 'GET',
      });

      // Alert parent that user wants to join
      if (event.isWebinar) {
        onJoinAsPanelist(startURL);
      } else {
        onJoinAsHost(startURL);
      }
    } catch (err) {
      // Handle user not found error
      if ((err as any).code && (err as any).code === 'ZOOM404-1113') {
        return dispatch({
          type: ActionType.ShowClaimAccountNotice,
        });
      }

      // Handle user not found during ZAK error
      if ((err as any).code && (err as any).code === 'ZOOM404-1001') {
        return dispatch({
          type: ActionType.ShowClaimAccountNotice,
        });
      }

      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ---------- Claim Account --------- */

  if (showClaimAccountNotice) {
    return (
      <Modal
        key="claim-account-modal"
        onClose={onCancel}
        type={ModalType.NoButtons}
      >
        <ClaimAccountNotice
          isLearner={isLearner}
          onTryAgain={() => {
            // Hide notice
            dispatch({
              type: ActionType.HideClaimAccountNotice,
            });

            // Retry getting start link
            getStartURL();
          }}
          onCancel={onCancel}
        />
      </Modal>
    );
  }

  /* --------- Join Link Modal -------- */

  // Body placeholder
  let body: React.ReactNode;

  // Loading
  if (loading) {
    // Loading spinner
    body = (
      <LoadingSpinner />
    );
  } else {
    // Loaded

    // Create startAsParticipant button
    const startAsParticipantButton = (
      <button
        type="button"
        id="ChooseJoinTypeSubpanel-join-as-participant-button"
        className="ChooseJoinTypeSubpanel-participant-button btn btn-dark btn-lg"
        aria-label="continue to Zoom as participant"
        onClick={onJoinAsParticipant}
        onMouseOver={() => {
          dispatch({
            type: ActionType.ShowParticipantNotice,
          });
        }}
        onMouseOut={() => {
          dispatch({
            type: ActionType.HideParticipantNotice,
          });
        }}
        onFocus={() => {
          dispatch({
            type: ActionType.ShowParticipantNotice,
          });
        }}
        onBlur={() => {
          dispatch({
            type: ActionType.HideParticipantNotice,
          });
        }}
      >
        <FontAwesomeIcon
          icon={faUserAlt}
          className="me-2"
        />
        <strong>
          Participant
        </strong>

        {/* Notice for joining as a participant */}
        <span
          className="d-block small"
          style={{
            opacity: (
              showParticipantNotice
                ? 1
                : 0
            ),
            maxHeight: (
              showParticipantNotice
                ? undefined
                : 0
            ),
            overflow: 'hidden',
          }}
        >
          Sign out of the Zoom app first!
        </span>
      </button>
    );

    // Create the body for the modal
    body = (
      <div>
        {/* Choice buttons */}
        <div>
          <div className="row">
            <div className="col d-grid">
              {startAsParticipantButton}
            </div>
            <div className="col d-grid">
              <button
                type="button"
                id={(
                  event.isWebinar
                    ? 'ChooseJoinTypeSubpanel-i-am-the-panelist-button'
                    : 'ChooseJoinTypeSubpanel-i-am-the-host-button'
                )}
                className="ChooseJoinTypeSubpanel-i-am-the-host-button btn btn-dark btn-lg"
                aria-label={`join the ${event.isWebinar ? 'webinar as a panelist' : 'meeting as a host'}`}
                onMouseOver={() => {
                  dispatch({
                    type: ActionType.ShowHostNotice,
                  });
                }}
                onMouseOut={() => {
                  dispatch({
                    type: ActionType.HideHostNotice,
                  });
                }}
                onFocus={() => {
                  dispatch({
                    type: ActionType.ShowHostNotice,
                  });
                }}
                onBlur={() => {
                  dispatch({
                    type: ActionType.HideHostNotice,
                  });
                }}
                onClick={() => {
                  getStartURL();
                }}
              >
                <FontAwesomeIcon
                  icon={faChalkboardTeacher}
                  className="me-2"
                />
                <strong>
                  {event.isWebinar ? 'Panelist' : 'Host'}
                </strong>

                {/* Notice for joining as a host */}
                <span
                  className="d-block small"
                  style={{
                    opacity: (
                      showHostNotice
                        ? 1
                        : 0
                    ),
                    maxHeight: (
                      showHostNotice
                        ? undefined
                        : 0
                    ),
                    overflow: 'hidden',
                  }}
                >
                  {
                    event.isWebinar
                      ? 'You may need to wait for staff to let you in'
                      : 'If not the first host, you\'ll be a co-host'
                  }
                </span>
              </button>
            </div>
          </div>
        </div>

        {/* Note */}
        <div className="text-start mt-3">
          <strong>
            Note:&nbsp;
          </strong>
          Students can only join as participants.
        </div>
      </div>
    );
  }

  // Render full UI
  return (
    <Modal
      key="main-modal"
      title="How do you want to join?"
      onClose={onCancel}
      type={ModalType.NoButtons}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ChooseJoinTypeSubpanel;
