/**
 * UI for creating or editing an event
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import dce-reactkit
import {
  ButtonInputGroup,
  LoadingSpinner,
  Modal,
  ModalType,
  ModalSize,
  RadioButton,
  Variant,
  alert,
  confirm,
  showFatalError,
  visitServerEndpoint,
  logClientEvent,
  LogAction,
  CopiableBox,
} from 'dce-reactkit';

// Import zaccl
import ZoomMeeting from 'zaccl/lib/types/ZoomMeeting';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faPlus,
  faTrash,
  faLock,
  faBan,
  faThumbtack,
} from '@fortawesome/free-solid-svg-icons';

// Import shared types
import CourseEvent from '../../../../shared/types/from-server/stored/CourseEvent';
import CourseEventType from '../../../../shared/types/from-server/stored/shared/CourseEventType';
import ZoomUserAccountStatus from '../../../../shared/types/from-server/ZoomUserAccountStatus';
import LogMetadata from '../../../../shared/types/from-server/LogMetadata';
import UserAndCourseInfo from '../../../../shared/types/UserAndCourseInfo';
import AssignToGroups from '../../../../shared/types/from-server/stored/CourseEvent/AssignToGroups';
import KeyZoomMeetingOrWebinarInfo from '../../../../shared/types/from-server/KeyZoomMeetingOrWebinarInfo';
import ServerErrorCode from '../../../../shared/types/from-server/ServerErrorCode';

// Import shared components
import EventType from './EventType';
import EventName from './EventName';
import ZoomInfo from './ZoomInfo';

// Import shared helpers
import genEmptyEventObj from '../../../../shared/helpers/genEmptyEventObj';
import showThisIsLockedModal from '../../../../shared/helpers/showThisIsLockedModal';
import createRecommendedZoomMeeting from '../../../../shared/helpers/createRecommendedZoomMeeting';

// Import shared constants
import MIN_EVENT_FORM_LABEL_WIDTH from '../../../../shared/constants/MIN_EVENT_FORM_LABEL_WIDTH';

// Import shared components
import ClaimAccountNotice from '../../../../shared/ClaimAccountNotice';

// Import other components
import AttachZoomMeetingModal from './AttachZoomMeetingOrWebinarModal';
import InPersonConfig from './InPersonConfig';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  /**
   * Handler for when the user is finished editing
   * @returns the updated/created/archived event
   */
  onFinish: (event: CourseEvent) => void,
  // Handler for when the user cancels
  onCancel: () => void,
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
  // The event to edit (leave out if creating an event)
  event?: CourseEvent,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Minimum length of an event name
const MIN_EVENT_NAME_LENGTH = 3;

// Maximum length of an event name
const MAX_EVENT_NAME_LENGTH = 50;

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Initial loading screen
  Loading = 'Loading',
  // Editor
  Editor = 'Editor',
}

/* -------- State Definition -------- */

type State = (
  | {
    // View
    view: View.Loading,
  }
  | {
    // View
    view: View.Editor,
    // If true, currently working
    working: boolean,
    // If true, user has not actually filled out the "type" field
    typeNotSelectedYet: boolean,
    // If true, user changed something and changes need to be saved
    userChangedSomething: boolean,
    // If true, the user changed the name
    userChangedName: boolean,
    // If true, admin is manually attaching a zoom meeting via the modal
    adminAttachingZoom: boolean,
    // If true, waiting room is on (defined if held in Zoom)
    waitingRoomOn?: boolean,
    // If true, auto record is on (defined if held in Zoom)
    autoRecordOn?: boolean,
    // The updated copy of the event
    event: CourseEvent,
    // All events in the course
    allEvents: CourseEvent[],
    // If true, currently claiming a Zoom account
    isClaimingZoomAccount: boolean,
    // If true, a Zoom meeting is being created by a TTM
    isCreatingTTMZoomMeeting: boolean,
    // If defined, showing results of resecure and these are the details
    resecureResults?: {
      // New password for the meeting
      password: string,
      // New direct Zoom URL
      joinURL: string,
    },
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish initial load
  FinishLoading = 'FinishLoading',
  // Show working
  ShowWorkingSpinner = 'ShowWorkingSpinner',
  // Hide working spinner
  HideWorkingSpinner = 'HideWorkingSpinner',
  // Update the event name
  UpdateName = 'UpdateName',
  // Update the event type
  UpdateType = 'UpdateType',
  // Show the admin modal to attach a zoom meeting
  ShowAttachZoomModal = 'ShowAttachZoomModal',
  // Cancel the admin modal to attach a zoom meeting
  CancelAttachZoomModal = 'CancelAttachZoomModal',
  // Update event and Zoom info
  UpdateEventAndZoomInfo = 'UpdateEventAndZoomInfo',
  // Update whether waiting room is on
  UpdateWaitingRoom = 'UpdateWaitingRoom',
  // Update whether auto record is on
  UpdateAutoRecord = 'UpdateAutoRecord',
  // Update the event's pinned status
  UpdatePinned = 'UpdatePinned',
  // Update lock settings
  UpdateLocks = 'UpdateLocks',
  // Update ban settings
  UpdateBans = 'UpdateBans',
  // Ask the user to claim their account
  AskUserToClaimAccount = 'AskUserToClaimAccount',
  // Finish claiming an account
  FinishClaimingAccount = 'FinishClaimingAccount',
  // Start create Zoom meeting for TTM
  StartCreateTTMZoomMeeting = 'StartCreateTTMZoomMeeting',
  // Finish create Zoom meeting for TTM
  FinishCreateTTMZoomMeeting = 'FinishCreateTTMZoomMeeting',
  // Show the results of the resecure modal
  ShowResecureResultsModal = 'ShowResecureResultsModal',
  // Hide the results of the resecure modal
  HideResecureResultsModal = 'HideResecureResultsModal',
  // Update whether the event is held in person or not
  UpdateEventIsHeldInPerson = 'UpdateEventIsHeldInPerson',
  // Update default grouping behavior for in person events
  UpdateAssignToGroups = 'UpdateAssignToGroups',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // If true, the user has to fill out the "type" field
    typeNotSelectedYet: boolean,
    // If true, auto record is on (defined if held in Zoom)
    autoRecordOn?: boolean,
    // If true, waiting room is on (defined if held in Zoom)
    waitingRoomOn?: boolean,
    // The version of the event to edit
    event: CourseEvent,
    // All events in the course
    allEvents: CourseEvent[],
    // If true, the event is starting already with modifications
    startsWithChanges: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateName,
    // New name
    name: string,
  }
  | {
    // Action type
    type: ActionType.UpdateType,
    // New type
    eventType: CourseEventType,
  }
  | {
    // Action type
    type: ActionType.UpdateAutoRecord,
    // New auto record value
    autoRecordOn: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateWaitingRoom,
    // New waiting room value
    waitingRoomOn: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdatePinned,
    // New pinned value
    pinned: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateLocks,
    // New lock settings
    lockAutoRecordSetting: boolean,
    lockZoomToggle: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateBans,
    // New ban settings
    banDCEStudents: boolean,
    banFASStudents: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateEventAndZoomInfo,
    // Updated event
    event: CourseEvent,
    // If true, waiting room is on
    waitingRoomOn?: boolean,
    // If true, auto record is on
    autoRecordOn?: boolean,
  }
  | {
    // Action type
    type: ActionType.ShowResecureResultsModal,
    // Results
    results: {
      // New password for the meeting
      password: string,
      // New direct Zoom URL
      joinURL: string,
    },
  }
  | {
    // Action type
    type: ActionType.UpdateEventIsHeldInPerson,
    // Whether the event is held in person
    isHeldInPerson: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateAssignToGroups,
    // Whether to always, never, or ask to assign to groups
    assignToGroups: AssignToGroups,
  }
  | {
    // Action type
    type: (
      | ActionType.ShowWorkingSpinner
      | ActionType.HideWorkingSpinner
      | ActionType.ShowAttachZoomModal
      | ActionType.CancelAttachZoomModal
      | ActionType.AskUserToClaimAccount
      | ActionType.FinishClaimingAccount
      | ActionType.StartCreateTTMZoomMeeting
      | ActionType.FinishCreateTTMZoomMeeting
      | ActionType.HideResecureResultsModal
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  /* ------------- Loading ------------ */

  if (state.view === View.Loading) {
    switch (action.type) {
      case ActionType.FinishLoading: {
        return {
          view: View.Editor,
          working: false,
          typeNotSelectedYet: action.typeNotSelectedYet,
          userChangedSomething: action.startsWithChanges,
          userChangedName: false,
          adminAttachingZoom: false,
          waitingRoomOn: action.waitingRoomOn,
          autoRecordOn: action.autoRecordOn,
          event: action.event,
          allEvents: action.allEvents,
          isClaimingZoomAccount: false,
          isCreatingTTMZoomMeeting: false,
        };
      }
      default: {
        return state;
      }
    }
  }

  /* ------------- Editor ------------- */

  switch (action.type) {
    case ActionType.ShowWorkingSpinner: {
      return {
        ...state,
        working: true,
      };
    }
    case ActionType.HideWorkingSpinner: {
      return {
        ...state,
        working: false,
      };
    }
    case ActionType.UpdateName: {
      return {
        ...state,
        userChangedName: true,
        userChangedSomething: true,
        event: {
          ...state.event,
          name: action.name,
        },
      };
    }
    case ActionType.UpdateType: {
      return {
        ...state,
        typeNotSelectedYet: false,
        userChangedSomething: true,
        event: {
          ...state.event,
          type: action.eventType,
        },
      };
    }
    case ActionType.UpdateAutoRecord: {
      return {
        ...state,
        userChangedSomething: true,
        autoRecordOn: action.autoRecordOn,
      };
    }
    case ActionType.UpdateWaitingRoom: {
      return {
        ...state,
        userChangedSomething: true,
        waitingRoomOn: action.waitingRoomOn,
      };
    }
    case ActionType.UpdatePinned: {
      return {
        ...state,
        userChangedSomething: true,
        event: {
          ...state.event,
          pinned: action.pinned,
        },
      };
    }
    case ActionType.UpdateLocks: {
      return {
        ...state,
        userChangedSomething: true,
        event: {
          ...state.event,
          lockAutoRecordSetting: action.lockAutoRecordSetting,
          lockZoomToggle: action.lockZoomToggle,
        },
      };
    }
    case ActionType.UpdateBans: {
      return {
        ...state,
        userChangedSomething: true,
        event: {
          ...state.event,
          banDCEStudents: action.banDCEStudents,
          banFASStudents: action.banFASStudents,
        },
      };
    }
    case ActionType.ShowAttachZoomModal: {
      return {
        ...state,
        adminAttachingZoom: true,
      };
    }
    case ActionType.CancelAttachZoomModal: {
      return {
        ...state,
        adminAttachingZoom: false,
      };
    }
    case ActionType.UpdateEventAndZoomInfo: {
      // Update state
      return {
        ...state,
        userChangedSomething: true,
        adminAttachingZoom: false,
        autoRecordOn: !!action.autoRecordOn,
        waitingRoomOn: !!action.waitingRoomOn,
        event: action.event,
      };
    }
    case ActionType.AskUserToClaimAccount: {
      return {
        ...state,
        isClaimingZoomAccount: true,
      };
    }
    case ActionType.FinishClaimingAccount: {
      return {
        ...state,
        isClaimingZoomAccount: false,
      };
    }
    case ActionType.StartCreateTTMZoomMeeting: {
      return {
        ...state,
        isCreatingTTMZoomMeeting: true,
        userChangedSomething: true,
      };
    }
    case ActionType.FinishCreateTTMZoomMeeting: {
      return {
        ...state,
        isCreatingTTMZoomMeeting: false,
        userChangedSomething: true,
      };
    }
    case ActionType.ShowResecureResultsModal: {
      return {
        ...state,
        userChangedSomething: true,
        resecureResults: action.results,
      };
    }
    case ActionType.HideResecureResultsModal: {
      return {
        ...state,
        resecureResults: undefined,
      };
    }
    case ActionType.UpdateEventIsHeldInPerson: {
      if (action.isHeldInPerson) {
        return {
          ...state,
          event: {
            ...state.event,
            isHeldInPerson: action.isHeldInPerson,
            inPersonConfig: {
              assignToGroups: AssignToGroups.Never,
            },
          },
        };
      }
      return {
        ...state,
        event: {
          ...state.event,
          isHeldInPerson: action.isHeldInPerson,
          inPersonConfig: undefined,
        },
      };
    }
    case ActionType.UpdateAssignToGroups: {
      if (!state.event.isHeldInPerson) {
        return state;
      }
      return {
        ...state,
        event: {
          ...state.event,
          inPersonConfig: {
            assignToGroups: action.assignToGroups,
          },
        },
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* --------------------------- Static Helpers --------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Remove Zoom info from an event object (switch to not held in Zoom,
 *   but don't update past Zoom info: that should be handled separately)
 * @author Gabe Abrams
 * @param event the event to remove Zoom info from
 * @returns the event with Zoom info removed
 */
const deleteZoomInfoFromEvent = (event: CourseEvent): CourseEvent => {
  return {
    ...event,
    currentZoomId: null,
    currentZoomHost: null,
    openZoomLink: null,
    isWebinar: null,
  };
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CreateEditEventSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onFinish,
    onCancel,
    userAndCourseInfo,
  } = props;

  // Destructure user and course info
  const {
    courseId,
    courseName,
    termName,
    school,
    isAdmin,
  } = userAndCourseInfo;

  // Create handy variables based on props
  const { event: originalEvent } = props;
  const isCreating = !originalEvent;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Handle an unclaimed Zoom user account status
   * @author Gabe Abrams
   */
  const handleUnclaimed = async () => {
    return alert(
      'Ask User to Claim Zoom Account',
      'Before you can create Zoom meetings on behalf of this user, they need to claim their Zoom account. Ask the user to claim their Zoom account, then try again.',
    );
  };

  /**
   * Detach Zoom and clean up metadata. Then, if newZoomInfo is included, add
   *   that info to the event and update state.
   *   If using to remove Zoom, just call with no arguments.
   *   If using to add Zoom, call with the newZoomInfo and isWebinar.
   *   If using to update Zoom, call with the newZoomInfo and isWebinar.
   * @author Gabe Abrams
   * @param opts object containing all arguments
   * @param [opts.newZoomInfo] the info about the new
   *   Zoom meeting
   *   or webinar to attach. If excluded, not adding a meeting
   * @param [opts.isWebinar] if true, the new Zoom space is a webinar
   * @param [opts.removingOrSwapping] if true, we're removing or swapping the
   * @returns event updated with space for a new Zoom meeting or webinar to be
   *   attached
   */
  const updateZoomInEvent = (
    opts: {
      newZoomInfo?: KeyZoomMeetingOrWebinarInfo,
      isWebinar?: boolean,
    },
  ) => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }
    let {
      event,
    } = state;

    /* --------- Account Issues --------- */

    // If needed, go to the "claim" or "ineligible" page
    if (
      opts.newZoomInfo
      && opts.newZoomInfo.status !== ZoomUserAccountStatus.Claimed
    ) {
      // Handle unclaimed
      if (opts.newZoomInfo.status === ZoomUserAccountStatus.NeedsToClaim) {
        // Show unclaimed
        handleUnclaimed();
      }
      // Other...just exit

      // Error occurred. Stop attaching if we were
      dispatch({
        type: ActionType.CancelAttachZoomModal,
      });
      return;
    }

    /* ------------- Remove ------------- */

    // Add current zoom id to past if it's changing
    if (
      // There's a meeting to add to the past
      event.currentZoomId
      // The new meeting is different
      && (opts.newZoomInfo?.zoomId !== event.currentZoomId)
    ) {
      // Check if pastZoomIds already includes the current zoom id
      const pastAlreadyIncludesCurrent = (
        event.pastZoomIds || []
      ).includes(event.currentZoomId);
      if (!pastAlreadyIncludesCurrent) {
        event.pastZoomIds = [
          event.currentZoomId,
          ...(event.pastZoomIds || []),
        ];
      }
    }

    // Add current host to past
    if (
      // There's a host to add to the past
      event.currentZoomHost
      // The new host is different
      && (opts.newZoomInfo?.hostId !== event.currentZoomHost)
    ) {
      // Check if pastZoomHosts already includes the current zoom host
      const pastAlreadyIncludesCurrentHost = (
        event.pastZoomHosts || []
      ).includes(event.currentZoomHost);
      if (!pastAlreadyIncludesCurrentHost) {
        event.pastZoomHosts = [
          event.currentZoomHost,
          ...(event.pastZoomHosts || []),
        ];
      }
    }

    // Remove current info
    event = deleteZoomInfoFromEvent(event);

    // If nothing to add in, we're done
    if (!opts.newZoomInfo) {
      // Save state
      dispatch({
        type: ActionType.UpdateEventAndZoomInfo,
        event,
        waitingRoomOn: undefined,
        autoRecordOn: undefined,
      });

      return;
    }

    /* ------------ Add Zoom ------------ */

    // Add info
    event.currentZoomId = opts.newZoomInfo.zoomId;
    event.currentZoomHost = opts.newZoomInfo.hostId;
    event.openZoomLink = opts.newZoomInfo.joinURL;
    event.isWebinar = !!opts.isWebinar;

    // Remove current zoom id from past
    event.pastZoomIds = (event.pastZoomIds || []).filter((id) => {
      return (id !== event.currentZoomId);
    });

    // Remove current zoom host from past
    event.pastZoomHosts = (event.pastZoomHosts || []).filter((host) => {
      return (host !== event.currentZoomHost);
    });

    // Save state
    dispatch({
      type: ActionType.UpdateEventAndZoomInfo,
      event,
      waitingRoomOn: !!opts.newZoomInfo?.waitingRoomOn,
      autoRecordOn: !!opts.newZoomInfo?.autoRecordOn,
    });
  };

  /**
   * Reimport and maybe re-secure meeting
   * @author Gabe Abrams
   * @param resecure if true, re-secure the meeting as well
   */
  const refreshMeeting = async (resecure: boolean) => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }
    const {
      event,
    } = state;

    // Skip if not held in Zoom
    if (!event.currentZoomId) {
      return;
    }

    // Skip if not a meeting
    if (event.isWebinar) {
      return;
    }

    // Start working indicator
    dispatch({
      type: ActionType.ShowWorkingSpinner,
    });

    try {
      // Request refresh and re-secure
      const meetingInfo: KeyZoomMeetingOrWebinarInfo = (
        await visitServerEndpoint({
          path: `/api/ttm/courses/${courseId}/meetings/${event.currentZoomId}/refresh`,
          method: 'PUT',
          params: {
            resecure,
          },
        })
      );

      // Update Zoom info in event
      await updateZoomInEvent({
        newZoomInfo: meetingInfo,
        isWebinar: false,
      });

      // Done working
      dispatch({
        type: ActionType.HideWorkingSpinner,
      });

      // Alert user
      if (meetingInfo.status === ZoomUserAccountStatus.Claimed) {
        if (resecure) {
          // Show the results
          dispatch({
            type: ActionType.ShowResecureResultsModal,
            results: {
              password: meetingInfo.password,
              joinURL: meetingInfo.joinURL,
            },
          });
        } else {
          await alert(
            'Reimport Successful',
            'We successfully re-imported the latest meeting info from Zoom.',
          );
        }
      } else {
        throw new Error('Zoom meeting status is not claimed');
      }
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Update Zoom meeting
   * @author Gabe Abrams
   */
  const sendChangesToZoomMeetingToZoom = async () => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }
    const {
      event,
      waitingRoomOn,
      autoRecordOn,
    } = state;

    // Skip if not held in Zoom
    if (!event.currentZoomId) {
      return;
    }

    // Skip if not a meeting
    if (event.isWebinar) {
      return;
    }

    try {
      // Send update to Zoom
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/meetings/${event.currentZoomId}`,
        method: 'PUT',
        params: {
          waitingRoomOn,
          autoRecordOn,
        },
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Save event changes
   * @author Gabe Abrams
   */
  const saveEvent = async () => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }
    const {
      event,
      userChangedSomething,
    } = state;

    // Show working indicator
    dispatch({
      type: ActionType.ShowWorkingSpinner,
    });

    // Save Zoom changes if applicable
    await sendChangesToZoomMeetingToZoom();

    // Save the event
    try {
      // Save via the ttm API
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'POST',
        params: {
          event,
        },
      });

      // Log changes
      if (userChangedSomething) {
        if (isCreating) {
          // Create
          logClientEvent({
            context: LogMetadata.Context.Home,
            subcontext: LogMetadata.Context.Home.CreateEditEventSubpanel,
            action: LogAction.Create,
            target: LogMetadata.Target.CourseEvent,
            metadata: {
              new: event,
            },
          });
        } else {
          // Destructure
          const {
            event: eventBefore,
          } = props;

          // Edit
          logClientEvent({
            context: LogMetadata.Context.Home,
            subcontext: LogMetadata.Context.Home.CreateEditEventSubpanel,
            action: LogAction.Modify,
            target: LogMetadata.Target.CourseEvent,
            metadata: {
              before: eventBefore,
              after: event,
            },
          });
        }
      }

      // Finish
      onFinish(event);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Archive the event and close the modal
   * @author Gabe Abrams
   */
  const archiveEvent = async () => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }
    const {
      event,
    } = state;

    // Skip if already archived
    if (event.archived) {
      return;
    }

    // Make sure the user is allowed to archive
    if (
      // User is not an admin
      !isAdmin
      // Event is locked
      && (event.lockAutoRecordSetting || event.lockZoomToggle)
    ) {
      return showThisIsLockedModal();
    }

    // Archive event
    event.archived = true;

    // Save the event
    try {
      // Save via the ttm API
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/events`,
        method: 'POST',
        params: {
          event,
        },
      });

      // Log
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.CreateEditEventSubpanel,
        action: LogAction.Delete,
        target: LogMetadata.Target.CourseEvent,
        metadata: {
          event,
        },
      });

      // Finish
      onFinish(event);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Handle cancel click
   * @author Gabe Abrams
   */
  const requestToCancel = async () => {
    if (view === View.Editor && state.userChangedSomething) {
      // Ask user to confirm
      const confirmed = await confirm(
        'Abandon Changes?',
        'Are you sure you want to abandon your changes?',
        {
          confirmButtonText: 'Abandon Changes',
          confirmButtonVariant: Variant.Warning,
          cancelButtonText: 'Cancel',
          cancelButtonVariant: Variant.Secondary,
        },
      );

      // Exit if not confirmed
      if (!confirmed) {
        return;
      }
    }

    // Just cancel
    onCancel();
  };

  /**
   * Attach a Zoom meeting/webinar to the event
   * @author Gabe Abrams
   * @param zoomInfo zoom meeting or webinar info
   * @para isWebinar if true, the zoom info is for a webinar
   */
  const attachZoom = async (
    newZoomInfo: KeyZoomMeetingOrWebinarInfo,
    isWebinar: boolean,
  ) => {
    updateZoomInEvent({
      newZoomInfo,
      isWebinar,
    });
  };

  /**
   * Create a recommended Zoom meeting for a TTM user
   * @author Gabe Abrams
   */
  const createZoomMeetingForTTM = async () => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }
    const {
      event,
      waitingRoomOn,
      autoRecordOn,
    } = state;

    // Show creating indicator
    dispatch({
      type: ActionType.StartCreateTTMZoomMeeting,
    });

    // Create the Zoom meeting
    try {
      const keyZoomMeetingOrWebinarInfo = await createRecommendedZoomMeeting({
        courseId,
        courseName,
        school,
        eventName: event.name,
        termName,
        autoRecordOn,
        waitingRoomOn,
      });

      // Hide creating indicator
      dispatch({
        type: ActionType.FinishCreateTTMZoomMeeting,
      });

      // If the user needs to claim their account, halt here
      if (
        keyZoomMeetingOrWebinarInfo.status
        === ZoomUserAccountStatus.NeedsToClaim
      ) {
        return dispatch({
          type: ActionType.AskUserToClaimAccount,
        });
      }

      // Attach
      attachZoom(keyZoomMeetingOrWebinarInfo, false);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount: perform initial load and preparation
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Load general info
        let allEvents: CourseEvent[] = [];
        let eventToEdit: CourseEvent;
        try {
          // Load list of other events
          allEvents = await visitServerEndpoint({
            path: `/api/ttm/courses/${courseId}/events`,
            method: 'GET',
          });

          // Log opening
          const metadata: {
            [k: string]: any,
          } = {};
          if (originalEvent) {
            metadata.ihid = originalEvent.ihid;
          }
          logClientEvent({
            context: LogMetadata.Context.Home,
            subcontext: LogMetadata.Context.Home.CreateEditEventSubpanel,
            action: LogAction.Open,
            metadata,
          });

          // Determine which event to edit
          eventToEdit = (
            originalEvent
            ?? genEmptyEventObj(courseId, allEvents)
          );
        } catch (err) {
          return showFatalError(err);
        }

        // If needed, fetch Zoom settings
        // Load the Zoom settings if held in Zoom meeting
        let startsWithChanges = false;
        let waitingRoomOn: boolean | undefined;
        let autoRecordOn: boolean | undefined;
        if (
          // Editing
          !isCreating
          // Held in Zoom
          && eventToEdit.currentZoomId
          // Meeting
          && !eventToEdit.isWebinar
        ) {
          try {
            // Load Zoom meeting object
            const zoomMeeting: ZoomMeeting = await visitServerEndpoint({
              path: `/api/ttm/courses/${courseId}/meetings/${eventToEdit.currentZoomId}`,
              method: 'GET',
            });

            // Save state to variables
            waitingRoomOn = !!zoomMeeting.settings.waiting_room;
            autoRecordOn = (zoomMeeting.settings.auto_recording === 'cloud');
          } catch (err) {
            // If meeting was deleted, switch to "not held in Zoom"
            if ((err as any)?.code === ServerErrorCode.MeetingNotFound) {
              // Destructively remove Zoom info from event
              eventToEdit = deleteZoomInfoFromEvent(eventToEdit);
              startsWithChanges = true;

              // Alert user
              await alert(
                'Meeting Deleted',
                'The Zoom meeting associated with this event was deleted. This event has been updated and is no longer held in Zoom. Remember to save the changes.',
              );
            } else {
              // Show other errors
              return showFatalError(err);
            }
          }
        }

        // Finish loading
        dispatch({
          type: ActionType.FinishLoading,
          typeNotSelectedYet: isCreating,
          event: eventToEdit,
          waitingRoomOn,
          autoRecordOn,
          allEvents,
          startsWithChanges,
        });
      })();
    },
    [],
  );

  /**
   * Unmount: log closing event
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Get metadata
        const metadata: {
          [k: string]: any,
        } = {};
        if (state.view === View.Editor && state.event) {
          metadata.ihid = state.event.ihid;
        }

        // Log closing
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.CreateEditEventSubpanel,
          action: LogAction.Close,
          target: LogMetadata.Target.CourseEvent,
          metadata,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ----------- Secondary Modal ---------- */
  /*----------------------------------------*/

  // Modal that shows up on top of current modal
  let secondaryModal: React.ReactNode;

  /* -- Claim Account Notice for TTMs - */

  if (view === View.Editor && state.isClaimingZoomAccount) {
    secondaryModal = (
      <Modal
        key="claim-account"
        type={ModalType.NoButtons}
        onClose={onCancel}
      >
        <ClaimAccountNotice
          isLearner={false}
          onTryAgain={() => {
            // Hide modal
            dispatch({
              type: ActionType.FinishClaimingAccount,
            });

            // Try again
            createZoomMeetingForTTM();
          }}
          onCancel={onCancel}
        />
      </Modal>
    );
  }

  /* ------- Attach Zoom Meeting ------ */

  if (view === View.Editor && state.adminAttachingZoom) {
    const {
      event,
      allEvents,
    } = state;

    secondaryModal = (
      <AttachZoomMeetingModal
        courseId={courseId}
        courseName={courseName}
        termName={termName}
        school={school}
        onCancel={() => {
          dispatch({
            type: ActionType.CancelAttachZoomModal,
          });
        }}
        onDone={(zoomInfo) => {
          attachZoom(zoomInfo, false);
        }}
        eventName={event.name}
        otherEvents={allEvents}
      />
    );
  }

  /* -------- Resecure Results -------- */

  if (view === View.Editor && state.resecureResults) {
    const {
      resecureResults,
    } = state;

    secondaryModal = (
      <Modal
        title="Resecured Successfully!"
        type={ModalType.Okay}
        onClose={() => {
          dispatch({
            type: ActionType.HideResecureResultsModal,
          });
        }}
      >
        <div className="mb-2">
          The meeting has been fully resecured and all shareable and breakout
          links have been updated. If you think any breakthrough links have been
          compromised, visit the admin panel to delete them.
        </div>
        <div className="d-grid gap-2">
          <CopiableBox
            label="New Password"
            text={resecureResults.password}
          />
          <CopiableBox
            label="New Zoom Link"
            text={resecureResults.joinURL}
          />
        </div>
      </Modal>
    );
  }

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------- Editor ------------- */

  if (view === View.Editor) {
    // Destructure state
    const {
      working,
      typeNotSelectedYet,
      event,
      autoRecordOn,
      waitingRoomOn,
      isCreatingTTMZoomMeeting,
    } = state;

    // Working
    if (working) {
      body = (
        <LoadingSpinner />
      );
    } else {
      /* ----------- Event Form ----------- */

      // List of elements in the form
      const eventForm = [];

      // Name
      eventForm.push(
        <EventName
          key="name"
          name={event.name}
          onChange={(name) => {
            dispatch({
              type: ActionType.UpdateName,
              name,
            });
          }}
        />,
      );

      // Type
      eventForm.push(
        <EventType
          key="type"
          type={(
            typeNotSelectedYet
              ? undefined
              : event.type
          )}
          onChange={(type) => {
            dispatch({
              type: ActionType.UpdateType,
              eventType: type,
            });
          }}
        />,
      );

      // Zoom
      const revealInAdminPanelURL = (
        (isAdmin && event.currentZoomId)
          ? `https://harvard.zoom.us/user/${event.currentZoomHost}/${event.isWebinar ? 'webinar' : 'meeting'}/${event.currentZoomId}`
          : undefined
      );

      eventForm.push(
        <ZoomInfo
          key="zoom-meeting"
          currentZoomId={event.currentZoomId}
          revealInAdminPanelURL={revealInAdminPanelURL}
          isLoading={isCreatingTTMZoomMeeting}
          onResecureRequested={() => {
            refreshMeeting(true);
          }}
          onReimportRequested={() => {
            refreshMeeting(false);
          }}
          onAttach={async () => {
            // Don't continue if the name isn't filled out
            if (event.name.trim().length < MIN_EVENT_NAME_LENGTH) {
              return alert(
                'Add an Event Name First',
                `The event name must be at least ${MIN_EVENT_NAME_LENGTH} chars long before you can add Zoom.`,
              );
            }

            // Immediately attach if not admin
            if (!isAdmin) {
              return createZoomMeetingForTTM();
            }

            // This user is an admin! Open the zoom attach modal
            dispatch({
              type: ActionType.ShowAttachZoomModal,
            });
          }}
          onDetach={async () => {
            // Ask user to confirm
            const confirmed = await confirm(
              'Remove Zoom?',
              'Are you sure you want to remove Zoom from this event?',
              {
                confirmButtonText: 'Remove Zoom',
                confirmButtonVariant: Variant.Danger,
                cancelButtonText: 'Cancel',
                cancelButtonVariant: Variant.Secondary,
              },
            );
            if (!confirmed) {
              return;
            }

            // Detach
            updateZoomInEvent({});
          }}
          lockZoomToggle={!isAdmin && event.lockZoomToggle}
          lockAutoRecordSetting={!isAdmin && event.lockAutoRecordSetting}
          autoRecordOn={!!autoRecordOn}
          onAutoRecordChanged={(newAutoRecordOn) => {
            dispatch({
              type: ActionType.UpdateAutoRecord,
              autoRecordOn: newAutoRecordOn,
            });
          }}
          waitingRoomOn={!!waitingRoomOn}
          isWebinar={!!event.isWebinar}
          onWaitingRoomChanged={(newWaitingRoomOn) => {
            dispatch({
              type: ActionType.UpdateWaitingRoom,
              waitingRoomOn: newWaitingRoomOn,
            });
          }}
        />,
      );

      // In-Person configuration
      eventForm.push(
        <InPersonConfig
          key="in-person-config"
          eventIsHeldInPerson={event.isHeldInPerson}
          setEventIsHeldInPerson={(eventIsHeldInPerson) => {
            dispatch({
              type: ActionType.UpdateEventIsHeldInPerson,
              isHeldInPerson: eventIsHeldInPerson,
            });
          }}
          assignToGroups={event.inPersonConfig?.assignToGroups}
          setAssignToGroups={(assignToGroups) => {
            dispatch({
              type: ActionType.UpdateAssignToGroups,
              assignToGroups,
            });
          }}
        />,
      );

      // Locks
      if (isAdmin) {
        // Determine if anything is locked
        const somethingIsLocked = (
          event.lockAutoRecordSetting
          || event.lockZoomToggle
        );

        // Render
        eventForm.push(
          <div key="locks">
            <ButtonInputGroup
              label={(
                <span>
                  <FontAwesomeIcon
                    icon={faLock}
                    className="me-1"
                  />
                  Locks
                </span>
              )}
              minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
              wrapButtonsAndAddGaps
              noMarginOnBottom
              isAdminFeature
            >
              {/* Unlock button */}
              <RadioButton
                text="No Locks"
                id="CreateEditEventSubpanel-unlock-event"
                ariaLabel="no locks"
                selected={!somethingIsLocked}
                selectedVariant={Variant.Warning}
                small
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateLocks,
                    lockAutoRecordSetting: false,
                    lockZoomToggle: false,
                  });
                }}
              />

              {/* Lock Zoom toggle */}
              <RadioButton
                text="Lock Zoom Toggle"
                selected={(
                  event.lockZoomToggle
                  && !event.lockAutoRecordSetting
                )}
                id="CreateEditEventSubpanel-lock-zoom-toggle"
                ariaLabel="lock Zoom toggle"
                selectedVariant={Variant.Warning}
                small
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateLocks,
                    lockAutoRecordSetting: false,
                    lockZoomToggle: true,
                  });
                }}
              />

              {/* No Zoom toggle or Zoom auto record edits */}
              <RadioButton
                text="Lock Auto Record and Zoom Toggle"
                id="CreateEditEventSubpanel-lock-auto-record-and-toggle"
                selected={(
                  event.lockAutoRecordSetting
                  && event.lockZoomToggle
                )}
                selectedVariant={Variant.Warning}
                small
                ariaLabel="lock auto record and zoom toggle"
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateLocks,
                    lockAutoRecordSetting: true,
                    lockZoomToggle: true,
                  });
                }}
              />
            </ButtonInputGroup>

            {/* Explanation of "Lock" */}
            <div className="text-start text-muted small mb-3">
              Locks apply only to non-admins.
              All locks also prevent the event from being deleted.
            </div>
          </div>,
        );
      }

      // Bans
      if (isAdmin) {
        eventForm.push(
          <div key="bans">
            <ButtonInputGroup
              label={(
                <span>
                  <FontAwesomeIcon
                    icon={faBan}
                    className="me-1"
                  />
                  Bans
                </span>
              )}
              minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
              wrapButtonsAndAddGaps
              noMarginOnBottom
              isAdminFeature
            >
              {/* No Ban */}
              <RadioButton
                text="Nobody Banned"
                id="CreateEditEventSubpanel-no-ban"
                ariaLabel="nobody banned"
                selectedVariant={Variant.Warning}
                small
                selected={(
                  !event.banDCEStudents
                  && !event.banFASStudents
                )}
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateBans,
                    banDCEStudents: false,
                    banFASStudents: false,
                  });
                }}
              />

              {/* Ban DCE Students */}
              <RadioButton
                text="Ban DCE Students"
                id="CreateEditEventSubpanel-ban-dce"
                ariaLabel="ban DCE students"
                selectedVariant={Variant.Warning}
                small
                selected={(
                  event.banDCEStudents
                  && !event.banFASStudents
                )}
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateBans,
                    banDCEStudents: true,
                    banFASStudents: false,
                  });
                }}
              />

              {/* Ban FAS Students */}
              <RadioButton
                text="Ban FAS Students"
                id="CreateEditEventSubpanel-ban-fas"
                ariaLabel="ban FAS students"
                selectedVariant={Variant.Warning}
                small
                selected={(
                  event.banFASStudents
                  && !event.banDCEStudents
                )}
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateBans,
                    banDCEStudents: false,
                    banFASStudents: true,
                  });
                }}
              />

              {/* Ban both */}
              <RadioButton
                text="Ban DCE + FAS Students"
                id="CreateEditEventSubpanel-ban-both"
                ariaLabel="ban both FAS and DCE students"
                selectedVariant={Variant.Warning}
                small
                selected={(
                  event.banDCEStudents
                  && event.banFASStudents
                )}
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdateBans,
                    banDCEStudents: true,
                    banFASStudents: true,
                  });
                }}
              />
            </ButtonInputGroup>

            {/* Explanation of "Lock" */}
            <div className="text-start text-muted small mb-3">
              When someone is banned from an event,
              the event doesn&apos;t even show up in their list.
            </div>
          </div>,
        );
      }

      // Pin to top
      if (isAdmin) {
        eventForm.push(
          <div key="pin">
            <ButtonInputGroup
              label={(
                <span>
                  <FontAwesomeIcon
                    icon={faThumbtack}
                    className="me-1"
                  />
                  Pin
                </span>
              )}
              minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
              wrapButtonsAndAddGaps
              noMarginOnBottom
              isAdminFeature
            >
              {/* Pinned to Top */}
              <RadioButton
                text="Pinned to Top"
                id="CreateEditEventSubpanel-pin-to-top"
                ariaLabel="pinned to top"
                selectedVariant={Variant.Warning}
                small
                selected={!!event.pinned}
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdatePinned,
                    pinned: true,
                  });
                }}
              />

              {/* Not Pinned */}
              <RadioButton
                text="Not Pinned"
                id="CreateEditEventSubpanel-unpin"
                ariaLabel="not pinned to top"
                selectedVariant={Variant.Warning}
                small
                selected={!event.pinned}
                onSelected={() => {
                  dispatch({
                    type: ActionType.UpdatePinned,
                    pinned: false,
                  });
                }}
              />
            </ButtonInputGroup>
          </div>,
        );
      }

      /* -------- Put Form Together ------- */

      body = (
        <div className="mt-1">
          {/* Entire event form */}
          {eventForm}

          {/* Save/Cancel buttons */}
          <div className="text-center mt-4">
            {/* Save/Create button */}
            <button
              id="CreateEditEventSubpanel-save-or-create-button"
              type="button"
              className="btn btn-lg btn-dark me-2"
              aria-label="save changes"
              onClick={async () => {
                // Validate the event
                // > Name
                if (event.name.trim().length < MIN_EVENT_NAME_LENGTH) {
                  // Name too short
                  return alert(
                    'Name Too Short',
                    `The event name must be at least ${MIN_EVENT_NAME_LENGTH} chars long.`,
                  );
                }
                if (event.name.trim().length > MAX_EVENT_NAME_LENGTH) {
                  // Name too long
                  return alert(
                    'Name Too Long',
                    `The event name can't be longer than ${MAX_EVENT_NAME_LENGTH} chars.`,
                  );
                }
                // > Type
                if (typeNotSelectedYet) {
                  // No type chosen
                  return alert(
                    'Choose an Event Type',
                    'Please choose an event type before saving.',
                  );
                }

                // Confirm if not held anywhere
                if (
                  // No zoom
                  !event.currentZoomId
                  // Not held in person
                  && !event.inPersonConfig
                ) {
                  // Alert user of invalid event
                  await alert(
                    'Where is this event held?',
                    'Please indicate whether this event is held in Zoom and/or in-person.',
                  );
                  return;
                }

                // All ready to save! Continue
                await saveEvent();
              }}
            >
              {/* Dynamic icon based on task */}
              <FontAwesomeIcon
                icon={isCreating ? faPlus : faSave}
                className="me-2"
              />

              {/* Dynamic text based on task */}
              {isCreating ? 'Create' : 'Save'}
            </button>

            {/* Delete button */}
            {!isCreating && (
              <button
                id="CreateEditEventSubpanel-delete-button"
                type="button"
                className="btn btn-secondary btn-lg me-2 position-relative"
                aria-label="delete event"
                onClick={async () => {
                  // Check if something is locked
                  const somethingIsLocked = (
                    event.lockAutoRecordSetting
                    || event.lockZoomToggle
                  );

                  // Show warning if locked
                  if (somethingIsLocked && !isAdmin) {
                    return showThisIsLockedModal();
                  }

                  // Ask user to confirm
                  const confirmed = await confirm(
                    'Confirm Permanent Delete',
                    'Once you delete this event, it cannot be undone.',
                    {
                      confirmButtonText: 'Delete Event',
                      confirmButtonVariant: Variant.Danger,
                      cancelButtonText: 'Cancel',
                      cancelButtonVariant: Variant.Secondary,
                    },
                  );

                  // Exit if not confirmed
                  if (!confirmed) {
                    return;
                  }

                  // Archive the event
                  archiveEvent();
                }}
              >
                <FontAwesomeIcon icon={faTrash} />
                <span className="d-none d-md-inline ms-2">
                  Delete
                </span>
              </button>
            )}

            {/*  Cancel button */}
            <button
              id="CreateEditEventSubpanel-cancel-button"
              type="button"
              className="btn btn-lg btn-secondary"
              aria-label="cancel and return to event list"
              onClick={requestToCancel}
            >
              Cancel
            </button>
          </div>
        </div>
      );
    }
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="CreateEditEventSubpanel-outermost-container">
      {/* Secondary Modal */}
      {secondaryModal}

      {/* Modal */}
      <Modal
        type={ModalType.NoButtons}
        title={(
          <>
            <div>
              {isCreating ? 'Create Event' : 'Edit Event'}
            </div>
            {isCreating && (
              <div
                className="fw-normal"
                style={{ fontSize: '0.7em' }}
              >
                <div>
                  Events can be recurring like &quot;Office Hours&quot;
                  or &quot;Monday Lab&quot;,
                  or one-off like &quot;Midterm Review&quot;.
                </div>
              </div>
            )}
          </>
        )}
        largeTitle
        size={ModalSize.ExtraLarge}
        onClose={requestToCancel}
      >
        {body}
      </Modal>
    </div>
  );
};

export default CreateEditEventSubpanel;
