/**
 * Displays a QR code card, with your choice of URL and specified width.
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import dce-reactkit tools
import { LoadingSpinner } from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCloudArrowDown,
  faInfoCircle,
} from '@fortawesome/free-solid-svg-icons';

// Import helpers
import getQrCardImg from './getQrCardImg';

// Import shared types
import UserAndCourseInfo from '../../../../../../shared/types/UserAndCourseInfo';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The URL to display in the QR code
  url: string,
  // Optional sub-text
  subTextForQr?: string,
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // When the QR code is being generated
  Loading = 'Loading',
  // Display the QR code
  Displaying = 'Displaying',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.Displaying,
    // QR code that is being displayed (DataURL)
    qrCode: string,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Set QR code data URL (and stop loading, if applicable)
  DisplayQrCode = 'DisplayQrCode',
  // Start loading QR code
  StartLoading = 'StartLoading',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.DisplayQrCode,
    // The data URL that contains the QR code
    qrCodeDataURL: string,
  }
  | {
    // Action type
    type: ActionType.StartLoading,
  }
);

/**
 * Reducer for setting the QR code
 * @author Benedikt Arnarsson
 * @param state current state
 * @param action action to execute
 * @returns updated state
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.DisplayQrCode: {
      return {
        view: View.Displaying,
        qrCode: action.qrCodeDataURL,
      };
    }
    case ActionType.StartLoading: {
      return {
        view: View.Loading,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const QrCard: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    url,
    userAndCourseInfo,
    subTextForQr = '',
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Starts creation of QR code.
   * @author Benedikt Arnarsson
   */
  useEffect(
    () => {
      (async () => {
        if (view === View.Displaying) {
          dispatch({ type: ActionType.StartLoading });
        }

        // Generate QR Code image
        const qrCode = await getQrCardImg({
          url,
          subTextForQr,
          userAndCourseInfo,
        });
        dispatch({
          type: ActionType.DisplayQrCode,
          qrCodeDataURL: qrCode,
        });
      })();
    },
    [url],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Displaying ------------- */

  if (view === View.Displaying) {
    // Destructure state
    const {
      qrCode,
    } = state;

    // Display QR code with card describing it
    body = (
      <>
        <div
          className="d-inline-block"
          style={{
            maxWidth: '58vmin',
          }}
        >
          <img
            style={{
              width: '100%',
            }}
            src={qrCode}
            alt="CheckIn QR code"
          />
        </div>

        {/* Download Button/Instructions */}
        <div className="alert alert-secondary d-flex align-items-center gap-2 mt-2 mb-0 pt-1 pb-1 ps-2 pe-2">
          <div className="text-start flex-grow-1">
            <div>
              <FontAwesomeIcon
                icon={faInfoCircle}
                className="me-1"
              />
              <strong>
                Tip:
              </strong>
              {' '}
              Use the same QR code all term.
              Download it, print it, or put it in your slides.
            </div>
          </div>
          <div>
            <a
              id="qr-code"
              download={`CheckIn QR Code for ${subTextForQr}.png`}
              href={qrCode}
              className="btn btn-secondary btn-sm"
              title="CheckIn QR Code"
              data-url={url}
            >
              <FontAwesomeIcon
                icon={faCloudArrowDown}
                className="me-1"
              />
              Download
            </a>
          </div>
        </div>
      </>
    );
  }

  /* -------------- Loading --------------- */

  if (view === View.Loading) {
    // Loading...
    body = (
      <LoadingSpinner />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default QrCard;
