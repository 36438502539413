/**
 * Full weekday to abbreviated version
 * @author Gabe Abrams
 */
const WEEKDAY_TO_ABBREVIATION = {
  Monday: 'Mon',
  Tuesday: 'Tue',
  Wednesday: 'Wed',
  Thursday: 'Thu',
  Friday: 'Fri',
  Saturday: 'Sat',
  Sunday: 'Sun',
};

export default WEEKDAY_TO_ABBREVIATION;
