/**
 * Set of outcomes for prospective events
 * @author Gabe Abrams
 */
enum ProspectiveEventStatus {
  // The event is pending
  Pending = 'Pending',
  // The event was successful
  Successful = 'Successful',
  // The event was created with a warning
  CreatedWithWarning = 'Created with WARNING',
  // The event failed
  Failed = 'Failed',
}

export default ProspectiveEventStatus;
