/**
 * Page for selecting previous groups.
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import DCE reactkit
import {
  CheckboxButton,
  LoadingSpinner,
  showFatalError,
} from 'dce-reactkit';

// Import Grouper
import { StudentGroupAssignment, GrouperAlgoSettings, GrouperAlgorithm } from 'dce-live-grouper';

// Import shared types
import AssignToGroups from '../../../../../../shared/types/from-server/stored/CourseEvent/AssignToGroups';
import CheckInIds from '../../types/CheckInIds';
import MMDDYY from '../../types/MMDDYY';

// Import components
import SeriesList from './SeriesList';

// Import helpers
import allGroupsToGroupAssignment from './helpers/allGroupsToGroupAssignments';
import getOldEventInfo from './helpers/getOldEventInfo';
import postNewEventInSeries from './helpers/postNewEventInSeries';
import putNewOccurrence from './helpers/putNewOccurrence';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // First part of OccurrenceBasicInfo
  checkInProps: CheckInIds,
  // Second part of the OccurrenceBasicInfo
  occurrenceDate: MMDDYY,
  // Choice of event-wide group assignment
  assignToGroups: AssignToGroups,
  /**
   * Handler for when user is ready to continue to the next step
   * @param [opts] all arguments in one object. If excluded, just taking
   *   attendance and skipping straight to the check in QR code screen. If
   *   included, moving on to next step (pre assign or group settings)
   * @param [opts.seriesId] the id of the selected series to copy from or the
   *   new series id
   * @param [opts.oldGroups] the groups from the selected series, or empty
   *   if starting a new series
   * @param [opts.preAssign] if true, go to pre assign next before creating
   *   occurrence. If false, occurrence was already set up and we can skip
   *   straight to the QR code screen
   */
  onSubmit: (
    opts?: {
      seriesId: number,
      oldGroups?: StudentGroupAssignment[],
      preAssign: boolean,
    },
  ) => void,
  /**
   * Set the custom modal title
   * @param customTitle new custom title (or leave undefined to reset)
   */
  setCustomModalTitle: (customTitle?: string) => void,
};

/**
 * Type of creation event that will be executed on submit.
 * Indicates whether the occurrence will be just for attendance,
 * copied from an old series, or the start of a new series.
 * @author Benedikt Arnarsson
 */
enum CreationType {
  JustTakeAttendance = 'JustTakeAttendance',
  CreateNewSeries = 'CreateNewSeries',
  CopyFromSeries = 'CopyFromSeries',
}

type CreationOpts = (
  | {
    type: CreationType.JustTakeAttendance,
  }
  | {
    type: CreationType.CreateNewSeries,
    seriesId: number,
  }
  | {
    type: CreationType.CopyFromSeries,
    seriesId: number,
    mmddyy: MMDDYY,
  }
);

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Whether to assign groups
  GroupOptions = 'GroupOptions',
  // Show the list of selections
  SeriesOptions = 'SeriesOptions',
  // Loading spinner while setting up occurrence
  CreationSpinner = 'CreationSpinner',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.SeriesOptions,
    // Whether we are moving to PreAssign
    preAssign?: boolean,
  }
  | {
    // Current view
    view: (
      | View.GroupOptions
      | View.CreationSpinner
    )
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Pick groups
  PickGroups = 'PickGroups',
  // Start the creation process, switching to the loading spinner
  ShowCreationSpinner = 'ShowCreationSpinner',
  // Toggle whether to go to PreAssign next
  ToggleManualAssign = 'ToggleManualAssign',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.PickGroups
      | ActionType.ShowCreationSpinner
      | ActionType.ToggleManualAssign
    )
  }
);

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @param state current state
 * @param action action to execute
 * @returns updated state
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.PickGroups: {
      if (state.view !== View.GroupOptions) {
        return state;
      }
      return {
        ...state,
        view: View.SeriesOptions,
      };
    }
    case ActionType.ShowCreationSpinner: {
      if (state.view === View.CreationSpinner) {
        return state;
      }
      return {
        view: View.CreationSpinner,
      };
    }
    case ActionType.ToggleManualAssign: {
      if (state.view !== View.SeriesOptions) {
        return state;
      }
      return {
        ...state,
        preAssign: !state.preAssign,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const SeriesSelection: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    checkInProps,
    occurrenceDate,
    assignToGroups,
    onSubmit,
    setCustomModalTitle,
  } = props;

  const {
    courseId,
    ihid,
  } = checkInProps;

  /* -------------- State ------------- */

  // Initial state
  let initialState: State = {
    view: View.GroupOptions,
  };
  if (assignToGroups === AssignToGroups.Always) {
    initialState = {
      view: View.SeriesOptions,
    };
  } else if (assignToGroups === AssignToGroups.Never) {
    initialState.view = View.CreationSpinner;
  } else {
    // Sometimes (chooser)
    initialState.view = View.GroupOptions;
  }

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Start creating the new occurrence
   * @author Gabe Abrams
   * @param opts all arguments in one object
   * @param opts.seriesId the id of the selected series to copy from
   * @param opts.oldGroups the groups from the selected series, or empty
   *   if starting a new series
   */
  const startCreatingOccurrence = async (
    opts: CreationOpts,
  ) => {
    //  1. dispatch StartCreating
    //  2. Create the occurrence and/or event
    //  3. call onSubmit

    // Just take attendance -> only create occurrence, don't need seriesId

    // If manual is toggled on, and old groups selected -> only create
    //   occurrence, with old seriesId
    //    -> forward seriesId (old seriesId)
    //    -> forward groups from series

    // If manual is toggled on, and new groups selected -> only create
    //   occurrence, with new seriesId
    //    -> forward seriesId (new seriesId)

    // If manual is not toggled, and old groups selected
    //    -> create event and occurrence (with old seriesId)

    // If manual is not toggled, and new groups selected
    //    -> only create occurrence (with new seriesId)
    //    -> go to algorithm selection

    // Show the loading indicator
    dispatch({
      type: ActionType.ShowCreationSpinner,
    });

    switch (opts.type) {
      case CreationType.JustTakeAttendance: {
        // only create occurrence, no seriesId
        const grouperEnabled = false as const;
        const occ = {
          courseId,
          ihid,
          ...occurrenceDate,
          grouperEnabled,
        };

        await putNewOccurrence(occ);
        onSubmit();
        break;
      }
      case CreationType.CreateNewSeries: {
        // only create occurrence, with new seriesId
        if (view !== View.SeriesOptions) {
          return;
        }

        onSubmit({
          seriesId: opts.seriesId,
          preAssign: !!state.preAssign,
        });
        break;
      }
      case CreationType.CopyFromSeries: {
        if (view !== View.SeriesOptions) {
          return;
        }
        const {
          seriesId,
          mmddyy,
        } = opts;

        // fetch the old groups from the series
        const occInfo = {
          courseId,
          ihid,
          ...occurrenceDate,
        };
        const eventStateData = await getOldEventInfo(occInfo, mmddyy);

        // Extract relevant data
        const grouperAlgoSettings: GrouperAlgoSettings = (
          eventStateData.grouperAlgorithm === GrouperAlgorithm.FixedGroupSize
            ? {
              grouperAlgorithm: GrouperAlgorithm.FixedGroupSize,
              capacity: eventStateData.capacity,
            }
            : {
              grouperAlgorithm: GrouperAlgorithm.FixedNumGroups,
              maxGroups: eventStateData.maxGroups,
            }
        );
        const preAssignments = allGroupsToGroupAssignment(eventStateData) ?? [];

        // create the event for live-grouper
        await postNewEventInSeries({
          occInfo,
          seriesId,
          grouperAlgoSettings,
          preAssignments,
        });

        // create occurrence, with the old seriesId
        const occ = {
          courseId,
          ihid,
          ...occurrenceDate,
          grouperEnabled: true,
          seriesId: opts.seriesId,
        };
        await putNewOccurrence(occ);

        // Go to next view in modal
        onSubmit({
          seriesId: opts.seriesId,
          oldGroups: preAssignments,
          // TODO: discuss this, we are currently not allow pre-assignment
          // for previous group sets
          // Will need to add a new action in CheckInSubpanel to go from
          // PreAssignToCheckIn
          // and pass in a different onSubmit to PreAssign (and put logic
          // there, based on passed in info?)
          // And will need logic to pass oldGroups around as well, without
          // messing up the (messy) SeriesSelection -> CheckIn logic for old
          // series
          preAssign: false,
        });
        break;
      }
      default: {
        // This should never occur
        showFatalError('Could not create occurrence.');
        break;
      }
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      if (assignToGroups === AssignToGroups.Never) {
        startCreatingOccurrence({
          type: CreationType.JustTakeAttendance,
        });
      } else if (assignToGroups === AssignToGroups.Ask) {
        // AssignToGroups is Sometimes
        setCustomModalTitle('Assign students to groups today?');
      }
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ---------- GroupOptions ----------- */

  if (view === View.GroupOptions) {
    // Pick whether to assign groups or just take attendance
    body = (
      <div>
        <div
          className="d-flex flex-row"
        >
          <button
            className="SeriesSelection-yes-groups btn btn-lg btn-secondary flex-grow-1 py-3"
            style={{
              flexBasis: 0,
              fontSize: '2rem',
            }}
            type="button"
            aria-label="yes, assign students to groups"
            onClick={() => {
              // Reset custom title
              setCustomModalTitle();

              // Pick groups
              dispatch({
                type: ActionType.PickGroups,
              });
            }}
          >
            Yes
          </button>
          <button
            className="SeriesSelection-no-groups btn btn-lg btn-secondary flex-grow-1 ms-2 py-3"
            style={{
              flexBasis: 0,
              fontSize: '2rem',
            }}
            type="button"
            aria-label="do not assign students to groups"
            onClick={() => {
              // Set to a loading title
              setCustomModalTitle('CheckIn');

              // Just take attendance
              startCreatingOccurrence({
                type: CreationType.JustTakeAttendance,
              });
            }}
          >
            No
          </button>
        </div>
      </div>
    );
  }

  /* ---------- SeriesOptions ---------- */

  if (view === View.SeriesOptions) {
    // Create body
    body = (
      <>
        <SeriesList
          checkInProps={checkInProps}
          onSelect={(selectedSeriesOpts) => {
            if (selectedSeriesOpts.isNewSeries) {
              startCreatingOccurrence({
                type: CreationType.CreateNewSeries,
                seriesId: selectedSeriesOpts.seriesId,
              });
            } else {
              // TODO: this goes to GrouperSettings instead of straight to
              // QrCard
              // BUT it does create the occurrence
              startCreatingOccurrence({
                type: CreationType.CopyFromSeries,
                seriesId: selectedSeriesOpts.seriesId,
                mmddyy: selectedSeriesOpts.mmddyy,
              });
            }
          }}
        />
        <div className="mt-3">
          <CheckboxButton
            id="manually-assign-students-button"
            text="Manually assign specific students"
            ariaLabel="assign a small set of students manually"
            small
            onChanged={() => {
              dispatch({
                type: ActionType.ToggleManualAssign,
              });
            }}
            checked={state.preAssign}
          />
        </div>
      </>
    );
  }

  /* --------- CreationSpinner --------- */

  if (view === View.CreationSpinner) {
    // Only show loading spinner when creating events/occurrences
    body = (
      <LoadingSpinner />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="SeriesSelection-outer-container">
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default SeriesSelection;
