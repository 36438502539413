/**
 * Page for selecting GrouperAlgorithm,
 * i.e. picking between max number of groups
 * or max group size.
 * @author Benedikt Arnarsson
 */

// Import React
import React, { useReducer } from 'react';

// Import DCE reactkit
import { alert, LoadingSpinner } from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';

// Import Grouper
import {
  GrouperAlgorithm,
  GrouperAlgoSettings,
  StudentGroupAssignment,
} from 'dce-live-grouper';

// Import shared types
import CheckInIds from '../../types/CheckInIds';
import MMDDYY from '../../types/MMDDYY';
import OccurrenceGrouperInfo from '../../types/OccurrenceGrouperInfo';

// Import helpers
import postEventAndOccurrence from './helpers/postEventAndOccurrence';

// Import components
import AlgoSettingsInput from './AlgoSettingInput';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // First part of OccurrenceBasicInfo, passed in as props
  checkInProps: CheckInIds,
  // Second part of OccurrenceBasicInfo, generated from LoadOccurrence
  occurrenceDate: MMDDYY,
  // Occurrence info needed to start DCE Live Grouper
  occurrenceGrouperInfo: OccurrenceGrouperInfo,
  // Pre-assigned student
  preAssignments: StudentGroupAssignment[],
  // Callback to move to the next step in the setup
  onSubmit: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// FIXME: have this set via a check to most common choices
// Initial settings for the Prioritize View
const DEFAULT_GROUPER_ALGORITHM = GrouperAlgorithm.FixedGroupSize;
const DEFAULT_CAPACITY = 5;
const DEFAULT_MAX_GROUPS = 5;

const DEFAULT_GROUPER_ALGORITHM_SETTINGS = {
  grouperAlgorithm: DEFAULT_GROUPER_ALGORITHM,
  capacity: DEFAULT_CAPACITY,
  maxGroups: DEFAULT_MAX_GROUPS,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // User is currently choosing their algorithm priorities
  ChoosePriorities = 'ChoosePriorities',
  // Loading after user has submitted choice
  LoadOnSubmit = 'LoadOnSubmit',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.ChoosePriorities
    // Chosen GrouperAlgorithm
    grouperAlgorithm: GrouperAlgorithm,
    // Maximum number of students per group, when FixedGroupSize is chosen
    capacity: number,
    // Maximum number of groups, when FixedNumGroups is chosen
    maxGroups: number,
  }
  | {
    // Current view
    view: View.LoadOnSubmit,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Set the GrouperAlgorithm
  SetGrouperAlgorithm = 'SetGrouperAlgorithm',
  // Change the capacity
  SetCapacity = 'SetCapacity',
  // Change the max number of groups
  SetMaxGroups = 'SetMaxGroups',
  // Start loading for onSubmit
  ShowLoadingIndicator = 'ShowLoadingIndicator',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.SetGrouperAlgorithm,
    // GrouperAlgorithm that is being changed to
    grouperAlgorithm: GrouperAlgorithm,
  }
  | {
    // Action type
    type: ActionType.SetCapacity,
    // New value for capacity
    capacity: number,
  }
  | {
    // Action type
    type: ActionType.SetMaxGroups,
    // New value for maximum number of groups
    maxGroups: number,
  }
  | {
    // Action type
    type: ActionType.ShowLoadingIndicator,
  }
);

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @param state current state
 * @param action action to execute
 * @returns updated state
 */
const reducer = (state: State, action: Action): State => {
  if (state.view === View.LoadOnSubmit) {
    // Waiting to unmount, no actions allowed
    return state;
  }
  switch (action.type) {
    case ActionType.SetGrouperAlgorithm: {
      return {
        ...state,
        grouperAlgorithm: action.grouperAlgorithm,
      };
    }
    case ActionType.SetCapacity: {
      return {
        ...state,
        capacity: action.capacity,
      };
    }
    case ActionType.SetMaxGroups: {
      return {
        ...state,
        maxGroups: action.maxGroups,
      };
    }
    case ActionType.ShowLoadingIndicator: {
      return {
        view: View.LoadOnSubmit,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const GrouperSettings: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    checkInProps,
    occurrenceDate,
    occurrenceGrouperInfo,
    preAssignments,
    onSubmit,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.ChoosePriorities,
    ...DEFAULT_GROUPER_ALGORITHM_SETTINGS,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Start the onSubmit process, post the event and occurrence,
   *   and switch views.
   * @author Benedikt Arnarsson
   */
  const startOnSubmit = async () => {
    if (view !== View.ChoosePriorities) {
      return;
    }

    // Get algorithm settings
    const {
      grouperAlgorithm,
      capacity,
      maxGroups,
    } = state;

    // Validate, generate grouper algo settings
    let grouperAlgoSettings: GrouperAlgoSettings | undefined;
    if (grouperAlgorithm === GrouperAlgorithm.FixedGroupSize) {
      if (capacity < 2 || Number.isNaN(capacity)) {
        return alert(
          'Enter a valid group size',
          'To continue, please enter a group size that is at least 2.',
        );
      }

      // Valid!
      grouperAlgoSettings = {
        grouperAlgorithm,
        capacity,
      };
    } else {
      // FixedNumGroups
      if (maxGroups < 2 || Number.isNaN(maxGroups)) {
        return alert(
          'Enter a valid number of groups',
          'To continue, please enter a number of groups that is at least 2.',
        );
      }

      // Valid!
      grouperAlgoSettings = {
        grouperAlgorithm,
        maxGroups,
      };
    }

    // Show loading indicator
    dispatch({
      type: ActionType.ShowLoadingIndicator,
    });

    // Post event and occurrence
    await postEventAndOccurrence({
      checkInProps,
      occurrenceDate,
      occurrenceGrouperInfo,
      preAssignments,
      grouperAlgoSettings,
    });

    // Continue
    onSubmit();
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Loading ------------ */

  if (view === View.LoadOnSubmit) {
    return (
      <LoadingSpinner />
    );
  }

  /* ------------- Main UI ------------ */

  return (
    <div className="GrouperSettings-container">
      <AlgoSettingsInput
        grouperAlgorithm={state.grouperAlgorithm}
        setGrouperAlgorithm={(grouperAlgorithm) => {
          dispatch({
            type: ActionType.SetGrouperAlgorithm,
            grouperAlgorithm,
          });
        }}
        capacity={state.capacity}
        setCapacity={(capacity) => {
          dispatch({
            type: ActionType.SetCapacity,
            capacity,
          });
        }}
        maxGroups={state.maxGroups}
        setMaxGroups={(maxGroups) => {
          dispatch({
            type: ActionType.SetMaxGroups,
            maxGroups,
          });
        }}
      />
      {/* Buttons */}
      <div className="text-center">
        <button
          id="submit-grouper-settings-button"
          type="button"
          aria-label="submit your choice of group prioritization"
          className="btn btn-dark btn-lg"
          onClick={startOnSubmit}
        >
          Start CheckIn
          <FontAwesomeIcon
            icon={faChevronRight}
            className="ms-2"
          />
        </button>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default GrouperSettings;
