/**
 * Editor for feature flag map (feature toggles)
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import {
  ButtonInputGroup,
  RadioButton,
  Variant,
} from 'dce-reactkit';

// Import shared types
import ICFeatureFlagMap from '../../../shared/types/from-server/ICFeatureFlagMap';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Feature flag map to edit
  featureFlagMap: ICFeatureFlagMap,
  /**
   * Handler to call when feature flag map is updated
   * @param updatedFeatureFlagMap updated feature flag map
   */
  onUpdate: (updatedFeatureFlagMap: ICFeatureFlagMap,) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const FeatureFlagMapEditor: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Extract props
  const {
    featureFlagMap,
    onUpdate,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="FeatureFlagMapEditor-container">
      {/* Settings */}
      <ButtonInputGroup
        label="Live Chat"
        wrapButtonsAndAddGaps
        minLabelWidth="9.5rem"
      >
        <RadioButton
          text="Enabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-live-chat-enabled"
          ariaLabel="live chat enabled"
          selected={!featureFlagMap.liveChatDisabled}
          onSelected={() => {
            featureFlagMap.liveChatDisabled = false;
            onUpdate(featureFlagMap);
          }}
        />
        <RadioButton
          text="Disabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-live-chat-disabled"
          ariaLabel="live chat disabled"
          selected={featureFlagMap.liveChatDisabled}
          onSelected={() => {
            featureFlagMap.liveChatDisabled = true;
            onUpdate(featureFlagMap);
          }}
        />
      </ButtonInputGroup>
      <ButtonInputGroup
        label="Bookmarks"
        wrapButtonsAndAddGaps
        minLabelWidth="9.5rem"
      >
        <RadioButton
          text="Enabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-bookmarks-enabled"
          ariaLabel="bookmarks enabled"
          selected={!featureFlagMap.bookmarksDisabled}
          onSelected={() => {
            featureFlagMap.bookmarksDisabled = false;
            onUpdate(featureFlagMap);
          }}
        />
        <RadioButton
          text="Disabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-bookmarks-disabled"
          ariaLabel="bookmarks disabled"
          selected={featureFlagMap.bookmarksDisabled}
          onSelected={() => {
            featureFlagMap.bookmarksDisabled = true;
            onUpdate(featureFlagMap);
          }}
        />
      </ButtonInputGroup>
      <ButtonInputGroup
        label="Video Comments"
        wrapButtonsAndAddGaps
        minLabelWidth="9.5rem"
      >
        <RadioButton
          text="Enabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-video-comments-enabled"
          ariaLabel="video comments enabled"
          selected={(
            !featureFlagMap.videoCommentsDisabled
          )}
          onSelected={() => {
            featureFlagMap.videoCommentsDisabled = (
              false
            );
            onUpdate(featureFlagMap);
          }}
        />
        <RadioButton
          text="Disabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-video-comments-disabled"
          ariaLabel="video comments disabled"
          selected={(
            featureFlagMap.videoCommentsDisabled
          )}
          onSelected={() => {
            featureFlagMap.videoCommentsDisabled = (
              true
            );
            onUpdate(featureFlagMap);
          }}
        />
      </ButtonInputGroup>
      <ButtonInputGroup
        label="PairUp Activity"
        wrapButtonsAndAddGaps
        minLabelWidth="9.5rem"
      >
        <RadioButton
          text="Enabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-pair-up-enabled"
          ariaLabel="pair up enabled"
          selected={!featureFlagMap.pairUpDisabled}
          onSelected={() => {
            featureFlagMap.pairUpDisabled = false;
            onUpdate(featureFlagMap);
          }}
        />
        <RadioButton
          text="Disabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-pair-up-disabled"
          ariaLabel="pair up disabled"
          selected={featureFlagMap.pairUpDisabled}
          onSelected={() => {
            featureFlagMap.pairUpDisabled = true;
            onUpdate(featureFlagMap);
          }}
        />
      </ButtonInputGroup>
      <ButtonInputGroup
        label="Poll"
        wrapButtonsAndAddGaps
        minLabelWidth="9.5rem"
      >
        <RadioButton
          text="Enabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-poll-enabled"
          ariaLabel="poll enabled"
          selected={!featureFlagMap.pollDisabled}
          onSelected={() => {
            featureFlagMap.pollDisabled = false;
            onUpdate(featureFlagMap);
          }}
        />
        <RadioButton
          text="Disabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-poll-disabled"
          ariaLabel="poll disabled"
          selected={featureFlagMap.pollDisabled}
          onSelected={() => {
            featureFlagMap.pollDisabled = true;
            onUpdate(featureFlagMap);
          }}
        />
      </ButtonInputGroup>
      <ButtonInputGroup
        label="Respond Activity"
        wrapButtonsAndAddGaps
        minLabelWidth="9.5rem"
        noMarginOnBottom
      >
        <RadioButton
          text="Enabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-respond-enabled"
          ariaLabel="respond enabled"
          selected={!featureFlagMap.respondDisabled}
          onSelected={() => {
            featureFlagMap.respondDisabled = false;
            onUpdate(featureFlagMap);
          }}
        />
        <RadioButton
          text="Disabled"
          selectedVariant={Variant.Warning}
          id="FeatureFlagMapEditor-respond-disabled"
          ariaLabel="respond disabled"
          selected={featureFlagMap.respondDisabled}
          onSelected={() => {
            featureFlagMap.respondDisabled = true;
            onUpdate(featureFlagMap);
          }}
        />
      </ButtonInputGroup>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default FeatureFlagMapEditor;
