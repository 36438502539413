/**
 * User chooses a course either from a list or by pasting in the CRN
 * @author Gabe Abrams
 */

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Import React
import React, { useEffect, useReducer } from 'react';

// Import dce-reactkit
import {
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import shared types
import LogMetadata from '../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // Current text in the Canvas box
  canvasText: string,
  // Current text in the CRN box
  crnText: string,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Update the Canvas text
  UpdateCanvasText = 'UpdateCanvasText',
  // Update the CRN text
  UpdateCRNText = 'UpdateCRNText',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateCanvasText,
    // New text
    canvasText: string,
  }
  | {
    // Action type
    type: ActionType.UpdateCRNText,
    // New text
    crnText: string,
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateCanvasText: {
      return {
        ...state,
        canvasText: action.canvasText,
      };
    }
    case ActionType.UpdateCRNText: {
      return {
        ...state,
        crnText: action.crnText,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const JumpToCourse: React.FC<{}> = () => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    canvasText: '',
    crnText: '',
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    canvasText,
    crnText,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Log opening
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          subcontext: LogMetadata.Context.AdminPanel.JumpToCourse,
          action: LogAction.Open,
        });
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log closing
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          subcontext: LogMetadata.Context.AdminPanel.JumpToCourse,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Canvas ------------- */

  // Process canvasId
  let courseId: number | null = null;
  // > Try just using as a number
  if (!Number.isNaN(Number.parseInt(canvasText.trim(), 10))) {
    // This is a number!
    courseId = Number.parseInt(canvasText, 10);
  }
  // > Try parse as URL
  const canvasLinkRegex = /https:\/\/.*\.edu\/courses\/([\d]+).*/g;
  if (canvasText.toLowerCase().match(canvasLinkRegex)) {
    // This is a valid Canvas url
    const matchInfo = canvasLinkRegex.exec(canvasText);
    if (matchInfo && matchInfo.length > 1) {
      courseId = Number.parseInt(matchInfo[1], 10);
    }
  }

  // Create the Canvas button
  const canvasButton = (
    courseId
      ? (
        <div className="d-grid">
          <a
            id="CourseChooser-jump-via-canvas-button"
            className="btn btn-secondary btn-lg btn-block mt-2"
            aria-label="jump to course based on Canvas link or id"
            href={`https://${window.location.hostname}/courses/${courseId}`}
          >
            Jump to Course
          </a>
        </div>
      )
      : (
        <div className="alert alert-warning mt-3 mb-0">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="me-2"
          />
          Add a valid course link or Canvas course ID
        </div>
      )
  );

  /* --------------- CRN -------------- */

  // Parse the CRN
  const crn = (
    (
      !Number.isNaN(Number.parseInt(crnText.trim(), 10))
      && Number.parseInt(crnText.trim(), 10) > 9999
    )
      ? Number.parseInt(crnText.trim(), 10)
      : null
  );

  // Create the CRN button
  const crnButton = (
    crn
      ? (
        <div className="d-grid">
          <a
            id="CourseChooser-jump-via-crn-button"
            className="btn btn-secondary btn-lg mt-2"
            aria-label="jump to course based on CRN"
            href={`https://${window.location.hostname}/crns/${crn}`}
          >
            Jump to Course
          </a>
        </div>
      )
      : (
        <div
          id="CourseChooser-add-valid-crn-warning"
          className="alert alert-warning mt-3 mb-0"
        >
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="me-2"
          />
          Add a valid CRN and year
        </div>
      )
  );

  /* ------------- Full UI ------------ */

  return (
    <div>
      <h1 className="text-white mb-1">
        Jump to Course
      </h1>
      <h3 className="text-white mb-4">
        Use either method:
      </h3>

      {/* Canvas Identifier */}
      <div className="mb-3">
        <div
          className="alert alert-light text-dark"
          style={{ maxWidth: '31.25rem', margin: 'auto' }}
        >
          <h3>
            CanvasId or Link
          </h3>

          <div>
            Enter the CanvasId or the Canvas Site Link:
          </div>

          <input
            type="text"
            id="CourseChooser-canvas-input-field"
            className="form-control"
            aria-label="canvas id or link"
            placeholder="e.g. 57382 or https://canvas.harvard.edu/..."
            value={canvasText}
            onChange={(e) => {
              dispatch({
                type: ActionType.UpdateCanvasText,
                canvasText: e.target.value.trim(),
              });
            }}
          />

          {canvasButton}
        </div>
      </div>

      <h3 className="text-light mb-3">
        or
      </h3>

      {/* CRN */}
      <div>
        <div
          className="alert alert-light text-dark"
          style={{ maxWidth: '31.25rem', margin: 'auto' }}
        >
          <h3>
            CRN
          </h3>

          <div>
            Enter the CRN for the course:
          </div>

          <input
            type="text"
            id="CourseChooser-crn-input-field"
            className="form-control mb-1"
            aria-label="course CRN"
            placeholder="e.g. 24759"
            value={crnText}
            onChange={(e) => {
              dispatch({
                type: ActionType.UpdateCRNText,
                crnText: e.target.value.trim(),
              });
            }}
          />

          {crnButton}
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default JumpToCourse;
