// Import FontAwesome
import {
  faUniversity,
  faVial,
  faUsers,
  faCalendarAlt,
  faHandshake,
  faUserFriends,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';

// Import shared types
import CourseEventType from '../types/from-server/stored/shared/CourseEventType';

// Lookup Map: type => icon
const typeToIcon = {
  [CourseEventType.Class]: faUniversity,
  [CourseEventType.Lab]: faVial,
  [CourseEventType.OfficeHours]: faUserClock,
  [CourseEventType.Section]: faUsers,
  [CourseEventType.Event]: faCalendarAlt,
  [CourseEventType.Meeting]: faHandshake,
  [CourseEventType.Other]: faUserFriends,
};

/**
 * Get the associated icon for a type
 * @author Gabe Abrams
 * @param type the event type
 * @returns FontAwesome Icon
 */
const eventTypeToIcon = (type: CourseEventType) => {
  // Get the type
  return typeToIcon[type];
};

export default eventTypeToIcon;
