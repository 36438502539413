/**
 * The main join event in Zoom page
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer, useRef } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFileVideo,
  faPlus,
  faChalkboardTeacher,
  faUserAlt,
  faUserCog,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  TabBox,
  waitMs,
  alert,
} from 'dce-reactkit';

// Import shared components
import NothingHereNotice from '../../shared/NothingHereNotice';
import VisitLinkModal from '../../shared/VisitLinkModal';

// Import shared types
import AssignToGroups from '../../shared/types/from-server/stored/CourseEvent/AssignToGroups';
import AttendanceMethod from '../../shared/types/from-server/stored/AttendanceLog/AttendanceMethod';
import CourseEvent from '../../shared/types/from-server/stored/CourseEvent';
import CourseLoungeWithVisitorNames from '../../shared/types/from-server/CourseLoungeWithVisitorNames';
import UserAndCourseInfo from '../../shared/types/UserAndCourseInfo';

// Import other components
import EventItem from './EventItem';
import LoungeItem from './LoungeItem';
import LoungePreparationSubpanel from './subpanels/LoungePreparationSubpanel';
import ShareableLinkSubpanel from './subpanels/ShareableLinkSubpanel';
import ChooseJoinTypeSubpanel from './subpanels/ChooseJoinTypeSubpanel';
import CheckInSubpanel from './subpanels/CheckInSubpanel';
import CreateEditEventSubpanel from './subpanels/CreateEditEventSubpanel';
import RecordingsSubpanel from './subpanels/RecordingsSubpanel';
import CreateEditLoungeSubpanel from './subpanels/CreateEditLoungeSubpanel';
import HelpSubpanel from './subpanels/HelpSubpanel';
import AttendanceSubpanel from './subpanels/AttendanceSubpanel';
import HeaderButtonInfo from '../../shared/Header/types/HeaderButtonInfo';
import Header from '../../shared/Header';

// Import helpers
import genGreeting from '../../shared/helpers/genGreeting';
import setPagePath from '../../shared/helpers/setPagePath';
import eventComparator from '../../shared/helpers/eventComparator';
import genShareableLink from '../../shared/helpers/genShareableLink';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
  // Handler for when admin wants to switch to the admin panel
  onSwitchToAdminPanel: () => void,
};

// Type of join
enum JoinType {
  // Joining as a participant
  JoinAsParticipant = 'JoinAsParticipant',
  // Joining as a host
  JoinAsHost = 'JoinAsHost',
  // Joining as a panelist
  JoinAsPanelist = 'JoinAsPanelist',
  // Joining a lounge
  JoinLounge = 'JoinLounge',
}

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Interval for how often to check for new lounge activity
const LOUNGE_REFRESH_MS = 30 * 1000; // 30s

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Home page
  Home = 'Home',
}

/* ------------ Subpanels ----------- */

enum SubpanelType {
  // Choose join type
  ChooseJoinType = 'ChooseJoinType',
  // Lounge preparation
  LoungePreparation = 'LoungePreparation',
  // Join modal
  JoinModal = 'JoinModal',
  // Shareable link
  ShareableLink = 'ShareableLink',
  // Help
  Help = 'Help',
  // Attendance Analytics
  Attendance = 'Attendance',
  // Recordings
  Recordings = 'Recordings',
  // Create/Edit Event
  CreateOrEditEvent = 'CreateOrEditEvent',
  // Create/Edit Lounge
  CreateOrEditLounge = 'CreateOrEditLounge',
  // Check-In Setup
  CheckInSetup = 'CheckInSetup',
}

type SubpanelState = (
  | {
    // Type of subpanel
    type: SubpanelType.ChooseJoinType,
    // Event to join after choosing join type
    eventToJoinAfterChoosingJoinType: CourseEvent,
  }
  | {
    // Type of subpanel
    type: SubpanelType.LoungePreparation,
    // Lounge to join after preparation
    loungeToJoinAfterPreparation: CourseLoungeWithVisitorNames,
  }
  | {
    // Type of subpanel
    type: SubpanelType.JoinModal,
    // Link that the current user is joining
    link: string,
    // If true, the link to join is currently loading
    loading: boolean,
    // Type of the link to join
    joinType: JoinType,
    // If true, the link being joined is a webinar
    isWebinar: boolean,
  }
  | {
    // Type of subpanel
    type: SubpanelType.ShareableLink,
    // Title of the link
    title: string,
    // The link
    link: string,
    // IHID of the event to share
    ihidToShare: string,
    // If true, the event is held in Zoom
    isHeldInZoom: boolean,
  }
  | {
    // Type of subpanel
    type: SubpanelType.Recordings,
    // Event to view recordings for (showing published recordings if undefined)
    event?: CourseEvent,
  }
  | {
    // Type of subpanel
    type: SubpanelType.Help,
  }
  | {
    // Type of subpanel
    type: SubpanelType.Attendance,
    // Event to show attendance for
    event: CourseEvent,
  }
  | {
    // Type of subpanel
    type: SubpanelType.CreateOrEditEvent,
    // Event to edit, or undefined if creating a new event
    event?: CourseEvent,
  }
  | {
    // Type of subpanel
    type: SubpanelType.CreateOrEditLounge,
    // Lounge to edit, or undefined if creating a new lounge
    lounge?: CourseLoungeWithVisitorNames,
  }
  | {
    // Type of subpanel
    type: SubpanelType.CheckInSetup,
    // Event that is being checked into
    eventToCheckPeopleInto: CourseEvent,
  }
);

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
    // Additional loading message to display
    loadingMessage?: string,
  }
  | {
    // Current view
    view: View.Home,
    // Subpanel that may be shown as a modal
    subpanel?: SubpanelState,
    // List of events to choose from
    events: CourseEvent[],
    // List of lounges
    lounges: CourseLoungeWithVisitorNames[],
    // Welcome greeting
    greeting: React.ReactNode,
    // Shorter greeting
    shortGreeting: React.ReactNode,
    // If true, showing the published recordings button
    showPublishedRecordings: boolean,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  /* ------------- General ------------ */
  // Update the custom loading message
  UpdateCustomLoadingMessage = 'UpdateCustomLoadingMessage',
  // Finish loading
  FinishLoading = 'FinishLoading',
  // Show the "published recordings" button
  ShowPublishedRecordingsButton = 'ShowPublishedRecordingsButton',
  // Update list of lounges
  UpdateLounges = 'UpdateLounges',
  /* ------------ Subpanels ----------- */
  // Hide subpanel
  HideSubpanel = 'HideSubpanel',
  // Show choose event join type modal
  ShowChooseEventJoinTypeModal = 'ShowChooseEventJoinTypeModal',
  // Show lounge preparation
  ShowLoungePreparation = 'ShowLoungePreparation',
  // Show join modal
  ShowJoinModal = 'ShowJoinModal',
  // Finish loading the link to join
  FinishLoadingLinkToJoin = 'FinishLoadingLinkToJoin',
  // Show a shareable link
  ShowShareableLink = 'ShowShareableLink',
  // Show the help subpanel
  ShowHelp = 'ShowHelp',
  // Show the attendance subpanel
  ShowAttendance = 'ShowAttendance',
  // Show published recordings
  ShowPublishedRecordings = 'ShowPublishedRecordings',
  // Show event recordings
  ShowEventRecordings = 'ShowEventRecordings',
  // Show event editor
  ShowEventEditor = 'ShowEventEditor',
  // Show event creator
  ShowEventCreator = 'ShowEventCreator',
  // Event edit/create finished
  EventEditOrCreateFinished = 'EventEditOrCreateFinished',
  // Show lounge editor
  ShowLoungeEditor = 'ShowLoungeEditor',
  // Show lounge creator
  ShowLoungeCreator = 'ShowLoungeCreator',
  // Lounge edit/create finished
  LoungeEditOrCreateFinished = 'LoungeEditOrCreateFinished',
  // Open Check-In setup
  StartCheckIn = 'StartCheckIn',
  // Close Check-In setup,
  CloseCheckIn = 'CloseCheckIn',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateCustomLoadingMessage,
    // New loading message
    message: string,
  }
  | {
    // Action type
    type: ActionType.FinishLoading,
    // List of course events
    events: CourseEvent[],
    // List of lounges
    lounges: CourseLoungeWithVisitorNames[],
    // Full greeting
    greeting: React.ReactNode,
    // Short greeting
    shortGreeting: React.ReactNode,
    // If true, showing the published recordings button
    showPublishedRecordings: boolean,
  }
  | {
    // Action type
    type: ActionType.UpdateLounges,
    // New list of lounges
    lounges: CourseLoungeWithVisitorNames[],
  }
  | {
    // Action type
    type: ActionType.ShowChooseEventJoinTypeModal,
    // Event to join after choosing join type
    event: CourseEvent,
  }
  | {
    // Action type
    type: ActionType.ShowJoinModal,
    // Link to join
    link: string,
    // Join type
    joinType: JoinType,
    // If true, joining a webinar
    isWebinar: boolean,
  }
  | {
    // Action type
    type: ActionType.ShowLoungePreparation,
    // Lounge to join after preparation
    lounge: CourseLoungeWithVisitorNames,
  }
  | {
    // Action type
    type: ActionType.ShowShareableLink,
    // Course id
    courseId: number,
    // The event to share
    event: CourseEvent,
  }
  | {
    // Action type
    type: ActionType.ShowEventRecordings,
    // Event to view recordings for (showing published recordings if undefined)
    event: CourseEvent,
  }
  | {
    // Action type
    type: ActionType.ShowEventEditor,
    // Event to edit
    event: CourseEvent,
  }
  | {
    // Action type
    type: ActionType.EventEditOrCreateFinished,
    // Event that was edited or created
    event: CourseEvent,
  }
  | {
    // Action type
    type: ActionType.ShowLoungeEditor,
    // Lounge to edit
    lounge: CourseLoungeWithVisitorNames,
  }
  | {
    // Action type
    type: ActionType.LoungeEditOrCreateFinished,
    // Lounge that was edited or created
    lounge: CourseLoungeWithVisitorNames,
  }
  | {
    // Action type
    type: ActionType.StartCheckIn,
    // Event
    event: CourseEvent,
  }
  | {
    // Action type
    type: ActionType.ShowAttendance,
    // Event
    event: CourseEvent,
  }
  | {
    // Action type
    type: (
      | ActionType.ShowPublishedRecordingsButton
      | ActionType.FinishLoadingLinkToJoin
      | ActionType.ShowHelp
      | ActionType.HideSubpanel
      | ActionType.ShowPublishedRecordings
      | ActionType.ShowLoungeCreator
      | ActionType.ShowEventCreator
      | ActionType.CloseCheckIn
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  // Loading
  if (state.view === View.Loading) {
    switch (action.type) {
      case ActionType.UpdateCustomLoadingMessage: {
        // Skip if the view is not loading
        if (state.view !== View.Loading) {
          return state;
        }

        return {
          ...state,
          loadingMessage: action.message,
        };
      }
      case ActionType.FinishLoading: {
        return {
          ...state,
          view: View.Home,
          events: action.events,
          lounges: action.lounges,
          greeting: action.greeting,
          shortGreeting: action.shortGreeting,
          showPublishedRecordings: action.showPublishedRecordings,
        };
      }
      default: {
        return state;
      }
    }
  }

  // Home
  switch (action.type) {
    /* ------------- General ------------ */
    case ActionType.ShowPublishedRecordingsButton: {
      return {
        ...state,
        showPublishedRecordings: true,
      };
    }
    case ActionType.UpdateLounges: {
      return {
        ...state,
        lounges: action.lounges,
      };
    }
    /* ------------ Subpanels ----------- */
    case ActionType.HideSubpanel: {
      return {
        ...state,
        subpanel: undefined,
      };
    }
    case ActionType.ShowChooseEventJoinTypeModal: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.ChooseJoinType,
          eventToJoinAfterChoosingJoinType: action.event,
        },
      };
    }
    case ActionType.ShowLoungePreparation: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.LoungePreparation,
          loungeToJoinAfterPreparation: action.lounge,
        },
      };
    }
    case ActionType.ShowJoinModal: {
      // Starts in the loading state
      return {
        ...state,
        subpanel: {
          type: SubpanelType.JoinModal,
          link: action.link,
          loading: (action.joinType !== JoinType.JoinLounge),
          joinType: action.joinType,
          isWebinar: action.isWebinar,
        },
      };
    }
    case ActionType.FinishLoadingLinkToJoin: {
      // Skip if the link to join is not loading
      if (state.subpanel?.type !== SubpanelType.JoinModal) {
        return state;
      }

      return {
        ...state,
        subpanel: {
          ...state.subpanel,
          loading: false,
        },
      };
    }
    case ActionType.ShowShareableLink: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.ShareableLink,
          title: action.event.name,
          link: genShareableLink(action.courseId, action.event.ihid),
          ihidToShare: action.event.ihid,
          isHeldInZoom: !!action.event.currentZoomId,
        },
      };
    }
    case ActionType.ShowHelp: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.Help,
        },
      };
    }
    case ActionType.ShowAttendance: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.Attendance,
          event: action.event,
        },
      };
    }
    case ActionType.ShowPublishedRecordings: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.Recordings,
        },
      };
    }
    case ActionType.ShowEventRecordings: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.Recordings,
          event: action.event,
        },
      };
    }
    case ActionType.ShowEventEditor: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.CreateOrEditEvent,
          event: action.event,
        },
      };
    }
    case ActionType.ShowEventCreator: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.CreateOrEditEvent,
        },
      };
    }
    case ActionType.EventEditOrCreateFinished: {
      // Destructure state
      const {
        events,
      } = state;

      // Replace the old event with the new one
      let replaced = false;
      const updatedEvents = events.map((event) => {
        if (event.ihid === action.event.ihid) {
          replaced = true;
          return action.event;
        }
        return event;
      });

      // If not replaced, add the new event to the list
      if (!replaced) {
        updatedEvents.push(action.event);
      }

      return {
        ...state,
        events: updatedEvents,
        subpanel: undefined, // Also close the subpanel
      };
    }
    case ActionType.ShowLoungeEditor: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.CreateOrEditLounge,
          lounge: action.lounge,
        },
      };
    }
    case ActionType.ShowLoungeCreator: {
      return {
        ...state,
        subpanel: {
          type: SubpanelType.CreateOrEditLounge,
        },
      };
    }
    case ActionType.LoungeEditOrCreateFinished: {
      // Destructure state
      const {
        lounges,
      } = state;

      // Handle operation
      let updatedLounges: CourseLoungeWithVisitorNames[];
      if (action.lounge.archived) {
        // Remove from list
        updatedLounges = lounges.filter((lounge) => {
          return lounge.loungeId !== action.lounge.loungeId;
        });
      } else {
        // Add or replace the old lounge with the new one
        let replaced = false;
        updatedLounges = lounges.map((lounge) => {
          if (lounge.loungeId === action.lounge.loungeId) {
            replaced = true;
            return action.lounge;
          }
          return lounge;
        });

        // If not replaced, add the new lounge to the list
        if (!replaced) {
          updatedLounges.push(action.lounge);
        }
      }

      return {
        ...state,
        lounges: updatedLounges,
        subpanel: undefined, // Also close the subpanel
      };
    }
    case ActionType.StartCheckIn: {
      const { event } = action;
      return {
        ...state,
        subpanel: {
          type: SubpanelType.CheckInSetup,
          eventToCheckPeopleInto: event,
        },
      };
    }
    case ActionType.CloseCheckIn: {
      return {
        ...state,
        subpanel: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Home: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
    onSwitchToAdminPanel,
  } = props;

  // Destructure user and course info
  const {
    courseId,
    courseName,
    userFirstName,
    userLastName,
    isAdmin,
    isLearner,
  } = userAndCourseInfo;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /* -------------- Refs -------------- */

  // Initialize refs
  const loungeRefreshIntervalId = useRef<NodeJS.Timer>();

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Refresh the lounge list
   * @author Gabe Abrams
   */
  const refreshLounges = async () => {
    // Skip if the view is not home
    if (state.view !== View.Home) {
      return;
    }

    // Destructure state
    const {
      lounges,
    } = state;

    try {
      // Get new lounge visitor map
      const visitorMap = await visitServerEndpoint({
        path: `/api/courses/${courseId}/lounges/visitors`,
        method: 'GET',
        params: {
          loungeIds: lounges.map((lounge) => {
            return lounge.loungeId;
          }),
        },
      });

      // Update lounges
      const updatedLounges = lounges.map((lounge) => {
        return {
          ...lounge,
          visitorFirstNames: (visitorMap[lounge.loungeId] || []),
        };
      });

      // Save to state
      dispatch({
        type: ActionType.UpdateLounges,
        lounges: updatedLounges,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Join an event. If a startURL is included, we assume the user is starting
   *   an event. Otherwise, we assume they're joining as a participant.
   * @author Gabe Abrams
   * @param {object} event the event to open
   * @param {string} [startURL] a startURL if the user is joining as a host.
   * @param {boolean} [joiningAsPanelist] true if the user is joining as a
   *   webinar panelist
   */
  const showJoinModal = async (
    opts: {
      event: CourseEvent,
      startURL?: string,
      joiningAsPanelist?: boolean,
    },
  ) => {
    const {
      event,
      startURL,
      joiningAsPanelist,
    } = opts;

    // Figure out the link
    const linkToJoin = (startURL ?? event.openZoomLink);

    // Skip if no link to join
    if (!linkToJoin) {
      return;
    }

    // Identify join type
    let linkToJoinType = JoinType.JoinAsParticipant;
    if (joiningAsPanelist) {
      linkToJoinType = JoinType.JoinAsPanelist;
    } else if (startURL) {
      linkToJoinType = JoinType.JoinAsHost;
    }

    // Show the join modal (starts as loading)
    dispatch({
      type: ActionType.ShowJoinModal,
      link: linkToJoin,
      joinType: linkToJoinType,
      isWebinar: !!event.isWebinar,
    });

    // Take attendance
    try {
      await visitServerEndpoint({
        path: `/api/courses/${courseId}/events/${event.ihid}/attendance`,
        method: 'POST',
        params: {
          method: AttendanceMethod.Live,
          isHost: !!startURL,
        },
      });

      // Success! Let the user click to join by turning off loading indicator
      dispatch({
        type: ActionType.FinishLoadingLinkToJoin,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Join a lounge (finish preparation)
   * @author Gabe Abrams
   * @param linkToJoin the link to join
   */
  const showJoinLoungeModal = async (linkToJoin: string) => {
    // Skip if not in home view
    if (
      state.view !== View.Home
      || state.subpanel?.type !== SubpanelType.LoungePreparation
    ) {
      return;
    }

    // Destructure state
    const {
      loungeToJoinAfterPreparation,
    } = state.subpanel;

    // Skip if no lounge to join
    if (!loungeToJoinAfterPreparation) {
      return;
    }

    // Add lounge activity
    await visitServerEndpoint({
      path: `/api/courses/${courseId}/lounges/${loungeToJoinAfterPreparation.loungeId}/visitors`,
      method: 'POST',
      params: {
        joinedAsHost: (
          linkToJoin.includes('?zak=')
            ? true
            : undefined
        ),
      },
    });

    // Show the join modal
    dispatch({
      type: ActionType.ShowJoinModal,
      link: linkToJoin,
      joinType: JoinType.JoinLounge,
      isWebinar: false,
    });
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        /* --------- Lounge Refresh --------- */

        // Start a lounge refresh interval
        loungeRefreshIntervalId.current = setInterval(
          refreshLounges,
          LOUNGE_REFRESH_MS,
        );

        /* -------- Loading Messages -------- */

        // Update the loadingMessage after certain amounts of time
        (async () => {
          await waitMs(5000);
          dispatch({
            type: ActionType.UpdateCustomLoadingMessage,
            message: 'We\'re waiting on Zoom...',
          });

          await waitMs(10000);
          dispatch({
            type: ActionType.UpdateCustomLoadingMessage,
            message: 'Thanks for your patience! This might take some time.',
          });

          await waitMs(10000);
          dispatch({
            type: ActionType.UpdateCustomLoadingMessage,
            message: 'Sit tight! If lots of people joined at the same time, this could take a few minutes.',
          });
        })();

        /* ------------ Load Data ----------- */

        try {
          // Load events and lounges
          const [
            events,
            lounges,
            showPublishedRecordings,
          ] = await Promise.all([
            visitServerEndpoint({
              path: `/api/courses/${courseId}/events`,
              method: 'GET',
            }),
            visitServerEndpoint({
              path: `/api/courses/${courseId}/lounges`,
              method: 'GET',
            }),
            visitServerEndpoint({
              path: `/api/courses/${courseId}/recordings/published/at_least_one`,
              method: 'GET',
            }),
          ]);

          // Create a role description
          let roleDescription = '(student)';
          if (isAdmin) {
            roleDescription = '(admin)';
          } else if (!isLearner) {
            roleDescription = '(teaching staff)';
          }

          // First name element
          const firstNameElem = (
            <span
              className="fw-bold"
              title={`You are logged in as ${userFirstName} ${userLastName} ${roleDescription}`}
            >
              {userFirstName}
            </span>
          );

          // Generate greetings
          const greeting = genGreeting(firstNameElem);
          const shortGreeting = (
            <span>
              Welcome,
              {' '}
              {firstNameElem}
            </span>
          );

          // Save to state
          dispatch({
            type: ActionType.FinishLoading,
            events,
            lounges,
            greeting,
            shortGreeting,
            showPublishedRecordings,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  // Set the page path and title
  setPagePath('Course Events', `/courses/${courseId}`);

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  if (view === View.Home) {
    // Destructure state
    const {
      subpanel,
    } = state;

    /* --------- Shareable Link --------- */

    if (subpanel?.type === SubpanelType.ShareableLink) {
      // Destructure subpanel
      const {
        title,
        link,
        ihidToShare,
        isHeldInZoom,
      } = subpanel;

      // Render modal
      modal = (
        <ShareableLinkSubpanel
          courseId={courseId}
          ihid={ihidToShare}
          title={title}
          shareableLink={link}
          onClose={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
          isAdmin={isAdmin}
          isLearner={isLearner}
          isHeldInZoom={isHeldInZoom}
        />
      );
    }

    /* -------- Choose Join Type -------- */

    if (subpanel?.type === SubpanelType.ChooseJoinType) {
      // Destructure subpanel
      const {
        eventToJoinAfterChoosingJoinType,
      } = subpanel;

      // Render modal
      modal = (
        <ChooseJoinTypeSubpanel
          courseId={courseId}
          isLearner={isLearner}
          event={eventToJoinAfterChoosingJoinType}
          onJoinAsParticipant={() => {
            showJoinModal({
              event: eventToJoinAfterChoosingJoinType,
            });
          }}
          onJoinAsHost={(startURL) => {
            showJoinModal({
              startURL,
              event: eventToJoinAfterChoosingJoinType,
            });
          }}
          onJoinAsPanelist={(startURL) => {
            showJoinModal({
              startURL,
              event: eventToJoinAfterChoosingJoinType,
              joiningAsPanelist: true,
            });
          }}
          onCancel={() => {
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
        />
      );
    }

    /* ------- Prepare Lounge Join ------ */

    if (subpanel?.type === SubpanelType.LoungePreparation) {
      // Destructure subpanel
      const {
        loungeToJoinAfterPreparation,
      } = subpanel;

      // Render modal
      modal = (
        <LoungePreparationSubpanel
          courseId={courseId}
          isLearner={isLearner}
          lounge={loungeToJoinAfterPreparation}
          onContinue={(newLinkToJoin) => {
            showJoinLoungeModal(newLinkToJoin);
          }}
          onCancel={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
        />
      );
    }

    /* ---------- Link to Join ---------- */

    if (subpanel?.type === SubpanelType.JoinModal) {
      // Destructure subpanel
      const {
        link,
        loading,
        joinType,
        isWebinar,
      } = subpanel;

      // Create the text for the modal body
      let modalTitle = 'Join as Participant';
      let modalBody = (
        isLearner
          ? 'Click below to join the event in Zoom'
          : (
            <span>
              <strong>
                Important:&nbsp;
              </strong>
              to join as participant,
              {' '}
              <button
                type="button"
                className="btn btn-link m-0 p-0 text-dark"
                style={{
                  verticalAlign: 'baseline',
                }}
                onClick={() => {
                  alert(
                    'How to Log Out of Zoom App',
                    'Switch to the Zoom app on your device. Then, if on mobile, tap "More", tap your profile, scroll down, tap "Sign out". If on a computer, open the main Zoom window, click your picture, click "Sign out".',
                  );
                }}
              >
                log out of the Zoom app
              </button>
              {' '}
              first
            </span>
          )
      );
      if (joinType === JoinType.JoinAsHost) {
        modalTitle = 'Join as Host';
        modalBody = 'If you\'re not the first to join as host, you will be a co-host';
      } else if (joinType === JoinType.JoinAsPanelist) {
        modalTitle = 'Join as Panelist';
        modalBody = 'You may need to wait for DCE staff to start the webinar';
      } else if (joinType === JoinType.JoinLounge) {
        modalTitle = 'Join Study Lounge';
        modalBody = 'Click below to join the Gather Study Lounge in Zoom';
      }

      // Create the modal itself
      modal = (
        <VisitLinkModal
          title={modalTitle}
          label="zoom meeting"
          body={modalBody}
          buttonText={(
            joinType === JoinType.JoinLounge
              ? 'I Agree, Join'
              : 'Join Event'
          )}
          loading={loading}
          link={link}
          icon={
            (
              joinType === JoinType.JoinAsHost
              || joinType === JoinType.JoinAsPanelist
            )
              ? faChalkboardTeacher
              : faUserAlt
          }
          onClose={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
          showAgreements
          useWebinarAgreements={isWebinar}
          isJoiningLounge={joinType === JoinType.JoinLounge}
        />
      );
    }

    /* ----------- Attendance ----------- */

    if (subpanel?.type === SubpanelType.Attendance) {
      // Destructure subpanel
      const {
        event,
      } = subpanel;

      // Render modal
      modal = (
        <AttendanceSubpanel
          event={event}
          userAndCourseInfo={userAndCourseInfo}
          onClose={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
        />
      );
    }

    /* ----------- Recordings ----------- */

    if (subpanel?.type === SubpanelType.Recordings) {
      // Destructure subpanel
      const {
        event,
      } = subpanel;

      // Render modal
      modal = (
        <RecordingsSubpanel
          userAndCourseInfo={userAndCourseInfo}
          event={event}
          onClose={(aRecordingWasPublished: boolean) => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });

            // If a recording was published,
            // show the published recordings button
            if (aRecordingWasPublished) {
              dispatch({
                type: ActionType.ShowPublishedRecordingsButton,
              });
            }
          }}
        />
      );
    }

    /* -------- Create/Edit Event ------- */

    if (subpanel?.type === SubpanelType.CreateOrEditEvent) {
      // Destructure subpanel
      const {
        event,
      } = subpanel;

      // Render modal
      modal = (
        <CreateEditEventSubpanel
          userAndCourseInfo={userAndCourseInfo}
          event={event}
          onFinish={(newOrUpdatedEvent) => {
            dispatch({
              type: ActionType.EventEditOrCreateFinished,
              event: newOrUpdatedEvent,
            });
          }}
          onCancel={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
        />
      );
    }

    /* ------- Create/Edit Lounge ------- */

    if (subpanel?.type === SubpanelType.CreateOrEditLounge) {
      // Destructure subpanel
      const {
        lounge,
      } = subpanel;

      // Render modal
      modal = (
        <CreateEditLoungeSubpanel
          userAndCourseInfo={userAndCourseInfo}
          lounge={lounge}
          onFinish={(updatedLounge) => {
            // Update the lounges list
            dispatch({
              type: ActionType.LoungeEditOrCreateFinished,
              lounge: updatedLounge,
            });
          }}
          onCancel={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
        />
      );
    }

    /* -------------- Help -------------- */

    if (subpanel?.type === SubpanelType.Help) {
      // Render modal
      modal = (
        <HelpSubpanel
          onClose={() => {
            // Hide the modal
            dispatch({
              type: ActionType.HideSubpanel,
            });
          }}
        />
      );
    }

    /* ------------- CheckIn ------------ */

    if (subpanel?.type === SubpanelType.CheckInSetup) {
      const { eventToCheckPeopleInto } = subpanel;
      const assignToGroups = (
        eventToCheckPeopleInto.inPersonConfig?.assignToGroups
        ?? AssignToGroups.Ask
      );
      // Render CheckInSubpanel
      modal = (
        <CheckInSubpanel
          courseId={courseId}
          ihid={eventToCheckPeopleInto.ihid}
          assignToGroups={assignToGroups}
          subTextForQr={eventToCheckPeopleInto.name}
          onClose={() => {
            dispatch({
              type: ActionType.CloseCheckIn,
            });
          }}
          userAndCourseInfo={userAndCourseInfo}
        />
      );
    }
  }

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Destructure state
    const {
      loadingMessage,
    } = state;

    // Loading spinner
    body = (
      <div>
        <LoadingSpinner />
        {loadingMessage && (
          <div className="text-muted">
            {loadingMessage}
          </div>
        )}
      </div>
    );
  }

  /* -------------- Home -------------- */

  if (view === View.Home) {
    // Destructure state
    const {
      events,
      lounges,
      greeting,
      shortGreeting,
      showPublishedRecordings,
    } = state;

    // List of event item elements
    let eventItemElems: React.ReactNode;

    // Check if there are any events
    if (events.length > 0) {
      // Sort events
      events.sort(eventComparator);

      // Create event buttons
      eventItemElems = (
        events
          // Filter out archived events
          .filter((event) => {
            return !event.archived;
          })
          // Create elements
          .map((event, i) => {
            return (
              <EventItem
                key={event.ihid}
                position={i}
                isLearner={isLearner}
                event={event}
                onShowLink={() => {
                  // Show the shareable link
                  dispatch({
                    type: ActionType.ShowShareableLink,
                    courseId,
                    event,
                  });
                }}
                onViewRecordings={() => {
                  // Show the recordings for this event
                  dispatch({
                    type: ActionType.ShowEventRecordings,
                    event,
                  });
                }}
                onShowAttendance={() => {
                  // Show the attendance subpanel
                  dispatch({
                    type: ActionType.ShowAttendance,
                    event,
                  });
                }}
                onJoin={() => {
                  if (isLearner) {
                    // Skip the join type chooser because user is a participant
                    showJoinModal({
                      event,
                    });
                  } else {
                    // Allow user to choose a join type
                    dispatch({
                      type: ActionType.ShowChooseEventJoinTypeModal,
                      event,
                    });
                  }
                }}
                onEdit={() => {
                  // Show the event editor
                  dispatch({
                    type: ActionType.ShowEventEditor,
                    event,
                  });
                }}
                onStartCheckIn={() => {
                  dispatch({
                    type: ActionType.StartCheckIn,
                    event,
                  });
                }}
              />
            );
          })
      );
    } else {
      // No events notice
      eventItemElems = (
        <NothingHereNotice
          title="No events yet"
          subtitle={(
            <span className="d-none d-sm-inline">
              This course doesn&apos;t have any current events.&nbsp;
              {
                isLearner
                  ? 'Please check back later.'
                  : 'Create one by clicking below.'
              }
            </span>
          )}
        />
      );
    }

    /* ------- Create Event Button ------ */

    const createEventButton = (
      isLearner
        ? undefined
        : (
          <div className="d-grid">
            <button
              type="button"
              id="Home-create-event-button"
              className="btn btn-lg btn-light btn-block p-3 mb-2"
              style={{ border: '0.15rem dashed black' }}
              aria-label="create a new event"
              onClick={() => {
                // Show the event creator
                dispatch({
                  type: ActionType.ShowEventCreator,
                });
              }}
            >
              <h3 className="m-0">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                />
                {/* Small Screen View */}
                <span className="d-inline d-sm-none">
                  Create
                </span>
                {/* Large Screen View */}
                <span className="d-none d-sm-inline">
                  Create Event
                </span>
              </h3>
            </button>
          </div>
        )
    );

    /* ---------- Lounge Items ---------- */

    const loungeButtons = lounges.map((lounge) => {
      return (
        <LoungeItem
          key={lounge.loungeId}
          lounge={lounge}
          isLearner={isLearner}
          onEdit={() => {
            // Show the lounge editor
            dispatch({
              type: ActionType.ShowLoungeEditor,
              lounge,
            });
          }}
          onJoin={() => {
            // Show the preparation modal
            dispatch({
              type: ActionType.ShowLoungePreparation,
              lounge,
            });
          }}
        />
      );
    });

    /* ------ Create Lounge Button ------ */

    let createLoungeButton: React.ReactNode;
    if (isAdmin && lounges.length < 1) {
      createLoungeButton = (
        <div className="d-grid">
          <button
            type="button"
            id="Home-create-lounge-button"
            className="btn btn-lg btn-success progress-bar-striped btn-block p-3 mb-2"
            aria-label="create a new lounge"
            onClick={() => {
              // Show the lounge creator
              dispatch({
                type: ActionType.ShowLoungeCreator,
              });
            }}
          >
            <h3 className="m-0">
              <FontAwesomeIcon
                icon={faPlus}
                className="me-2"
              />
              Create Lounge
            </h3>
          </button>
        </div>
      );
    }

    /* ------- Other Pages Buttons ------ */

    // Create an array of buttons to show on the home page
    const morePageButtons: React.ReactNode[] = [];

    // Published recordings
    if (showPublishedRecordings) {
      morePageButtons.push(
        <button
          key="all-published-recordings"
          id="Home-view-published-recordings-button"
          type="button"
          aria-label="view the list of published recordings for this course"
          className="Home-shrinking-button btn btn-secondary btn-lg me-2 mb-2"
          onClick={() => {
            dispatch({
              type: ActionType.ShowPublishedRecordings,
            });
          }}
        >
          <FontAwesomeIcon
            icon={faFileVideo}
            className="me-2"
          />
          Published Recordings
        </button>,
      );
    }

    // Put buttons into a TabBox
    const morePageContainer = (
      morePageButtons.length > 0
        ? (
          <TabBox
            title="More Pages"
            noBottomPadding
          >
            {morePageButtons}
          </TabBox>
        )
        : null
    );

    // Create body
    body = (
      <div>
        {/* Header */}
        <h2 id="Home-page-title" className="m-0">
          {/* Short Greeting for Small Screens */}
          <span className="d-inline d-md-none">
            {shortGreeting}
          </span>
          {/* Longer Greeting for Large Screens */}
          <span className="d-none d-md-inline">
            {greeting}
          </span>
        </h2>

        {/* Current Course */}
        <p
          id={`Home-current-course-with-canvas-id-${courseId}`}
          className="lead"
        >
          Current course:&nbsp;
          {courseName}
        </p>

        {/* More Pages (if there are any) */}
        {morePageContainer && (
          <div className="mt-4">
            {morePageContainer}
          </div>
        )}

        {/* Lounges */}
        {(loungeButtons.length > 0 || isAdmin) && (
          <div className="mt-4">
            <TabBox
              title="Study Lounges (open 24/7)"
              noBottomPadding
            >
              {/* Lounge List */}
              {loungeButtons}

              {/* Create Lounge Button (if it is there) */}
              {createLoungeButton}
            </TabBox>
          </div>
        )}

        {/* Events */}
        <div className="mt-4">
          <TabBox
            title="Course Events"
            noBottomPadding
          >
            {/* Event List */}
            {eventItemElems}

            {/* Create Event Button (if it is there) */}
            {createEventButton}
          </TabBox>
        </div>

        {/* Admin Role Indicator */}
        {isAdmin && (
          <div className="small text-muted">
            <div id="Home-role-is-admin-message">
              Admin-only features have a&nbsp;
              <span
                className="badge bg-success progress-bar-striped"
                style={{
                  transform: 'translateY(-0.1rem)',
                }}
              >
                striped green background
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Header ------------- */

  // Right button
  let headerRightButton: HeaderButtonInfo | undefined;
  if (userAndCourseInfo.isAdmin) {
    // Go to admin panel button
    headerRightButton = {
      onClick: onSwitchToAdminPanel,
      contents: (
        <span>
          <FontAwesomeIcon
            icon={faUserCog}
          />
          <span className="d-none d-sm-inline">
            {' '}
            Admin Panel
          </span>
        </span>
      ),
      isAdminFeature: true,
    };
  }

  // Help and Tutorials
  let helpButton: HeaderButtonInfo | undefined;
  if (!isLearner) {
    helpButton = {
      icon: null,
      contents: (
        <span>
          <FontAwesomeIcon
            icon={faCircleInfo}
          />
          <span className="d-none d-sm-inline">
            {' '}
            View Tutorial
          </span>
        </span>
      ),
      onClick: () => {
        dispatch({
          type: ActionType.ShowHelp,
        });
      },
    };
  }

  const header = (
    <Header
      leftButton={helpButton}
      rightButton={headerRightButton}
      userAndCourseInfo={userAndCourseInfo}
    />
  );

  /* ---------- Put Together ---------- */

  return (
    <div className="App-content-container">
      {/* Header */}
      {header}

      {/* Add Modal */}
      {modal}

      {/* All content (add "below header" class if header exists) */}
      <div className="App-content-below-header">
        {/* Content */}
        {body}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default Home;
