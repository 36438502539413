/**
 * Form element for an event's zoom meeting (or webinar)
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import {
  ButtonInputGroup,
  Drawer,
  LoadingSpinner,
  RadioButton,
  Variant,
  confirm,
} from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faExternalLinkAlt,
  faUser,
  faLock,
  faFileImport,
  faBars,
} from '@fortawesome/free-solid-svg-icons';

// Import shared types
import SupportEmail from '../../../../shared/types/from-server/SupportEmail';

// Import shared helpers
import showThisIsLockedModal from '../../../../shared/helpers/showThisIsLockedModal';

// Import shared constants
import MIN_EVENT_FORM_LABEL_WIDTH from '../../../../shared/constants/MIN_EVENT_FORM_LABEL_WIDTH';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler to call when detaching Zoom
  onDetach: () => void,
  // Handler to call when user chooses to attach Zoom meeting
  onAttach: () => void,
  // Handler for when the user requests a resecure process
  onResecureRequested: () => void,
  // Handler for when the user requests a reimport process
  onReimportRequested: () => void,
  // If true, TTMs cannot toggle whether the event is held in Zoom
  // (cannot add or remove Zoom)
  lockZoomToggle?: boolean,
  // If true, TTMs cannot edit Zoom auto record setting
  lockAutoRecordSetting?: boolean,
  // If true, auto-record setting is on
  autoRecordOn: boolean,
  /**
   * Function to handle an update to auto record setting
   * @param on true if on
   */
  onAutoRecordChanged: (on: boolean) => void,
  // If true, waiting room is on
  waitingRoomOn: boolean,
  // If true, using a webinar
  isWebinar: boolean,
  // If true, show a loading indicator in the drawer
  isLoading?: boolean,
  /**
   * Function to handle an update to waiting room setting
   * @param on true if on
   */
  onWaitingRoomChanged: (on: boolean) => void,
  // The event's zoom meeting
  currentZoomId?: number | null,
  // Link to open Zoom meeting in the admin panel
  revealInAdminPanelURL?: string,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ZoomInfo: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    currentZoomId,
    onAttach,
    onDetach,
    lockZoomToggle,
    lockAutoRecordSetting,
    autoRecordOn,
    isWebinar,
    isLoading,
    onAutoRecordChanged,
    waitingRoomOn,
    onWaitingRoomChanged,
    revealInAdminPanelURL,
    onReimportRequested,
    onResecureRequested,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Check if Zoom is attached
  const expanded = !!currentZoomId;

  // Create drawer contents
  let drawerContents: React.ReactNode;
  if (isLoading) {
    drawerContents = (
      <LoadingSpinner />
    );
  } else if (expanded) {
    // Show warning for webinars
    if (isWebinar) {
      drawerContents = (
        <div>
          <div className="fw-bold">
            <FontAwesomeIcon
              icon={faUser}
              className="me-1"
            />
            Managed by DCE Staff
          </div>
          <div>
            To make changes to this Zoom webinar,
            please contact DCE support at
            {' '}
            <a
              href={`mailto:${SupportEmail.ForTTMs}`}
              style={{
                color: 'inherit',
                textDecoration: 'underline',
              }}
            >
              {SupportEmail.ForTTMs}
            </a>
            .
          </div>
          {/* Reveal in Admin Panel */}
          {revealInAdminPanelURL && (
            <div className="mt-1">
              <a
                href={revealInAdminPanelURL}
                target="_blank"
                rel="noopener noreferrer"
                className="ZoomInfo-webinar-id-link btn progress-bar-striped fw-bold bg-success text-white pt-0 pb-0 btn-sm"
                aria-label="reveal the webinar in Zoom"
                id="ZoomInfo-reveal-webinar-in-zoom-button"
                style={{
                  textDecoration: 'none',
                }}
              >
                Reveal
                <FontAwesomeIcon
                  icon={faExternalLinkAlt}
                  className="ms-1"
                />
              </a>
            </div>
          )}
        </div>
      );
    } else {
      // Create auto-record toggle
      const autoRecordToggle = (
        <div className="mt-3">
          <ButtonInputGroup
            label="Auto-record"
            noMarginOnBottom
            minLabelWidth="8rem"
          >
            {/* Turn on auto-record Button */}
            <RadioButton
              id="ZoomInfo-turn-on-auto-record-button"
              ariaLabel="Auto Record On"
              selected={autoRecordOn}
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {
                // Skip if already changed
                if (autoRecordOn) {
                  return;
                }

                // Show lock
                if (lockAutoRecordSetting) {
                  return showThisIsLockedModal();
                }

                // Change feature
                onAutoRecordChanged(true);
              }}
              text="On"
            />

            {/* Turn off auto-record Button */}
            <RadioButton
              id="ZoomInfo-turn-off-auto-record-button"
              ariaLabel="Auto Record Off"
              selected={!autoRecordOn}
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {
                // Skip if already changed
                if (!autoRecordOn) {
                  return;
                }

                // Show lock
                if (lockAutoRecordSetting) {
                  return showThisIsLockedModal();
                }

                // Change feature
                onAutoRecordChanged(false);
              }}
              text="Off"
            />
          </ButtonInputGroup>

          {/* Explanation of auto-record */}
          <div className="text-muted text-start">
            <small>
              If &quot;Auto-record&quot; is on,
              whenever this event occurs,
              it will automatically be recorded.
            </small>
          </div>
        </div>
      );

      // Create waiting room toggle
      const waitingRoomToggle = (
        <div className="mt-3">
          <ButtonInputGroup
            label="Waiting Room"
            noMarginOnBottom
            minLabelWidth="8rem"
          >
            {/* Turn on waiting room Button */}
            <RadioButton
              id="ZoomInfo-turn-waiting-room-on-button"
              ariaLabel="Waiting Room On"
              selected={waitingRoomOn}
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {
                // Skip if already changed
                if (waitingRoomOn) {
                  return;
                }

                // Change feature
                onWaitingRoomChanged(true);
              }}
              text="On"
            />

            {/* Turn off waiting room Button */}
            <RadioButton
              id="ZoomInfo-turn-waiting-room-off-button"
              ariaLabel="Waiting Room Off"
              selected={!waitingRoomOn}
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {
                // Skip if already changed
                if (!waitingRoomOn) {
                  return;
                }

                // Change feature
                onWaitingRoomChanged(false);
              }}
              text="Off"
            />
          </ButtonInputGroup>

          {/* Explanation of waiting room */}
          <div className="text-muted text-start">
            <small>
              If &quot;Waiting Room&quot; is on,
              the host must individually admit each
              participant as they arrive.
            </small>
          </div>
        </div>
      );

      // Create attendance "fake" toggle
      const attendanceToggle = (
        <div className="mt-3">
          <ButtonInputGroup
            label="Attendance"
            noMarginOnBottom
            minLabelWidth="8rem"
          >
            {/* Fake toggle */}
            <RadioButton
              ariaLabel="attendance on"
              selected
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {}}
              text="On"
            />
            {/* Description */}
            <span className="text-muted fst-italic">
              (always on)
            </span>
          </ButtonInputGroup>

          {/* Explanation of waiting room */}
          <div className="text-muted text-start">
            <small>
              Attendance is taken automatically.
              To view attendance, click
              {' '}
              <span className="sr-only">
                more options
              </span>
              <span style={{ pointerEvents: 'none' }}>
                <button
                  type="button"
                  className="btn btn-secondary btn-sm pt-0 pb-0 ps-1 pe-1"
                  aria-label="visual depiction of more options button, not interactive"
                >
                  <FontAwesomeIcon
                    icon={faBars}
                  />
                </button>
              </span>
              {' '}
              and select &quot;Attendance&quot;.
            </small>
          </div>
        </div>
      );

      // Render contents
      drawerContents = (
        <div className="text-center">
          <div>
            <div>
              <strong>
                Zoom Meeting Settings&nbsp;
              </strong>
              | ID&nbsp;
              <span id="ZoomInfo-attached-zoom-meeting-id">
                {currentZoomId}
              </span>
            </div>
            {revealInAdminPanelURL && (
              <div>
                <a
                  href={revealInAdminPanelURL}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="ZoomInfo-id-link btn progress-bar-striped fw-bold bg-success text-white pt-0 pb-0 btn-sm ms-2"
                  aria-label="reveal the meeting in Zoom"
                  id="ZoomInfo-reveal-meeting-in-zoom-button"
                  style={{
                    textDecoration: 'none',
                  }}
                >
                  Reveal
                  <FontAwesomeIcon
                    icon={faExternalLinkAlt}
                    className="ms-1"
                  />
                </a>
                <button
                  type="button"
                  className="btn progress-bar-striped fw-bold bg-success text-white pt-0 pb-0 btn-sm ms-1"
                  aria-label="reimport the meeting from Zoom"
                  id="ZoomInfo-reimport-meeting-from-zoom-button"
                  onClick={onReimportRequested}
                >
                  Reimport
                  <FontAwesomeIcon
                    icon={faFileImport}
                    className="ms-1"
                  />
                </button>
                <button
                  type="button"
                  className="btn progress-bar-striped fw-bold bg-success text-white pt-0 pb-0 btn-sm ms-1"
                  aria-label="reimport the meeting from Zoom"
                  id="ZoomInfo-reimport-meeting-from-zoom-button"
                  onClick={async () => {
                    // Confirm
                    const confirmed = await confirm(
                      'Resecure Zoom Meeting?',
                      'We will generate a new password and direct Zoom link for this meeting.',
                      {
                        confirmButtonText: 'Resecure Now',
                        confirmButtonVariant: Variant.Dark,
                      },
                    );
                    if (!confirmed) {
                      return;
                    }

                    // Resecure
                    onResecureRequested();
                  }}
                >
                  Resecure
                  <FontAwesomeIcon
                    icon={faLock}
                    className="ms-1"
                  />
                </button>
              </div>
            )}
          </div>

          {autoRecordToggle}
          {waitingRoomToggle}
          {attendanceToggle}
        </div>
      );
    }
  }

  // Return full UI (most logic is inline)
  return (
    <div className="mb-3">
      <ButtonInputGroup
        label={(
          <span>
            <FontAwesomeIcon
              icon={faHeadset}
              className="me-1"
            />
            Zoom
          </span>
        )}
        minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
        noMarginOnBottom={expanded}
      >
        {/* Loading Indicator */}
        {isLoading && (
          <span>
            Working...
          </span>
        )}

        {/* Options */}
        {!isLoading && (
          <>
            {/* Attach button */}
            <RadioButton
              id="ZoomInfo-held-in-zoom-button"
              ariaLabel="Held in Zoom"
              selected={!!currentZoomId}
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {
                // Skip if already attached
                if (currentZoomId) {
                  return;
                }

                // Show lock
                if (lockZoomToggle) {
                  return showThisIsLockedModal();
                }

                // Attach
                onAttach();
              }}
              text="Held in Zoom"
            />

            {/* Detach button */}
            <RadioButton
              id="ZoomInfo-not-held-in-zoom-button"
              ariaLabel="Not held in Zoom"
              selected={!currentZoomId}
              selectedVariant={Variant.Warning}
              small
              onSelected={() => {
                // Skip if already done
                if (!currentZoomId) {
                  return;
                }

                // Show lock
                if (lockZoomToggle) {
                  return showThisIsLockedModal();
                }

                // Detach
                onDetach();
              }}
              text="Not Held in Zoom"
            />
          </>
        )}
      </ButtonInputGroup>

      {/* Drawer for Zoom settings */}
      {drawerContents && (
        <Drawer grayBackground>
          {drawerContents}
        </Drawer>
      )}
    </div>
  );
};

export default ZoomInfo;
