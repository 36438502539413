// Import types
import MMDDYY from '../types/MMDDYY';

/**
 * Create a string representation of an MMDDYY object.
 * @author Benedikt Arnarsson
 * @param mmddyy the inputted object with `year`, `month`, and `day` properties.
 * @returns a string representing the object, of the form YYYY.[M]M.[D]D
 */
const toStringMMDDYY = (mmddyy: MMDDYY): string => {
  const {
    year,
    month,
    day,
  } = mmddyy;
  return `${year}.${month}.${day}`;
};

export default toStringMMDDYY;
