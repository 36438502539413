/**
 * A notice indicating that no items are left
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faKiwiBird } from '@fortawesome/free-solid-svg-icons';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Title of the notice
  title?: React.ReactNode,
  // Subtitle of the notice
  subtitle?: React.ReactNode,
  // If true, no margin on bottom
  noMarginOnBottom?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const NothingHereNotice: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    title = 'Nothing Here',
    subtitle = 'Please check back later.',
    noMarginOnBottom,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Full UI
  return (
    <div
      className={`alert alert-warning text-center mb-${noMarginOnBottom ? '0' : '2'}`}
    >
      {/* Bird Icon */}
      <div
        style={{
          fontSize: '2.5rem',
          lineHeight: '1.875rem',
        }}
      >
        <FontAwesomeIcon icon={faKiwiBird} />
      </div>

      {/* Title */}
      <h5 className="text-center m-0 NothingHereNotice-title">
        {title}
      </h5>

      {/* Subtitle */}
      <div className="text-center NothingHereNotice-subtitle">
        {subtitle}
      </div>
    </div>
  );
};

export default NothingHereNotice;
