/**
 * First modal of the check-in flow.
 * Checks the Occurrence backend and either goes to CheckInModal or
 * starts setup.
 * @author Benedikt Arnarsson
 */

// Import React
import React, { useEffect } from 'react';

// Import DCE reactkit
import {
  getTimeInfoInET,
  LoadingSpinner,
} from 'dce-reactkit';

// Import types
import CheckInIds from '../../types/CheckInIds';
import MMDDYY from '../../types/MMDDYY';

// Import helpers
import isOccurrenceStarted from './isOccurrenceStarted';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = (
  & CheckInIds
  & {
    /**
     * Continue setup process
     * @param mmddyy occurrence date
     * @param occIsStarted if true, occurrence is started
     */
    onSubmit: (mmddyy: MMDDYY, occIsStarted?: boolean) => void,
  }
);

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoadOccurrence: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    ihid,
    onSubmit,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Benedikt Arnarsson
   */
  useEffect(
    () => {
      (async () => {
        const {
          year,
          month,
          day,
        } = getTimeInfoInET();
        const mmddyy = {
          year,
          month,
          day,
        };

        // Build basic info
        const occBasicInfo = {
          courseId,
          ihid,
          ...mmddyy,
        };

        // Determine whether the occurrence has started
        const occIsStarted = await isOccurrenceStarted(occBasicInfo);

        // If the occurrence has started, we skip straight to the QR card
        // Otherwise, start the occurrence creation process
        onSubmit(mmddyy, occIsStarted);
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <LoadingSpinner />
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoadOccurrence;
