/**
 * Instructions on how to set up a Google Sheet
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  ModalType,
} from 'dce-reactkit';

// Import constants
import COLUMN_HEADERS from '../constants/COLUMN_HEADERS';

// Import shared types
import GatherTag from '../../../../shared/types/from-server/GatherTag';

// Import shared components
import CSVDownloadButton from '../../../../shared/CSVDownloadButton';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler for when modal is closed
  onClose: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const GoogleSheetInstructionsModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onClose,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------------ Examples and Descriptions ----------------- */

  // Add a human-readable header name to the headers
  const headers = COLUMN_HEADERS.map((header) => {
    // > Capitalize the name
    const text = header.names[0];
    // > Figure out which tag to use
    const tag = (header.isWriteColumn ? GatherTag.Write : GatherTag.Read);
    // > Concatenate the name and tag
    const newName = `${text} ${tag}`;

    return {
      ...header,
      name: newName,
    };
  });

  // Turn the list of headers into descriptions
  // > List of header descriptions (read-only columns)
  const readHeaderDescriptions: React.ReactNode[] = [];
  // > List of header descriptions (write-only columns)
  const writeHeaderDescriptions: React.ReactNode[] = [];
  // > Header row of the example spreadsheet
  const exampleHeader: React.ReactNode[] = [];
  // > First row of the example spreadsheet
  const exampleRow1: React.ReactNode[] = [];
  // > Second row of the example spreadsheet
  const exampleRow2: React.ReactNode[] = [];
  // > Third row of the example spreadsheet (ellipses)
  const exampleRow3: React.ReactNode[] = [];
  // CSV information
  const exampleHeaderMap: {
    [prop: string]: string;
  } = {};
  const exampleData: {
    [prop: string]: React.ReactNode
  }[] = [{}, {}];
  headers.forEach((header) => {
    const description = (
      <div
        key={header.prop}
        className="alert small alert-light p-1 mb-2 text-dark"
        id={`GoogleSheetInstructionsModal-header-description-${header.prop}`}
      >
        {/* Title */}
        <div>
          <strong>
            {header.name}
          </strong>
          &nbsp;
          <em>
            {header.description}
          </em>
        </div>

        {/* Tags */}
        <div>
          {/* Required/optional tag */}
          <div
            className={`badge bg-${header.required ? 'danger' : 'info'} me-1`}
            id={`GoogleSheetInstructionsModal-header-description-${header.prop}-${header.required ? 'required' : 'optional'}-tag`}
          >
            {header.required ? 'Required' : 'Optional'}
          </div>

          {/* Type */}
          <div
            className="badge bg-secondary me-1"
            id={`GoogleSheetInstructionsModal-header-description-${header.prop}-type-${header.type}-tag`}
          >
            Type:&nbsp;
            {header.type}
          </div>
        </div>
      </div>
    );

    // Add to the appropriate list
    if (header.isWriteColumn) {
      writeHeaderDescriptions.push(description);
    } else {
      readHeaderDescriptions.push(description);
    }

    // Add examples
    exampleHeader.push(
      <th
        scope="col"
        key={header.prop}
        className="text-center border"
        style={{ whiteSpace: 'nowrap' }}
        id={`GoogleSheetInstructionsModal-${header.prop}-example-header`}
      >
        {header.name}
      </th>,
    );
    exampleRow1.push(
      <td
        key={header.prop}
        className="text-center border"
        style={{ whiteSpace: 'nowrap' }}
        id={`GoogleSheetInstructionsModal-${header.prop}-example-r1`}
      >
        {header.examples[0]}
      </td>,
    );
    exampleRow2.push(
      <td
        key={header.prop}
        className="text-center border"
        style={{ whiteSpace: 'nowrap' }}
        id={`GoogleSheetInstructionsModal-${header.prop}-example-r2`}
      >
        {header.examples[1]}
      </td>,
    );
    exampleRow3.push(
      <td
        key={header.prop}
        className="text-center border text-muted"
        id={`GoogleSheetInstructionsModal-${header.prop}-example-r3`}
        aria-label="Example of a row with more columns than shown here."
      >
        <FontAwesomeIcon icon={faEllipsisV} />
      </td>,
    );

    // Add to CSV
    exampleHeaderMap[header.prop] = header.name;
    exampleData[0][header.prop] = (
      typeof header.examples[0] === 'object'
        ? 'Add Checkbox Here'
        : header.examples[0]
    );
    exampleData[1][header.prop] = (
      typeof header.examples[1] === 'object'
        ? 'Add Checkbox Here'
        : header.examples[1]
    );
  });

  // Example table
  const exampleSheet = (
    <div style={{ overflowX: 'auto' }}>
      <table
        className="table table-striped"
        id="GoogleSheetInstructionsModal-example-sheet-table"
      >
        {/* Header row */}
        <thead>
          <tr>
            {exampleHeader}
          </tr>
        </thead>

        {/* Body */}
        <tbody>
          <tr>
            {exampleRow1}
          </tr>
          <tr>
            {exampleRow2}
          </tr>
          <tr>
            {exampleRow3}
          </tr>
        </tbody>
      </table>
    </div>
  );

  // Create the example download button
  const exampleDownloadButton = (
    <CSVDownloadButton
      filename="Example Batch Creation Spreadsheet"
      headerMap={exampleHeaderMap}
      data={exampleData}
      id="GoogleSheetInstructionsModal-download-example-spreadsheet"
      title="Example Spreadsheet"
    />
  );

  /* ------------------------- Assemble UI ------------------------ */

  const body = (
    <div>
      {/* Tab Instructions */}
      <div
        className="mb-5"
        id="GoogleSheetInstructionsModal-tab-instructions"
      >
        {/* Step */}
        <h4>
          1. Mark your tab
        </h4>

        {/* Explanation */}
        <div>
          End your spreadsheet tab with the &quot;
          {GatherTag.Read}
          &quot; mark. See the example below:
        </div>

        {/* Visual example */}
        <div>
          <ul
            className="nav nav-tabs"
            style={{ pointerEvents: 'none' }}
            id="GoogleSheetInstructionsModal-tab-instructions-example"
          >
            <li className="nav-item">
              <span className="nav-link text-dark">
                First Tab
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link active">
                Main&nbsp;
                {GatherTag.Read}
              </span>
            </li>
            <li className="nav-item">
              <span className="nav-link text-dark">
                Another Tab
              </span>
            </li>
          </ul>
        </div>
      </div>

      {/* Header Descriptions */}
      <div className="mb-5">
        <h4>
          2. Add columns
        </h4>
        <div className="mb-1">
          If you leave out an
          {' '}
          <div className="badge bg-info">
            Optional
          </div>
          {' '}
          column,
          Gather will treat it as every row having an
          unchecked checkbox.
        </div>
        <div className="mb-2">
          You can also add your own columns. Gather will ignore them.
        </div>

        {/* Read */}
        <div className="text-center">
          <strong>
            Read-only Columns Marked With &quot;
            {GatherTag.Read}
            &quot;
          </strong>
          &nbsp;(Gather reads from these):
        </div>
        <div
          className="alert alert-dark ps-2 pe-2 pt-2 pb-0"
          id="GoogleSheetInstructionsModal-read-header-descriptions"
        >
          {readHeaderDescriptions}
        </div>

        {/* Write */}
        <div className="text-center">
          <strong>
            Write-only Columns Marked With &quot;
            {GatherTag.Write}
            &quot;
          </strong>
          &nbsp;(Gather writes to these):
        </div>
        <div
          className="alert alert-dark ps-2 pe-2 pt-2 pb-0"
          id="GoogleSheetInstructionsModal-write-header-descriptions"
        >
          {writeHeaderDescriptions}
        </div>
      </div>

      {/* Example */}
      <div>
        <h4>
          3. Check Out This Example
        </h4>
        {exampleSheet}
        <div className="text-center mt-2">
          {exampleDownloadButton}
        </div>
      </div>
    </div>
  );

  /* ------------------------ Create Modal ------------------------ */

  return (
    <Modal
      title="How to Set Up the Spreadsheet"
      type={ModalType.NoButtons}
      onClose={onClose}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default GoogleSheetInstructionsModal;
