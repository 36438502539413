/**
 * Icon representing check-in
 * @author Benedikt Arnarsson
 */

// Import React
import React from 'react';

// Import SVG
import UserCircleCheck from './UserCircleCheck.svg';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Optional em size, defaults to 2em
  sizeEm?: number,
  // Light mode, defaults to false
  lightMode?: boolean,
};

const CheckInIcon: React.FC<Props> = (props) => {
  // Destructure props
  const {
    sizeEm = 2,
    lightMode = false,
  } = props;

  // Get inversion percentage based on choice of light/dark mode
  const invertPerc = (
    lightMode
      ? '100%'
      : '0%'
  );

  // Create style based on props
  const style = {
    height: `${sizeEm}em`,
    width: `${sizeEm}em`,
    '-webkit-filter': `invert(${invertPerc})`, /* safari 6.0 - 9.0 */
    filter: `invert(${invertPerc})`,
  };

  return (
    <img
      className="EventItem-check-in-logo me-1"
      src={UserCircleCheck}
      style={style}
      alt="CheckInLogo"
    />
  );
};

export default CheckInIcon;
