/**
 * A blinking smiley face
 * @author Gabe Abrams
 */

// Import React
import React, { useRef } from 'react';

// Import dce-reactkit
import {
  Tooltip,
} from 'dce-reactkit';

// Import style
import './Smiley.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Student name
  name: string,
};

// Customized eye information
type EyeInfo = {
  // Animation delay
  eyeAnimationDelay: string,
  // Animation direction
  eyeAnimationDirection: 'normal' | 'reversed',
  // Animation duration
  eyeAnimationDuration: string,
  // Blink animation delay
  blinkAnimationDelay: string,
  // Blink animation duration
  blinkAnimationDuration: string,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

const WIDTH_REM = 2; // Also must update variable in .scss file

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Smiley: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    name,
  } = props;

  /* -------------- Refs -------------- */

  // Initialize refs
  const eyeInfo = useRef<null | EyeInfo>(null);

  // Determine random offsets for animations
  if (!eyeInfo.current) {
    eyeInfo.current = {
      eyeAnimationDelay: `-${Math.round(Math.random() * 40 * 100) / 100}s`,
      eyeAnimationDirection: (
        Math.random() > 0.5
          ? 'normal'
          : 'reversed'
      ),
      eyeAnimationDuration: `${40 + Math.round(Math.random() * 10)}s`,
      blinkAnimationDelay: `-${Math.round(Math.random() * 30 * 100) / 100}s`,
      blinkAnimationDuration: `${30 + Math.round(Math.random() * 10)}s`,
    };
  }

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      className="d-inline-block"
      style={{ width: `${WIDTH_REM * 1.1}rem` }}
    >
      <Tooltip
        text={name}
      >
        <div className="Smiley-container">
          <div
            className="Smiley-eyes"
            style={{
              animationDelay: eyeInfo.current?.eyeAnimationDelay,
              animationDirection: eyeInfo.current?.eyeAnimationDirection,
              animationDuration: eyeInfo.current?.eyeAnimationDuration,
            }}
          >
            <div
              className="Smiley-left-eye"
              style={{
                animationDelay: eyeInfo.current?.blinkAnimationDelay,
                animationDuration: eyeInfo.current?.blinkAnimationDuration,
              }}
            />
            <div
              className="Smiley-right-eye"
              style={{
                animationDelay: eyeInfo.current?.blinkAnimationDelay,
                animationDuration: eyeInfo.current?.blinkAnimationDuration,
              }}
            />
          </div>
        </div>
      </Tooltip>
    </div>
  );
};

export default Smiley;
