/**
 * Form element for an event name
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { faFont } from '@fortawesome/free-solid-svg-icons';

// Shared components
import InputLabel from '../../../../shared/InputLabel';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Name of the event
  name: string,
  // Handler to call when event name changes
  onChange: (newValue: string) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const EventName: React.FC<Props> = (props: Props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    name,
    onChange,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Full UI
  return (
    <div
      className="input-group mb-3"
      key="name"
    >
      {/* Label */}
      <InputLabel
        icon={faFont}
        title="Name"
      />

      {/* Text input field for title */}
      <input
        type="text"
        className="form-control"
        aria-label="event name"
        id="CreateEditEvent-name"
        placeholder={'e.g. "Class" or "Office Hours"'}
        value={name}
        style={{
          height: '2.7rem',
        }}
        onChange={(e) => {
          // Update value in parent
          return onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default EventName;
