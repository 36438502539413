import CourseEvent from '../types/from-server/stored/CourseEvent';

/**
 * Comparator for sorting events
 * @author Gabe Abrams
 * @param {Event} firstEvent - the first event to compare
 * @param {Event} secondEvent - the second event to compare
 * @return {number} comparator result
 */
const eventComparator = (a: CourseEvent, b: CourseEvent) => {
  // Second, sort pinned events to the top
  if (a.pinned && !b.pinned) {
    return -1;
  }
  if (!a.pinned && b.pinned) {
    return 1;
  }

  // Extract ihid number
  const aIHIDNumber = Number.parseInt(a.ihid.substring(1), 10);
  const bIHIDNumber = Number.parseInt(b.ihid.substring(1), 10);

  // Compare numbers second
  if (aIHIDNumber < bIHIDNumber) {
    return -1;
  }
  if (aIHIDNumber > bIHIDNumber) {
    return 1;
  }

  // Events are equal
  return 0;
};

export default eventComparator;
