/**
 * Create a pre-assignment pairing (student + group number)
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// Import shared types
import User from '../../types/User';

// Import style
import './CreatePreAssignment.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // List of students to choose from
  students: User[],
  // Handler to call if the user cancels
  onCancel: () => void,
  /**
   * Handler to call when user is done creating the pre-assignment
   * @param studentId the id of the student to assign
   * @param groupNum the group number to assign the student to
   */
  onDone: (studentId: number, groupNum: number) => void,
  /**
   * Set the custom modal title
   * @param customTitle new custom title (or leave undefined to reset)
   */
  setCustomModalTitle: (customTitle?: string) => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Choose a student
  ChooseStudent = 'ChooseStudent',
  // Choose a group number
  ChooseGroup = 'ChooseGroup',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.ChooseStudent,
  }
  | {
    // Current view
    view: View.ChooseGroup,
    // Chosen student
    student: User,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Choose a student
  ChooseStudent = 'ChooseStudent',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ChooseStudent,
    // Chosen student
    student: User,
  }
);

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChooseStudent: {
      return {
        view: View.ChooseGroup,
        student: action.student,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CreatePreAssignment: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    students,
    onCancel,
    onDone,
    setCustomModalTitle,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.ChooseStudent,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* --------- Choose Student --------- */

  if (view === View.ChooseStudent) {
    // Create body
    body = (
      <div className="CreatePreAssignment-choose-student-container">
        <div className="CreatePreAssignment-students-container CreatePreAssignment-scrollable-container">
          {
            students.map((student) => {
              return (
                <div
                  key={student.userId}
                  className="CreatePreAssignment-student-choice alert alert-secondary mb-1 p-2 d-flex align-items-center"
                >
                  <h4 className="m-0 text-start flex-grow-1">
                    {student.userFirstName}
                    {' '}
                    {student.userLastName}
                  </h4>
                  <button
                    type="button"
                    className="CreatePreAssignment-student-choice-button btn btn-secondary"
                    aria-label={`select student ${student.userFirstName} ${student.userLastName}`}
                    onClick={() => {
                      // Set custom modal title
                      setCustomModalTitle(`Choose Group for ${student.userFirstName}`);

                      // Move to next step
                      dispatch({
                        type: ActionType.ChooseStudent,
                        student,
                      });
                    }}
                  >
                    Choose
                  </button>
                </div>
              );
            })
          }
        </div>
        <div className="mt-3 text-center">
          <button
            id="cancel-student-assignment-button"
            type="button"
            className="btn btn-dark btn-lg"
            aria-label="cancel assigning student to group"
            onClick={onCancel}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="me-2"
            />
            Cancel
          </button>
        </div>
      </div>
    );
  }

  /* ---------- Choose Group ---------- */

  if (view === View.ChooseGroup) {
    // Destructure state
    const {
      student,
    } = state;

    // Create a list of groups
    const numberOfGroups = 50;
    const groupNums: number[] = [];
    for (let i = 1; i <= numberOfGroups; i++) {
      groupNums.push(i);
    }

    // Create body
    body = (
      <div className="CreatePreAssignment-choose-group-container">
        <div className="CreatePreAssignment-group-choice-container CreatePreAssignment-scrollable-container">
          {
            groupNums.map((groupNum) => {
              return (
                <div
                  key={groupNum}
                  className="CreatePreAssignment-group-choice alert alert-secondary mb-1 p-2 d-flex align-items-center"
                >
                  <h4 className="mb-0 text-start flex-grow-1">
                    Group
                    {' '}
                    {groupNum}
                  </h4>
                  <button
                    type="button"
                    className="CreatePreAssignment-group-choice-button btn btn-secondary"
                    aria-label={`choose group ${groupNum}`}
                    onClick={() => {
                      onDone(student.userId, groupNum);
                    }}
                  >
                    Choose
                  </button>
                </div>
              );
            })
          }
        </div>
        <div className="mt-3 text-center">
          <button
            id="cancel-student-assignment-button"
            type="button"
            className="btn btn-dark btn-lg"
            aria-label="cancel assigning student to group"
            onClick={onCancel}
          >
            <FontAwesomeIcon
              icon={faChevronLeft}
              className="me-2"
            />
            Cancel
          </button>
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="CreatePreAssignment-outer-container">
      {/* Body */}
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default CreatePreAssignment;
