/**
 * Add component description
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import dce-reactkit
import {
  Modal,
  ModalType,
  onlyKeepLetters,
} from 'dce-reactkit';

// Import caccl
import CanvasUser from 'caccl/types/CanvasUser';

// Import shared types
import LoungeBan from '../../../shared/types/from-server/stored/LoungeBan';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Canvas ID for the current course
  courseId: number,
  // Name of the current course
  courseName: string,
  // List of students in the course that don't yet have a ban
  studentsWithoutBans: CanvasUser[],
  // Handler for when the user cancels
  onCancel: () => void,
  /**
   * Handler for when the user wants to create a ban
   * @param ban the ban to create
   */
  onStartCreation: (ban: LoungeBan) => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Choose a student
  ChooseStudent = 'ChooseStudent',
  // Choose a scope
  ChooseScope = 'ChooseScope',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.ChooseStudent,
  }
  | {
    // Current view
    view: View.ChooseScope,
    // The student to ban
    student: CanvasUser,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Choose a specific student for the ban
  ChooseStudent = 'ChooseStudent',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ChooseStudent,
    // The student to ban
    student: CanvasUser,
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChooseStudent: {
      return {
        ...state,
        view: View.ChooseScope,
        student: action.student,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CreateLoungeBanModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    courseName,
    studentsWithoutBans,
    onCancel,
    onStartCreation,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.ChooseStudent,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;
  let title = 'Create Lounge Ban';

  /* --------- Choose Student --------- */

  if (view === View.ChooseStudent) {
    // Choose title
    title = 'Choose Student to Ban:';

    // Create body
    body = (
      <div>
        {
          studentsWithoutBans.map((student) => {
            // Separate name into parts
            const [userLastName, userFirstName] = student.sortable_name.split(', ');

            // Render
            return (
              <div
                key={student.id}
                className="alert alert-secondary mb-2 p-1 d-flex align-items-center justify-content-center"
              >
                <h4 className="flex-grow-1 m-0">
                  {student.name}
                  {student.login_id && (
                    <span>
                      {' '}
                      (
                      {student.login_id}
                      )
                    </span>
                  )}
                </h4>
                <div>
                  <button
                    type="button"
                    className={`LoungeBanManager-choose-button-named-${onlyKeepLetters(userFirstName)}-${onlyKeepLetters(userLastName)} LoungeBanManager-choose-button btn btn-dark btn-sm`}
                    aria-label={`choose ${student.name} as the target of the ban`}
                    id={`LoungeBanManager-choose-${student.id}`}
                    onClick={() => {
                      // Move to next step
                      dispatch({
                        type: ActionType.ChooseStudent,
                        student,
                      });
                    }}
                  >
                    Choose
                  </button>
                </div>
              </div>
            );
          })
        }
      </div>
    );
  }

  /* ---------- Choose Scope ---------- */

  if (view === View.ChooseScope) {
    // Destructure state
    const {
      student,
    } = state;

    // Separate name into parts
    const [userLastName, userFirstName] = student.sortable_name.split(', ');

    // Choose title
    title = 'Choose Ban Scope:';

    // Create body
    body = (
      <div className="d-flex flex-row gap-2">
        <div
          className="flex-grow-1 d-grid"
          style={{
            flexBasis: 1,
          }}
        >
          <button
            type="button"
            className="LoungeBanManager-target-current-course btn btn-dark btn-lg"
            aria-label="set target to current course"
            onClick={() => {
              // Create ban
              const ban: LoungeBan = {
                userId: student.id,
                userFirstName,
                userLastName,
                banFromAllLounges: false,
                banFromCourseId: courseId,
                banFromCourseName: courseName,
                createdAt: Date.now(),
              };

              // Add ban
              onStartCreation(ban);
            }}
          >
            <h3 className="m-0">
              Just Current Course
            </h3>
            (Ban within
            {' '}
            {courseName}
            )
          </button>
        </div>
        <div
          className="flex-grow-1 d-grid"
          style={{
            flexBasis: 1,
          }}
        >
          <button
            type="button"
            className="LoungeBanManager-target-all-courses btn btn-dark btn-lg"
            aria-label="set target to all courses"
            onClick={() => {
              // Create ban
              const ban: LoungeBan = {
                userId: student.id,
                userFirstName,
                userLastName,
                banFromAllLounges: true,
                createdAt: Date.now(),
              };

              // Add ban
              onStartCreation(ban);
            }}
          >
            <h3 className="m-0">
              All Courses
            </h3>
            (Ban for all lounges)
          </button>
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      key="lounge-ban-creator"
      title={title}
      type={ModalType.NoButtons}
      onClose={onCancel}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default CreateLoungeBanModal;
