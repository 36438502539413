/**
 * Individual row of the breakthrough link
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faEye,
  faTrash,
  faQuestionCircle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import { getTimeInfoInET } from 'dce-reactkit';

// Import shared types
import BreakthroughLink from '../../../shared/types/from-server/stored/BreakthroughLink';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Link to display
  link: BreakthroughLink,
  // Event corresponding to the event
  eventInfo: {
    // Event name
    name: string,
    // Event icon
    icon: IconDefinition,
    // If true, event is archived
    archived: boolean,
    // IHID for the event that the link sends you to
    ihid: string,
  },
  // Handler for when user opts to view the link
  onView: () => void,
  // Handler for when user opts to delete the link
  onDelete: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Constants
const DAY_MS = 86400000;

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BreakthroughLinkRow: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    link,
    eventInfo,
    onView,
    onDelete,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  const {
    key,
    createdAt,
    creatorFirstName,
    creatorLastName,
    creatorIsAdmin,
    startYear,
    startMonth,
    startDay,
    durationDays,
    label,
    timesUsed,
  } = link;
  const {
    name,
    icon,
    archived,
    ihid,
  } = eventInfo;

  // Shortened label (truncate after 10chars)
  let shortenedLabel = '–';
  if (label && label.trim().length > 0) {
    shortenedLabel = (
      label.length > 10
        ? `${label.substring(0, 7)}...`
        : label
    );
  }

  // Create an end date based on start and duration
  const endDate = new Date(
    (new Date(`${startMonth}/${startDay}/${startYear}`)).getTime()
    + (DAY_MS * (durationDays - 1)),
  );
  const endMonth = (endDate.getMonth() + 1);
  const endDay = endDate.getDate();
  const endYear = endDate.getFullYear();
  const endYearSuffix = String(endYear).substring(2);
  const startYearSuffix = String(startYear).substring(2);

  // Schedule label
  // (only show years if not current year, exclude from
  // start if matches end)
  let schedule;
  const currentYear = (new Date()).getFullYear();
  if (durationDays === 1) {
    // Just start date
    schedule = (
      startYear === currentYear
        ? `${startMonth}/${startDay}`
        : `${startMonth}/${startDay}/${startYearSuffix}`
    );
  } else {
    // Date range
    const startDateLabel = (
      (startYear === currentYear || startYear === endYear)
        ? `${startMonth}/${startDay}`
        : `${startMonth}/${startDay}/${startYearSuffix}`
    );
    const endDateLabel = (
      endYear === currentYear
        ? `${endMonth}/${endDay}`
        : `${endMonth}/${endDay}/${endYearSuffix}`
    );
    schedule = `${startDateLabel} - ${endDateLabel}`;
  }

  // Description of when link was created
  // (Only show year if relevant: not current year)
  const createdDate = new Date(createdAt);
  const {
    year: createdYear,
    month: createdMonth,
    day: createdDay,
  } = getTimeInfoInET(createdDate);
  const createdDescription = (
    createdYear === currentYear
      ? `${createdMonth}/${createdDay}`
      : `${createdMonth}/${createdDay}/${createdYear}`
  );

  // Shortened name (truncate after 10chars)
  let shortenedName = 'Unknown';
  if (name) {
    shortenedName = (
      name.length > 10
        ? `${name.substring(0, 7)}...`
        : name
    );
  }

  // Create a table row
  return (
    <tr key={key}>
      {/* Label */}
      <td
        title={label || 'No label'}
        className={`BreakthroughLinkRow-event-${ihid}-label`}
      >
        {shortenedLabel}
      </td>
      {/* Event Name */}
      <td
        title={`${name || 'Unknown Event'}${archived ? ' (Deleted)' : ''}`}
        className={`BreakthroughLinkRow-event-${ihid}-name`}
      >
        <FontAwesomeIcon
          icon={icon || faQuestionCircle}
          className="me-1 d-none d-md-inline"
        />
        <span
          style={{
            textDecoration: (
              archived
                ? 'line-through'
                : undefined
            ),
          }}
        >
          {shortenedName}
        </span>
      </td>
      {/* Creator */}
      <td
        title={`${creatorFirstName} ${creatorLastName} (${creatorIsAdmin ? 'Admin' : 'TTM'})`}
        className={`BreakthroughLinkRow-event-${ihid}-creator BreakthroughLinkRow-role-${creatorIsAdmin ? 'admin' : 'ttm'}`}
      >
        {creatorFirstName}
        {' '}
        {creatorLastName.substring(0, 1)}
      </td>
      {/* Created At */}
      <td className={`BreakthroughLinkRow-event-${ihid}-created-at`}>
        {createdDescription}
      </td>
      {/* Schedule */}
      <td className={`BreakthroughLinkRow-event-${ihid}-schedule`}>
        {schedule}
      </td>
      {/* Visits */}
      <td className={`BreakthroughLinkRow-event-${ihid}-num-visits`}>
        {timesUsed || 0}
      </td>
      {/* Operations */}
      <td className={`BreakthroughLinkRow-event-${ihid}-buttons`}>
        <button
          type="button"
          className={`BreakthroughLinkRow-event-${ihid}-reveal-button BreakthroughLinkRow-reveal-button btn btn-secondary btn-sm me-1`}
          aria-label="reveal the breakthrough link"
          title="Reveal the Breakthrough Link"
          onClick={onView}
        >
          <FontAwesomeIcon
            icon={faEye}
          />
        </button>
        <button
          type="button"
          className={`BreakthroughLinkRow-event-${ihid}-delete-button BreakthroughLinkRow-delete-button btn btn-danger btn-sm`}
          aria-label="delete breakthrough link in this row"
          title="Delete Breakthrough Link"
          onClick={onDelete}
        >
          <FontAwesomeIcon
            icon={faTrash}
          />
        </button>
      </td>
    </tr>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BreakthroughLinkRow;
