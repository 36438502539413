/**
 * A customizable header at the top of the page
 * @author Gabe Abrams
 */

// Import React
import { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faBug } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  CopiableBox,
  DynamicWord,
  Modal,
  ModalType,
} from 'dce-reactkit';

// Import shared types
import SupportEmail from '../types/from-server/SupportEmail';
import UserAndCourseInfo from '../types/UserAndCourseInfo';
import HeaderButtonInfo from './types/HeaderButtonInfo';

// Import shared constants
import VERSION from '../constants/VERSION';

// Import logo
import logo from './logo.svg';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // If true, we are in the admin panel
  showingAdminPanel?: boolean,
  // Button on left of navbar
  leftButton?: HeaderButtonInfo,
  // Button on right of navbar
  rightButton?: HeaderButtonInfo,
  // User and course info
  userAndCourseInfo?: UserAndCourseInfo,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, report issue modal is visible
  reportIssueModalVisible: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the report issue modal
  ShowReportIssueModal = 'ShowReportIssueModal',
  // Hide the report issue modal
  HideReportIssueModal = 'HideReportIssueModal',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.ShowReportIssueModal
      | ActionType.HideReportIssueModal
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowReportIssueModal: {
      return {
        ...state,
        reportIssueModalVisible: true,
      };
    }
    case ActionType.HideReportIssueModal: {
      return {
        ...state,
        reportIssueModalVisible: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const Header: React.FC<Props> = (props: Props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    showingAdminPanel,
    leftButton,
    rightButton,
    userAndCourseInfo,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    reportIssueModalVisible: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    reportIssueModalVisible,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ---------- Report Issue ---------- */

  if (reportIssueModalVisible && userAndCourseInfo) {
    // Create modal
    modal = (
      <Modal
        type={ModalType.NoButtons}
        title={(
          <span>
            <FontAwesomeIcon
              icon={faBug}
              className="me-2"
            />
            Report Issue
          </span>
        )}
        onClose={() => {
          dispatch({
            type: ActionType.HideReportIssueModal,
          });
        }}
      >
        <div className="lead m-0">
          We&apos;re here to help! Either
          {' '}
          <a
            href={`mailto:${userAndCourseInfo.isLearner ? SupportEmail.ForStudents : SupportEmail.ForTTMs}?subject=Issue with Gather v${VERSION} in Canvas site ${userAndCourseInfo.courseId}`}
            aria-label="email DCE support"
            className="text-dark"
            id="Header-report-issue-modal-contact-email"
          >
            {DynamicWord.Click}
            {' '}
            to open your email client
          </a>
          {' '}
          or manually copy the following:
          <div className="d-grid gap-2 mt-3">
            <CopiableBox
              label="To:"
              text={(
                userAndCourseInfo.isLearner
                  ? SupportEmail.ForStudents
                  : SupportEmail.ForTTMs
              )}
            />
            <CopiableBox
              label="Subject:"
              text={`Issue with Gather v${VERSION} in Canvas site ${userAndCourseInfo.courseId}`}
            />
          </div>
        </div>
      </Modal>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Create buttons
  const leftButtonElem = (
    leftButton
      ? (
        <button
          type="button"
          className="Header-left-button btn btn-light text-dark"
          onClick={leftButton.onClick}
        >
          {/* Icon */}
          {leftButton.icon !== null && (
            <FontAwesomeIcon
              icon={leftButton.icon ?? faChevronLeft}
              className="me-1"
            />
          )}

          {/* Label */}
          {leftButton.contents}
        </button>
      )
      : null
  );
  let rightButtonElem: React.ReactNode | undefined;
  if (userAndCourseInfo) {
    rightButtonElem = (
      <button
        type="button"
        className={(
          (rightButton && rightButton.isAdminFeature)
            ? 'Header-right-button btn btn-success progress-bar-striped text-white'
            : 'Header-right-button btn btn-light text-dark'
        )}
        onClick={(
          rightButton
            // Included button
            ? rightButton.onClick
            // Report issue
            : () => {
              dispatch({
                type: ActionType.ShowReportIssueModal,
              });
            }
        )}
        aria-label={(
          (rightButton && rightButton.contents)
            ? undefined
            : 'show information on how to report an issue'
        )}
      >
        {
          rightButton
            // Included button
            ? (
              <>
                {/* Icon */}
                {
                  rightButton.icon
                    ? (
                      <FontAwesomeIcon
                        icon={rightButton.icon}
                        className="me-1"
                      />
                    )
                    : null
                }

                {/* Label */}
                {rightButton.contents}
              </>
            )
            // Report issue
            : (
              <span>
                {/* Extra Small Screen View */}
                <span className="d-inline d-sm-none">
                  <FontAwesomeIcon
                    icon={faBug}
                  />
                </span>

                {/* Larger Screen View */}
                <span className="d-none d-sm-inline">
                  <FontAwesomeIcon
                    icon={faBug}
                    className="me-1"
                  />
                  Report Issue
                </span>
              </span>
            )
        }
      </button>
    );
  }

  // Create the title
  const title = (
    showingAdminPanel
      ? (
        <span>
          {/* Extra Small Screen View */}
          <span className="d-inline d-sm-none">
            Admin
          </span>

          {/* Small Screen View */}
          <span className="d-none d-sm-inline d-md-none">
            Admin Panel
          </span>

          {/* Large Screen View */}
          <span className="d-none d-md-inline">
            Gather&nbsp;
            <span className="font-weight-lighter">
              | Admin Panel
            </span>
          </span>
        </span>
      )
      : (
        <span>
          Gather
        </span>
      )
  );

  return (
    <div className="Header-container">
      {modal}
      <nav className="Header-navbar navbar bg-secondary text-light shadow">
        {leftButtonElem}
        <h2 className="m-0 text-center w-100">
          {/* logo */}
          <div className="Header-icon-container">
            <img
              src={logo}
              alt=""
              className="Header-icon"
            />
          </div>

          {/* Title */}
          {title}
        </h2>
        {rightButtonElem}
      </nav>

      {/* Version Number */}
      <div className="Header-version-number">
        v
        {VERSION}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default Header;
