/**
 * Help and tutorials panel
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect } from 'react';

// Import dce-reactkit
import {
  Modal,
  ModalSize,
  ModalType,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

// Import video tutorials
import tutorials from './tutorials';

// Import helpers
import setPagePath from '../../../../shared/helpers/setPagePath';

// Import style
import './style.scss';
import LogMetadata from '../../../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler to call when closed
  onClose: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const HelpSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onClose,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Log open
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.HelpSubpanel,
          action: LogAction.Open,
        });
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log close
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.HelpSubpanel,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Set page path
  setPagePath('Help and Tutorials', '/tutorials');

  /* ---------------------- Create Tutorials ---------------------- */

  const tutorialElems = tutorials.map((tutorial) => {
    const {
      title,
      durationSec,
      youTubeId,
      taller,
    } = tutorial;

    const videoPlayer = (
      <div className={`HelpSubpanel-video-responsive ${taller ? 'HelpSubpanel-video-responsive-taller' : ''}`}>
        <iframe
          title={title}
          src={`https://www.youtube-nocookie.com/embed/${youTubeId}?color=white&controls=1&modestbranding=1&rel=0`}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          className="HelpSubpanel-video-container"
        />
      </div>
    );

    return (
      <div
        key={youTubeId}
        className="mb-3"
      >
        {/* Title (inline if more than one tutorial) */}
        {tutorials.length > 1 && (
          <h3>
            {/* Tutorial Title */}
            <strong>
              {title}
            </strong>

            {/* Duration Tag */}
            <div
              className="badge bg-secondary ms-2"
              style={{
                transform: 'translateY(-0.2rem)',
              }}
            >
              <FontAwesomeIcon
                icon={faClock}
                className="me-1"
              />
              {durationSec}
              s
            </div>
          </h3>
        )}
        {/* Video */}
        {videoPlayer}
      </div>
    );
  });

  /* --------------------------- Full UI -------------------------- */

  return (
    <Modal
      title="Tutorial"
      largeTitle
      type={ModalType.NoButtons}
      size={ModalSize.ExtraLarge}
      onClose={onClose}
    >
      {/* Videos */}
      <div className="HelpSubpanel-tutorial-container">
        {tutorialElems}
      </div>
    </Modal>
  );
};

export default HelpSubpanel;
