/**
 * Attendance icon with a tooltip describing the type of attendance
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

// Import dce-reactkit
import {
  Tooltip,
} from 'dce-reactkit';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Text to show in tooltip
  tooltipText: string,
  // FontAwesome icon
  icon: IconDefinition,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceMethodTooltip: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    tooltipText,
    icon,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Tooltip text={tooltipText}>
      <FontAwesomeIcon
        icon={icon}
        style={{
          transform: 'scale(1.1)',
        }}
        aria-label={tooltipText}
      />
    </Tooltip>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceMethodTooltip;
