// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import live-grouper
import { EventStateData } from 'dce-live-grouper';

// Import shared helper
import genOccurrenceId from '../../../helpers/genOccurrenceId';

// Import shared constants
import TTM_PATH_PREFIX from '../../../constants/TTM_PATH_PREFIX';

// Import shared types
import OccurrenceBasicInfo from '../../../types/OccurrenceBasicInfo';
import MMDDYY from '../../../types/MMDDYY';

/**
 * Call the backend to retrieve old groups and algorithm choices.
 * @author Benedikt Arnarsson
 * @param occInfo the basic info for the current occurrence.
 * @param mmddyy the date of the event we are retrieving info for.
 */
const getOldEventInfo = async (
  occInfo: OccurrenceBasicInfo,
  mmddyy: MMDDYY,
): Promise<EventStateData> => {
  const {
    courseId,
    ihid,
  } = occInfo;

  // Generate occurrence id
  const occId = genOccurrenceId({
    courseId,
    ihid,
    ...mmddyy,
  });

  // Remove courseId to get eventId
  const eventId = occId.replace(`${courseId}-`, '');

  // Get event info
  return visitServerEndpoint({
    path: `${TTM_PATH_PREFIX}/grouper/event`,
    params: {
      courseId,
      eventId,
    },
  });
};

export default getOldEventInfo;
