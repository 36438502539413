/**
 * Faded text title (not used  in the event list, those are customized)
 *   NOTE: parent must be a div with "overflow: hidden"
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import { Variant } from 'dce-reactkit';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Contents
  children: React.ReactNode,
  // Width of the fade
  fadeWidthRem?: number,
  // Height of the fade
  fadeHeightRem?: number,
  // Variant color of the fade (alert background)
  // currently supported: secondary, dark, light, warning
  alertVariant?: (
    Variant.Secondary | Variant.Dark | Variant.Light | Variant.Warning
  ),
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const FadedTitleContainer: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    children,
    fadeWidthRem = 4,
    fadeHeightRem = 1,
    alertVariant = Variant.Secondary,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Create the background image based on the variant
  let rgb: string;
  if (alertVariant === Variant.Dark) {
    rgb = '214, 216, 217';
  } else if (alertVariant === Variant.Warning) {
    rgb = '255, 243, 205';
  } else if (alertVariant === Variant.Light) {
    rgb = '254, 254, 254';
  } else {
    // Assume "secondary"
    rgb = '226, 227, 229';
  }
  const backgroundImage = `linear-gradient(to right, rgba(${rgb}, 0), rgba(${rgb}, 1))`;

  return (
    <div
      className="d-flex"
      style={{
        overflow: 'hidden',
        position: 'relative',
      }}
    >
      {/* Text */}
      <div
        className="flex-grow-1"
        style={{
          whiteSpace: 'nowrap',
          position: 'relative',
        }}
      >
        {children}
      </div>

      {/* Fader */}
      <div
        style={{
          width: 0, // Take up no space (overlap with text)
          height: 0,
          pointerEvents: 'none', // Pass through mouse events
          position: 'absolute',
          right: 0,
          top: 0,
          zIndex: 10000,
        }}
      >
        <div
          style={{
            backgroundImage,
            width: `${fadeWidthRem}rem`,
            height: `${fadeHeightRem}rem`,
            position: 'relative',
            transform: `translate(-${fadeWidthRem}rem, 0)`,
          }}
        />
      </div>
    </div>
  );
};

export default FadedTitleContainer;
