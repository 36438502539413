// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import live grouper types
import {
  StudentGroupAssignment,
  GrouperAlgoSettings,
} from 'dce-live-grouper';

// Import types
import CheckInIds from '../../../types/CheckInIds';
import CreateEventBody from '../../../types/CreateEventBody';
import MMDDYY from '../../../types/MMDDYY';
import Occurrence from '../../../types/Occurrence';
import OccurrenceGrouperInfo from '../../../types/OccurrenceGrouperInfo';

// Import helpers
import toStringMMDDYY from '../../../helpers/toStringMMDDYY';

// Import constants
import TTM_PATH_PREFIX from '../../../constants/TTM_PATH_PREFIX';

/**
 * Function for calling the backend to create EventStateData for the occurrence.
 * @author Benedikt Arnarsson
 * @param opts all of the options for the event.
 * @param opts.checkInProps the course ID and IHID for the event.
 * @param opts.occurrenceDate the date when the occurrence happens.
 * @param opts.occurrenceGrouperInfo the series ID for the occurrence.
 * @param opts.preAssignments the students that are pre-assigned to groups.
 * @param opts.grouperAlgoSettings the algorithm settings for live-grouper.
 */
const postEventForOccurrence = async (
  opts: {
    checkInProps: CheckInIds,
    occurrenceDate: MMDDYY,
    occurrenceGrouperInfo: OccurrenceGrouperInfo,
    preAssignments: StudentGroupAssignment[],
    grouperAlgoSettings: GrouperAlgoSettings,
  },
): Promise<void> => {
  const {
    checkInProps,
    occurrenceDate,
    occurrenceGrouperInfo,
    preAssignments,
    grouperAlgoSettings,
  } = opts;

  const {
    courseId,
    ihid,
  } = checkInProps;

  // Retrieve formatted date to construct event ID that corresponds to
  // the occurrence
  const formattedDate = toStringMMDDYY(occurrenceDate);

  const eventId = `${ihid}-O${formattedDate}`;

  const { seriesId } = occurrenceGrouperInfo;

  // Create occurrence
  const occ: Occurrence = {
    courseId,
    ihid,
    ...occurrenceDate,
    ...occurrenceGrouperInfo,
  };

  // Post the occurrence
  await visitServerEndpoint({
    path: `${TTM_PATH_PREFIX}/occurrence`,
    method: 'POST',
    params: {
      occurrence: occ,
    },
  });

  // Create event
  const createEventBody: CreateEventBody = {
    courseId,
    eventId,
    preAssignments,
    ...grouperAlgoSettings,
    metadata: {
      seriesId,
    },
  };

  // Post the corresponding event
  await visitServerEndpoint({
    path: `${TTM_PATH_PREFIX}/grouper/event`,
    method: 'POST',
    params: {
      createEventBody,
    },
  });
};

export default postEventForOccurrence;
