/**
 * Visualizer for attendance
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import {
  IconDefinition,
  faChalkboardTeacher,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';

// Import shared types
import AttendanceRecord from '../../types/AttendanceRecord';
import UserRole from '../../../../../../shared/types/UserRole';

// Import shared components
import NothingHereNotice from '../../../../../../shared/NothingHereNotice';

// Import other components
import AttendanceTableBody from './AttendanceTableBody';
import AttendanceTableHeader from './AttendanceTableHeader';
import AttendanceTableFooter from './AttendanceTableFooter';

// Import other types
import AttendanceLogMap from '../../types/AttendanceLogMap';

// Style
import './index.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Map of attendance (date => ihid => userId => record)
  attendanceLogMap: AttendanceLogMap,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // Sort by key
  sortBy?: string,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Choose new sort by column
  ChooseNewSortBy = 'ChooseNewSortBy',
  // Stop sorting
  StopSorting = 'StopSorting',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ChooseNewSortBy,
    // Sort by key
    sortBy: string,
  }
  | {
    // Action type
    type: ActionType.StopSorting,
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ChooseNewSortBy: {
      return {
        ...state,
        sortBy: action.sortBy,
      };
    }
    case ActionType.StopSorting: {
      return {
        ...state,
        sortBy: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceTable: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    attendanceLogMap,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    sortBy: undefined,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    sortBy,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* -------------- Empty ------------- */

  const atLeastOneItem = (
    Object.values(attendanceLogMap).length > 0
  );
  if (!atLeastOneItem) {
    return (
      <NothingHereNotice
        title="No Attendance Yet"
        subtitle="If you've applied filters, try a broader filter. Or check back later once more people have attended."
      />
    );
  }

  /* --------- Pre-processing --------- */

  // Order the date keys earliest to latest
  const dateKeys = Object.keys(attendanceLogMap);
  dateKeys.sort((a, b) => {
    const timestampA = (new Date(a)).getTime();
    const timestampB = (new Date(b)).getTime();

    return (timestampA - timestampB);
  });

  /* -------------- Rows -------------- */

  // Gather all logs into one array
  const attendanceLogs: AttendanceRecord[] = [];
  Object.keys(attendanceLogMap).forEach((dateKey) => {
    Object.keys(attendanceLogMap[dateKey]).forEach((ihid) => {
      Object.keys(attendanceLogMap[dateKey][ihid]).forEach((userId) => {
        const log = attendanceLogMap[dateKey][ihid][userId];
        attendanceLogs.push(log);
      });
    });
  });

  // Generate row names
  const rowInfos: {
    name: string,
    id: number,
    icon: IconDefinition,
  }[] = []; // List of { name, id, [icon] } for each row
  // Collect the list of attendees
  const userAdded: {
    [userId: string]: boolean,
  } = {}; // id => true if added
  attendanceLogs.forEach((attendanceLog) => {
    const {
      userName,
      userId,
      userRole,
    } = attendanceLog;

    // Skip if already added
    if (userAdded[userId]) {
      return;
    }
    userAdded[userId] = true;

    // Add to list of rows
    rowInfos.push({
      name: userName,
      id: userId,
      icon: (
        userRole === UserRole.Student
          ? faUserGraduate
          : faChalkboardTeacher
      ),
    });
  });

  // Sort rows by name
  rowInfos.sort((a, b) => {
    return (a.name.localeCompare(b.name));
  });

  // Keep track of values for comparator
  const valueWeightMap: {
    [userId: string]: {
      [dateKey: string]: number,
    },
  } = {}; // rowId => dateKey => comparisonValue

  // Add values for each row
  // A value can be:
  // - null for not in attendance (relevant for student comparison)
  // - attendance method if in attendance (relevant for student comparison)
  // - number for number attended (relevant for event comparison)
  const rowValueLists = rowInfos.map((rowInfo) => {
    // Find logs for this row
    const relevantLogs = attendanceLogs.filter((log) => {
      return (log.userId === rowInfo.id);
    });

    // Create the row value list for this row
    return dateKeys.map((dateKey) => {
      // Filter logs to only logs for this date
      const relevantLogsOnDate = relevantLogs.filter((log) => {
        return (
          // Must be an attendance log (not absence)
          log.inAttendance
          // Must be for this date
          && log.eventDate === dateKey
        );
      });

      // Initialize weight map for this row
      if (!valueWeightMap[rowInfo.id]) {
        valueWeightMap[rowInfo.id] = {};
      }

      // Set value
      const value = (
        relevantLogsOnDate.length > 0
          ? relevantLogsOnDate[0].method // Attendance method if attended
          : null // not in attendance
      );

      // Log for sorting
      valueWeightMap[rowInfo.id][dateKey] = (value ? 1 : 0);

      // Return the value
      return value;
    });
  });

  /* -------------- Table ------------- */

  return (
    <div className="AttendanceTable-container align-items-center">
      <div>
        <table className="AttendanceTable-table table">
          <AttendanceTableHeader
            dateKeys={dateKeys}
            sortBy={sortBy}
            onSortByChange={(newSortBy) => {
              dispatch({
                type: ActionType.ChooseNewSortBy,
                sortBy: newSortBy,
              });
            }}
            onStopSorting={() => {
              dispatch({
                type: ActionType.StopSorting,
              });
            }}
          />
          <AttendanceTableBody
            rowInfos={rowInfos}
            dateKeys={dateKeys}
            valueLists={rowValueLists}
            valueWeightMap={valueWeightMap}
            sortBy={sortBy}
          />
          <AttendanceTableFooter
            dateKeys={dateKeys}
            valueLists={rowValueLists}
          />
        </table>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceTable;
