// Highest error: GTHR95

enum ServerErrorCode {
  // Unknown
  Unknown = 'GTHR1',

  // API Middleware
  SessionExpired = 'GTHR2',
  NoLTILaunch = 'GTHR3',
  WrongLaunchCourse = 'GTHR4',
  NotMember = 'GTHR23',

  // APIs for Everyone
  EventListUnavailable = 'GTHR5',
  NotAllowedToVisitEvent = 'GTHR6',
  AttendanceNotSaved = 'GTHR7',
  PublishedRecordingsCacheLookupFailed = 'GTHR8',
  CantGetLoungeBecauseDoesntExist = 'GTHR71',
  CantGetLoungeBecauseAccessDenied = 'GTHR72',

  // APIs for TTMs
  MeetingInfoExcluded = 'GTHR9',
  MalformedMeeting = 'GTHR10',
  NotTTM = 'GTHR11',
  CRNNotFound = 'GTHR12',
  MalformedEvent = 'GTHR13',
  InvalidEvent = 'GTHR14',
  RecordingExcluded = 'GTHR15',
  MalformedRecording = 'GTHR16',
  PublishUnavailableOtherCourseOpen = 'GTHR17',
  InvalidRecordingBounds = 'GTHR21',
  EventUpdateFailed = 'GTHR67',
  EventFetchFailed = 'GTHR68',
  CannotPublishRecording = 'GTHR74',
  CannotUnpublishRecording = 'GTHR75',

  // APIS for Admins
  NotAdmin = 'GTHR18',
  NoCRNMatch = 'GTHR19',
  MalformedSettings = 'GTHR20',
  MalformedBan = 'GTHR57',
  ArchivedEventFetchFailed = 'GTHR69',
  // > Google Sheet Errors
  NoGSheetURL = 'GTHR24',
  NoGSheetTabId = 'GTHR25',
  GSheetUpdateFailed = 'GTHR26',
  GSheetReadFailed = 'GTHR27',
  GSheetPermissionsDenied = 'GTHR28',
  InvalidGSheetColumn = 'GTHR70',
  GSheetURLHasNoId = 'GTHR73',

  // Zoom Operations
  MeetingNotFound = 'GTHR22',
  WebinarNotFound = 'GTHR49',
  EventCannotBeStartedWithoutZoom = 'GTHR80',
  CannotFetchZoomHostEmailWithoutLaunch = 'GTHR81',
  CannotFetchZoomHostEmailWithInvalidHUID = 'GTHR82',

  // SideDoor
  NotEnoughInfoForSidedoor = 'GTHR29',
  IncompleteSidedoorInfo = 'GTHR30',
  CannotParseSidedoorKey = 'GTHR31',
  SidedoorKeyOutOfDate = 'GTHR32',
  InvalidSidedoorKey = 'GTHR33',
  SidedoorCouldNotLookUpCRN = 'GTHR34',
  SidedoorSessionSaveFailed = 'GTHR35',
  SidedoorNoSession = 'GTHR36',

  // Helpers
  EventNotFound = 'GTHR85',
  CourseNotFound = 'GTHR86',
  CannotStartWithoutId = 'GTHR87',

  // Install XML
  NoSchoolCode = 'GTHR37',

  // Stable Links
  StableLinkNoCourse = 'GTHR43',
  StableLinkSessionNotSaved = 'GTHR44',
  StableLinkNoToolId = 'GTHR45',
  StableLinkCourseLookupFailed = 'GTHR46',

  // Breakthrough Links
  BreakthroughLinkNoTokenOrId = 'GTHR38',
  BreakthroughLinkInvalid = 'GTHR39',
  BreakthroughEventLacksZoom = 'GTHR40',
  BreakthroughEventArchived = 'GTHR41',
  BreakthroughUnknownError = 'GTHR42',
  BreakthroughLinkNotFound = 'GTHR60',
  BreakthroughLinkNotValidYet = 'GTHR61',
  BreakthroughLinkExpired = 'GTHR62',
  BreakthroughLinkEventLookupFailed = 'GTHR63',
  BreakthroughLinkArchived = 'GTHR64',
  BreakthroughLinkDoesntHaveZoom = 'GTHR65',
  BreakthroughLinkCouldNotBeLogged = 'GTHR66',
  BreakthroughHasNoLink = 'GTHR88',

  // Auth/Red Button
  CanvasUnavailable = 'GTHR47',
  AuthlessLaunchFailed = 'GTHR48',

  // Lounges
  LoungeLookupFailed = 'GTHR50',
  MalformedLoungeIds = 'GTHR51',
  LoungeActivityRefreshFailed = 'GTHR52',
  LoungeCreationFailed = 'GTHR53',
  LoungeUpdateFailed = 'GTHR54',
  LoungeMalformed = 'GTHR55',
  LoungeActivitySaveFailed = 'GTHR56',
  LoungeZoomNameMissing = 'GTHR58',
  LoungeJoinLinkUnavailable = 'GTHR59',

  // Identity & iCommons
  ICommonsCRNNotFound = 'GTHR74',
  ICommonsNoCourseSite = 'GTHR75',
  ICommonsTooManyCourseSites = 'GTHR76',
  ICommonsInvalidCRN = 'GTHR77',
  ICommonsInvalidResponse = 'GTHR78',
  ICommonsLookupFailed = 'GTHR79',
  ICommonsInvalidCanvasURL = 'GTHR83',
  CRNToCanvasIdNotFound = 'GTHR84',

  // CheckIn
  CheckInEventNotFound = 'GTHR89',
  CheckInEventNotHeldInPerson = 'GTHR90',
  CheckInOccurrenceNotSetUpToday = 'GTHR91',
  UnknownCheckInError = 'GTHR92',

  // External
  IHIDNotFoundBecauseEventNotFound = 'GTHR93',

  // IC
  ICFeatureFlagMapNotSet = 'GTHR94',
  ICFeatureFlagMapNotRetrieved = 'GTHR95',
}

export default ServerErrorCode;
