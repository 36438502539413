// Import dce-reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import shared helpers
import genZoomMeetingTitle from './genZoomMeetingTitle';

// Import shared types
import KeyZoomMeetingOrWebinarInfo from '../types/from-server/KeyZoomMeetingOrWebinarInfo';
import School from '../types/from-server/School';

/**
 * Create a Zoom meeting with recommended settings
 * @author Gabe Abrams
 * @param opts object containing all arguments
 * @param opts.courseId the ID of the course to create the meeting for
 * @param opts.courseName the name of the course
 * @param opts.school the school code of the course
 * @param opts.eventName the name of the event
 * @param opts.termName the name of the term
 * @param [opts.hostEmail] the email of the host (if not provided, the current
 *   user's Zoom account email will be used)
 * @param [opts.autoRecordOn] whether to automatically record the meeting
 * @param [opts.waitingRoomOn] whether to enable the waiting room
 * @param [opts.hostVideoDisabled] whether to disable the host's video upon
 *   joining the meeting
 * @returns information about the created meeting if there was one,
 *   plus the status of the user's Zoom account
 */
const createRecommendedZoomMeeting = async (
  opts: {
    courseId: number,
    courseName: string,
    school: School,
    eventName: string,
    termName: string,
    hostEmail?: string,
    autoRecordOn?: boolean,
    waitingRoomOn?: boolean,
    hostVideoDisabled?: boolean,
  },
): Promise<KeyZoomMeetingOrWebinarInfo> => {
  const {
    courseId,
    courseName,
    eventName,
    hostEmail,
    termName,
    school = 'DCE',
    autoRecordOn = false,
    waitingRoomOn = false,
    hostVideoDisabled = false,
  } = opts;

  // Generate the meeting title
  const meetingTitle = await genZoomMeetingTitle({
    courseId,
    eventName,
    courseName,
    termName,
  });

  // Ask the server to create the meeting
  const createdMeetingInfo: KeyZoomMeetingOrWebinarInfo = (
    await visitServerEndpoint({
      path: `/api/ttm/courses/${courseId}/meetings`,
      method: 'POST',
      params: {
        hostEmail,
        school,
        waitingRoomOn,
        autoRecordOn,
        title: meetingTitle,
        hostVideoDisabled,
      },
    })
  );

  // Return the created meeting info
  return createdMeetingInfo;
};

export default createRecommendedZoomMeeting;
