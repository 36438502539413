/**
 * Check if the email is valid
 * @author Gabe Abrams
 * @param email the email to check
 * @returns true if the email is valid
 */
const isEmail = (email: string): boolean => {
  // Make sure email is not empty
  if (!email || String(email).trim().length === 0) {
    return false;
  }

  // Check if the email is valid
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(String(email));
};

export default isEmail;
