/**
 * Event property for whether to assign groups across occurrences.
 * @author Benedikt Arnarsson
 */
enum AssignToGroups {
  // Always assign to groups
  Always = 'Always',
  // Never assign to groups
  Never = 'Never',
  // Ask TTM to choose for every occurrence
  Ask = 'Ask',
}

export default AssignToGroups;
