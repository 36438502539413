/**
 * List of tutorial videos
 * @author Gabe Abrams
 */
const tutorials: {
  title: string;
  durationSec: number;
  youTubeId: string;
  taller?: boolean;
}[] = [
  {
    title: 'Overview Video',
    durationSec: 270,
    youTubeId: 'nSpN1wgOkvs',
    taller: false,
  },
];

export default tutorials;
