/**
 * Form element for a lounge email
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { faHeadset } from '@fortawesome/free-solid-svg-icons';

// Shared components
import InputLabel from '../../../../shared/InputLabel';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Current value of the email
  email: string,
  /**
   * Handler for when the email changes
   * @param email the new email
   */
  onChange: (email: string) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungeEmail: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    email,
    onChange,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Full UI
  return (
    <div
      className="input-group mb-3"
      key="name"
    >
      {/* Label */}
      <InputLabel
        icon={faHeadset}
        title="Zoom"
        isAdminFeature
      />

      {/* Text input field for title */}
      <input
        type="text"
        className="form-control border-success"
        aria-label="event email"
        id="LoungeEmail-input"
        placeholder={'e.g. "professor@harvard.edu'}
        value={email}
        onChange={(e) => {
          // Update state in parent
          return onChange(e.target.value);
        }}
      />
    </div>
  );
};

export default LoungeEmail;
