/**
 * Fixed-width input label
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

// Import shared constants
import MIN_EVENT_FORM_LABEL_WIDTH from './constants/MIN_EVENT_FORM_LABEL_WIDTH';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // An icon to display next to the title
  icon?: IconDefinition, // FontAwesome icon
  // Title of the input field
  title: React.ReactNode,
  // If true, this is an admin feature (change the theme)
  isAdminFeature?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const InputLabel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    icon,
    title,
    isAdminFeature,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <span
      className={`InputLabel-outer-container input-group-text ${isAdminFeature ? 'progress-bar-striped bg-success text-white text-start fw-bold border border-success' : ''}`}
      style={{
        borderTopRightRadius: 0,
        borderBottomRightRadius: 0,
        minWidth: MIN_EVENT_FORM_LABEL_WIDTH,
        height: '2.7rem',
      }}
    >
      {/* Small Screen View */}
      <span className="d-inline d-sm-none">
        {title}
      </span>

      {/* Large Screen View */}
      <span
        className="InputLabel-container d-none d-sm-inline"
      >
        {/* Optional Icon */}
        {icon && (
          <FontAwesomeIcon
            icon={icon}
            className="me-2"
          />
        )}

        {/* Label */}
        {title}
      </span>
    </span>
  );
};

export default InputLabel;
