// Import shared types
import CourseEventType from '../types/from-server/stored/shared/CourseEventType';

/**
 * Turn an event type into a human-readable title
 * @author Gabe Abrams
 * @param type the event type
 * @returns title
 */
const eventTypeToTitle = (type: CourseEventType) => {
  return (
    type
      // Split into words
      .split('-')
      // Capitalize words
      .map((word) => {
        return `${word.charAt(0).toUpperCase()}${word.substring(1)}`;
      })
      // Put back together
      .join(' ')
  );
};

export default eventTypeToTitle;
