// Import types
import OccurrenceBasicInfo from '../types/OccurrenceBasicInfo';

// Import helpers
import toStringMMDDYY from './toStringMMDDYY';

/**
 * Given occurrence info generates the corresponding ID.
 * @author Benedikt Arnarsson
 * @param occurrence the basic info for the occurrence.
 */
const genOccurrenceId = (occurrence: OccurrenceBasicInfo): string => {
  const {
    courseId,
    ihid,
    // Spread deconstruct will collect month, day, year
    ...mmddyy
  } = occurrence;

  const formattedDate = toStringMMDDYY(mmddyy);

  return `${courseId}-${ihid}-O${formattedDate}`;
};

export default genOccurrenceId;
