// Import types
import MMDDYY from '../types/MMDDYY';

/**
 * Parse a string to MMDDYY.
 * @author Benedikt Arnarsson
 * @param str inputted string to parse.
 * @returns object with `year`, `month`, and `day` properties.
 */
const parseMMDDYY = (str: string): MMDDYY => {
  const [year, month, day] = str.split('.').map((num) => {
    return Number.parseInt(num, 10);
  });
  return {
    year,
    month,
    day,
  };
};

export default parseMMDDYY;
