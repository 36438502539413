/**
 * One series in the list
 * @author Gabe Abrams
 * @author Benedikt Arnarsson
 */

// Import React
import React from 'react';

// Import types
import MMDDYY from '../../../types/MMDDYY';

// Import helpers
import dateToDescription from '../helpers/dateToDescription';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = (
  | {
    // Whether to display date information
    displayDate: true,
    // Date of the most recent occurrence in the series
    occurrenceDate: MMDDYY,
    // Handler to call when user chooses this item
    onSelect: () => void,
  }
  | {
    // Whether to display date information
    displayDate: false,
    // Description of the item
    description: string,
    // Handler to call when user chooses this item
    onSelect: () => void,
  }
);

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const SeriesListItem: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onSelect,
    displayDate,
  } = props;

  let itemInfo = '';
  if (displayDate) {
    const { occurrenceDate } = props;
    itemInfo = `Use ${dateToDescription(occurrenceDate)} Groups`;
  } else {
    const { description } = props;
    itemInfo = description;
  }

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <button
      type="button"
      className="SeriesListItem-series-item btn btn-secondary"
      aria-label={`Choose ${itemInfo}`}
      onClick={onSelect}
    >
      {/* Info */}
      <div className="SeriesListItem-series-item-info">
        <h3 className="SeriesListItem-series-item-info-title m-0">
          {itemInfo}
        </h3>
      </div>
    </button>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default SeriesListItem;
