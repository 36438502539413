/**
 * Modal for checking students.
 * @author Benedikt Arnarsson
 */

// Import React
import React from 'react';

// Import types
import OccurrenceBasicInfo from '../../types/OccurrenceBasicInfo';
import UserAndCourseInfo from '../../../../../../shared/types/UserAndCourseInfo';

// Import components
import QrCard from './QrCard';

// Import constants
import STUDENT_PATH_PREFIX from '../../constants/STUDENT_PATH_PREFIX';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Occurrence info needed to make backend-call
  occurrence: OccurrenceBasicInfo,
  // Optional sub-text for QrCard
  subTextForQr?: string,
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CheckInQrCard: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */
  const {
    occurrence,
    subTextForQr,
    userAndCourseInfo,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ---------------------------- Construct URL --------------------------- */
  /*------------------------------------------------------------------------*/

  const { ihid } = occurrence;

  const path = `${STUDENT_PATH_PREFIX}/attendance/checkin/`;

  const checkInUrl = `https://${window.location.hostname}${path}${occurrence.courseId}/${ihid}`;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="text-center">
      <QrCard
        url={checkInUrl}
        subTextForQr={subTextForQr}
        userAndCourseInfo={userAndCourseInfo}
      />
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default CheckInQrCard;
