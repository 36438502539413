/**
 * Prepares for joining a lounge: loads info, attempts to add the user
 *   as a host, displays a warning if the user cannot be added as a host
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import dce-reactkit
import {
  Modal,
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  ModalType,
} from 'dce-reactkit';

// Import shared types
import CourseLounge from '../../../shared/types/from-server/stored/CourseLounge';

// Import shared components
import ClaimAccountNotice from '../../../shared/ClaimAccountNotice';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // If true, user is a learner
  isLearner: boolean,
  // Id of the course
  courseId: number,
  // The lounge to join
  lounge: CourseLounge,
  /**
   * Handler to call when user is ready to join
   * @param joinLink the link to use to join
   */
  onContinue: (joinLink: string) => void,
  // Handler to call when user cancels the join
  onCancel: () => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, showing the "claim account" notice
  showClaimAccountNotice: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show claim account notice
  ShowClaimAccountNotice = 'ShowClaimAccountNotice',
  // Hide claim account notice
  HideClaimAccountNotice = 'HideClaimAccountNotice',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.ShowClaimAccountNotice
      | ActionType.HideClaimAccountNotice
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.HideClaimAccountNotice: {
      return {
        ...state,
        showClaimAccountNotice: false,
      };
    }
    case ActionType.ShowClaimAccountNotice: {
      return {
        ...state,
        showClaimAccountNotice: true,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungePreparationSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    isLearner,
    courseId,
    lounge,
    onContinue,
    onCancel,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    showClaimAccountNotice: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    showClaimAccountNotice,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Get the join URL
   * @author Gabe Abrams
   */
  const getJoinURL = async () => {
    // Update state
    dispatch({
      type: ActionType.HideClaimAccountNotice,
    });

    // Get the join URL
    try {
      const {
        link,
        willBeHost,
        errorCode,
      } = await visitServerEndpoint({
        path: `/api/courses/${courseId}/lounges/${lounge.loungeId}/join_url`,
        method: 'GET',
      });

      // Failure with known error code
      if (!willBeHost) {
        // Handle user not found error
        if (errorCode === 'ZOOM404-1113') {
          return dispatch({
            type: ActionType.ShowClaimAccountNotice,
          });
        }

        // Handle user not found during ZAK error
        if (errorCode === 'ZOOM404-1001') {
          return dispatch({
            type: ActionType.ShowClaimAccountNotice,
          });
        }
      }

      // Success! Continue
      onContinue(link);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        getJoinURL();
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ---------- Claim Account --------- */

  if (showClaimAccountNotice) {
    return (
      <Modal
        key="claim-account"
        type={ModalType.NoButtons}
        onClose={onCancel}
      >
        <ClaimAccountNotice
          isLearner={isLearner}
          onTryAgain={() => {
            // Try get the link again
            getJoinURL();
          }}
          onSkip={() => {
            // Just use the participant link
            onContinue(lounge.openZoomLink);
          }}
          onCancel={onCancel}
        />
      </Modal>
    );
  }

  /* ------------- Working ------------ */

  // Loading spinner
  return (
    <Modal
      key="loading"
      title="Loading..."
      onClose={onCancel}
      type={ModalType.NoButtons}
    >
      <LoadingSpinner />
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoungePreparationSubpanel;
