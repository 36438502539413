// Import shared type
import CourseEvent from '../types/from-server/stored/CourseEvent';
import genIHID from './genIHID';

/**
 * Generate an empty event object shell (must be filled with data)
 * @author Gabe Abrams
 * @param courseId the id of the course containing the event
 * @param otherEvents other events in the course
 * @returns event object
 */
const genEmptyEventObj = (
  courseId: number,
  otherEvents: CourseEvent[],
): CourseEvent => {
  // Generate a unique id
  const otherIHIDs = otherEvents.map((event) => {
    return event.ihid;
  });
  const ihid = genIHID(otherIHIDs);

  // Generate the object
  return {
    key: `${courseId}-${ihid}`,
    courseId,
    ihid,
    name: '', // Empty name
    type: null as any, // No type yet
    archived: false, // Not archived
    currentZoomId: null, // No zoom
    currentZoomHost: null, // No host
    openZoomLink: null, // No open zoom link
    pastZoomIds: null, // No past zoom ids
    isWebinar: null, // No Zoom
    lockZoomToggle: false, // Not locked
    lockAutoRecordSetting: false, // Not locked
    banDCEStudents: false, // No ban
    banFASStudents: false, // No ban
    pinned: false, // Not pinned
    isHeldInPerson: false, // Not held in person
  };
};

export default genEmptyEventObj;
