// Import dce-reactkit
import { getOrdinal } from 'dce-reactkit';

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

const MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/*------------------------------------------------------------------------*/
/* -------------------------------- Main -------------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Format a date object
 * @author Gabe Abrams
 * @param date the date to format
 * @returns formatted date
 */
const formatDate = (date: Date) => {
  // Month
  const month = MONTHS[date.getMonth()];

  // Day
  // > Ordinal
  const ordinal = getOrdinal(date.getDate());
  // > Assemble string
  const day = `${date.getDate()}${ordinal}`;

  // Year
  const year = (
    (new Date()).getFullYear() === date.getFullYear()
      // Same year, just leave out the year
      ? ''
      // Different year. Leave it in
      : date.getFullYear()
  );

  // Put together
  return `${month} ${day}${year ? ', ' : ''}${year}`;
};

export default formatDate;
