/**
 * Progress bar and numerical indicator of progress
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The number of finished tasks
  numFinished: number,
  // The total number of tasks
  numTasks: number,
  // If true, the batch creation has ended
  ended: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BatchCreationProgress: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    numFinished,
    numTasks,
    ended,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Calculate the percentage of finished events
  const percentFinished = Math.ceil(
    (100 * numFinished)
    / numTasks,
  );

  // Create the UI
  return (
    <div className="d-flex align-items-center justify-content-center mb-2">
      {/* Progress Bar */}
      <div className="flex-grow-1">
        <div className="progress">
          <div
            className={`progress-bar progress-bar-striped bg-warning ${ended ? '' : 'progress-bar-animated'}`}
            role="progressbar"
            style={{
              width: `${percentFinished}%`,
            }}
            aria-label={`progress bar indicating that ${percentFinished} percent of the job has been finished`}
            aria-valuenow={numFinished}
            aria-valuemin={0}
            aria-valuemax={numTasks}
          />
        </div>
      </div>

      {/* Status Count */}
      <div className="fw-bold ps-2">
        <span id="BatchCreationProgress-num-finished">
          {numFinished}
        </span>
        /
        <span id="BatchCreationProgress-num-tasks">
          {numTasks}
        </span>
        &nbsp;Finished
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BatchCreationProgress;
