/**
 * A decorative lounge table
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';

// Import style
import './LoungeTable.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Position in rems
  top?: number,
  bottom?: number,
  left?: number,
  right?: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungeTable: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    top,
    left,
    bottom,
    right,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      className="LoungeTable-container"
      style={{
        top: (top ? `${top}rem` : undefined),
        left: (left ? `${left}rem` : undefined),
        bottom: (bottom ? `${bottom}rem` : undefined),
        right: (right ? `${right}rem` : undefined),
      }}
    >
      <FontAwesomeIcon
        icon={faStickyNote}
        className="LoungeTable-paper"
      />
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoungeTable;
