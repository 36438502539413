// Import dce-reactkit
import { visitServerEndpoint } from 'dce-reactkit';

/**
 * Create a Zoom meeting title
 * @author Gabe Abrams
 * @param opts object containing all arguments
 * @param [opts.crn] the CRN of the course (either include this or courseId)
 * @param [opts.courseId] the ID of the course (either include this or crn)
 * @param opts.eventName the name of the event
 * @param opts.courseName the name of the course
 * @param opts.termName the name of the term the course is in
 */
const genZoomMeetingTitle = async (
  opts: (
    {
      eventName: string,
      courseName: string,
      termName: string,
    } & (
      | { crn: number }
      | { courseId: number }
    )
  ),
): Promise<string> => {
  const {
    eventName,
    courseName,
    termName,
  } = opts;

  // Load the CRN or get it from opts
  let { crn } = (opts as any);
  if (!crn) {
    crn = await visitServerEndpoint({
      path: `/api/ttm/courses/${(opts as any).courseId}/crn`,
      method: 'GET',
    });
  }

  // Remove CRN from course name
  const adjustedCourseName = (
    courseName
      // Split into word
      .split(' ')
      // Modify words
      .map((word) => {
        // Remove word if it's the CRN
        if (word.includes(String(crn))) {
          return '';
        }

        // Leave word unchanged
        return word;
      })
      // Filter out empty words
      .filter((word) => {
        return word.trim().length > 0;
      })
      // Put back into string
      .join(' ')
  );

  // Create a Zoom name with the event name, course name, and CRN
  return `${eventName} [${adjustedCourseName} – ${crn} – ${termName}]`;
};

export default genZoomMeetingTitle;
