/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

import PublishableRecordingWithInfo from '../types/from-server/PublishableRecordingWithInfo';

// Sort order
const VIEW_SORT_ORDER = [
  'Shared Screen with Speaker (CC)',
  'Shared Screen with Speaker',
  'Shared Screen with Gallery',
  'Active Speaker',
  'Just the Speaker',
  'Just the Gallery',
  'Just the Shared Screen',
  'Other View',
];

/*------------------------------------------------------------------------*/
/* -------------------------------- Main -------------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Pre-process recordings
 * @author Gabe Abrams
 * @param recordings the published recordings to process
 * @return processed recording pairs
 */
const preprocessRecordingPairs = (
  recordings: PublishableRecordingWithInfo[],
) => {
  // Order the recording views
  return recordings.map((recordingInfo) => {
    const { recording } = recordingInfo;
    const { otherPlayURLs } = recording;

    // Sort the otherPlayURLs
    const orderedPlayURLs: {
      [k: string]: string,
    } = {};
    const urlTitles = Object.keys(otherPlayURLs);
    urlTitles.sort((a, b) => {
      // Figure out the sort order indexes
      let aOrder = Number.MAX_SAFE_INTEGER;
      let bOrder = Number.MAX_SAFE_INTEGER;
      for (let i = 0; i < VIEW_SORT_ORDER.length; i++) {
        if (a.includes(VIEW_SORT_ORDER[i])) {
          aOrder = i;
        }
        if (b.includes(VIEW_SORT_ORDER[i])) {
          bOrder = i;
        }
      }

      // Sort by index
      if (aOrder < bOrder) {
        return -1;
      }
      if (aOrder > bOrder) {
        return 1;
      }
      return 0;
    });

    // Add ordered play URLs in their order
    urlTitles.forEach((key) => {
      const value = otherPlayURLs[key];
      orderedPlayURLs[key] = value;
    });

    // Replace the otherPlayURLs
    const newRecordingInfo = {
      ...recordingInfo,
      recording: {
        ...recording,
        otherPlayURLs: orderedPlayURLs,
      },
    };
    return newRecordingInfo;
  });
};

export default preprocessRecordingPairs;
