// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import types
import Occurrence from '../../../types/Occurrence';

// Import constants
import TTM_PATH_PREFIX from '../../../constants/TTM_PATH_PREFIX';

/**
 * Create a new occurrence document in the database.
 * @author Benedikt Arnarsson
 * @param occ the occurrence that is being placed in the DB
 */
const putNewOccurrence = async (
  occ: Occurrence,
) => {
  // FIXME: error handling
  await visitServerEndpoint({
    path: `${TTM_PATH_PREFIX}/occurrence`,
    method: 'POST',
    params: {
      occurrence: occ,
    },
  });
};

export default putNewOccurrence;
