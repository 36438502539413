/**
 * AttendanceTableFooter for attendance table
 * @author Karen Dolan
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Array of date string
  dateKeys: string[],
  // Array of Arrays of String, null, or numbers
  valueLists: (string | null)[][],
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

const TOTAL_BAR_HEIGHT = 6.25; // rems
const TOTAL_BAR_WIDTH = 4.25; // rems

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceTableFooter: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    dateKeys,
    valueLists,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Count up totals
  let largestTotal = 0;
  const totals = dateKeys.map((date, i) => {
    // Sum up the whole column
    let total = 0;
    valueLists.forEach((valueList) => {
      const value = valueList[i];

      // Count the value of the current cell
      let cellValue: number;
      if (value === null) {
        cellValue = 0;
      } else if (typeof value === 'number') {
        // Add the total number of people attending this event
        cellValue = value;
      } else {
        // Add 1 if in attendance, 0 otherwise
        cellValue = (value ? 1 : 0);
      }

      total += Number.parseInt(String(cellValue), 10);
    });

    // Keep track of largest total
    if (largestTotal < total) {
      largestTotal = total;
    }

    // Add to list
    return total;
  });

  // Make a list of totals cells
  const totalsCells = totals.map((total, i) => {
    const date = new Date(dateKeys[i]);
    const shortDate = `${date.getMonth() + 1}/${date.getDate()}`;
    const idFriendlyShortDate = `${date.getMonth() + 1}-${date.getDate()}-${date.getFullYear()}`;

    // Decide if label is above
    const labelAbove = (total < (largestTotal / 3));

    // Create a bar
    const barElem = (
      <div
        className="d-flex flex-col align-items-end justify-content-center"
        style={{
          height: `${TOTAL_BAR_HEIGHT}rem`,
        }}
      >
        <div
          className="d-inline-block bg-light position-relative"
          style={{
            width: `${TOTAL_BAR_WIDTH}rem`,
            height: `${Math.round((total / largestTotal) * TOTAL_BAR_HEIGHT)}rem`,
          }}
        >
          {/* Label */}
          <div
            className={`text-${labelAbove ? 'light' : 'dark'} fw-bold text-center AttendanceTableFooter-total-attendance-label`}
            id={`AttendanceTableFooter-total-attendance-on-${idFriendlyShortDate}`}
            style={{
              position: 'absolute',
              top: (
                labelAbove
                  ? '-1.5625rem'
                  : undefined
              ),
              width: `${TOTAL_BAR_WIDTH}rem`,
              fontSize: '1rem',
            }}
          >
            {total}
          </div>
        </div>
      </div>
    );
    return (
      <td
        key={date.getTime()}
        className="bg-secondary text-light fw-bold"
        style={{
          padding: 0,
        }}
        aria-label={`total attendance on ${shortDate} is ${total}`}
      >
        {barElem}
      </td>
    );
  });

  return (
    <tfoot>
      <tr>
        <th
          scope="row"
          className="bg-secondary text-light"
        >
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: `${TOTAL_BAR_HEIGHT}rem` }}
          >
            <div>
              Total Attendance:
            </div>
          </div>
        </th>
        {totalsCells}
      </tr>
    </tfoot>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceTableFooter;
