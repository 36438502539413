/**
 * Map of human-readable user roles
 * @author Gabe Abrams
 */
enum UserRole {
  // Teaching team member (TA, teacher, etc.)
  TTM = 'Teaching Team Member',
  // Student
  Student = 'Student',
  // Canvas admin
  Admin = 'Admin',
  // Unknown role
  Unknown = 'Unknown',
}

export default UserRole;
