/**
 * A recording item in the list
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPlayCircle,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  DynamicWord,
  ToggleSwitch,
  Variant,
  confirm,
} from 'dce-reactkit';

// Import shared components
import ChoosePublishNameModal from './ChoosePublishNameModal';

// Import shared types
import ZoomRecording from '../../../../../shared/types/from-server/stored/ZoomRecording';
import CourseEventType from '../../../../../shared/types/from-server/stored/shared/CourseEventType';

// Import helpers
import formatDate from '../../../../../shared/helpers/formatDate';
import formatTime from '../../../../../shared/helpers/formatTime';
import eventTypeToIcon from '../../../../../shared/helpers/eventTypeToIcon';

// Import styles
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Type of the event corresponding to this recording
  eventType: CourseEventType,
  // Recording
  recording: ZoomRecording,
  // If true, published
  published: boolean,
  /**
   * Handler for when user click to play a recording
   * @param url the url to play
   */
  onPlay: (url: string) => void,
  // If true, the current user is a learner
  isLearner: boolean,
  /**
   * Handler for when user clicks "Publish" - if excluded, no button
   * @param recording the updated recording to publish
   */
  onPublish?: (recording: ZoomRecording) => void,
  // Handler for when user clicks "Unpublish" - if excluded, no button
  onUnpublish?: () => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, choosing a recording name
  choosingRecordingName: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show recording name chooser
  ShowRecordingNameChooser = 'ShowRecordingNameChooser',
  // Hide recording name chooser
  HideRecordingNameChooser = 'HideRecordingNameChooser',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.ShowRecordingNameChooser
      | ActionType.HideRecordingNameChooser
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ShowRecordingNameChooser: {
      return {
        ...state,
        choosingRecordingName: true,
      };
    }
    case ActionType.HideRecordingNameChooser: {
      return {
        ...state,
        choosingRecordingName: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const RecordingPreview: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    eventType,
    recording,
    onPublish,
    onUnpublish,
    isLearner,
    published,
    onPlay,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    choosingRecordingName: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    choosingRecordingName,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Handler for when user clicks the publish toggle
   * @author Gabe Abrams
   */
  const toggleRecordingPublish = async () => {
    if (published && onUnpublish) {
      // Confirm
      const confirmed = await confirm(
        'Are you sure?',
        'If you unpublish this recording, students will no longer be able to watch it.',
        {
          confirmButtonText: 'Yes, Unpublish',
          confirmButtonVariant: Variant.Danger,
        },
      );
      if (!confirmed) {
        return;
      }

      // Unpublish
      onUnpublish();
    } else {
      // Show the publish modal
      dispatch({
        type: ActionType.ShowRecordingNameChooser,
      });
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ------ Choose Recording Name ----- */

  if (choosingRecordingName && onPublish) {
    // Create modal
    modal = (
      <ChoosePublishNameModal
        recording={recording}
        onPublish={(updatedRecording) => {
          // Hide the modal
          dispatch({
            type: ActionType.HideRecordingNameChooser,
          });

          // Publish the recording
          onPublish(updatedRecording);
        }}
        onCancel={() => {
          // Hide the modal
          dispatch({
            type: ActionType.HideRecordingNameChooser,
          });
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Destructure recording
  const {
    name,
    ihid,
    timestamp,
    defaultPlayURL,
  } = recording;

  // Create description strings
  const date = new Date(timestamp);
  const dayString = formatDate(date);
  const timeString = formatTime(date);

  return (
    <div className={`RecordingPreview-${ihid} RecordingPreview-${ihid}-at-${timestamp} alert alert-light p-2 bg-light text-dark border mb-0`}>
      {/* Modal */}
      {modal}

      {/* Container */}
      <div className="RecordingPreview-container d-flex align-items-center justify-content-center">
        {/* Title */}
        <div className="RecordingPreview-title-text flex-grow-1 ms-1">
          <h5 className="m-0 RecordingPreview-recording-name-and-date">
            {/* Vertically Centered Title and Icon */}
            <div className="d-flex align-items-center justify-content-center">
              {/* Icon */}
              <div>
                {/* Icon for larger screens */}
                <span className="d-none d-md-inline me-2">
                  <FontAwesomeIcon
                    icon={eventTypeToIcon(eventType)}
                    className="ml-1 me-1"
                    style={{ fontSize: '1.1rem' }}
                  />
                </span>
              </div>
              {/* Text */}
              <div className="flex-grow-1">
                {/* Name */}
                <div>
                  <strong className={`RecordingPreview-${ihid} RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-recording-name`}>
                    {name}
                  </strong>
                </div>
                {/* Date and Time */}
                <div className="small">
                  <span className="text-nowrap">
                    {/* Date */}
                    {dayString}

                    {/* Separator */}
                    &nbsp;at&nbsp;

                    {/* Time */}
                    {timeString}
                  </span>
                </div>
              </div>
            </div>
          </h5>
        </div>
        {/* Buttons */}
        <div
          className="RecordingPreview-buttons d-flex align-items-center justify-content-center gap-1"
          style={{ whiteSpace: 'nowrap' }}
        >
          {/* Publish Toggle */}
          {(onUnpublish && onPublish && !isLearner) && (
            <button
              type="button"
              className="btn btn-secondary"
              onClick={toggleRecordingPublish}
              aria-label={`publish recording of ${name} from ${dayString} at ${timeString}`}
              style={{
                minHeight: '2.5rem',
              }}
            >
              {published ? 'Published' : `${DynamicWord.ClickCapitalized} to Publish`}
              {' '}
              <ToggleSwitch
                className={(
                  published
                    ? `RecordingPreview-${ihid}-button RecordingPreview-${ihid}-unpublish-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-unpublish-button`
                    : `RecordingPreview-${ihid}-button RecordingPreview-${ihid}-publish-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-publish-button`
                )}
                description="recording is published"
                isOn={published}
                onToggle={toggleRecordingPublish}
                backgroundVariantWhenOn={Variant.Warning}
              />
            </button>
          )}

          {/* Play Button */}
          <button
            type="button"
            onClick={() => {
              onPlay(defaultPlayURL);
            }}
            className={`RecordingPreview-play-button RecordingPreview-${ihid}-button RecordingPreview-${ihid}-play-button RecordingPreview-${ihid}-at-${timestamp} RecordingPreview-play-button btn btn-secondary text-light`}
            aria-label={`play recording of ${name} from ${dayString} at ${timeString}`}
            title="Play Recording"
            style={{
              minHeight: '2.5rem',
            }}
          >
            <FontAwesomeIcon
              icon={faPlayCircle}
              className="me-2"
            />
            Play
          </button>
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default RecordingPreview;
