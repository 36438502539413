// Import React
import React from 'react';

// Import dce-reactkit
import {
  Tooltip,
} from 'dce-reactkit';

// Import shared helpers
import showThisIsLockedModal from './helpers/showThisIsLockedModal';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // ID of the button
  id?: string,
  // the classes for the button except btn-lg
  className: string,
  // the aria-label for the button
  ariaLabel: string,
  // the tooltip text
  tooltipText: string,
  // the onClick handler for the button
  onClick: () => void,
  // the contents of the button
  children: React.ReactNode,
  // if true, the button will be locked
  locked?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const TooltipButton: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    id,
    className,
    ariaLabel,
    tooltipText,
    onClick,
    children,
    locked,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Tooltip text={tooltipText}>
      <button
        type="button"
        id={id}
        className={`btn btn-secondary ${className}`}
        aria-label={ariaLabel}
        onClick={() => {
          // If locked, alert user
          if (locked) {
            return showThisIsLockedModal();
          }

          // Call onClick
          onClick();
        }}
      >
        {children}
      </button>
    </Tooltip>
  );
};

export default TooltipButton;
