/**
 * A decorative lounge door
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import style
import './LoungeDoor.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // If true, on left
  onLeft?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungeDoor: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onLeft,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      className="LoungeDoor-container d-none d-md-block"
      style={{
        [onLeft ? 'left' : 'right']: '1.9rem',
      }}
    >
      <div className="LoungeDoor-left" />
      <div className="LoungeDoor-right" />
      <div className="LoungeDoor-knob" />
      <div className="LoungeDoor-mat">
        welcome
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoungeDoor;
