import WEEKDAY_TO_CODE from './WEEKDAY_TO_CODE';

const CODE_TO_WEEKDAY: {
  [code: string]: string,
} = {};

// Swap the key and value
Object.entries(WEEKDAY_TO_CODE).forEach(([key, val]) => {
  CODE_TO_WEEKDAY[val] = key;
});

/**
 * Dynamically created weekday map
 * @author Gabe Abrams
 */
export default CODE_TO_WEEKDAY;
