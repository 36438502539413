/**
 * Create a new IHID for a meeting
 * @author Gabe Abrams
 * @returns ihid for the meeting
 */
const genIHID = (existingIHIDs: string[]) => {
  let ihid: string | undefined;
  let index = 1;

  // Loop through indexes until we find one that isn't used
  while (!ihid) {
    // Create a potential IHID
    const potentialIHID = `G${index}`;

    // Check if it's used
    if (existingIHIDs.indexOf(potentialIHID) < 0) {
      // Not used. Save it
      ihid = potentialIHID;
    } else {
      // Used. Continue incrementing
      index += 1;
    }
  }

  // Return the ihid
  return ihid;
};

export default genIHID;
