/**
 * Popup for choosing the publish name of a recording
 * @author Karen Dolan
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFont,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  ModalButtonType,
  ModalType,
  Variant,
} from 'dce-reactkit';

// Import shared types
import ZoomRecording from '../../../../../shared/types/from-server/stored/ZoomRecording';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Recording to publish
  recording: ZoomRecording,
  /**
   * Handler for publish
   * @param recording the updated recording to publish
   */
  onPublish: (recording: ZoomRecording) => void,
  // Handler for cancel
  onCancel: () => void,
};
/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Local constants
const NAME_MIN_CHAR = 3;
const NAME_MAX_CHAR = 50;

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // The current name of the recording
  recordingName: string,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Change the recording name
  UpdateName = 'UpdateName',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateName,
    // New recording name
    name: string,
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateName: {
      return {
        ...state,
        recordingName: action.name,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ChoosePublishNameModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    recording,
    onPublish,
    onCancel,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    recordingName: recording.name,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    recordingName,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Validate
  let validationFailureMessage: string | null = null;
  if (recordingName.trim().length < NAME_MIN_CHAR) {
    validationFailureMessage = `The recording name must be at least ${NAME_MIN_CHAR} characters long.`;
  } else if (recordingName.trim().length > NAME_MAX_CHAR) {
    validationFailureMessage = `The recording name must be less than ${NAME_MAX_CHAR} characters long.`;
  }

  // Create the modal
  return (
    <Modal
      title="Name the Recording"
      type={(
        validationFailureMessage
          ? ModalType.NoButtons
          : ModalType.OkayCancel
      )}
      okayLabel="Publish Recording"
      okayVariant={Variant.Dark}
      onClose={(buttonType) => {
        if (buttonType === ModalButtonType.Cancel) {
          return onCancel();
        }

        // Confirmed!

        // Update the recording
        recording.name = recordingName.trim();

        // Publish the recording
        onPublish(recording);
      }}
    >
      {/* Text input field for recording name */}
      <div className="input-group" key="name">
        <div className="input-group-text">
          <FontAwesomeIcon
            icon={faFont}
            className="me-2"
          />
          Name
        </div>
        <input
          type="text"
          className="form-control"
          aria-label="recording name"
          id="ChoosePublishNameModal-name"
          value={recordingName}
          onChange={(e) => {
            dispatch({
              type: ActionType.UpdateName,
              name: e.target.value,
            });
          }}
        />
      </div>

      {/* Validation Message */}
      {
        validationFailureMessage
          ? (
            <div className="mt-2">
              <div
                id="ChoosePublishNameModal-meeting-id-validation-message"
                className="text-danger"
              >
                {validationFailureMessage}
              </div>
            </div>
          )
          : (
            <div className="mt-2">
              Give the recording a unique and descriptive name.
            </div>
          )
      }
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ChoosePublishNameModal;
