/**
 * A single pre-assignment entry
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import shared types
import User from '../../types/User';

// Import style
import './PreAssignmentItem.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The student that's assigned
  student: User,
  // The group number
  groupNum: number,
  // Handler to call when the user clicks the remove button
  onRemove: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const PreAssignmentItem: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    student,
    groupNum,
    onRemove,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="PreAssignmentItem-outer-container alert alert-secondary">
      <div className="row">
        {/* Name */}
        <div className="PreAssignmentItem-column col-4">
          <div className="PreAssignmentItem-name-container">
            {student.userFirstName}
            {' '}
            {student.userLastName}
          </div>
        </div>
        {/* Group Number */}
        <div className="PreAssignmentItem-column col-4">
          <div className="PreAssignmentItem-group-num-container">
            {groupNum}
          </div>
        </div>
        {/* Remove Button */}
        <div className="PreAssignmentItem-column col-4">
          <div className="PreAssignmentItem-remove-button-container">
            <button
              type="button"
              className="PreAssignmentItem-remove-button btn btn-danger"
              onClick={onRemove}
              aria-label={`remove ${student.userFirstName} ${student.userLastName} from group ${groupNum}`}
            >
              Remove
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default PreAssignmentItem;
