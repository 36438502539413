/**
 * Gather tags for google spreadsheets.
 *   NOTE: there are two copies of this file! (in the server and client)
 * @author Gabe Abrams
 */
enum GatherTag {
  Read = '[G]',
  Write = '[G+]',
}

export default GatherTag;
