/**
 * Manage the course's breakthrough links
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import FontAwesome
import { IconDefinition } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  confirm,
  Variant,
  ModalType,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import shared components
import NothingHereNotice from '../../../shared/NothingHereNotice';
import CopyButton from '../../../shared/CopyButton';

// Import other components
import BreakthroughLinkRow from './BreakthroughLinkRow';

// Import helpers
import eventTypeToIcon from '../../../shared/helpers/eventTypeToIcon';
import BreakthroughLink from '../../../shared/types/from-server/stored/BreakthroughLink';

// Import shared types
import CourseEvent from '../../../shared/types/from-server/stored/CourseEvent';
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

// Import other types
import AdminPanelFeatureProps from '../types/AdminPanelFeatureProps';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Breakthrough link list
  BreakthroughLinkList = 'BreakthroughLinkList',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.BreakthroughLinkList,
    // List of links that exist in the course
    links: BreakthroughLink[],
    // Map of events: ihid => event info
    eventInfoMap: {
      [ihid: string]: {
        name: string,
        ihid: string,
        icon: IconDefinition,
        archived: boolean,
      },
    },
    // The link to that is being confirmed for deletion
    linkToDelete?: BreakthroughLink,
    // Filter string (pasted in link)
    linkToMatch: string,
    // Full breakthrough link to view and copy
    fullLinkToView?: BreakthroughLink,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish loading
  FinishLoading = 'FinishLoading',
  // Update the link to match query
  UpdateLinkToMatch = 'UpdateLinkToMatch',
  // View a link
  ViewLink = 'ViewLink',
  // Hide link that's being viewed
  StopViewingLink = 'StopViewingLink',
  // Start deleting a link
  StartDeletingLink = 'StartDeletingLink',
  // Cancel deleting a link
  CancelDeletingLink = 'CancelDeletingLink',
  // Finish deleting a link
  FinishDeletingLink = 'FinishDeletingLink',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // List of links
    links: BreakthroughLink[],
    // Map of events: ihid => event info
    eventInfoMap: {
      [ihid: string]: {
        name: string,
        ihid: string,
        icon: IconDefinition,
        archived: boolean,
      },
    },
  }
  | {
    // Action type
    type: ActionType.UpdateLinkToMatch,
    // New link to match
    linkToMatch: string,
  }
  | {
    // Action type
    type: ActionType.ViewLink,
    // Link to view
    link: BreakthroughLink,
  }
  | {
    // Action type
    type: ActionType.StartDeletingLink,
    // Link to delete
    link: BreakthroughLink,
  }
  | {
    // Action type
    type: (
      | ActionType.CancelDeletingLink
      | ActionType.FinishDeletingLink
      | ActionType.StopViewingLink
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  if (action.type === ActionType.FinishLoading) {
    return {
      view: View.BreakthroughLinkList,
      links: action.links,
      eventInfoMap: action.eventInfoMap,
      linkToMatch: '',
    };
  }
  if (state.view === View.Loading) {
    return state;
  }

  // Breakthrough link list
  switch (action.type) {
    case ActionType.StartDeletingLink: {
      return {
        ...state,
        linkToDelete: action.link,
      };
    }
    case ActionType.CancelDeletingLink: {
      return {
        ...state,
        linkToDelete: undefined,
      };
    }
    case ActionType.FinishDeletingLink: {
      // Skip if no link to delete
      if (!state.linkToDelete) {
        return state;
      }

      // Destructure link to delete
      const { linkToDelete } = state;

      // Return state without the link to delete
      return {
        ...state,
        links: state.links.filter((link) => {
          return link.linkId !== linkToDelete.linkId;
        }),
        linkToDelete: undefined,
      };
    }
    case ActionType.UpdateLinkToMatch: {
      return {
        ...state,
        linkToMatch: action.linkToMatch.trim(),
      };
    }
    case ActionType.ViewLink: {
      return {
        ...state,
        fullLinkToView: action.link,
      };
    }
    case ActionType.StopViewingLink: {
      return {
        ...state,
        fullLinkToView: undefined,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BreakthroughLinkManager: React.FC<AdminPanelFeatureProps> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
  } = props;

  const {
    courseId,
    courseName,
  } = userAndCourseInfo;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Delete a breakthrough link
   * @author Gabe Abrams
   * @param link the link to delete
   */
  const deleteBreakthroughLink = async (link: BreakthroughLink) => {
    // Skip if in wrong view
    if (view === View.Loading) {
      return;
    }

    // Update state
    dispatch({
      type: ActionType.StartDeletingLink,
      link,
    });

    // Confirm
    const confirmed = await confirm(
      'Are you sure?',
      'This link will immediately and permanently stop working.',
      {
        confirmButtonText: 'Yes, delete',
        confirmButtonVariant: Variant.Danger,
      },
    );
    if (!confirmed) {
      dispatch({
        type: ActionType.CancelDeletingLink,
      });
      return;
    }

    // Try to delete
    try {
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/events/${link.ihid}/breakthroughs/${link.linkId}`,
        method: 'DELETE',
      });

      // Log deletion
      logClientEvent({
        context: LogMetadata.Context.AdminPanel,
        subcontext: LogMetadata.Context.AdminPanel.BreakthroughLinkManager,
        action: LogAction.Delete,
        target: LogMetadata.Target.BreakthroughLink,
        metadata: {
          link,
        },
      });

      // Successfully deleted!
      dispatch({
        type: ActionType.FinishDeletingLink,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          // Log opening
          logClientEvent({
            context: LogMetadata.Context.AdminPanel,
            subcontext: LogMetadata.Context.AdminPanel.BreakthroughLinkManager,
            action: LogAction.Open,
          });

          // Load links and events
          const [
            links,
            events,
          ] = await Promise.all([
            visitServerEndpoint({
              path: `/api/admin/courses/${courseId}/breakthroughs`,
              method: 'GET',
            }) as Promise<BreakthroughLink[]>,
            visitServerEndpoint({
              path: `/api/ttm/courses/${courseId}/events`,
              method: 'GET',
            }) as Promise<CourseEvent[]>,
          ]);

          // Turn the event list into a lookup map
          const eventMap: {
            [ihid: string]: {
              name: string,
              ihid: string,
              icon: IconDefinition,
              archived: boolean,
            },
          } = {}; // ihid => { name, icon, archived }
          events.forEach((event) => {
            const {
              ihid,
              name,
              type,
              archived,
            } = event;

            eventMap[ihid] = {
              name,
              ihid,
              archived: !!archived,
              icon: eventTypeToIcon(type),
            };
          });

          // Update the state
          dispatch({
            type: ActionType.FinishLoading,
            links,
            eventInfoMap: eventMap,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log opening
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          subcontext: LogMetadata.Context.AdminPanel.BreakthroughLinkManager,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ---------- Link to View ---------- */

  if (view === View.BreakthroughLinkList && state.fullLinkToView) {
    // Get full link to view
    const { fullLinkToView } = state;
    const linkURL = `https://${window.location.hostname}/courses/${fullLinkToView.courseId}/events/${fullLinkToView.ihid}/breakthroughs/${fullLinkToView.linkId}`;
    modal = (
      <Modal
        title="Breakthrough Link"
        type={ModalType.NoButtons}
        onClose={() => {
          dispatch({
            type: ActionType.StopViewingLink,
          });
        }}
      >
        <div className="input-group">
          {/* Label */}
          <span className="input-group-text">
            Link
          </span>

          {/* Input Field (just for being copied) */}
          <input
            id="BreakthroughLinkManager-link-box"
            type="text"
            className="form-control bg-light"
            value={linkURL}
            aria-label="breakthrough link"
            readOnly
          />

          {/* Copy Button */}
          <div className="input-group-append">
            <CopyButton
              text={linkURL}
              item="full breakthrough link"
              variant={Variant.Secondary}
            />
          </div>
        </div>
      </Modal>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    return (
      <LoadingSpinner />
    );
  }

  /* -------- Breakthrough List ------- */

  // Destructure state
  const {
    links,
    eventInfoMap,
    linkToMatch,
  } = state;

  // Create link table
  let linkTable: React.ReactNode;
  if (!links || links.length === 0) {
    // No links
    linkTable = (
      <NothingHereNotice
        title="No Breakthrough Links"
        subtitle="This course does not have any breakthrough links."
      />
    );
  } else {
    // Apply filter
    let filteredLinks = links;
    let invalidFilter = false;
    if (linkToMatch.trim().length > 0) {
      // Find information in the link
      let courseIdToMatchTo: number | undefined;
      let ihidToMatchTo: string | undefined;
      let linkIdToMatchTo: string | undefined;
      const parts = linkToMatch.trim().split('/');
      for (let i = 0; i < parts.length - 1; i++) {
        if (parts[i].toLowerCase() === 'courses') {
          courseIdToMatchTo = Number.parseInt(parts[i + 1], 10);
        } else if (parts[i].toLowerCase() === 'events') {
          ihidToMatchTo = parts[i + 1];
        } else if (parts[i].toLowerCase() === 'breakthroughs') {
          linkIdToMatchTo = parts[i + 1];
        }
      }

      // Check for invalid filter
      if (
        !courseIdToMatchTo
        || Number.isNaN(courseIdToMatchTo)
        || !ihidToMatchTo
        || !linkIdToMatchTo
      ) {
        // Invalid filter
        invalidFilter = true;
      } else {
        // Filter the links
        filteredLinks = links.filter((link) => {
          return (
            (link.ihid === ihidToMatchTo)
            && (link.linkId === linkIdToMatchTo)
            && (link.courseId === courseIdToMatchTo)
          );
        });
      }
    }

    // Create a link table
    linkTable = (
      (filteredLinks.length === 0 || invalidFilter)
        ? (
          <div className="BreakthroughLinkManager-nothing-here-notice">
            <NothingHereNotice
              title={(
                invalidFilter
                  ? 'Invalid Search Text'
                  : 'No Matches'
              )}
              subtitle={(
                invalidFilter
                  ? 'Check your search text and try again'
                  : 'Perhaps this link is for another course? Or maybe the link was already deleted.'
              )}
            />
          </div>
        )
        : (
          <table className="table table-striped">
            {/* Header */}
            <thead>
              <tr>
                <th title="Label">
                  Label
                </th>
                <th title="Name of the event being linked to is...">
                  Event
                </th>
                <th title="Person who created the link is...">
                  Creator
                </th>
                <th title="Link was made on...">
                  Made
                </th>
                <th title="Link usable on...">
                  Schedule
                </th>
                <th title="Number of times link was used">
                  Visits
                </th>
                <th>
                  Buttons
                </th>
              </tr>
            </thead>
            {/* Rows */}
            <tbody>
              {
                filteredLinks.map((link) => {
                  return (
                    <BreakthroughLinkRow
                      key={link.linkId}
                      link={link}
                      eventInfo={eventInfoMap[link.ihid]}
                      onView={() => {
                        dispatch({
                          type: ActionType.ViewLink,
                          link,
                        });
                      }}
                      onDelete={() => {
                        deleteBreakthroughLink(link);
                      }}
                    />
                  );
                })
              }
            </tbody>
          </table>
        )
    );
  }

  // Put together
  return (
    <div>
      {modal}

      {/* Title */}
      <h2 className="text-white fw-bold">
        Breakthrough Manager
      </h2>

      {/* Current Course */}
      <p className="text-white lead">
        Current course:
        {' '}
        {courseName}
      </p>

      {/* Search */}
      <div className="input-group mb-1">
        <span className="input-group-text">
          Search:
        </span>
        <input
          type="text"
          id="BreakthroughLinkManager-search-field"
          className="form-control"
          placeholder="paste a breakthrough link here"
          aria-label="breakthrough link to search for"
          value={linkToMatch}
          onChange={(e) => {
            dispatch({
              type: ActionType.UpdateLinkToMatch,
              linkToMatch: e.target.value,
            });
          }}
        />
      </div>

      {/* Links */}
      <div className="bg-white">
        {linkTable}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BreakthroughLinkManager;
