/**
 * Visualizer for attendance, AttendanceTableHeader
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
} from '@fortawesome/free-solid-svg-icons';

// Import shared constants
import WEEKDAY_TO_ABBREVIATION from '../../../../../../shared/constants/WEEKDAY_TO_ABBREVIATION';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Array of date string
  dateKeys: string[],
  // Column to sort by
  sortBy?: string,
  /**
   * On sort column change
   * @param dateKey date key to sort by
   */
  onSortByChange: (dateKey: string) => void,
  // Handler for when the user wants to stop sorting
  onStopSorting: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AddComponentName: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    dateKeys,
    sortBy,
    onSortByChange,
    onStopSorting,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ----------- Date Cells ----------- */

  const dateCells = (
    dateKeys
      .map((dateKey) => {
        // Create prettier date
        const date = new Date(dateKey);
        const year = String(date.getFullYear());
        const fullDateText = `${date.getMonth() + 1}/${date.getDate()}/${year}`;
        const dateText = `${date.getMonth() + 1}/${date.getDate()}`;
        const weekday = date.toLocaleString(
          'en-US',
          {
            timeZone: 'America/New_York',
            weekday: 'long',
          },
        );
        const weekdayAbbreviation = WEEKDAY_TO_ABBREVIATION[
          weekday as keyof typeof WEEKDAY_TO_ABBREVIATION
        ];
        return (
          <th
            key={dateKey}
            className="bg-secondary text-nowrap text-light"
            title={fullDateText}
          >
            <div>
              {weekdayAbbreviation}
            </div>
            {dateText}
            <button
              type="button"
              className={`btn btn-sm pt-0 pb-0 ps-1 pe-1 ms-1 btn-${sortBy === dateKey ? 'warning' : 'secondary'}`}
              aria-label={`sort by attendance on date column: ${dateKey}`}
              id={`AttendanceTable-sort-by-${String(dateKey).replace(/\//g, '-')}`}
              data-sort={dateKey}
              onClick={() => {
                return onSortByChange(dateKey);
              }}
            >
              <FontAwesomeIcon icon={faChevronDown} />
            </button>
          </th>
        );
      })
  );

  /* ----------- Item Toggle ---------- */

  const itemToggleCell = (
    <th className="bg-secondary text-nowrap text-light">
      <div style={{ marginBottom: '11px' }}>
        Attendee Name

        {/* Sort Button */}
        <button
          type="button"
          className={`btn btn-sm pt-0 pb-0 ps-1 pe-1 ms-2 btn-${!sortBy ? 'warning' : 'secondary'}`}
          aria-label="sort by attendee name"
          id="AttendanceTable-sort-by-name"
          onClick={() => {
            onStopSorting();
          }}
        >
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
      </div>
    </th>
  );

  return (
    <thead>
      <tr>
        {itemToggleCell}
        {dateCells}
      </tr>
    </thead>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AddComponentName;
