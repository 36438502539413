/**
 * Matcher for attendance that was manually entered
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faPersonCircleCheck,
  faPersonCircleQuestion,
  faTrash,
} from '@fortawesome/free-solid-svg-icons';

// Import caccl types
import CanvasUser from 'caccl/types/CanvasUser';

// Import dce-reactkit
import {
  LoadingSpinner,
  Modal,
  ModalType,
  visitServerEndpoint,
  showFatalError,
  ModalSize,
  confirm,
  capitalize,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import shared types
import SelfEnteredAttendance from '../../../../../shared/types/from-server/stored/SelfEnteredAttendance';
import LogMetadata from '../../../../../shared/types/from-server/LogMetadata';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Batched self-entered attendance entries
  batches: SelfEnteredAttendance[][],
  // List of Canvas users that can be chosen as the match
  canvasUsers: CanvasUser[],
  // Handler for when the user is finished
  onFinish: () => void,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Currently matching
  Matching = 'Matching',
  // Finished
  Finished = 'Finished',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Matching,
    // Number of batches
    numBatches: number,
    // Index of the current batch
    batchIndex: number,
    // If true, a batch is being processed
    processing: boolean,
  }
  | {
    // Current view
    view: View.Finished,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Start saving a batch matching
  StartSavingBatch = 'StartSavingBatch',
  // Finish saving batch and move on to next batch, or finish
  FinishSavingBatch = 'FinishSavingBatch',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.StartSavingBatch
      | ActionType.FinishSavingBatch
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  // No actions allowed if finished
  if (state.view === View.Finished) {
    return state;
  }

  // Actions for matcher
  switch (action.type) {
    case ActionType.StartSavingBatch: {
      return {
        ...state,
        processing: true,
      };
    }
    case ActionType.FinishSavingBatch: {
      if (state.batchIndex + 1 >= state.numBatches) {
        // Finish (there are no more batches)
        return {
          view: View.Finished,
        };
      }

      // Move to next batch
      return {
        ...state,
        batchIndex: state.batchIndex + 1,
        processing: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceMatcher: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    batches,
    canvasUsers,
    onFinish,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Matching,
    numBatches: batches.length,
    batchIndex: 0,
    processing: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Perform matching process for the current user
   * @author Gabe Abrams
   * @param matchedUser the canvas user that was matched
   */
  const matchUser = async (
    matchedUser: CanvasUser,
  ) => {
    // Make sure we're currently matching a user
    if (view !== View.Matching) {
      return;
    }

    // Destructure state
    const {
      batchIndex,
    } = state;

    // Get the batch
    const batch = batches[batchIndex];

    // Start processing
    dispatch({
      type: ActionType.StartSavingBatch,
    });

    // Send results to server
    try {
      for (let i = 0; i < batch.length; i++) {
        // Get the self entered attendance entry
        const selfEntered = batch[i];

        // Send to server
        await visitServerEndpoint({
          path: `/api/ttm/courses/${selfEntered.courseId}/events/${selfEntered.ihid}/attendance/match`,
          method: 'POST',
          params: {
            selfEnteredAttendance: selfEntered,
            canvasUser: matchedUser,
          },
        });
      }

      // Log matching batch
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.AttendanceSubpanel,
        action: LogAction.Post,
        target: LogMetadata.Target.SelfEnteredAttendance,
        metadata: {
          matchedBatch: batch,
          matchedUser,
        },
      });

      // Finish saving batch
      dispatch({
        type: ActionType.FinishSavingBatch,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Delete the current batch of self entered attendance entries
   *  (ignore them all) and move on to the next batch or finish if there
   *  are no more batches to process
   * @author Gabe Abrams
   */
  const deleteCurrentBatch = async () => {
    // Make sure we're currently matching a user
    if (view !== View.Matching) {
      return;
    }

    // Destructure state
    const {
      batchIndex,
    } = state;

    // Confirm
    const confirmed = await confirm(
      'Delete attendance?',
      'All attendance for this unknown person will be permanently deleted.',
    );
    if (!confirmed) {
      return;
    }

    // Get the batch
    const batch = batches[batchIndex];

    // Start processing
    dispatch({
      type: ActionType.StartSavingBatch,
    });

    // Send results to server
    try {
      for (let i = 0; i < batch.length; i++) {
        // Get the self entered attendance entry
        const selfEntered = batch[i];

        // Send to server
        await visitServerEndpoint({
          path: `/api/ttm/courses/${selfEntered.courseId}/events/${selfEntered.ihid}/attendance/match/${selfEntered.id}`,
          method: 'DELETE',
        });
      }

      // Log ignoring batch
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.AttendanceSubpanel,
        action: LogAction.Delete,
        target: LogMetadata.Target.SelfEnteredAttendance,
        metadata: {
          deletedBatch: batch,
        },
      });

      // Finish saving batch
      dispatch({
        type: ActionType.FinishSavingBatch,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ---------- Matching User --------- */

  if (view === View.Matching) {
    // Destructure state
    const {
      batchIndex,
      processing,
    } = state;

    // Show loading indicator if processing
    if (processing) {
      body = (
        <LoadingSpinner />
      );
    } else {
      // Ask user to match the next batch

      // Get the current batch
      const batch = batches[batchIndex];

      // Get info on the current batch
      const {
        userFirstName,
        userLastName,
        isLearner,
        isAdmin,
      } = batch[0];

      // Create a role as text
      let role = 'Student';
      if (isAdmin) {
        role = 'Admin';
      } else if (!isLearner) {
        role = 'Teaching Staff';
      }

      // Create body
      body = (
        <div key={`batch-${batchIndex}`}>
          {/* Header */}
          <h4 className="text-center">
            Find this unknown person...
          </h4>
          {/* Profile */}
          <div className="alert alert-warning p-2 d-flex align-items-center justify-content-center">
            {/* Title */}
            <div className="flex-grow-1 text-dark">
              <h4 className="m-0">
                <FontAwesomeIcon
                  icon={faPersonCircleQuestion}
                  className="me-2"
                />
                {capitalize(userFirstName)}
                {' '}
                {capitalize(userLastName)}
                {' '}
                (
                {role}
                )
              </h4>
            </div>
            {/* Buttons */}
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={deleteCurrentBatch}
                aria-label="Delete all attendance for this unknown person"
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="me-1"
                />
                Ignore
              </button>
            </div>
          </div>

          {/* Instructions */}
          <h4 className="text-center">
            ...in the list of known users in your course:
          </h4>

          {/* Options */}
          <div className="d-grid gap-1">
            {canvasUsers.map((canvasUser) => {
              // Process user
              const [
                canvasUserLastName,
                canvasUserFirstName,
              ] = canvasUser.sortable_name.split(', ');
              const huid = canvasUser.login_id;

              return (
                <div
                  key={canvasUser.id}
                  className="alert alert-secondary pt-1 pb-1 ps-2 pe-2 d-flex align-items-center justify-content-center m-0"
                >
                  {/* Title */}
                  <div className="flex-grow-1">
                    <h5 className="m-0">
                      <FontAwesomeIcon
                        icon={faPersonCircleCheck}
                        className="me-2"
                      />
                      {canvasUserFirstName}
                      {' '}
                      {canvasUserLastName}
                      {
                        huid
                          ? (
                            <span>
                              {' '}
                              (
                              {huid}
                              )
                            </span>
                          )
                          : null
                      }
                    </h5>
                  </div>
                  {/* Buttons */}
                  <div>
                    <button
                      type="button"
                      className="btn btn-secondary"
                      onClick={() => {
                        matchUser(canvasUser);
                      }}
                      aria-label={`Match ${userLastName}, ${userFirstName} to ${canvasUser.sortable_name}`}
                    >
                      Select
                    </button>
                  </div>
                </div>
              );
            })}

            {/* Delete */}
            <div className="d-grid">
              <button
                type="button"
                className="btn btn-dark btn-lg"
                onClick={deleteCurrentBatch}
                aria-label="Delete all attendance for this unknown person"
              >
                <FontAwesomeIcon
                  icon={faTrash}
                  className="me-2"
                />
                Ignore this Person
              </button>
            </div>
          </div>

        </div>
      );
    }
  }

  /* ------------ Finished ------------ */

  if (view === View.Finished) {
    // Show the "finished" modal
    return (
      <Modal
        title="Matching Finished"
        type={ModalType.Okay}
        onClose={onFinish}
        okayLabel="Finish"
      >
        All attendance has been matched!
      </Modal>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Destructure state
  const {
    batchIndex,
    numBatches,
  } = state;

  // Render main UI
  return (
    <Modal
      title={`Manual Attendance Matching (${numBatches - batchIndex} left)`}
      type={ModalType.NoButtons}
      onClose={async () => {
        // Confirm
        const confirmed = await confirm(
          'Skip Matching Attendance?',
          'If you skip part of the attendance matching process, attendance data will not be accurate and complete.',
        );
        if (!confirmed) {
          return;
        }

        // Finished
        onFinish();
      }}
      size={ModalSize.Large}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceMatcher;
