/**
 * Zoom user account status
 * @author Gabe Abrams
 */
enum ZoomUserAccountStatus {
  // User is eligible for a Zoom account and has claimed one
  Claimed = 'Claimed',
  // User is *probably* eligible for a Zoom account but has not claimed one
  NeedsToClaim = 'NeedsToClaim',
}

export default ZoomUserAccountStatus;
