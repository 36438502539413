/**
 * Configure Immersive Classroom for a specific course
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  visitServerEndpoint,
  showFatalError,
  logClientEvent,
  LogAction,
  confirm,
} from 'dce-reactkit';

// Import shared types
import ICFeatureFlagMap from '../../../shared/types/from-server/ICFeatureFlagMap';
import AdminPanelFeatureProps from '../types/AdminPanelFeatureProps';
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

// Import other components
import FeatureFlagMapEditor from './FeatureFlagMapEditor';

// Import constants
import INITIAL_FEATURE_FLAG_MAP from './constants/INITIAL_FEATURE_FLAG_MAP';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Not Yet Configured
  NotYetConfigured = 'NotYetConfigured',
  // Configure
  Configure = 'Configure',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.NotYetConfigured,
  }
  | {
    // Current view
    view: View.Configure,
    // If true, currently saving
    saving: boolean,
    // Current default feature flag map
    defaultFeatureFlagMap: ICFeatureFlagMap,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Start loading again
  StartLoading = 'StartLoading',
  // Show not yet configured
  ShowNotYetConfigured = 'ShowNotYetConfigured',
  // Show configuration screen
  ShowConfigPanel = 'ShowConfigPanel',
  // Start saving indicator
  StartSaving = 'StartSaving',
  // Finish saving
  FinishSaving = 'FinishSaving',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.ShowConfigPanel,
    // Feature flag map to show
    defaultFeatureFlagMap: ICFeatureFlagMap,
  }
  | {
    // Action type
    type: ActionType.FinishSaving,
    // Updated feature flag map
    defaultFeatureFlagMap: ICFeatureFlagMap,
  }
  | {
    // Action type
    type: (
      | ActionType.ShowNotYetConfigured
      | ActionType.StartSaving
      | ActionType.StartLoading
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  if (state.view === View.Loading) {
    switch (action.type) {
      case ActionType.ShowNotYetConfigured: {
        return {
          view: View.NotYetConfigured,
        };
      }
      case ActionType.ShowConfigPanel: {
        return {
          view: View.Configure,
          saving: false,
          defaultFeatureFlagMap: action.defaultFeatureFlagMap,
        };
      }
      default: {
        return state;
      }
    }
  }

  // Not yet configured
  if (state.view === View.NotYetConfigured) {
    switch (action.type) {
      case ActionType.StartLoading: {
        return {
          view: View.Loading,
        };
      }
      case ActionType.ShowConfigPanel: {
        return {
          view: View.Configure,
          saving: false,
          defaultFeatureFlagMap: action.defaultFeatureFlagMap,
        };
      }
      default: {
        return state;
      }
    }
  }

  // In config view
  switch (action.type) {
    case ActionType.StartSaving: {
      return {
        ...state,
        saving: true,
      };
    }
    case ActionType.FinishSaving: {
      return {
        ...state,
        saving: false,
        defaultFeatureFlagMap: action.defaultFeatureFlagMap,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ICConfiguration: React.FC<AdminPanelFeatureProps> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
  } = props;

  const {
    courseId,
    courseName,
  } = userAndCourseInfo;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Initialize IC and configure it with a baseline feature flag map
   * @author Gabe Abrams
   */
  const initICConfig = async () => {
    // Skip if in wrong view
    if (state.view !== View.NotYetConfigured) {
      return;
    }

    try {
      // Show loading
      dispatch({
        type: ActionType.StartSaving,
      });

      // Send the request to initialize IC
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/ic/default-feature-flag-map`,
        method: 'POST',
        params: {
          defaultFeatureFlagMap: INITIAL_FEATURE_FLAG_MAP,
        },
      });

      // Update the state
      dispatch({
        type: ActionType.ShowConfigPanel,
        defaultFeatureFlagMap: INITIAL_FEATURE_FLAG_MAP,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Update the default feature flag map for IC in this course
   * @author Gabe Abrams
   */
  const updateFeatureFlagMap = async (newFeatureFlagMap: ICFeatureFlagMap) => {
    // Skip if in wrong view
    if (state.view !== View.Configure) {
      return;
    }

    try {
      // Show loading
      dispatch({
        type: ActionType.StartSaving,
      });

      // Send the request to update the feature flag map
      await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/ic/default-feature-flag-map`,
        method: 'POST',
        params: {
          defaultFeatureFlagMap: newFeatureFlagMap,
        },
      });

      // Update the state
      dispatch({
        type: ActionType.FinishSaving,
        defaultFeatureFlagMap: newFeatureFlagMap,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          // Log opening
          logClientEvent({
            context: LogMetadata.Context.AdminPanel,
            subcontext: LogMetadata.Context.AdminPanel.ICConfiguration,
            action: LogAction.Open,
          });

          // Get the default feature flag map
          const defaultFeatureFlagMap = await visitServerEndpoint({
            path: `/api/admin/courses/${courseId}/ic/default-feature-flag-map`,
            method: 'GET',
          });

          // Choose view based on load results
          if (defaultFeatureFlagMap) {
            // Configured. Go to config panel
            dispatch({
              type: ActionType.ShowConfigPanel,
              defaultFeatureFlagMap,
            });
          } else {
            // Not yet configured
            dispatch({
              type: ActionType.ShowNotYetConfigured,
            });
          }
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* ------- Not Yet Configured ------- */

  if (view === View.NotYetConfigured) {
    // Create body
    body = (
      <div className="alert alert-warning text-dark">
        <h3 className="m-0">
          <FontAwesomeIcon
            icon={faInfoCircle}
            className="me-2"
          />
          Immersive Classroom Not Enabled
        </h3>
        <div>
          IC for
          {' '}
          {courseName}
          {' '}
          needs to be enabled before it is configured.
        </div>
        <div className="text-center mt-2">
          <button
            type="button"
            className="btn btn-dark"
            aria-label="enable Immersive Classroom"
            onClick={async () => {
              await confirm(
                'First, Configure OpenCast',
                'Before enabling Immersive Classroom, you *must* first make sure OpenCast has been configured to support IC and OCSocial.',
                {
                  confirmButtonText: 'OpenCast is Configured',
                },
              );

              initICConfig();
            }}
          >
            <FontAwesomeIcon
              icon={faCircle}
              className="me-2"
            />
            Enable Immersive Classroom
          </button>
        </div>
      </div>
    );
  }

  /* ------- Configuration Panel ------ */

  if (view === View.Configure) {
    // Destructure the state
    const {
      saving,
      defaultFeatureFlagMap,
    } = state;

    // Saving
    if (saving) {
      body = (
        <LoadingSpinner />
      );
    } else {
      // Configuring

      // Create body
      body = (
        <FeatureFlagMapEditor
          featureFlagMap={defaultFeatureFlagMap}
          onUpdate={updateFeatureFlagMap}
        />
      );
    }
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="ICConfiguration-outer-container">
      <div className="ICConfiguration-inner-container">
        {/* Title */}
        <h2 className="text-white fw-bold mb-0">
          Configure Immersive Classroom
        </h2>
        <p className="lead fw-normal text-white mb-3">
          Course:
          {' '}
          {courseName}
        </p>

        {/* Body */}
        {body}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ICConfiguration;
