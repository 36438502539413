/**
 * Modal for opening a link to a recording or a meeting (or anything else)
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import {
  Modal,
  LoadingSpinner,
  ModalType,
} from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHeadset,
  faExclamationTriangle,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';

// Import shared constants
import ZOOM_TRAINING_PDF_URL from '../constants/ZOOM_TRAINING_PDF_URL';

// Import other components
import VisitLinkAgreements from './VisitLinkAgreements';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler for when the modal is closed
  onClose: () => void,
  // Title of the modal
  title: string,
  // The description of the location being visited (e.g. "recording")
  label: string,
  // Body (just above the button)
  body?: React.ReactNode,
  // Name of the button
  buttonText: string,
  // If true, the link should be shown as loading
  loading?: boolean,
  // Icon for the title
  icon?: IconDefinition,
  // Link for the button
  link?: string,
  // If true, show agreements
  showAgreements?: boolean,
  // If true, show agreements for webinars
  useWebinarAgreements?: boolean,
  // If true, user is joining a lounge
  isJoiningLounge?: boolean,
  // If true, user is opening a recording
  isOpeningRecording?: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const VisitLinkModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onClose,
    title,
    label,
    body,
    buttonText,
    loading,
    icon,
    link,
    showAgreements,
    useWebinarAgreements,
    isJoiningLounge,
    isOpeningRecording,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* -------------- Help -------------- */

  let help: React.ReactNode;
  if (!isOpeningRecording) {
    help = (
      isJoiningLounge
        ? (
          <div className="VisitLinkModal-lounge-help alert alert-info">
            <div>
              <div className="fw-bold">
                Need help?
              </div>
              <div
                className="d-inline-block text-start"
                style={{
                  maxWidth: '37.5rem',
                  width: '100%',
                }}
              >
                <div className="d-flex mb-1">
                  <div className="VisitLinkModal-icon">
                    <FontAwesomeIcon
                      icon={faHeadset}
                    />
                  </div>
                  <div className="flex-grow-1">
                    For help with Zoom, check out our
                    {' '}
                    <a
                      href={ZOOM_TRAINING_PDF_URL}
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{
                        textDecoration: 'underline',
                        color: 'inherit',
                      }}
                    >
                      Zoom Help Guide
                    </a>
                  </div>
                </div>
                <div className="d-flex mb-1">
                  <div className="VisitLinkModal-icon">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                    />
                  </div>
                  <div className="flex-grow-1">
                    If you witness a code of conduct violation,
                    please contact your instructor or teaching assistant
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
        : (
          <div className="VisitLinkModal-event-help mb-2">
            <strong>
              Need help with Zoom?
            </strong>
            {' '}
            Check out our
            {' '}
            <a
              href={ZOOM_TRAINING_PDF_URL}
              target="_blank"
              rel="noopener noreferrer"
              className="text-dark"
              style={{ textDecoration: 'underline' }}
            >
              Zoom Help Guide
            </a>
          </div>
        )
    );
  }

  /*----------------------------------------*/
  /*                 Full UI                */
  /*----------------------------------------*/

  // Body
  const modalBody = (
    loading
      ? (
        <div className="VisitLinkModal-loading text-center">
          <LoadingSpinner />
        </div>
      )
      : (
        <div className="text-center">
          {/* Agreements */}
          {showAgreements && (
            <VisitLinkAgreements
              useWebinarAgreements={useWebinarAgreements}
              isJoiningLounge={isJoiningLounge}
            />
          )}

          {/* Help */}
          {help}

          {/* Body */}
          {body && (
            <div className="VisitLinkModal-body">
              {body}
            </div>
          )}

          {/* Button */}
          <div className="mt-2">
            <a
              href={link}
              aria-label={`open the ${label} in another tab`}
              title={`Open the ${label} in another tab`}
              rel="noopener noreferrer"
              target="_blank"
              className="btn btn-dark btn-lg"
              id="VisitLinkModal-button"
              onClick={onClose}
            >
              {buttonText}
            </a>
          </div>
        </div>
      )
  );

  return (
    <Modal
      key="visit-link-modal"
      title={(
        <span className="VisitLinkModal-title">
          {/* Icon */}
          {icon && (
            <FontAwesomeIcon
              icon={icon}
              className="me-2"
            />
          )}

          {/* Title */}
          {title}
        </span>
      )}
      type={ModalType.NoButtons}
      onClose={onClose}
      onTopOfOtherModals
    >
      {modalBody}
    </Modal>
  );
};

export default VisitLinkModal;
