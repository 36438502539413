/**
 * An individual archived event item
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
  faTrashRestore,
} from '@fortawesome/free-solid-svg-icons';

// Import shared types
import CourseEvent from '../../../shared/types/from-server/stored/CourseEvent';

// Import helpers
import eventTypeToIcon from '../../../shared/helpers/eventTypeToIcon';
import eventTypeToTitle from '../../../shared/helpers/eventTypeToTitle';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The event
  event: CourseEvent,
  // Handler to call when the event is restored
  onRecover: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ArchivedEventItem: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    event,
    onRecover,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Extract event attributes
  const {
    currentZoomId,
    name,
    ihid,
    type,
  } = event;

  // Choose the event icon based on the type
  const eventIcon = eventTypeToIcon(type);

  // Container for the event type icon
  const eventTypeIconContainer = (
    <div
      style={{ minWidth: '3.125rem' }}
      className="text-center"
      aria-label={`This event's type is: "${eventTypeToTitle(type)}"`}
    >
      <FontAwesomeIcon
        icon={eventIcon || faCalendarAlt}
        className="m-1"
        size="2x"
      />
    </div>
  );

  // Set Zoom Id text if zoom event
  const zoomDiv = (
    currentZoomId
      ? (
        <div className={`ArchivedEventItem-zoom-meeting-id ArchivedEventItem-zoom-meeting-id-${ihid}`}>
          Zoom Meeting ID:
          {' '}
          {currentZoomId}
        </div>
      )
      : null
  );

  // Container for the event title and Zoom ID text
  const titleAndZoomIdContainer = (
    <div className="ArchivedEventItem-title-container flex-grow-1 text-start ms-2 me-1">
      <h3 className={`ArchivedEventItem-title ArchivedEventItem-title-${ihid} m-0`}>
        {name}
      </h3>
      {zoomDiv}
    </div>
  );

  // The recover button
  const button = (
    <button
      type="button"
      className="btn btn-lg btn-dark"
      id={`ArchivedEventItem-recover-${event.ihid}`}
      aria-label={`Recover event: ${name}`}
      onClick={() => {
        onRecover();
      }}
    >
      <FontAwesomeIcon
        icon={faTrashRestore}
        className="me-1"
      />
      Recover
    </button>
  );

  return (
    <div className="alert alert-secondary ps-1 pe-1 pt-1 pb-1 mb-2">
      {/* Spacer that Appears for md+ Screens */}
      <div className="d-none mt-1" />

      {/* Flex Container */}
      <div className="ArchivedEventItem-container d-flex justify-content-around align-items-center">
        {/* Event Type Icon */}
        {eventTypeIconContainer}

        {/* Title */}
        {titleAndZoomIdContainer}

        {/* Button */}
        <div
          className="ArchivedEventItem-button-container me-1"
          style={{ whiteSpace: 'nowrap' }}
        >
          {button}
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ArchivedEventItem;
