/**
 * Enum for the attendance method
 * @author Gabe Abrams
 */
enum AttendanceMethod {
  // Attended physically in-person
  InPerson = 'in-person',
  // Attended synchronously online (Zoom, for example)
  Live = 'live',
  // Watched a recorded version of the event after the fact
  Async = 'async',
  // Did not attend, but was excused and gets full credit
  Excused = 'excused',
  // Did not attend, was not excused
  Absent = 'absent',
}

export default AttendanceMethod;
