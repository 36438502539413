/**
 * Main component which manages the check-in flow.
 * Wraps all subsequent steps in a body.
 * Should be opened on a button click.
 * @author Benedikt Arnarsson
 */

// Import React
import React, { useReducer } from 'react';

// Import DCE reactkit
import { Modal } from 'dce-reactkit';

// Import Grouper
import { StudentGroupAssignment } from 'dce-live-grouper';

// Import shared types
import AssignToGroups from '../../../../shared/types/from-server/stored/CourseEvent/AssignToGroups';

// Import types
import CheckInIds from './types/CheckInIds';
import MMDDYY from './types/MMDDYY';
import OccurrenceBasicInfo from './types/OccurrenceBasicInfo';
import OccurrenceGrouperInfo from './types/OccurrenceGrouperInfo';
import UserAndCourseInfo from '../../../../shared/types/UserAndCourseInfo';

// Import components
import CheckInQrCard from './components/CheckInQrCard';
import GrouperSettings from './components/GrouperSettings';
import LoadOccurrence from './components/LoadOccurrence';
import PreAssign from './components/PreAssign';
import SeriesSelection from './components/SeriesSelection';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = (
  & CheckInIds
  & {
    // Function to run to close the Modal
    onClose: () => void,
    // Choice of event-wide group assignment
    assignToGroups: AssignToGroups,
    // Optional sub-text to display over QR code
    subTextForQr?: string,
    // User and course info
    userAndCourseInfo: UserAndCourseInfo,
  }
);

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Starting view, loading in data and checking if occurrence has started
  LoadOccurrenceModal = 'LoadOccurrenceModal',
  // Display body for selecting a previous set of groups or starting a new
  // set of groups
  SeriesSelectionModal = 'SeriesSelectionModal',
  // Display body for setting pre-assignments to groups
  PreAssignModal = 'PreAssignModal',
  // Display body for setting up the live grouper algorithm
  GrouperSettingsModal = 'GrouperSettingsModal',
  // Display body with QR card for taking attendance
  CheckInQrCardModal = 'CheckInQrCardModal',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.LoadOccurrenceModal,
    // First part of OccurrenceBasicInfo, passed in as props
    checkInProps: CheckInIds,
  }
  | {
    // Current view
    view: View.SeriesSelectionModal,
    // First part of OccurrenceBasicInfo, passed in as props
    checkInProps: CheckInIds,
    // Second part of OccurrenceBasicInfo, generated from LoadOccurrence
    occurrenceDate: MMDDYY,
    // Custom modal title
    customModalTitle?: string,
  }
  | {
    // Current view
    view: View.PreAssignModal,
    // First part of OccurrenceBasicInfo, passed in as props
    checkInProps: CheckInIds,
    // Second part of OccurrenceBasicInfo, generated from LoadOccurrence
    occurrenceDate: MMDDYY,
    // Occurrence info needed to start DCE Live Grouper
    occurrenceGrouperInfo: OccurrenceGrouperInfo,
    // Pre-assignments from old groups
    oldGroups: StudentGroupAssignment[],
    // Custom modal title
    customModalTitle?: string,
  }
  | {
    // Current view
    view: View.GrouperSettingsModal,
    // First part of OccurrenceBasicInfo, passed in as props
    checkInProps: CheckInIds,
    // Second part of OccurrenceBasicInfo, generated from LoadOccurrence
    occurrenceDate: MMDDYY,
    // Occurrence info needed to start DCE Live Grouper
    occurrenceGrouperInfo: OccurrenceGrouperInfo,
    // Pre-assigned students
    preAssignments: StudentGroupAssignment[],
  }
  | {
    // Current view
    view: View.CheckInQrCardModal,
    // Complete occurrence info
    occurrence: OccurrenceBasicInfo,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Go from loading screen to check-in, when occurrence is already started
  LoadToCheckIn = 'LoadToCheckIn',
  // Go from loading screen to series selection, when setting up occurrence
  LoadToSeries = 'LoadToSeries',
  // Go from series selection to check-in
  SeriesToCheckIn = 'SeriesToCheckIn',
  // Go from series selection to grouper settings
  SeriesToGrouper = 'SeriesToGrouper',
  // Go from series selection to pre-assignment
  SeriesToPreAssign = 'SeriesToPreAssign',
  // Go from pre-assignments to grouper settings
  PreAssignToGrouper = 'PreAssignToGrouper',
  // Go from grouper settings to check in
  GrouperToCheckIn = 'GrouperToCheckIn',
  // Set a custom modal title or remove it
  SetCustomModalTitle = 'SetCustomModalTitle',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.LoadToCheckIn,
    // The info needed to construct OccurrenceBasicInfo for CheckInQrCard
    occurrenceDate: MMDDYY,
  }
  | {
    // Action type
    type: ActionType.LoadToSeries,
    // The date info needed to continue constructing the occurrence
    occurrenceDate: MMDDYY,
  }
  | {
    // Action type
    type: ActionType.SeriesToCheckIn,
  }
  | {
    // Action type
    type: ActionType.SeriesToGrouper,
    // The full occurrence, with new series and grouper enabled
    occurrenceGrouperInfo: OccurrenceGrouperInfo,
    // Groups from previous occurrence in series
    oldGroups: StudentGroupAssignment[],
  }
  | {
    // Action type
    type: ActionType.SeriesToPreAssign,
    // The full occurrence that is being checked into, with grouper enabled
    occurrenceGrouperInfo: OccurrenceGrouperInfo,
    // Groups from previous occurrence in series
    oldGroups: StudentGroupAssignment[],
  }
  | {
    // Action type
    type: ActionType.PreAssignToGrouper,
    // The pre-assigned students being passed the live-grouper
    preAssignments: StudentGroupAssignment[],
  }
  | {
    // Action type
    type: ActionType.GrouperToCheckIn,
  }
  | {
    // Action type
    type: ActionType.SetCustomModalTitle,
    // The custom title to set (undefined to unset)
    customModalTitle?: string,
  }
);

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 * @returns updated state
 */
const reducer = (state: State, action: Action): State => {
  const { view } = state;
  switch (action.type) {
    case ActionType.LoadToCheckIn: {
      if (view !== View.LoadOccurrenceModal) {
        return state;
      }
      return {
        view: View.CheckInQrCardModal,
        // Merge CheckInProps and MMDDYY into OccurrenceBasicInfo
        occurrence: {
          ...state.checkInProps,
          ...action.occurrenceDate,
        },
      };
    }
    case ActionType.LoadToSeries: {
      if (view !== View.LoadOccurrenceModal) {
        return state;
      }
      return {
        ...state,
        view: View.SeriesSelectionModal,
        occurrenceDate: action.occurrenceDate,
      };
    }
    case ActionType.SeriesToCheckIn: {
      if (view !== View.SeriesSelectionModal) {
        return state;
      }
      return {
        view: View.CheckInQrCardModal,
        // Merge CheckInProps and MMDDYY into OccurrenceBasicInfo
        occurrence: {
          ...state.checkInProps,
          ...state.occurrenceDate,
        },
      };
    }
    case ActionType.SeriesToGrouper: {
      if (view !== View.SeriesSelectionModal) {
        return state;
      }
      return {
        ...state,
        view: View.GrouperSettingsModal,
        occurrenceGrouperInfo: action.occurrenceGrouperInfo,
        preAssignments: action.oldGroups,
      };
    }
    case ActionType.SeriesToPreAssign: {
      if (view !== View.SeriesSelectionModal) {
        return state;
      }
      return {
        ...state,
        view: View.PreAssignModal,
        occurrenceGrouperInfo: action.occurrenceGrouperInfo,
        oldGroups: action.oldGroups,
      };
    }
    case ActionType.PreAssignToGrouper: {
      if (view !== View.PreAssignModal) {
        return state;
      }
      return {
        ...state,
        view: View.GrouperSettingsModal,
        preAssignments: action.preAssignments,
      };
    }
    case ActionType.GrouperToCheckIn: {
      if (view !== View.GrouperSettingsModal) {
        return state;
      }
      return {
        view: View.CheckInQrCardModal,
        // Merge CheckInProps and MMDDYY into OccurrenceBasicInfo
        occurrence: {
          ...state.checkInProps,
          ...state.occurrenceDate,
        },
      };
    }
    case ActionType.SetCustomModalTitle: {
      if (
        view !== View.PreAssignModal
        && view !== View.SeriesSelectionModal
      ) {
        return state;
      }
      return {
        ...state,
        customModalTitle: action.customModalTitle,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* --------------------------- Static Helpers --------------------------- */
/*------------------------------------------------------------------------*/

const MODAL_TITLES: Record<View, string> = {
  [View.LoadOccurrenceModal]: 'Loading...',
  [View.SeriesSelectionModal]: 'Choose Groups',
  [View.PreAssignModal]: 'Assign Students to Groups',
  [View.GrouperSettingsModal]: 'How should groups be created?',
  [View.CheckInQrCardModal]: 'CheckIn',
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CheckInSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    courseId,
    ihid,
    assignToGroups,
    onClose,
    subTextForQr,
    userAndCourseInfo,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.LoadOccurrenceModal,
    checkInProps: props,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let body: React.ReactNode;

  // Determine default modal title
  let modalTitle = MODAL_TITLES[view];

  /* ----------- SetupModal ----------- */

  if (view === View.LoadOccurrenceModal) {
    // Create body
    body = (
      <LoadOccurrence
        courseId={courseId}
        ihid={ihid}
        onSubmit={(mmddyy, occIsStarted) => {
          if (occIsStarted) {
            dispatch({
              type: ActionType.LoadToCheckIn,
              occurrenceDate: mmddyy,
            });
          } else {
            dispatch({
              type: ActionType.LoadToSeries,
              occurrenceDate: mmddyy,
            });
          }
        }}
      />
    );
  }

  /* ------ SeriesSelectionModal ------ */

  if (view === View.SeriesSelectionModal) {
    // Overwrite modal title
    if (state.customModalTitle) {
      modalTitle = state.customModalTitle;
    }

    // Create body
    body = (
      <SeriesSelection
        checkInProps={state.checkInProps}
        occurrenceDate={state.occurrenceDate}
        assignToGroups={assignToGroups}
        setCustomModalTitle={(customModalTitle?: string) => {
          dispatch({
            type: ActionType.SetCustomModalTitle,
            customModalTitle,
          });
        }}
        onSubmit={(opts) => {
          // Opts will not be passed in when we just take attendance
          // OR, we will pass in preAssign=false and oldGroups
          if (!opts || (!opts.preAssign && opts.oldGroups)) {
            dispatch({
              type: ActionType.SeriesToCheckIn,
            });
          } else {
            const actionType = (
              opts.preAssign
                ? ActionType.SeriesToPreAssign
                : ActionType.SeriesToGrouper
            );
            dispatch({
              type: actionType,
              oldGroups: opts.oldGroups ?? [],
              occurrenceGrouperInfo: {
                grouperEnabled: true,
                seriesId: opts.seriesId,
              },
            });
          }
        }}
      />
    );
  }

  /* --------- PreAssignModal --------- */

  if (view === View.PreAssignModal) {
    // Overwrite modal title
    if (state.customModalTitle) {
      modalTitle = state.customModalTitle;
    }

    // Create body
    body = (
      <PreAssign
        oldGroups={state.oldGroups}
        setCustomModalTitle={(customModalTitle?: string) => {
          dispatch({
            type: ActionType.SetCustomModalTitle,
            customModalTitle,
          });
        }}
        onSubmit={(preAssignments) => {
          dispatch({
            type: ActionType.PreAssignToGrouper,
            preAssignments,
          });
        }}
      />
    );
  }

  /* ------ GrouperSettingsModal ------ */

  if (view === View.GrouperSettingsModal) {
    // Create body
    body = (
      <GrouperSettings
        checkInProps={state.checkInProps}
        occurrenceDate={state.occurrenceDate}
        occurrenceGrouperInfo={state.occurrenceGrouperInfo}
        preAssignments={state.preAssignments}
        onSubmit={() => {
          dispatch({
            type: ActionType.GrouperToCheckIn,
          });
        }}
      />
    );
  }

  /* ------- CheckInQrCardModal ------- */

  if (view === View.CheckInQrCardModal) {
    // Create body
    body = (
      <CheckInQrCard
        occurrence={state.occurrence}
        subTextForQr={subTextForQr}
        userAndCourseInfo={userAndCourseInfo}
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      title={modalTitle}
      largeTitle
      onClose={onClose}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component

export default CheckInSubpanel;
