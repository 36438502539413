// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import types
import User from '../../../types/User';

// Import constants
import STUDENT_PATH_PREFIX from '../../../constants/STUDENT_PATH_PREFIX';

// Constants
const PATH = `${STUDENT_PATH_PREFIX}/attendance/students`;

/**
 * Given a course and event ID, fetch the corresponding roster.
 * @author Benedikt Arnarsson
 * @param courseId the ID of the course (Canvas generated)
 * @returns a list of Users, containing ID and name information.
 */
const fetchStudentList = async (): Promise<User[]> => {
  return visitServerEndpoint({ path: PATH });
};

export default fetchStudentList;
