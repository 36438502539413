/**
 * Generate a shareable link for an event
 * @author Gabe Abrams
 * @param courseId the Canvas id of the course
 * @param ihid the ihid for the event we're linking to
 * @returns shareable link
 */
const genShareableLink = (courseId: number, ihid: string) => {
  return `https://${window.location.hostname}/courses/${courseId}/events/${ihid}`;
};

export default genShareableLink;
