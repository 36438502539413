// Import reactkit
import { visitServerEndpoint } from 'dce-reactkit';

// Import helpers
import genOccurrenceId from '../../helpers/genOccurrenceId';

// Import types
import OccurrenceBasicInfo from '../../types/OccurrenceBasicInfo';

// Import constants
import TTM_PATH_PREFIX from '../../constants/TTM_PATH_PREFIX';

/**
 * Call the backend to check whether the occurrence has already started.
 * @author Benedikt Arnarsson
 * @param occurrenceBasicInfo information needed to query for the occurrence.
 * @returns a boolean indicating whether the occurrence has already been
 *   created/started.
 */
const isOccurrenceStarted = async (
  occurrenceBasicInfo: OccurrenceBasicInfo,
): Promise<boolean> => {
  const occurrenceId = genOccurrenceId(occurrenceBasicInfo);
  const occurrence = await visitServerEndpoint({
    path: `${TTM_PATH_PREFIX}/occurrence`,
    params: {
      occurrenceId,
    },
  });

  return !!occurrence;
};

export default isOccurrenceStarted;
