/**
 * The Prospective event details
 * Includes event skip reasons and event create error reasons
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import other components
import ShowMoreToggle from './ShowMoreToggle';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Course CRN
  crn: string,
  // The array of skip reasons
  skipReasons: string[],
  // The error message string
  errorMessage?: string,
  // The warning message
  warningMessage?: string,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, show all skip reasons
  showAllReasons: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Toggle showing all reasons
  ToggleShowAllReasons = 'ToggleShowAllReasons',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.ToggleShowAllReasons
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.ToggleShowAllReasons: {
      return {
        ...state,
        showAllReasons: !state.showAllReasons,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const EventDetails: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    crn,
    skipReasons,
    errorMessage,
    warningMessage,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    showAllReasons: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    showAllReasons,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Format the skip list and error message, if any
  const details = [];

  // Push error message if something to push (avoid pushing "false")
  if (errorMessage && errorMessage.length > 0) {
    details.push(errorMessage);
  }

  // Push warning message if there is one
  if (warningMessage) {
    details.push(warningMessage);
  }

  // Create skip reason detail component
  skipReasons.forEach((reasonText, i) => {
    const reasonSequence = `skip-reason-${i}`;
    const idClassName = `EventDetails-skip-reason-crn-${crn}`;
    const visibleClassName = showAllReasons || i === 0 ? 'd-inherit' : 'd-none';
    const hasButtonClassName = skipReasons.length > 1 && i === 0 ? 'has-button' : '';
    details.push(
      <div
        className="EventDetails-row"
        key={reasonText}
      >
        <div className="EventDetails-row-item fw-normal">
          <div
            className={`${idClassName} ${reasonSequence} ${visibleClassName} ${hasButtonClassName} text-truncate`}
          >
            {reasonText}
          </div>
        </div>
        {/* Put "show more" button on first row when there's more than one */}
        {(i === 0 && skipReasons.length > 1) && (
          <ShowMoreToggle
            showMore={showAllReasons}
            onToggle={(() => {
              dispatch({
                type: ActionType.ToggleShowAllReasons,
              });
            })}
            crn={crn}
          />
        )}
      </div>,
    );
  });

  // Add  details to the return statement
  return (
    <div className={`EventDetails-container EventDetails-${crn}`}>
      {details}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default EventDetails;
