/**
 * Get the status message to append to successful event creation events
 * @author Gabe Abrams
 * @param ensuringLoungeExists true if ensuring a lounge exists
 * @returns status message
 */
const getBatchCreateSuccessMessage = (ensuringLoungeExists?: boolean) => {
  let addon = '';
  if (ensuringLoungeExists) {
    addon += ', ensured study lounge exists';
  }
  return `Batch Created by Gather on ${(new Date()).toLocaleDateString()}${addon}`;
};

export default getBatchCreateSuccessMessage;
