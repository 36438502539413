// Import dce-reactkit
import { alert } from 'dce-reactkit';

// Import shared types
import SupportEmail from '../types/from-server/SupportEmail';

/**
 * Show an alert indicating that this feature is locked
 * @author Gabe Abrams
 */
const showThisIsLockedModal = async () => {
  await alert(
    'This is Locked',
    `To keep the student experience secure and consistent, this feature is locked. If you have questions, email support at ${SupportEmail.ForTTMs}.`,
  );
};

export default showThisIsLockedModal;
