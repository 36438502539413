/**
 * Attendance dashboard
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilterCircleXmark } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import { Tooltip } from 'dce-reactkit';

// Import shared types
import CourseEvent from '../../../../../shared/types/from-server/stored/CourseEvent';
import UserRole from '../../../../../shared/types/UserRole';
import AttendanceMethod from '../../../../../shared/types/from-server/stored/AttendanceLog/AttendanceMethod';

// Import filters
import AttendanceTimeFilter, { EARLIEST_HOUR, LATEST_HOUR } from './filters/AttendanceTimeFilter';
import AttendanceDayFilter from './filters/AttendanceDayFilter';

// Import shared components
import CSVDownloadButton from '../../../../../shared/CSVDownloadButton';

// Import shared constants
import WEEKDAY_TO_CODE from '../../../../../shared/constants/WEEKDAY_TO_CODE';

// Import other components
import AttendanceTable from './AttendanceTable';

// Import other types
import AttendanceLogMap from '../types/AttendanceLogMap';
import AttendanceRecord from '../types/AttendanceRecord';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Map of attendance (date => ihid => userId => record)
  attendanceLogMap: AttendanceLogMap,
  // The event to show
  event: CourseEvent,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  /* -------------- Date -------------- */
  // Specific date (key form)
  dateKey?: string,
  // Days of week (codes, empty list means all)
  daysOfWeek: string[],
  /* -------------- Time -------------- */
  // Earliest time (hours with fraction for minutes)
  earliestTime: number,
  // Latest time (hours with fraction for minutes)
  latestTime: number,
  /* -------- Attendance Method ------- */
  // If true, including live attendance
  includeLive: boolean,
  // If true, including async attendance
  includeAsync: boolean,
  // If true, including in-person attendance
  includeInPerson: boolean,
  /* ------------ User Role ----------- */
  // If true, TTMs included
  includeTTMs: boolean,
  // If true, Students included
  includeStudents: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Update days of the week
  UpdateDaysOfWeek = 'UpdateDaysOfWeek',
  // Update earliest and latest time
  UpdateTime = 'UpdateTime',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.UpdateDaysOfWeek,
    // New days of the week
    newDaysOfWeek: string[],
  }
  | {
    // Action type
    type: ActionType.UpdateTime,
    // New earliest time
    newEarliestTime: number,
    // New latest time
    newLatestTime: number,
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.UpdateDaysOfWeek: {
      return {
        ...state,
        daysOfWeek: action.newDaysOfWeek,
      };
    }
    case ActionType.UpdateTime: {
      return {
        ...state,
        earliestTime: action.newEarliestTime,
        latestTime: action.newLatestTime,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceDashboard: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    attendanceLogMap,
    event,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    earliestTime: EARLIEST_HOUR,
    latestTime: LATEST_HOUR,
    daysOfWeek: Object.values(WEEKDAY_TO_CODE),
    includeAsync: false,
    includeLive: true,
    includeInPerson: true,
    includeTTMs: false,
    includeStudents: true,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    dateKey,
    daysOfWeek,
    earliestTime,
    latestTime,
    includeAsync,
    includeLive,
    includeInPerson,
    includeTTMs,
    includeStudents,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Filter ------------- */

  // New map with filtered results
  const filteredAttendanceLogMap: {
    [date: string]: {
      [ihid: string]: {
        [userId: string]: AttendanceRecord,
      },
    },
  } = {}; // date => ihid => userId => log

  // Loop through dates
  Object.keys(attendanceLogMap).forEach((currDateKey) => {
    // Loop through event IHIDs
    Object.keys(attendanceLogMap[currDateKey]).forEach((ihid) => {
      // Loop through users
      Object.keys(attendanceLogMap[currDateKey][ihid]).forEach((userId) => {
        const {
          method,
          userRole,
          eventDayOfWeek,
          eventFractionalHour,
        } = attendanceLogMap[currDateKey][ihid][userId];

        // Check if specific date doesn't match
        if (dateKey && currDateKey !== dateKey) {
          return;
        }

        // Check if event doesn't match
        if (event.ihid !== ihid) {
          return;
        }

        // Check if day of week doesn't match
        if (
          daysOfWeek.length > 0
          && daysOfWeek.length < 7
          && daysOfWeek.indexOf(eventDayOfWeek) < 0
        ) {
          return;
        }

        // Check if method doesn't match
        if (
          (method === AttendanceMethod.Live && !includeLive)
          || (method === AttendanceMethod.Async && !includeAsync)
          || (method === AttendanceMethod.InPerson && !includeInPerson)
        ) {
          return;
        }

        // Check if before earliest time (add 1 hour buffer)
        if (earliestTime - 1 > eventFractionalHour) {
          return;
        }

        // Check if after latest time
        if (latestTime < eventFractionalHour) {
          return;
        }

        // Check if role doesn't match
        if (
          (userRole === UserRole.TTM && !includeTTMs)
          || (userRole === UserRole.Student && !includeStudents)
          || (userRole === UserRole.Admin)
        ) {
          return;
        }

        // All checks passed! Add entry

        /* ------------ Add Entry ----------- */

        // Add parent maps
        if (!filteredAttendanceLogMap[currDateKey]) {
          filteredAttendanceLogMap[currDateKey] = {};
        }
        if (!filteredAttendanceLogMap[currDateKey][ihid]) {
          filteredAttendanceLogMap[currDateKey][ihid] = {};
        }

        // Add log entry
        const logEntry = attendanceLogMap[currDateKey][ihid][userId];
        filteredAttendanceLogMap[currDateKey][ihid][userId] = logEntry;
      });
    });
  });

  /* ---------- CSV Download ---------- */

  // Create raw data CSV
  const rawDataHeaders: {
    [key: string]: string,
  } = {
    userName: 'User Name',
    userId: 'User Canvas ID',
    userRole: 'User Role',
    inAttendance: 'Attended',
    userWasHost: 'User Joined as Host',
    eventId: 'Event ID',
    eventName: 'Event Name',
    eventDate: 'Occurrence Date',
    method: 'Attendance Method',
    joinTimesString: 'Join Times',
    groupNumber: 'Group Number',
  };

  // Check if there's at least one group number
  // Also add join times string
  let atLeastOneGroupNumber;
  const rawData: {
    [key: string]: string,
  }[] = [];
  Object.values(filteredAttendanceLogMap).forEach((dateEntry) => {
    Object.values(dateEntry).forEach((eventEntry) => {
      Object.values(eventEntry).forEach((userEntry) => {
        // Add joinTimesString
        const updatedUserEntry: {
          [key: string]: any,
        } = userEntry;
        updatedUserEntry.joinTimesString = (
          userEntry
            .joinTimes
            // Filter out excluded attendance
            .filter((joinTime) => {
              const { method } = joinTime;

              return (
                // Check live attendance
                (
                  method === AttendanceMethod.Live
                  && includeLive // Check if included
                )
                // Check in-person attendance
                || (
                  method === AttendanceMethod.InPerson
                  && includeInPerson // Check if included
                )
                // Check async attendance
                || (
                  method === AttendanceMethod.Async
                  && includeAsync // Check if included
                )
              );
            })
            // Extract descriptions
            .map((joinTime) => {
              return joinTime.description;
            })
            // Join descriptions together
            .join(', ')
        );

        // Add the entry to the list
        rawData.push(updatedUserEntry);

        // Keep track of group number existence
        if (userEntry.groupNumber && userEntry.groupNumber !== 'N/A') {
          atLeastOneGroupNumber = true;
        }
      });
    });
  });

  // Decide whether to include the group number column
  if (!atLeastOneGroupNumber) {
    delete rawDataHeaders.groupNumber;
  }

  const dateStamp = (
    (new Date())
      .toLocaleDateString('en-US')
      .replace(/\//g, '-')
  );
  const rawDataDownloadButton = (
    <CSVDownloadButton
      id="AttendanceDashboard-download-csv-attendance"
      headerMap={rawDataHeaders}
      data={rawData}
      title="CSV"
      filename={`Attendance for ${event.name} - Exported on ${dateStamp}.csv`}
    />
  );

  const attendanceLogs = (
    <div className="text-center position-relative overflow-hidden">
      <div className="AttendanceDashboard-drawer-inner text-dark m-0">
        {/* Filters */}
        <div className="d-flex flex-row gap-2 align-items-center justify-content-center mb-2">
          <div className="flex-grow-1">
            {/* Day Filter */}
            <AttendanceDayFilter
              onChange={(newDaysOfWeek) => {
                // Update state
                dispatch({
                  type: ActionType.UpdateDaysOfWeek,
                  newDaysOfWeek,
                });
              }}
              daysOfWeek={daysOfWeek}
            />
          </div>
          <div>
            <Tooltip
              text="Reset Filters"
            >
              <button
                type="button"
                className="btn btn-secondary btn-lg"
                aria-label="reset filters"
                style={{
                  paddingLeft: '0.9rem',
                  paddingRight: '0.9rem',
                }}
                onClick={() => {
                  // Reset days
                  dispatch({
                    type: ActionType.UpdateDaysOfWeek,
                    newDaysOfWeek: Object.values(WEEKDAY_TO_CODE),
                  });
                  // Reset time
                  dispatch({
                    type: ActionType.UpdateTime,
                    newEarliestTime: EARLIEST_HOUR,
                    newLatestTime: LATEST_HOUR,
                  });
                }}
              >
                <FontAwesomeIcon
                  icon={faFilterCircleXmark}
                  className="me-1"
                />
                Reset
              </button>
            </Tooltip>
          </div>
        </div>
        <div className="d-flex flex-row gap-2 align-items-center justify-content-center mb-4">
          <div className="flex-grow-1">
            {/* Time Filter */}
            <AttendanceTimeFilter
              onChange={(newEarliestTime, newLatestTime) => {
                // Update state
                dispatch({
                  type: ActionType.UpdateTime,
                  newEarliestTime,
                  newLatestTime,
                });
              }}
              earliestTime={earliestTime}
              latestTime={latestTime}
            />
          </div>
          {/* Download Button */}
          <div>
            {rawDataDownloadButton}
          </div>
        </div>

        {/* Attendance Table */}
        <AttendanceTable
          attendanceLogMap={filteredAttendanceLogMap}
        />
      </div>
    </div>
  );

  /* ------------- Full UI ------------ */

  return (
    <div id="AttendanceDashboard-event-analytics">
      {attendanceLogs}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceDashboard;
