/**
 * CSV downloader
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import CSV generator
import Papa from 'papaparse';

// Import shared components
import DownloadButton from './DownloadButton';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Name of the file
  filename: string,
  // Map of prop to header: prop => header title
  headerMap: {
    [prop: string]: string,
  },
  // Array of data objects containing the same props as in the headerMap
  data: {
    [k: string]: any,
  }[],
  // Id for the button
  id: string,
  // Human-readable title of the file
  title?: string,
  // Handler for onClick (not needed for download, that happens automatically)
  onClick?: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CSVDownloadButton: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    filename,
    headerMap,
    data,
    id,
    title = 'CSV',
    onClick,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------ CSV Text ------------ */

  // Create CSV
  // > Create input for Papaparse
  const headers: string[] = [];
  const rows: string[][] = [];
  Object.keys(headerMap).forEach((prop) => {
    // Get header title
    headers.push(headerMap[prop]);

    // Add data to each row
    (data || []).forEach((datum, i) => {
      // Add row entry if there isn't one
      if (!rows[i]) {
        rows[i] = [];
      }

      // Add this prop
      rows[i].push(
        datum[prop] === undefined
          ? '–'
          : datum[prop],
      );
    });
  });
  // > Use Papa to create downloadable content
  const downloadData = encodeURIComponent(Papa.unparse({
    fields: headers,
    data: rows,
  }));

  // Make sure filename has extension
  const filenameWithExtension = `${filename}${filename.endsWith('.csv') ? '' : '.csv'}`;

  /* ----------------------------- UI ----------------------------- */

  // Create the download button
  return (
    <DownloadButton
      id={id}
      label={title}
      filename={filenameWithExtension}
      downloadData={downloadData}
      onClick={onClick}
    />
  );
};

export default CSVDownloadButton;
