/**
 * Given all existing seriesIds, generate a new one that is not in the list.
 * @author Gabe Abrams
 * @param seriesIds - the list of existing seriesIds
 * @returns a new seriesId
 */
const genNewSeriesId = (seriesIds: number[]): number => {
  // Find the highest existing seriesId
  let maxId = 0;
  seriesIds.forEach((id) => {
    if (id > maxId) {
      maxId = id;
    }
  });

  // Return the next id
  return maxId + 1;
};

export default genNewSeriesId;
