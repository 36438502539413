/**
 * Button for copying text to the clipboard
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer } from 'react';

// Import dce-reactkit
import {
  Variant,
  alert,
  waitMs,
} from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard,
} from '@fortawesome/free-solid-svg-icons';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The text to copy
  text: string,
  // Name of the item being copied
  item: string,
  // If true, button is small
  small?: boolean,
  // Variant
  variant: Variant,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, user recently copied the text
  copied: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Indicate that the user recently copied
  IndicateCopied = 'IndicateCopied',
  // Indicate that the user hasn't recently copied
  IndicateNotCopied = 'IndicateNotCopied',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: (
      | ActionType.IndicateCopied
      | ActionType.IndicateNotCopied
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.IndicateCopied: {
      return {
        ...state,
        copied: true,
      };
    }
    case ActionType.IndicateNotCopied: {
      return {
        ...state,
        copied: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CopyButton: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    text,
    item,
    small,
    variant = Variant.Dark,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    copied: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    copied,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Full UI
  return (
    <button
      className={`btn btn-${variant} CopyButton-button ${small ? 'btn-sm' : ''}`}
      type="button"
      aria-label={`copy ${item} to clipboard`}
      disabled={copied}
      style={{
        borderTopLeftRadius: 0,
        borderBottomLeftRadius: 0,
        height: '100%',
      }}
      onClick={async () => {
        try {
          // Try to copy the text
          await navigator.clipboard.writeText(text);

          // Save copy success
          dispatch({
            type: ActionType.IndicateCopied,
          });

          // Wait a moment then put the button back
          await waitMs(3000);
          dispatch({
            type: ActionType.IndicateNotCopied,
          });
        } catch (err) {
          // An error occurred! Let the user manually copy it.
          alert(
            'Not Copied',
            'Oops! Your browser blocked us from copying to the clipboard. Please copy it manually.',
          );
        }
      }}
    >
      <span
        style={{
          minWidth: '3.8em',
          display: 'inline-block',
        }}
      >
        {
          copied
            ? 'Copied'
            : (
              <>
                <FontAwesomeIcon
                  icon={faClipboard}
                  className="me-2"
                />
                Copy
              </>
            )
        }
      </span>
    </button>
  );
};

export default CopyButton;
