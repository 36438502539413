/**
 * A prospective event item
 * @author Gabe Abrams
*/

// Import React
import React from 'react';

// Import other components
import EventDetails from './EventDetails';
import EventBadges from './EventBadges';

// Import shared types
import ProspectiveEvent from '../helpers/ProspectiveEvent';
import ProspectiveEventStatus from '../types/ProspectiveEventStatus';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The event to preview
  event: ProspectiveEvent,
  // if true, show the settings badges
  showSettings: boolean,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ProspectiveEventPreview: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    event,
    showSettings,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* ------------- Details ------------ */

  // Extract event info
  const status = event.getStatus();
  const skipReasons = event.listSkipReasons();
  const crn = event.getCRN();
  const errorMessage = event.getErrorMessage();
  const warningMessage = event.getWarningMessage();

  // Determine background color
  let backgroundVariant;
  if (status === ProspectiveEventStatus.Failed) {
    backgroundVariant = 'danger';
  } else if (status === ProspectiveEventStatus.CreatedWithWarning) {
    backgroundVariant = 'warning text-dark';
  } else {
    backgroundVariant = 'secondary';
  }

  return (
    <div className={`ProspectiveEventPreview ProspectiveEventPreview-crn-${crn} alert alert-${backgroundVariant} d-flex align-items-center fw-bold mb-2 p-2`}>
      <div className="ProspectiveEventPreview-centered-content w-100">
        <div className="ProspectiveEventPreview-title h4 d-flex flex-row mb-0">
          {/* Event Course Code */}
          <div className="flex-grow-1 text-start">
            <h4 className="text-truncate mb-0">
              {event.getCourseCode() || '[empty]'}
            </h4>
          </div>
          {/* Event Course Number */}
          <div>
            {event.getCRN()}
          </div>
        </div>
        {/* Event Badges, Skip details, Error details */}
        <div className="ProspectiveEventPreview-subText d-flex flex-row mb-0">
          <EventDetails
            skipReasons={skipReasons}
            errorMessage={errorMessage}
            warningMessage={warningMessage}
            crn={crn}
          />
          {showSettings && (
            <EventBadges
              event={event}
            />
          )}
        </div>
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ProspectiveEventPreview;
