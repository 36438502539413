/**
 * Attendance filter for specific day(s) of week
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  ButtonInputGroup,
  CheckboxButton,
} from 'dce-reactkit';

// Import days of week
import WEEKDAY_TO_CODE from '../../../../../../shared/constants/WEEKDAY_TO_CODE';
import CODE_TO_WEEKDAY from '../../../../../../shared/constants/CODE_TO_WEEKDAY';
import WEEKDAY_TO_ABBREVIATION from '../../../../../../shared/constants/WEEKDAY_TO_ABBREVIATION';

// Style
import './AttendanceDayFilter.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  /**
   * Handler for changes
   * @param newDaysOfWeek new list of days of the week
   */
  onChange: (newDaysOfWeek: string[]) => void,
  // Days of week (codes, empty list means all)
  daysOfWeek: string[],
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceDayFilter: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onChange,
    daysOfWeek,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Create list of checkboxes
  const checkboxes: React.ReactNode[] = [];
  Object.keys(WEEKDAY_TO_CODE).forEach((weekday) => {
    const code = WEEKDAY_TO_CODE[weekday as keyof typeof WEEKDAY_TO_CODE];
    checkboxes.push(
      <CheckboxButton
        key={code}
        text={(
          <>
            <span className="d-md-none">
              {code}
            </span>
            <span className="d-none d-md-inline">
              {
                WEEKDAY_TO_ABBREVIATION[
                  weekday as keyof typeof WEEKDAY_TO_ABBREVIATION
                ]
              }
            </span>
          </>
        )}
        checked={daysOfWeek.indexOf(code) >= 0}
        ariaLabel={`include ${weekday} in the filter`}
        onChanged={(checked) => {
          let newDaysOfWeek: string[];
          if (!checked) {
            // Remove from list
            newDaysOfWeek = (
              daysOfWeek
                .filter((day) => {
                  return (day !== code);
                })
            );
          } else {
            // Add to list (add in order)
            newDaysOfWeek = (
              Object.keys(CODE_TO_WEEKDAY)
                .filter((currCode) => {
                  return (
                    // Already in the list
                    daysOfWeek.indexOf(currCode) >= 0
                    // This is the code we're adding
                    || code === currCode
                  );
                })
            );
          }

          // Save
          onChange(newDaysOfWeek);
        }}
      />,
    );
  });

  /* ---------- Put Together ---------- */

  return (
    <div className="AttendanceDayFilter-container">
      <ButtonInputGroup
        label={(
          <FontAwesomeIcon
            icon={faCalendarAlt}
          />
        )}
        minLabelWidth="2.7rem"
        noMarginOnBottom
      >
        <div className="d-flex align-items-center w-100">
          {checkboxes}
        </div>
      </ButtonInputGroup>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceDayFilter;
