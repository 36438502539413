// Import dce-reactkit
import {
  visitServerEndpoint,
} from 'dce-reactkit';

// Import shared types
import GatherTag from '../../../../shared/types/from-server/GatherTag';

// Import types
import ColumnHeader from '../types/ColumnHeader';
import IdentifiedColumnHeader from '../types/IdentifiedColumnHeader';

// Import constants
import COLUMN_HEADERS from '../constants/COLUMN_HEADERS';

// Import classes
import ProspectiveEvent from './ProspectiveEvent';

/* --------------------------- Helpers -------------------------- */

/**
 * Find the header row
 * @author Gabe Abrams
 * @param {object} data - sheet data
 * @param {object[]} columnHeaders - list of column header object
 * @return {number} headerRowNumber
 */
const getHeaderRowNumber = (
  data: {
    [rowNumber: number]: {
      [columnLetter: string]: string
    }
  },
  columnHeaders: ColumnHeader[],
) => {
  // Count the number of required columns
  const numRequiredColumns = (
    columnHeaders
      .filter((header) => {
        return header.required;
      })
      .length
  );

  // Find the first row that contains enough gather tags to be a header row
  const numRows = Object.keys(data).length;
  for (let i = 1; i <= numRows; i++) { // Indexing starts at 1
    // Look for gather tags
    const numGatherTags = (
      // Get the list of cells in the current row
      Object.values(data[i])
        // Filter to only the cells with tags
        .filter((cell) => {
          return (
            cell.trim().endsWith(GatherTag.Read)
            || cell.trim().endsWith(GatherTag.Write)
          );
        })
        // Count the cells with tags
        .length
    );

    // If there are enough gather tags, we found the header!
    if (numGatherTags >= numRequiredColumns) {
      // Found the header! Return the number
      return i;
    }
  }

  // No header found. Throw an error
  throw new Error(`Could not find the header row in this sheet: there are ${numRequiredColumns} required columns but we didn't find find a row with that many Gather tags.`);
};

/**
 * Convert text to a boolean. Throws an error if text doesn't represent a
 *   boolean
 * @author Gabe Abrams
 * @param text the text to convert
 * @returns boolean value
 */
const convertToBoolean = (text: string) => {
  // Mapping of text to the boolean values (used case-insensitive)
  const booleanMap = {
    true: true, // Google Sheet Checkbox
    yes: true,
    on: true,
    y: true,
    t: true,
    false: false, // Google Sheet Checkbox
    no: false,
    off: false,
    n: false,
    f: false,
  };

  // Simplify the text
  const textSimplified = text.trim().toLowerCase();

  // Make sure there's a mapping value
  if ((booleanMap as any)[textSimplified] === undefined) {
    // The booleanMap doesn't have the text
    throw new Error();
  }

  // Get the value
  return (booleanMap as any)[textSimplified];
};

/**
 * Convert text to number. Throws an error if text doesn't represent a
 *   number
 * @author Gabe Abrams
 * @param text the text to convert
 * @returns number value
 */
const convertToNumber = (text: string) => {
  // Make sure the cell contains a number
  if (Number.isNaN(Number.parseInt(text, 10))) {
    throw new Error();
  }

  // Convert to number
  return Number.parseInt(text, 10);
};

/**
 * Convert text to string. Throws an error if text doesn't represent a
 *   valid string
 * @author Gabe Abrams
 * @param text the text to convert
 * @param [allowSpaces] if true, allow the text to have spaces
 * @returns string value
 */
const convertToString = (text: string, allowSpaces?: boolean) => {
  // Make sure the string is valid
  const hasSpaces = (text.trim().indexOf(' ') >= 0);
  if (!allowSpaces && hasSpaces) {
    throw new Error();
  }

  // Return the trimmed text
  return text.trim();
};

/**
 * Convert the value of a cell to a given type. Throws an error with the ending
 *   to an error message if the value could not be converted
 * @author Gabe Abrams
 * @param cellContents the contents to convert
 * @param type the type to convert to
 *   ("boolean", "number", or "string")
 * @param [allowSpaces] only relevant if converting to a string:
 *   if true, allow the string to have spaces
 * @returns converted value
 */
const convertToType = (
  cellContents: string,
  type: 'boolean' | 'number' | 'string',
  allowSpaces?: boolean,
): any => {
  // Handle booleans
  if (type === 'boolean') {
    try {
      return convertToBoolean(cellContents);
    } catch (err) {
      throw new Error('was not a boolean.');
    }
  }

  // Handle numbers
  if (type === 'number') {
    try {
      return convertToNumber(cellContents);
    } catch (err) {
      throw new Error('was not a number.');
    }
  }

  // Handle strings
  if (type === 'string') {
    try {
      return convertToString(cellContents, allowSpaces);
    } catch (err) {
      throw new Error('contains spaces.');
    }
  }

  // Oops! The type isn't valid
  throw new Error('was of an unknown type.');
};

/* ------------------------ Main Function ----------------------- */

/**
 * Get the list of skipped and prospective events
 * @author Gabe Abrams
 * @param googleSheetURL the url of the google sheet
 * @returns events object in the form
 *   { prospectiveEvents, skippedEvents }
 */
const listProspectiveAndSkippedEvents = async (
  googleSheetURL: string,
): Promise<{
  prospectiveEvents: ProspectiveEvent[],
  skippedEvents: ProspectiveEvent[],
}> => {
  /*----------------------------------------*/
  /*            Fetch Sheet Data            */
  /*----------------------------------------*/

  const {
    tabId,
    data,
  } = await visitServerEndpoint({
    path: '/api/admin/sheets/data',
    method: 'GET',
    params: {
      url: googleSheetURL,
    },
  });

  /*----------------------------------------*/
  /*             Process Headers            */
  /*----------------------------------------*/

  // Locate the header row
  const headerRowNumber = getHeaderRowNumber(data, COLUMN_HEADERS);

  // Locate each column (add columns[i].columnLetter)
  const headerRow = data[headerRowNumber];
  const columnLetters = Object.keys(headerRow);
  const columns = COLUMN_HEADERS.map((col) => {
    // Figure out which tag to use
    const TAG = (col.isWriteColumn ? GatherTag.Write : GatherTag.Read);

    // Find the column header by looping through all column letters
    const newCol: IdentifiedColumnHeader = {
      ...col,
      columnLetter: 'A',
      name: 'Unknown',
    };
    let found = false;
    for (let i = 0; i < columnLetters.length; i++) {
      const columnLetter = columnLetters[i];
      const cell = headerRow[columnLetter].trim();

      // Make sure the cell has the tag
      if (cell.endsWith(TAG)) {
        // Get the name of the column
        const colName = (
          cell
            // Remove the tag
            .substring(0, cell.length - TAG.length)
            // Remove whitespace
            .trim()
            // Make lowercase so we can compare case-insensitively
            .toLowerCase()
        );

        // Check if this is the right column
        const thisIsTheColumn = (
          col
            .names
            .map((candidateName) => {
              return candidateName.toLowerCase().trim();
            })
            .includes(colName)
        );
        if (thisIsTheColumn) {
          // Store column information
          newCol.columnLetter = columnLetter;
          newCol.name = colName;
          found = true;

          // Exit the loop (we found the column)
          break;
        }
      }
    }
    // Make sure column was found if it is required
    if (!found && newCol.required) {
      throw new Error(`Could not find the column "${col.names[0]} ${TAG}" in the header row.`);
    }

    // Return the potentially updated column
    return newCol;
  });

  // Create a map for looking up column locations
  const columnPropToLetter: {
    [prop: string]: string
  } = {}; // prop => columnLetter
  columns.forEach((col) => {
    const { columnLetter, prop } = col;
    columnPropToLetter[prop] = columnLetter;
  });

  /*----------------------------------------*/
  /*        Process Spreadsheet Data        */
  /*----------------------------------------*/

  // Process the spreadsheet data
  const rows = [];
  // Start on the row just after the header row:
  let currentRowNumber = headerRowNumber + 1;
  // Loop through the data while there is another row to process
  while (data[currentRowNumber]) {
    // Get the current row
    const rowMap = data[currentRowNumber];

    // Create an object that will represent the row
    const row: {
      [prop: string]: any,
      rowNumber: number,
      skipReasons: string[],
      alreadyHasResults: boolean,
    } = {
      // Add the row number to the row
      rowNumber: currentRowNumber,

      // Add an explanation for skipped rows
      skipReasons: [],

      // Add an alreadyHasResults boolean
      alreadyHasResults: false,
    };

    // Go through each column and add info
    /* eslint-disable @typescript-eslint/no-loop-func */
    columns.forEach((col) => {
      const {
        prop,
        type,
        allowSpaces,
        isWriteColumn,
        columnLetter,
        name,
      } = col;

      // Create a human-readable description of the cell
      const coordDescription = `Cell ${columnLetter}${currentRowNumber} (${name})`;

      // Determine if the column exists
      const columnExists = !!columnLetter;

      // Determine if a value is required in every cell in this column
      const valueRequired = (
        col.defaultValue === undefined // No default value
        && !col.isWriteColumn // Not write col (write column vals are optional)
      );

      // Determine the default value (null if no default value in header obj)
      const defaultValue = (
        (col.defaultValue !== undefined)
          ? col.defaultValue
          : null
      );

      // Get the contents of the cell (always trim)
      const cellContents = String(rowMap[columnLetter]).trim();
      const cellEmpty = (cellContents.length === 0);

      // Determine the value
      let value = defaultValue; // Start with the default value
      if (columnExists) {
        // Handle error cases
        if (valueRequired && cellEmpty) {
          // Add an error because the cell should not be empty
          row.skipReasons.push(`${coordDescription} has no value.`);
        } else if (isWriteColumn && !cellEmpty) {
          // Add an error because the cell should be empty
          row.skipReasons.push(`${coordDescription} already has contents.`);

          // Mark this as already having results
          row.alreadyHasResults = true;
        } else if (!cellEmpty) {
          // Parse the value
          try {
            value = convertToType(cellContents, type, allowSpaces);
          } catch (err) {
            row.skipReasons.push(`${coordDescription} ${(err as any).message}`);
          }
        }
      }

      // Save to row
      row[prop] = value;
    });

    // Add the row to the list
    // Only save rows marked for batch create, but keep incrementing row count
    if (row.batchCreate) {
      rows.push(row);
    }

    // Increment the row number for next iteration
    currentRowNumber += 1;
  }

  /*----------------------------------------*/
  /*             Post-processing            */
  /*----------------------------------------*/

  // Skip events where the CRN shows up more than once
  const crnCount: {
    [crn: number]: number
  } = {}; // crn => number of times it is used
  rows.forEach((row) => {
    if (!crnCount[row.crn]) {
      crnCount[row.crn] = 0;
    }
    crnCount[row.crn] += 1;
  });
  rows.forEach((row) => {
    if (crnCount[row.crn] > 1) {
      // This row has a duplicate! Skip it
      row.skipReasons.push('The CRN was used for more than one event.');
    }
  });

  // Skip events where there is more than one ban
  rows.forEach((row) => {
    if (row.isDCEBanOn && row.isFASBanOn) {
      // This row has more than one ban!
      row.skipReasons.push('Both DCE and FAS students were banned (only one ban is allowed).');
    }
  });

  /*----------------------------------------*/
  /*    Create ProspectiveEvent Instances   */
  /*----------------------------------------*/

  // Create ProspectiveEvents from each row
  const prospectiveEvents: ProspectiveEvent[] = [];
  const skippedEvents: ProspectiveEvent[] = [];
  rows.forEach((row) => {
    // Get all the info out of the row
    const {
      batchCreate,
      crn,
      hostEmail,
      courseCode,
      isLockAutoRecordSettingOn,
      isDCEBanOn,
      isFASBanOn,
      isAutoRecordOn,
      isWaitingRoomOn,
      isEmergencyEvent,
      skipReasons,
      alreadyHasResults,
      ensureLoungeExists,
      hostVideoDisabled,
      customEventName,
      isWebinar,
      isICEnabled,
      isICPairUpDisabled,
      isICPollDisabled,
      isICRespondDisabled,
    } = row;

    // Ignore rows where batchCreate is not checked
    if (!batchCreate) {
      return;
    }

    // Create an applyUpdates function

    /**
     * Apply updates to the cells of interest
     * @author Gabe Abrams
     * @param updates map of updates in the form
     *   { prop => value } where prop can be any of the following:
     *   openZoomLink, gatherLink, zoomPassword, canvasLink, results
     */
    const applyUpdates = async (
      updates: {
        [prop: string]: string | boolean | number
      } = {},
    ) => {
      // Transform into { row, col, value } updates
      const updatesWithCoords = Object.keys(updates).map((prop) => {
        const value = updates[prop];

        // Get the columnLetter
        const col = columnPropToLetter[prop];

        // Create the new update object
        return {
          value,
          col, // Column letters (e.g. P)
          row: row.rowNumber, // Google row number (e.g. 5)
        };
      });

      // Send the updates to the server
      return visitServerEndpoint({
        path: '/api/admin/sheets/data',
        method: 'PUT',
        params: {
          tabId,
          updates: updatesWithCoords,
          url: googleSheetURL,
        },
      });
    };

    // Create an event object
    const event = new ProspectiveEvent({
      crn,
      courseCode,
      hostEmail,
      applyUpdates,
      isLockAutoRecordSettingOn,
      isWaitingRoomOn,
      isAutoRecordOn,
      isDCEBanOn,
      isFASBanOn,
      skipReasons,
      alreadyHasResults,
      isEmergencyEvent,
      ensureLoungeExists,
      hostVideoDisabled,
      customEventName,
      isWebinar,
      isICEnabled,
      isICPairUpDisabled,
      isICPollDisabled,
      isICRespondDisabled,
    });

    // Add the event to the appropriate list
    if (skipReasons && skipReasons.length > 0) {
      // Skipped
      skippedEvents.push(event);
    } else {
      // Prospective
      prospectiveEvents.push(event);
    }
  });

  /*----------------------------------------*/
  /*                 Sorting                */
  /*----------------------------------------*/

  // Put events that have results at the end of the list
  skippedEvents.sort((a, b) => {
    if (!a.alreadyHasResults() && b.alreadyHasResults()) {
      return -1;
    }
    if (a.alreadyHasResults() && !b.alreadyHasResults()) {
      return 1;
    }
    return 0;
  });

  /*----------------------------------------*/
  /*                 Return                 */
  /*----------------------------------------*/

  return {
    prospectiveEvents,
    skippedEvents,
  };
};

export default listProspectiveAndSkippedEvents;
