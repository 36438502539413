/**
 * Form element for an event type
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import dce-reactkit
import {
  RadioButton,
  ButtonInputGroup,
  Variant,
} from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faList } from '@fortawesome/free-solid-svg-icons';

// Import helpers
import eventTypeToTitle from '../../../../shared/helpers/eventTypeToTitle';

// Import shared types
import CourseEventType from '../../../../shared/types/from-server/stored/shared/CourseEventType';

// Import shared constants
import MIN_EVENT_FORM_LABEL_WIDTH from '../../../../shared/constants/MIN_EVENT_FORM_LABEL_WIDTH';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  /**
  * Handler for when the type changes
  * @param newType the new type that's selected
  */
  onChange: (newType: CourseEventType) => void,
  // Type that's selected
  type?: CourseEventType,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const EventType: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    type,
    onChange,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // List of buttons (click to select the type)
  const types = Object.values(CourseEventType);
  const typeButtons: React.ReactNode[] = (
    types.map((typeToRender, i) => {
      const title = eventTypeToTitle(typeToRender);
      return (
        <RadioButton
          key={typeToRender}
          id={`EventType-choose-type-${typeToRender}`}
          text={eventTypeToTitle(typeToRender)}
          selected={type && type === typeToRender}
          ariaLabel={`event type is ${title}`}
          noMarginOnRight={i === types.length - 1}
          selectedVariant={Variant.Warning}
          small
          onSelected={() => {
            onChange(typeToRender);
          }}
        />
      );
    })
  );

  // Create the container
  return (
    <div className="mb-3">
      <ButtonInputGroup
        label={(
          <>
            <FontAwesomeIcon
              icon={faList}
              className="me-1"
            />
            Type
          </>
        )}
        minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
        wrapButtonsAndAddGaps
      >
        {typeButtons}
      </ButtonInputGroup>
    </div>
  );
};

export default EventType;
