/**
 * A button that downloads a file directly to the user's computer
 * @author Gabe Abrams
 */
// Import react
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCloudDownloadAlt } from '@fortawesome/free-solid-svg-icons';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // The label to be displayed on the screen e.g. 'Download CSV'
  label: React.ReactNode,
  // Download file name
  filename: string,
  // Download data (already converted to one encoded string)
  downloadData: string,
  // Id for the button
  id: string,
  // Addition onClick handler
  onClick?: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const DownloadButton: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    label,
    filename,
    downloadData,
    id,
    onClick,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Build an href with the download data
  const href = `data:application/octet-stream,${downloadData}`;

  // Render the button, icon is on its left
  return (
    <a
      id={id}
      download={filename}
      href={href}
      className="DownloadButton btn btn-dark btn-lg fw-bold"
      aria-label={`Click to download ${filename}`}
      onClick={onClick}
    >
      {/* Icon */}
      <FontAwesomeIcon
        icon={faCloudDownloadAlt}
        className="me-2"
      />

      {/* Label */}
      {label}
    </a>
  );
};

export default DownloadButton;
