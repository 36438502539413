/**
 * Allowed support emails
 * @author Gabe Abrams
 */
enum SupportEmail {
  // For teaching team members
  ForTTMs = 'webconference@dce.harvard.edu',
  // For students
  ForStudents = 'academictechnology@dce.harvard.edu',
}

export default SupportEmail;
