/**
 * Visualizer for attendance
 * @author Gabe Abrams
 * @author Karen Dolan
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconDefinition,
  faFileVideo,
  faSignInAlt,
  faUniversity,
  faUserGraduate,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Variant,
} from 'dce-reactkit';

// Import shared types
import AttendanceMethod from '../../../../../../../shared/types/from-server/stored/AttendanceLog/AttendanceMethod';

// Import shared components
import FadedTitleContainer from '../../../../../../../shared/FadedTitleContainer';

// Import other components
import AttendanceMethodTooltip from './AttendanceMethodTooltip';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Map of row data
  rowInfos: {
    name: string,
    id: number,
    icon: IconDefinition,
  }[],
  // Array of Arrays of String, null, or numbers
  valueLists: (string | null | number)[][],
  // Values for comparator
  valueWeightMap: {
    [userId: string]: {
      [dateKey: string]: number,
    },
  },
  // Array of date string
  dateKeys: string[],
  // Column to sort by
  sortBy?: string,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceTableBody: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    rowInfos,
    valueLists,
    valueWeightMap,
    dateKeys,
    sortBy,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  /* -------------- Create packaged row info objects -------------- */

  const rowPacks = valueLists.map((rowValueList, i) => {
    return {
      rowInfo: rowInfos[i],
      valueList: rowValueList,
    };
  });

  /* --------------------------- Sorting -------------------------- */

  if (sortBy) {
    // Sort by date column
    rowPacks.sort((a, b) => {
      const aRowId = a.rowInfo.id;
      const bRowId = b.rowInfo.id;
      const aWeight = valueWeightMap[aRowId][sortBy];
      const bWeight = valueWeightMap[bRowId][sortBy];

      return (bWeight - aWeight); // Descending
    });
  } else {
    // Sort by name ASCENDING
    rowPacks.sort((a, b) => {
      const aRowName = a.rowInfo.name;
      const bRowName = b.rowInfo.name;
      return (aRowName.localeCompare(bRowName));
    });
  }

  /* ------------------------- Create rows ------------------------ */

  const dataRows = rowPacks.map((pack, i) => {
    const {
      valueList,
      rowInfo,
    } = pack;

    // Create cells
    const cells = valueList.map((value, j) => {
      const dateKey = dateKeys[j];

      let contents: React.ReactNode;
      let bgVariant: string | undefined;
      if (typeof value === 'number') {
        // Number in attendance
        contents = (
          <div>
            {value}
          </div>
        );
        bgVariant = 'info';
      } else if (value === null) {
        // Not in attendance
        contents = (
          <div className="sr-only">
            Not in Attendance
          </div>
        ); // Blank cell
      } else {
        // In attendance
        let tooltipText: string = 'Joined via Zoom'; // Live
        let icon: IconDefinition = faSignInAlt; // Live
        if (value === AttendanceMethod.Async) {
          tooltipText = 'Watched Recording';
          icon = faFileVideo; // Recording
        } else if (value === AttendanceMethod.InPerson) {
          tooltipText = 'Joined In Person';
          icon = faUniversity; // In person
        }
        contents = (
          <div>
            <AttendanceMethodTooltip
              tooltipText={tooltipText}
              icon={icon}
            />
          </div>
        );
        bgVariant = 'info';
      }

      return (
        <td
          key={dateKey}
          className={`${bgVariant ? `bg-${bgVariant}` : ''} text-light`}
        >
          {contents}
        </td>
      );
    });

    // Create row
    const {
      name,
      id,
      icon,
    } = rowInfo;

    return (
      <tr key={id}>
        { /* A row consists of one header and data cells */ }
        <th
          scope="row"
          key={id}
          className="AttendanceTableBody-header-column-cell text-nowrap text-start fw-bold align-items-center"
          style={{
            zIndex: 100,
          }}
        >
          <FadedTitleContainer
            fadeWidthRem={1.875}
            alertVariant={(i % 2 === 0) ? Variant.Light : Variant.Secondary}
          >
            <div
              className="AttendanceTableBody-icon d-inline-block text-center me-2"
              title={(
                icon === faUserGraduate
                  ? 'Student'
                  : 'Teaching Team Member'
              )}
            >
              <FontAwesomeIcon
                icon={icon}
              />
            </div>
            {name}
          </FadedTitleContainer>
        </th>
        {cells}
      </tr>
    );
  });

  /* --------------------------- Return --------------------------- */

  return (
    <tbody>
      {dataRows}
    </tbody>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceTableBody;
