/**
 * Allows user to filter by arrival time
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClock } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  ButtonInputGroup,
} from 'dce-reactkit';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  /**
   * Handler to call when change occurs
   * @param {number} newEarliestTime - new early time value
   * @param {number} newLatestTime - new latest time value
   */
  onChange: (newEarliestTime: number, newLatestTime: number) => void,
  // Earliest time (hours with fraction for minutes)
  earliestTime: number,
  // Latest time (hours with fraction for minutes)
  latestTime: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Constants
const MIN_WINDOW_SIZE = 1; // hours
const STEP_SIZE = 0.25; // hours

// Create time options
const TIME_OPTIONS: {
  label: string,
  hours: number,
  minutes: string,
  onTheHour: boolean,
  value: number,
}[] = [];
export const EARLIEST_HOUR = 1; // Start at 1am
export const LATEST_HOUR = 24; // End at midnight
for (let i = EARLIEST_HOUR; i <= LATEST_HOUR; i += STEP_SIZE) {
  // Calculate hours
  const hours = Math.floor(i);
  // Calculate minutes
  let minutes = String((i % 1) * 60);
  const onTheHour = (i % 1 === 0);
  if (minutes.length === 1) {
    minutes = `0${minutes}`;
  }
  // Calculate AM/PM and hours12
  const ampm = ((hours >= 12 && hours < 24) ? 'pm' : 'am');
  let hours12 = (hours % 12);
  if (hours12 === 0) {
    hours12 = 12;
  }

  // Create label
  let label = '';
  if ((hours % 24) === 0 && onTheHour) {
    label = 'Midnight';
  } else if (hours === 12 && onTheHour) {
    label = 'Noon';
  } else {
    label = `${hours12}:${minutes} ${ampm}`;
  }

  // Add to list of options
  TIME_OPTIONS.push({
    label,
    hours,
    minutes,
    onTheHour,
    value: i,
  });
}

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AttendanceTimeFilter: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    earliestTime,
    latestTime,
    onChange,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Create early time options
  const earlyTimeOptions: React.ReactNode[] = [];
  TIME_OPTIONS
    // Filter out minimum window size
    .filter((option) => {
      return (option.value <= (LATEST_HOUR - MIN_WINDOW_SIZE));
    })
    .forEach((option) => {
      // Add spacer
      if (option.onTheHour && option.value > EARLIEST_HOUR) {
        earlyTimeOptions.push(
          <option
            key={`spacer-${option.value}`}
            disabled
          >
            ––––––––
          </option>,
        );
      }

      // Add item
      earlyTimeOptions.push(
        <option
          key={option.value}
          value={option.value}
          id={`AttendanceTimeFilter-earliest-time-${option.hours}-${option.minutes}`}
        >
          {option.label}
        </option>,
      );
    });

  // Create latest time options
  const latestTimeOptions: React.ReactNode[] = [];
  TIME_OPTIONS
    // Filter out minimum window size
    .filter((option) => {
      return (option.value >= (EARLIEST_HOUR + MIN_WINDOW_SIZE));
    })
    .forEach((option) => {
      // Add spacer
      if (
        option.onTheHour
        && option.value > EARLIEST_HOUR + MIN_WINDOW_SIZE
      ) {
        latestTimeOptions.push(
          <option
            key={`spacer-${option.value}`}
            disabled
          >
            ––––––––
          </option>,
        );
      }

      // Add item
      latestTimeOptions.push(
        <option
          key={option.value}
          value={option.value}
          id={`AttendanceTimeFilter-latest-time-${option.hours}-${option.minutes}`}
        >
          {option.label}
        </option>,
      );
    });

  return (
    <ButtonInputGroup
      label={(
        <FontAwesomeIcon
          icon={faClock}
        />
      )}
      minLabelWidth="2.7rem"
      noMarginOnBottom
    >
      {/* Earliest Time */}
      <span className="mr-3">
        {/* Selector */}
        <select
          id="AttendanceTimeFilter-earliest-time-selector"
          className="AttendanceTimeFilter-time-selector form-select border-secondary"
          aria-label="Arrival time filter: earliest time to include (minus one hour)"
          value={earliestTime}
          onChange={(e) => {
            const newEarliestTime = Number.parseFloat(e.target.value);

            // Adjust latest time if needed
            const newLatestTime = (
              (latestTime < newEarliestTime + MIN_WINDOW_SIZE)
                ? newEarliestTime + MIN_WINDOW_SIZE // Adhere to window
                : latestTime
            );

            onChange(newEarliestTime, newLatestTime);
          }}
        >
          {earlyTimeOptions}
        </select>
      </span>

      {/* Divider */}
      <span className="ms-1 me-1">
        to
      </span>

      {/* Latest Time */}
      <span>
        {/* Selector */}
        <select
          id="AttendanceTimeFilter-latest-time-selector"
          className="AttendanceTimeFilter-time-selector form-select border-secondary"
          aria-label="Arrival time filter: latest time to include"
          value={latestTime}
          onChange={(e) => {
            const newLatestTime = Number.parseFloat(e.target.value);

            // Adjust earliest time if needed
            const newEarliestTime = (
              (earliestTime > newLatestTime - MIN_WINDOW_SIZE)
                ? newLatestTime - MIN_WINDOW_SIZE // Adhere to window
                : earliestTime
            );

            onChange(newEarliestTime, newLatestTime);
          }}
        >
          {latestTimeOptions}
        </select>
      </span>

      {/* Explanation */}
      <div
        className="small text-muted text-start ms-1 d-none d-md-block"
        style={{
          lineHeight: '1.2em',
        }}
      >
        Note: we also include
        <br />
        people who join early
      </div>
    </ButtonInputGroup>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AttendanceTimeFilter;
