let currentTitle = window.document.title;
let currentPath = '/';

/**
 * Set the path of the current page
 * @author Gabe Abrams
 * @param title the title of the page
 * @param path the path to set in the page
 */
const setPagePath = (title: string, path: string) => {
  // Title
  const fullTitle = `Gather | ${title}`;
  if (currentTitle !== fullTitle) {
    // Update the window title
    window.document.title = fullTitle;
    currentTitle = fullTitle;
  }

  // Path
  if (currentPath !== path) {
    // Set the path
    window.history.pushState(null, 'Gather', path);
    currentPath = path;
  }
};

export default setPagePath;
