/**
 * Type of a course event
 * @author Gabe Abrams
 */
enum CourseEventType {
  Class = 'class',
  Section = 'section',
  Lab = 'lab',
  OfficeHours = 'office-hours',
  Meeting = 'meeting',
  Event = 'event',
  Other = 'other',
}

export default CourseEventType;
