/**
 * Lounge item
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCog,
  faLock,
  faQuestion,
  faSignInAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import shared components
import Smiley from '../../../shared/Smiley';

// Import lounge furniture
import LoungeChair from './LoungeChair';
import LoungeTable from './LoungeTable';
import LoungeDoor from './LoungeDoor';
import LoungeWindow from './LoungeWindow';

// Import shared types
import CourseLoungeWithVisitorNames from '../../../shared/types/from-server/CourseLoungeWithVisitorNames';

// Import shared constants
import STUDY_LOUNGE_TUTORIAL_URL from '../../../shared/constants/STUDY_LOUNGE_TUTORIAL_URL';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Lounge
  lounge: CourseLoungeWithVisitorNames,
  // If true, user is a learner
  isLearner: boolean,
  // Handler to call when user wants to edit the lounge
  onEdit?: () => void,
  // Handler to call when user wants to join the lounge
  onJoin: () => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungeItem: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    lounge,
    isLearner,
    onEdit,
    onJoin,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  const {
    name,
    visitorFirstNames,
  } = lounge;

  // Create smileys
  let visitorSmileys: React.ReactNode[] = [];
  if (!lounge.locked) {
    visitorSmileys = visitorFirstNames.map((firstName, i) => {
      const key = `${firstName}-${i}`;
      return (
        <Smiley
          key={key}
          name={firstName}
        />
      );
    });
  }

  // Create name
  const nameElem = (
    name.toLowerCase().includes('lounge')
      ? name
      : `${name} Lounge`
  );

  // Furniture
  const furniture = (
    <div>
      {/* Door */}
      <LoungeDoor />

      {/* Table */}
      <div className="LoungeItem-hide-23rem">
        <LoungeTable
          bottom={0.8}
          left={2.2}
        />
      </div>

      {/* Chairs at Table */}
      <LoungeChair
        angle={-30}
        bottom={0.3125}
        left={0.5}
      />
      <div className="LoungeItem-hide-26rem">
        <LoungeChair
          angle={-150}
          bottom={0.3125}
          left={5.1}
        />
      </div>

      {/* Chair Alone */}
      <LoungeChair
        angle={225}
        bottom={0.3125}
        right={0.5}
      />

      {/* Windows */}
      <LoungeWindow
        onLeft
      />
    </div>
  );

  // Render
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
    <div
      className={`LoungeItem-container ${!lounge.locked ? 'LoungeItem-container-hoverable ' : ''}alert alert-dark ps-2 pe-2 pt-2 pb-3 mb-2`}
      onClick={(e) => {
        // Ignore when lounge is locked
        if (lounge.locked) {
          return;
        }

        // Ignore button clicks
        const tagNames = [
          (e.target as any)?.tagName?.toLowerCase(),
          (e.target as any)?.parentNode?.tagName?.toLowerCase(),
          (e.target as any)?.parentNode?.parentNode?.tagName.toLowerCase(),
        ];
        if (tagNames.includes('button') || tagNames.includes('a')) {
          // Ignore button clicks
          return;
        }

        // Handle join
        onJoin();
      }}
    >
      {/* Flex Container */}
      <div className="text-center">
        {/* Title */}
        <div className="text-center">
          <h3 className="LoungeItem-title m-0">
            <strong>
              {nameElem}
            </strong>
          </h3>
        </div>

        {/* Visitors */}
        {visitorSmileys.length > 0 && (
          <div className="LoungeItem-smiley-container">
            <div className="d-flex flex-row align-items-center justify-content-center">
              <div
                className="d-inline-block"
                style={{ transform: 'translate(0, -0.3rem)' }}
              >
                {visitorSmileys.length}
                {' '}
                visitor
                {visitorSmileys.length > 1 ? 's' : ''}
                {' '}
                this hour:
                {' '}
              </div>
              <div>
                {visitorSmileys}
              </div>
            </div>
          </div>
        )}

        {/* Buttons and Notices */}
        <div className="d-flex align-items-center justify-content-center">
          {/* Lock Notice */}
          {lounge.locked && (
            <div className="LoungeItem-locked-notice alert alert-warning bg-warning text-dark mb-0 me-1">
              <FontAwesomeIcon
                icon={faLock}
                className="me-2"
              />
              <strong>
                <span className="d-none d-sm-inline">
                  Temporarily
                  {' '}
                </span>
                Locked
              </strong>
              <span className="d-none d-md-inline">
                {' '}
                by Teaching Staff
              </span>
            </div>
          )}
          {/* Edit Button */}
          {!isLearner && (
            <button
              type="button"
              id={`LoungeItem-edit-button-${lounge.loungeId}`}
              className="LoungeItem-edit-button btn btn-secondary me-1"
              aria-label={`edit the lounge titled "${name}"`}
              onClick={onEdit}
            >
              <FontAwesomeIcon
                icon={faCog}
              />
            </button>
          )}
          {/* Tutorial Button */}
          {(!lounge.locked && !isLearner) && (
            <a
              className="LoungeItem-tutorial-button btn btn-secondary me-1"
              href={STUDY_LOUNGE_TUTORIAL_URL}
              target="_blank"
              rel="noopener noreferrer"
              aria-label="view the study lounge tutorial"
            >
              <FontAwesomeIcon
                icon={faQuestion}
              />
            </a>
          )}
          {/* Join Button */}
          {!lounge.locked && (
            <button
              type="button"
              id={`LoungeItem-join-button-${lounge.loungeId}`}
              className="LoungeItem-join-button btn btn-dark"
              aria-label={`join the lounge titled "${name}"`}
              onClick={onJoin}
            >
              <FontAwesomeIcon
                icon={faSignInAlt}
                className="me-2"
              />
              Join
            </button>
          )}
        </div>

        {/* Room Furniture */}
        {furniture}
      </div>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoungeItem;
