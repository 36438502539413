/**
 * Component for generating GrouperAlgoSettings for input to dce-live-grouper.
 * @author Benedikt Arnarsson
 */

// Import React
import React from 'react';

// Import DCE reactkit
import { ButtonInputGroup, Drawer, RadioButton } from 'dce-reactkit';

// Import Grouper
import { GrouperAlgorithm } from 'dce-live-grouper';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Chosen GrouperAlgorithm
  grouperAlgorithm: GrouperAlgorithm,
  /**
   * Set the chosen GrouperAlgorithm, either fixed number of groups or
   *   fixed group size
   * @param grouperAlgorithm type of algorithm that is chosen.
   */
  setGrouperAlgorithm: (grouperAlgorithm: GrouperAlgorithm) => void,
  // Maximum number of students per group, when FixedGroupSize is chosen
  capacity: number,
  /**
   * Set the chosen capacity. Only applicable to fixed group size.
   * @param capacity integer max size for the groups.
   */
  setCapacity: (capacity: number) => void,
  // Maximum number of groups, when FixedNumGroups is chosen
  maxGroups: number,
  /**
   * Set the maximum number of groups, when fixed number of groups is chosen.
   * @param maxGroups the chosen maximum number of groups allowed.
   */
  setMaxGroups: (maxGroups: number) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const AlgoSettingsInput: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    grouperAlgorithm,
    setGrouperAlgorithm,
    capacity,
    setCapacity,
    maxGroups,
    setMaxGroups,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------ Capacity ------------ */

  if (grouperAlgorithm === GrouperAlgorithm.FixedGroupSize) {
    // Create body
    body = (
      <div>
        {/* Input */}
        <div className="input-group">
          <span className="input-group-text">
            Group size
          </span>
          <input
            type="number"
            className="form-control"
            id="input-max-group-size"
            placeholder="Max group size"
            value={capacity || ''}
            onChange={(e) => {
              if (e.target.value === '') {
                setCapacity(0);
                return;
              }

              const newCapacity = Number.parseInt(e.target.value, 10);
              if (!Number.isNaN(newCapacity)) {
                setCapacity(newCapacity);
              }
            }}
          />
        </div>

        {/* Explanation */}
        <div className="small text-muted">
          We&apos;ll divvy students up into groups of this size,
          no matter how many groups that takes.
        </div>
      </div>
    );
  }

  /* ----------- MaxGroups ------------ */

  if (grouperAlgorithm === GrouperAlgorithm.FixedNumGroups) {
    // Create body
    body = (
      <div>
        {/* Input */}
        <div className="input-group">
          <span className="input-group-text">
            Number of groups
          </span>
          <input
            type="number"
            className="form-control"
            id="input-max-number-of-groups"
            placeholder="Max number of groups"
            value={maxGroups || ''}
            onChange={(e) => {
              if (e.target.value === '') {
                setMaxGroups(0);
                return;
              }

              const newMaxGroups = Number.parseInt(e.target.value, 10);
              if (!Number.isNaN(newMaxGroups)) {
                setMaxGroups(newMaxGroups);
              }
            }}
          />
        </div>

        {/* Explanation */}
        <div className="small text-muted">
          We&apos;ll divvy students up into this many groups,
          no matter how large the groups get.
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {/* Pick the Algorithm */}
      <ButtonInputGroup
        label="Prioritize"
        minLabelWidth="7rem"
        noMarginOnBottom
      >
        <RadioButton
          id="Prioritize-select-fixed-group-size"
          text="Group Size"
          ariaLabel="prioritize having a maximum group size"
          onSelected={() => {
            setGrouperAlgorithm(GrouperAlgorithm.FixedGroupSize);
          }}
          small
          selected={grouperAlgorithm === GrouperAlgorithm.FixedGroupSize}
        />
        <RadioButton
          id="Prioritize-select-fixed-number-of-groups"
          text="Number of Groups"
          ariaLabel="prioritize having a fixed number of groups"
          onSelected={() => {
            setGrouperAlgorithm(GrouperAlgorithm.FixedNumGroups);
          }}
          small
          selected={grouperAlgorithm === GrouperAlgorithm.FixedNumGroups}
        />
      </ButtonInputGroup>

      {/* Input the values */}
      <Drawer>
        {body}
      </Drawer>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default AlgoSettingsInput;
