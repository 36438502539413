/**
 * A decorative lounge chair
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import style
import './LoungeChair.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Angle that the chair faces
  angle: number,
  // Position in rems
  top?: number,
  bottom?: number,
  left?: number,
  right?: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungeChair: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    angle,
    top,
    left,
    bottom,
    right,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div
      className="LoungeChair-container"
      style={{
        top: (top ? `${top}rem` : undefined),
        left: (left ? `${left}rem` : undefined),
        bottom: (bottom ? `${bottom}rem` : undefined),
        right: (right ? `${right}rem` : undefined),
        transform: `rotate(${angle}deg)`,
      }}
    >
      <div className="LoungeChair-left" />
      <div className="LoungeChair-back" />
      <div className="LoungeChair-right" />
      <div className="LoungeChair-seat" />
      <div className="LoungeChair-pillow" />
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoungeChair;
