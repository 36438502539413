/**
 * Show more button component for a Prospective Event
 * @author Karen Dolan
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import style
import './ShowMoreToggle.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // if the button is toggled on or off
  showMore: boolean,
  // The method to call on toggle
  onToggle: () => void,
  // The course CRN associated to this toggle
  crn: string,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const ShowMoreToggle: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    showMore,
    onToggle,
    crn,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div className="d-inline-block">
      <button
        className="ShowMoreToggle btn btn-light btn-sm ms-2 pt-0 pb-0"
        id={`ShowMoreToggle-button-crn-${crn}`}
        aria-label={`Show ${showMore ? 'less' : 'more'} details for course with CRN ${crn}`}
        onClick={onToggle}
        type="button"
      >
        {showMore ? 'Show less' : 'Show more'}
      </button>
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default ShowMoreToggle;
