/**
 * Creating or editing a lounge
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSave,
  faPlus,
  faTrash,
  faDoorOpen,
  faExternalLinkAlt,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  Modal,
  ModalSize,
  ModalType,
  RadioButton,
  ButtonInputGroup,
  showFatalError,
  visitServerEndpoint,
  alert,
  confirm,
  Variant,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Shared components
import LoungeEmail from './LoungeEmail';

// Import shared types
import CourseLounge from '../../../../shared/types/from-server/stored/CourseLounge';
import SupportEmail from '../../../../shared/types/from-server/SupportEmail';
import CourseLoungeWithVisitorNames from '../../../../shared/types/from-server/CourseLoungeWithVisitorNames';
import UserAndCourseInfo from '../../../../shared/types/UserAndCourseInfo';
import LogMetadata from '../../../../shared/types/from-server/LogMetadata';

// Import helpers
import genZoomMeetingTitle from '../../../../shared/helpers/genZoomMeetingTitle';
import isEmail from '../../../../shared/helpers/isEmail';

// Import shared constants
import MIN_EVENT_FORM_LABEL_WIDTH from '../../../../shared/constants/MIN_EVENT_FORM_LABEL_WIDTH';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler for when the user cancels the lounge creation/editing
  onCancel: () => void,
  /**
   * Handler for when the user finishes editing/creating/archiving the lounge
   * @returns the edited/created/archived lounge
   */
  onFinish: (lounge: CourseLoungeWithVisitorNames) => void,
  // If included, this is the lounge to edit
  lounge?: CourseLoungeWithVisitorNames,
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Creator
  Creator = 'Creator',
  // Editor
  Editor = 'Editor',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.Creator,
    // The loungeId for the lounge that will be created
    loungeId: string,
    // User changed something
    userChangedSomething: boolean,
    // Zoom account email who will own the lounge
    hostEmail: string,
  }
  | {
    // Current view
    view: View.Editor,
    // User changed something
    userChangedSomething: boolean,
    // The lounge that's currently being edited
    loungeBeingEdited: CourseLoungeWithVisitorNames,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Show the creator view
  StartCreation = 'StartCreation',
  // Show the loading spinner
  StartLoading = 'StartLoading',
  // Update host email
  UpdateHostEmail = 'UpdateHostEmail',
  // Update lounge
  UpdateLounge = 'UpdateLounge',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.StartCreation,
    // The new lounge id
    loungeId: string,
  }
  | {
    // Action type
    type: ActionType.UpdateHostEmail,
    // The new host email
    hostEmail: string,
  }
  | {
    // Action type
    type: ActionType.UpdateLounge,
    // The new lounge
    lounge: CourseLoungeWithVisitorNames,
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartCreation: {
      return {
        ...state,
        view: View.Creator,
        loungeId: action.loungeId,
        userChangedSomething: false,
        hostEmail: '',
      };
    }
    case ActionType.UpdateHostEmail: {
      if (state.view !== View.Creator) {
        return state;
      }
      return {
        ...state,
        hostEmail: action.hostEmail,
      };
    }
    case ActionType.UpdateLounge: {
      if (state.view !== View.Editor) {
        return state;
      }
      return {
        ...state,
        loungeBeingEdited: action.lounge,
        userChangedSomething: true,
      };
    }
    case ActionType.StartLoading: {
      return {
        ...state,
        view: View.Loading,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const CreateEditLoungeSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onCancel,
    onFinish,
    lounge,
    userAndCourseInfo,
  } = props;

  // Destructure user and course info
  const {
    courseId,
    courseName,
    termName,
    school,
    isAdmin,
  } = userAndCourseInfo;

  // Detect if creating
  const creating = !lounge;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = (
    creating
      ? {
        view: View.Loading,
      }
      : {
        view: View.Editor,
        userChangedSomething: false,
        loungeBeingEdited: lounge,
      }
  );

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Create the lounge
   * @author Gabe Abrams
   */
  const createLounge = async () => {
    // Skip if not in creator
    if (view !== View.Creator) {
      return;
    }

    // Destructure state
    const {
      loungeId,
      hostEmail,
    } = state;

    // Validate the host email
    if (!isEmail(hostEmail)) {
      return alert(
        'Invalid Host Email',
        'Please provide a valid email for the host of the lounge',
      );
    }

    // Start loading
    dispatch({
      type: ActionType.StartLoading,
    });

    // Create the lounge Zoom name
    const loungeZoomName = await genZoomMeetingTitle({
      courseId,
      eventName: 'Gather Study Lounge',
      courseName,
      termName,
    });

    // Create the lounge
    try {
      const loungeWithVisitorNames = await visitServerEndpoint({
        path: `/api/admin/courses/${courseId}/lounges`,
        method: 'POST',
        params: {
          name: 'Study Lounge',
          loungeId,
          loungeZoomName,
          hostEmail,
          school,
        },
      });

      // Log creation
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.CreateEditLoungeSubpanel,
        action: LogAction.Create,
        target: LogMetadata.Target.CourseLounge,
        metadata: {
          lounge: loungeWithVisitorNames,
        },
      });

      // Exit
      onFinish(loungeWithVisitorNames);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Save changes for an existing lounge
   * @author Gabe Abrams
   */
  const saveChanges = async () => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }

    // Get the lounge being edited
    const {
      loungeBeingEdited,
    } = state;

    // Start loading
    dispatch({
      type: ActionType.StartLoading,
    });

    // Save the lounge
    try {
      await visitServerEndpoint({
        path: `/api/ttm/courses/${courseId}/lounges/${loungeBeingEdited.loungeId}`,
        method: 'PUT',
        params: {
          lounge: loungeBeingEdited,
        },
      });

      // Log save
      if (loungeBeingEdited.archived) {
        // Delete
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.CreateEditLoungeSubpanel,
          action: LogAction.Delete,
          target: LogMetadata.Target.CourseLounge,
          metadata: {
            lounge: loungeBeingEdited,
          },
        });
      } else {
        // Modify
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.CreateEditLoungeSubpanel,
          action: LogAction.Modify,
          target: LogMetadata.Target.CourseLounge,
          metadata: {
            before: lounge,
            after: loungeBeingEdited,
          },
        });
      }

      // Exit
      return onFinish(loungeBeingEdited);
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Delete the lounge
   * @author Gabe Abrams
   */
  const deleteLounge = async () => {
    // Skip if not in editor
    if (view !== View.Editor) {
      return;
    }

    // Destructure state
    const {
      loungeBeingEdited,
    } = state;

    // Mark the lounge as archived
    loungeBeingEdited.archived = true;

    // Update state
    dispatch({
      type: ActionType.UpdateLounge,
      lounge: loungeBeingEdited,
    });

    // Save
    await saveChanges();
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Log open
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.CreateEditLoungeSubpanel,
          action: LogAction.Open,
          target: LogMetadata.Target.CourseLounge,
          metadata: {
            lounge: lounge || null,
          },
        });

        // Only load if creating
        if (!creating) {
          return;
        }

        // Load the full list of lounges
        try {
          const lounges: CourseLounge[] = await visitServerEndpoint({
            path: `/api/courses/${courseId}/lounges`,
            method: 'GET',
            params: {
              includeArchived: true,
            },
          });

          // Extract the list of loungeIds
          const loungeIds = lounges.map((loadedLounge) => {
            return loadedLounge.loungeId;
          });

          // Generate a new loungeId by incrementing the sequence
          let highestNumber = 0;
          loungeIds.forEach((loungeId) => {
            // Extract the number
            const num = Number.parseInt(loungeId.substring(1), 10);

            // Keep highest
            if (highestNumber < num) {
              highestNumber = num;
            }
          });
          const loungeId = `L${highestNumber + 1}`;

          // Finish loading
          dispatch({
            type: ActionType.StartCreation,
            loungeId,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      // Log close
      return () => {
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.CreateEditLoungeSubpanel,
          action: LogAction.Close,
          target: LogMetadata.Target.CourseLounge,
          metadata: {
            lounge: lounge || null,
          },
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;
  let title: string = 'Manage Study Lounge';

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body and title
    title = 'Working...';
    body = (
      <LoadingSpinner />
    );
  }

  /* ------------- Creator ------------ */

  if (view === View.Creator) {
    // Destructure state
    const {
      hostEmail,
    } = state;

    // Create body and title
    title = 'Create Study Lounge';
    body = (
      <div>
        <div className="mb-2">
          The email address for the host of the lounge:
        </div>
        {/* Form */}
        <LoungeEmail
          email={hostEmail}
          onChange={(email) => {
            dispatch({
              type: ActionType.UpdateHostEmail,
              hostEmail: email,
            });
          }}
        />

        {/* Save/Cancel buttons */}
        <div className="text-center mt-4">
          {/* Create button */}
          <button
            id="CreateEditLoungeSubpanel-save-or-create-button"
            type="button"
            className="btn btn-lg btn-dark me-2"
            aria-label="create lounge"
            onClick={createLounge}
          >
            <FontAwesomeIcon
              icon={faPlus}
              className="me-2"
            />
            Create
          </button>

          {/*  Cancel button */}
          <button
            id="CreateEditLoungeSubpanel-cancel-button"
            type="button"
            className="btn btn-lg btn-secondary"
            aria-label="cancel and return to home page"
            onClick={onCancel}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  /* ------------- Editor ------------- */

  if (view === View.Editor) {
    // Destructure state
    const {
      userChangedSomething,
      loungeBeingEdited,
    } = state;

    // Create body and title
    title = 'Edit Study Lounge';
    body = (
      <div>
        {/* Reveal */}
        {isAdmin && (
          <div className="mb-3">
            <strong>
              Zoom Meeting ID:
            </strong>
            {' '}
            {loungeBeingEdited.currentZoomId}
            {' '}
            <a
              href={`https://harvard.zoom.us/user/${loungeBeingEdited.currentZoomHost}/meeting/${loungeBeingEdited.currentZoomId}`}
              target="_blank"
              rel="noopener noreferrer"
              className="CreateEditLoungeSubpanel-reveal-in-zoom-dashboard btn progress-bar-striped fw-bold bg-success text-white pt-0 pb-0 btn-sm"
              aria-label="reveal the meeting in Zoom dashboard"
              id="CreateEditLoungeSubpanel-reveal-in-zoom-dashboard"
              style={{
                textDecoration: 'none',
              }}
            >
              Reveal
              <FontAwesomeIcon
                icon={faExternalLinkAlt}
                className="ms-1"
              />
            </a>
          </div>
        )}

        {/* Lock */}
        <ButtonInputGroup
          label={(
            <>
              <FontAwesomeIcon
                icon={faDoorOpen}
                className="me-1"
              />
              Access
            </>
          )}
          minLabelWidth={MIN_EVENT_FORM_LABEL_WIDTH}
          noMarginOnBottom
          wrapButtonsAndAddGaps
        >
          {/* Unlock button */}
          <RadioButton
            text="Open 24/7"
            selected={!loungeBeingEdited.locked}
            selectedVariant={Variant.Warning}
            small
            id="CreateEditLoungeSubpanel-unlock-lounge"
            ariaLabel="indicate that this lounge should be open 24/7"
            onSelected={() => {
              // Unlock the lounge
              loungeBeingEdited.locked = false;

              // Update state
              dispatch({
                type: ActionType.UpdateLounge,
                lounge: loungeBeingEdited,
              });
            }}
          />

          {/* Lock button */}
          <RadioButton
            text="Locked"
            title="After this is selected, no new members can join the lounge (until unlocked)"
            selected={!!loungeBeingEdited.locked}
            selectedVariant={Variant.Warning}
            small
            id="CreateEditLoungeSubpanel-lock-lounge"
            ariaLabel="indicate that this lounge should be locked"
            onSelected={async () => {
              // Confirm
              const confirmed = await confirm(
                'Are you sure?',
                'Don\'t lock the lounge unless absolutely necessary (i.e. during exams). Remember to unlock the lounge as soon as possible!',
                {
                  confirmButtonText: 'Lock Lounge',
                  confirmButtonVariant: Variant.Dark,
                },
              );
              if (!confirmed) {
                return;
              }

              // Lock the lounge
              loungeBeingEdited.locked = true;

              // Update state
              dispatch({
                type: ActionType.UpdateLounge,
                lounge: loungeBeingEdited,
              });
            }}
          />
        </ButtonInputGroup>

        {/* Explanation of "Lock" */}
        <div className="text-start text-muted small mb-2">
          To create a consistent place for collaboration,
          don&apos;t lock lounges unless
          absolutely necessary
          (i.e. during exams)
        </div>

        {/* Save/Delete/Cancel buttons */}
        <div className="text-center mt-4">
          {/* Save button */}
          <button
            id="CreateEditLoungeSubpanel-save-or-create-button"
            type="button"
            className="btn btn-lg btn-dark me-2"
            aria-label="save changes"
            onClick={saveChanges}
          >
            <FontAwesomeIcon
              icon={faSave}
              className="me-2"
            />
            Save Changes
          </button>

          {/* Delete button */}
          <button
            id="CreateEditLoungeSubpanel-delete-button"
            type="button"
            className="btn btn-lg btn-secondary me-2"
            aria-label="delete the lounge permanently"
            title="Permanently Delete Lounge"
            onClick={async () => {
              // Confirm
              const confirmed = await confirm(
                'Are you sure?',
                `Once you delete this study lounge, it cannot be undone. If you want a new study lounge, you will need to contact DCE online support at ${SupportEmail.ForTTMs}.`,
                {
                  confirmButtonText: 'Delete Lounge',
                  confirmButtonVariant: Variant.Danger,
                },
              );
              if (!confirmed) {
                return;
              }

              // Delete
              await deleteLounge();
            }}
          >
            <FontAwesomeIcon
              icon={faTrash}
            />
          </button>

          {/* Cancel button */}
          <button
            id="CreateEditLoungeSubpanel-cancel-button"
            type="button"
            className="btn btn-lg btn-secondary"
            aria-label="cancel and return to home page"
            onClick={async () => {
              // Immediately return if the user hasn't changed anything
              if (!userChangedSomething) {
                return onCancel();
              }

              // Confirm
              const confirmed = await confirm(
                'Abandon Changes?',
                'Are you sure you want to abandon your changes?',
                {
                  confirmButtonText: 'Abandon Changes',
                  confirmButtonVariant: Variant.Danger,
                  cancelButtonText: 'Continue Editing',
                },
              );
              if (confirmed) {
                onCancel();
              }
            }}
          >
            Cancel
          </button>
        </div>
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      title={title}
      largeTitle
      size={ModalSize.Large}
      type={ModalType.NoButtons}
      onClose={async () => {
        // Skip if the user hasn't changed anything
        if (
          view === View.Editor
          && !state.userChangedSomething
        ) {
          return onCancel();
        }
        onCancel();
      }}
    >
      {body}
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default CreateEditLoungeSubpanel;
