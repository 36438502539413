// Import shared types
import ICFeatureFlagMap from '../../../../shared/types/from-server/ICFeatureFlagMap';

/**
 * Initial default feature flag map
 * @author Gabe Abrams
 */
const INITIAL_FEATURE_FLAG_MAP: ICFeatureFlagMap = {
  liveChatDisabled: false,
  bookmarksDisabled: false,
  pairUpDisabled: false,
  pollDisabled: false,
  respondDisabled: false,
  videoCommentsDisabled: false,
};

export default INITIAL_FEATURE_FLAG_MAP;
