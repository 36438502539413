/**
 * Page for pre-assigning students to groups.
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 */

// Import React
import React, { useReducer, useEffect } from 'react';

// Import DCE reactkit
import {
  LoadingSpinner,
} from 'dce-reactkit';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight, faPlus } from '@fortawesome/free-solid-svg-icons';

// Import Grouper
import { StudentGroupAssignment } from 'dce-live-grouper';

// Import shared types
import User from '../../types/User';

// Import helpers
import fetchStudentList from './helpers/fetchStudentList';

// Import components
import PreAssignmentItem from './PreAssignmentItem';
import CreatePreAssignment from './CreatePreAssignment';

// Import style
import './index.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Pre-assignments from old groups
  oldGroups: StudentGroupAssignment[],
  /**
   * Continue the setup process
   * @param preAssignments pre assignments that were added/copied over
   */
  onSubmit: (preAssignments: StudentGroupAssignment[]) => void,
  /**
   * Set the custom modal title
   * @param customTitle new custom title (or leave undefined to reset)
   */
  setCustomModalTitle: (customTitle?: string) => void,
};

/*------------------------------------------------------------------------*/
/* --------------------------- Static Helpers --------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Merge two sets of group assignments into one.
 * @author Benedikt Arnarsson
 * @param oldGroups old set of group assignments, will be overridden
 * @param newGroups new set of group assignments, will override the old
 * @returns merged set of group assignments.
 */
const mergeGroups = (
  oldGroups: StudentGroupAssignment[],
  newGroups: StudentGroupAssignment[],
): StudentGroupAssignment[] => {
  // TODO: implement overriding and merging
  return oldGroups.concat(newGroups);
};

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, currently loading
  loading: boolean,
  // List of all students in the course
  studentList: User[],
  // List of assigned students
  assignedStudents: StudentGroupAssignment[],
  // If true, currently creating a new pre assignment
  creatingPreAssignment: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Set the studentList, should only be called once after loading it in on
  // mount
  SetStudentList = 'SetStudentList',
  // Start adding a student to a group
  StartAddingGroupAssignment = 'StartAddingGroupAssignment',
  // Cancel adding a student to a group
  CancelAddingGroupAssignment = 'CancelAddingGroupAssignment',
  // Add a student to a group, pushing to the `assignedStudents` state variable
  AddStudentToGroup = 'AddStudentToGroup',
  // Remove a student from a group, removing from the `assignedStudents` list
  RemoveStudentFromGroup = 'RemoveStudentFromGroup',
}

// Action definitions
type Action = (
  {
    // Action type
    type: ActionType.SetStudentList,
    // The new student list
    newStudentList: User[],
  }
  | {
    // Action type
    type: ActionType.AddStudentToGroup,
    // ID of the student we are adding
    studentId: number,
    // Number for the group they are being added to
    groupNum: number,
  }
  | {
    // Action type
    type: ActionType.RemoveStudentFromGroup,
    // ID of the student we are removing
    studentId: number,
  }
  | {
    // Action type
    type: (
      | ActionType.StartAddingGroupAssignment
      | ActionType.CancelAddingGroupAssignment
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Benedikt Arnarsson
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 * @returns updated state
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.SetStudentList: {
      return {
        ...state,
        loading: false,
        studentList: action.newStudentList,
      };
    }
    case ActionType.StartAddingGroupAssignment: {
      return {
        ...state,
        creatingPreAssignment: true,
      };
    }
    case ActionType.CancelAddingGroupAssignment: {
      return {
        ...state,
        creatingPreAssignment: false,
      };
    }
    case ActionType.AddStudentToGroup: {
      return {
        ...state,
        assignedStudents: [
          ...state.assignedStudents,
          {
            studentId: action.studentId,
            groupNum: action.groupNum,
          },
        ],
        creatingPreAssignment: false,
      };
    }
    case ActionType.RemoveStudentFromGroup: {
      const { assignedStudents } = state;

      // Remove student from the list
      const newAssignedStudents = assignedStudents.filter((groupAssignment) => {
        return groupAssignment.studentId !== action.studentId;
      });

      // Update state
      return {
        ...state,
        assignedStudents: newAssignedStudents,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const PreAssign: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    oldGroups,
    onSubmit,
    setCustomModalTitle,
  } = props;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: true,
    studentList: [],
    assignedStudents: oldGroups,
    creatingPreAssignment: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    studentList,
    assignedStudents,
    creatingPreAssignment,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount - load in the list of students.
   * @author Benedikt Arnarsson
   */
  useEffect(
    () => {
      (async () => {
        // Fetch list of students
        const newStudentList = await fetchStudentList();

        // Update state
        dispatch({
          type: ActionType.SetStudentList,
          newStudentList,
        });
      })();
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // Body content
  let body: React.ReactNode;

  /* --------- Pre-processing --------- */

  // Find all unassigned students
  const studentIsAssigned: { [k: number]: boolean } = {};
  assignedStudents.forEach((groupAssignment) => {
    studentIsAssigned[groupAssignment.studentId] = true;
  });
  const unassignedStudents = studentList.filter((student) => {
    return !studentIsAssigned[student.userId];
  });

  /* ------------- Loading ------------ */

  if (loading) {
    body = (
      <div className="PreAssign-loading-container">
        <LoadingSpinner />
      </div>
    );
  }

  /* ----- Creating Pre-assignment ---- */

  if (!body && creatingPreAssignment) {
    body = (
      <CreatePreAssignment
        students={unassignedStudents}
        setCustomModalTitle={setCustomModalTitle}
        onCancel={() => {
          // Reset the custom modal title
          setCustomModalTitle();

          // Cancel adding a student to a group
          dispatch({
            type: ActionType.CancelAddingGroupAssignment,
          });
        }}
        onDone={(studentId: number, groupNum: number) => {
          // Reset the custom modal title
          setCustomModalTitle();

          // Finish adding a student to a group
          dispatch({
            type: ActionType.AddStudentToGroup,
            studentId,
            groupNum,
          });
        }}
      />
    );
  }

  /* ------- Empty Student List ------- */

  if (!body && studentList.length === 0) {
    body = (
      <div>
        {/* Indicating there is an issue */}
        <h2>No students found, please contact support.</h2>

        {/* Submit Button */}
        <div className="d-grid mt-2">
          <button
            type="button"
            className="btn btn-dark"
            id="PreAssign-submit-pre-assignment"
            onClick={() => {
              onSubmit(mergeGroups(oldGroups, assignedStudents));
            }}
          >
            Continue without Assignments
          </button>
        </div>
      </div>
    );
  }

  /* ----------- Assignments ---------- */

  if (!body) {
    // Prep student list into quick lookup map
    const studentIdToInfo: { [k: number]: User } = {};
    studentList.forEach((student) => {
      studentIdToInfo[student.userId] = student;
    });

    // Order assigned students by group (smallest groups first)
    assignedStudents.sort((a, b) => {
      return a.groupNum - b.groupNum;
    });

    // Render
    body = (
      <div className="PreAssign-home-container">
        {/* Student group assignments */}
        <div className="row">
          <p className="lead m-0 col-4 text-start">
            Student Name
          </p>
          <p className="lead m-0 col-4 text-center">
            Group Number
          </p>
          <p className="lead m-0 col-4 text-end">
            Operations
          </p>
        </div>
        <div className="PreAssign-scrollable-container">
          {
            assignedStudents.map((groupAssignment) => {
              // Lookup the student
              const student = studentIdToInfo[groupAssignment.studentId];

              // Skip if user no longer found
              if (!student) {
                return null;
              }

              // Render item
              return (
                <PreAssignmentItem
                  key={groupAssignment.studentId}
                  student={student}
                  groupNum={groupAssignment.groupNum}
                  onRemove={() => {
                    dispatch({
                      type: ActionType.RemoveStudentFromGroup,
                      studentId: groupAssignment.studentId,
                    });
                  }}
                />
              );
            })
          }
        </div>

        {/* Add Button */}
        {unassignedStudents.length > 0 && (
          <div className="d-grid">
            <button
              type="button"
              id="PreAssign-add-student-to-group-button"
              className="btn btn-lg btn-light btn-block mb-2"
              style={{
                border: '0.12rem dashed black',
                paddingTop: '0.5rem',
                paddingBottom: '0.5rem',
              }}
              aria-label="assign student to group"
              onClick={() => {
                // Set the custom modal title
                setCustomModalTitle('Choose a Student');

                // Start assigning
                dispatch({
                  type: ActionType.StartAddingGroupAssignment,
                });
              }}
            >
              <h4 className="m-0">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                />
                Assign Student to Group
              </h4>
            </button>
          </div>
        )}

        {/* Submit Button */}
        <div className="text-center mt-4">
          <button
            type="button"
            className="btn btn-dark btn-lg"
            id="PreAssign-submit-pre-assignment"
            onClick={() => {
              onSubmit(mergeGroups(oldGroups, assignedStudents));
            }}
          >
            Submit Manual Assignments
            <FontAwesomeIcon
              icon={faChevronRight}
              className="ms-2"
            />
          </button>
        </div>
      </div>
    );
  }

  /* ------------- Wrapper ------------ */

  return (
    <div className="PreAssign-outer-container">
      {/* Body */}
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default PreAssign;
