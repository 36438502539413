// Import reactkit
import {
  getTimeInfoInET,
  getOrdinal,
  DAY_IN_MS,
} from 'dce-reactkit';

// Import types
import MMDDYY from '../../../types/MMDDYY';

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

// Number of milliseconds in a week
const WEEK_IN_MS = 7 * DAY_IN_MS;

/*------------------------------------------------------------------------*/
/* -------------------------------- Main -------------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Extract a description of a date, relative to the current week-day.
 * @author Benedikt Arnarsson
 * @param date the date that info is being displayed for.
 * @returns a string describing the day, relative to the current day.
 */
const dateToDescription = (date: MMDDYY): string => {
  const startOfDay = new Date(date.year, date.month - 1, date.day);

  // Could use dce-reactkit `getMonthName` here
  const month = startOfDay.toLocaleDateString(
    'en-US',
    { month: 'short' },
  );
  const monthAndDate = `${month} ${date.day}${getOrdinal(date.day)}`;

  const dayOfWeek = startOfDay.toLocaleDateString(
    'en-US',
    { weekday: 'long' },
  );

  const currDate = getTimeInfoInET();
  const startOfCurrDay = new Date(
    currDate.year,
    currDate.month - 1,
    currDate.day,
  );

  const msFromStartOfWeek = DAY_IN_MS * startOfCurrDay.getDay();

  // Case 1: within the current week
  const startOfWeek = new Date(startOfCurrDay.valueOf() - msFromStartOfWeek);
  if (startOfWeek <= startOfDay) {
    return `${dayOfWeek} (${monthAndDate})`;
  }

  // Case 2: within the last week
  const startOfLastWeek = new Date(startOfWeek.valueOf() - WEEK_IN_MS);
  if (startOfLastWeek <= startOfDay) {
    return `Last ${dayOfWeek} (${monthAndDate})`;
  }

  // Case 3: just display the month and date
  return monthAndDate;
};

export default dateToDescription;
