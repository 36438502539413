// Import React
import React from 'react';

/**
 * Generate a user greeting
 * @author Gabe Abrams
 * @param firstNameElem the first name of the user as an element
 */
const genGreeting = (firstNameElem: React.ReactNode): React.ReactNode => {
  // Time of day
  let timeOfDay = 'day';
  const hours = (new Date()).getHours();
  if (hours < 12) {
    timeOfDay = 'morning';
  } else if (hours >= 12 && hours <= 16) {
    timeOfDay = 'afternoon';
  } else if (hours > 16 && hours <= 24) {
    timeOfDay = 'evening';
  }

  // Day of week
  const day = (new Date()).getDay();
  const dayOfWeek = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ][day];

  // Choose a random greeting
  const greetings = [
    'Welcome, NAME!',
    'Hi NAME!',
    'Glad you\'re here, NAME.',
    'NAME, good to see you!',
    `Have a great ${timeOfDay}, NAME.`,
    `Happy ${dayOfWeek}, NAME!`,
    'NAME, hope you\'re doing well!',
  ];
  const greetingText = (
    greetings[Math.floor(Math.random() * greetings.length)]
  );

  // Splice in the user's name
  const greetingHalves = greetingText.split('NAME');
  const greeting = (
    <span>
      {greetingHalves[0]}
      {firstNameElem}
      {greetingHalves[1]}
    </span>
  );

  // Return the greeting
  return greeting;
};

export default genGreeting;
