/**
 * Modal with the results of the process
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave } from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  Modal,
  ModalType,
} from 'dce-reactkit';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Handler for when the modal is closed
  onClose: () => void,
  // If true, process was halted
  halted: boolean,
  // Number of successfully created events
  numSuccessful: number,
  // Number of events created with warnings
  numWarning: number,
  // Number of failed events
  numFailed: number,
  // Number of events that were skipped
  numSkipped: number,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BatchEventCreationResultsModal: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    onClose,
    halted,
    numSuccessful,
    numWarning,
    numFailed,
    numSkipped,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <Modal
      title={`Results${halted ? ' (Halted)' : ''}`}
      type={ModalType.NoButtons}
      onClose={onClose}
    >
      {/* Results */}
      <ul>
        {/* Num Successful */}
        <li>
          <strong>
            Events created successfully:&nbsp;
          </strong>
          {numSuccessful}
          {' '}
          event
          {numSuccessful === 1 ? '' : 's'}
        </li>
        {/* Num Warning */}
        <li className={numWarning > 0 ? 'text-danger' : ''}>
          <strong>
            Events we created with warnings:&nbsp;
          </strong>
          {numWarning}
          {' '}
          event
          {numWarning === 1 ? '' : 's'}
        </li>
        {/* Num Failed */}
        <li className={numFailed > 0 ? 'text-danger' : ''}>
          <strong>
            Events we failed to create:&nbsp;
          </strong>
          {numFailed}
          {' '}
          event
          {numFailed === 1 ? '' : 's'}
        </li>
        {/* Num Skipped (if there are any) */}
        {numSkipped > 0 && (
          <li>
            <strong>
              Events skipped (due to halt):&nbsp;
            </strong>
            {numSkipped}
            {' '}
            event
            {numSkipped === 1 ? '' : 's'}
          </li>
        )}
      </ul>

      {/* Notes */}
      <div className="mt-3">
        <strong>
          <FontAwesomeIcon
            icon={faSave}
            className="me-2"
          />
          Saved!&nbsp;
        </strong>
        Results are in your spreadsheet.
        You can also scroll through the results on this page.
      </div>
    </Modal>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BatchEventCreationResultsModal;
