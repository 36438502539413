/**
 * Manage the events in an event
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClone,
  faFileVideo,
} from '@fortawesome/free-solid-svg-icons';

// Import dce-reactkit
import {
  LoadingSpinner,
  Modal,
  ModalSize,
  alert,
  showFatalError,
  visitServerEndpoint,
  logClientEvent,
  LogAction,
  DAY_IN_MS,
} from 'dce-reactkit';

// Import shared components
import NothingHereNotice from '../../../../shared/NothingHereNotice';
import VisitLinkModal from '../../../../shared/VisitLinkModal';

// Import shared types
import CourseEvent from '../../../../shared/types/from-server/stored/CourseEvent';
import CourseEventType from '../../../../shared/types/from-server/stored/shared/CourseEventType';
import AttendanceMethod from '../../../../shared/types/from-server/stored/AttendanceLog/AttendanceMethod';
import PublishableRecordingWithInfo from '../../../../shared/types/from-server/PublishableRecordingWithInfo';
import ZoomRecording from '../../../../shared/types/from-server/stored/ZoomRecording';
import UserAndCourseInfo from '../../../../shared/types/UserAndCourseInfo';
import LogMetadata from '../../../../shared/types/from-server/LogMetadata';

// Import shared helpers
import preprocessRecordingPairs from '../../../../shared/helpers/preprocessRecordingPairs';
import formatDate from '../../../../shared/helpers/formatDate';

// Import other components
import RecordingPreview from './RecordingPreview';

// Import style
import './style.scss';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // User and course info
  userAndCourseInfo: UserAndCourseInfo,
  // The event to show recordings for. If undefined, show published recordings
  // for the whole course
  event?: CourseEvent,
  /**
   * Handler to call when closed
   * @param aRecordingWasPublished - true if a recording was published
   */
  onClose: (aRecordingWasPublished: boolean) => void,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Constants ----------------------------- */
/*------------------------------------------------------------------------*/

const BOUNDARY_WIDTH_MS = 2419000000; // Approx. 1 month

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------- State Definition -------- */

type State = {
  // If true, this loading indicator is the very first one
  firstLoad: boolean,
  // If true, currently loading
  loading: boolean,
  // Publishable recordings
  recordings: PublishableRecordingWithInfo[],
  // Link that the user is currently opening
  linkToOpen?: string,
  // If true, the link  to open is loading
  linkToOpenLoading: boolean,
  // The current number of batches to fetch
  numLoadedBatches: number,
  // List of events in the course
  events: CourseEvent[],
  // If true, a recording was published
  aRecordingWasPublished: boolean,
};

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Start the loading indicator
  StartLoading = 'StartLoading',
  // Finish loading recordings and/or events
  FinishLoading = 'FinishLoading',
  // Start loading the opening of a link
  StartOpeningLink = 'StartOpeningLink',
  // Finish opening a link
  FinishOpeningLink = 'FinishOpeningLink',
  // Close the visit link modal
  CloseLinkModal = 'CloseLinkModal',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // The new recordings to set
    recordings: PublishableRecordingWithInfo[],
    // Num loaded batches
    numLoadedBatches: number,
    // The new events to set
    events?: CourseEvent[],
  }
  | {
    // Action type
    type: ActionType.StartOpeningLink,
    // The link that is being opened
    link: string,
  }
  | {
    // Action type
    type: (
      | ActionType.StartLoading
      | ActionType.FinishOpeningLink
      | ActionType.CloseLinkModal
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.StartLoading: {
      return {
        ...state,
        loading: true,
      };
    }
    case ActionType.FinishLoading: {
      return {
        ...state,
        loading: false,
        recordings: action.recordings,
        events: action.events ?? state.events ?? [],
        numLoadedBatches: action.numLoadedBatches,
        firstLoad: false,
        aRecordingWasPublished: action.recordings.some((recordingPair) => {
          return recordingPair.published;
        }),
      };
    }
    case ActionType.StartOpeningLink: {
      return {
        ...state,
        linkToOpen: action.link,
        linkToOpenLoading: true,
      };
    }
    case ActionType.FinishOpeningLink: {
      return {
        ...state,
        linkToOpenLoading: false,
      };
    }
    case ActionType.CloseLinkModal: {
      return {
        ...state,
        linkToOpen: undefined,
        linkToOpenLoading: false,
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* --------------------------- Static Helpers --------------------------- */
/*------------------------------------------------------------------------*/

/**
 * Sort recordings by timestamp (newest on top)
 * @author Gabe Abrams
 * @param recordingPairs list of recording pairs to sort
 * @returns sorted recording pairs
 */
const sortByTimestamp = (recordingPairs: PublishableRecordingWithInfo[]) => {
  // Duplicate so we can edit
  const sortedPairs = recordingPairs;

  // Sort by timestamp
  sortedPairs.sort((a, b) => {
    if (a.recording.timestamp < b.recording.timestamp) {
      return 1;
    }
    if (a.recording.timestamp > b.recording.timestamp) {
      return -1;
    }
    return 0;
  });

  // Return
  return sortedPairs;
};

/* ---- Cached Recording Fetching --- */

// Batch date boundaries
const batchDateBoundaries: {
  [batchNumber: number]: {
    start: Date,
    end: Date,
  },
} = {};
let boundaryDate = new Date(Date.now() + DAY_IN_MS); // Start 1 day in future
for (let batch = 1; batch <= 6; batch++) {
  // Move date one day earlier
  boundaryDate = new Date(boundaryDate.getTime() - DAY_IN_MS);
  // Save date as the end of the range
  const end = boundaryDate;

  // Move one boundary width earlier
  boundaryDate = new Date(boundaryDate.getTime() - BOUNDARY_WIDTH_MS);
  // Save the date as the beginning of the range
  const start = boundaryDate;

  // Save the pair
  batchDateBoundaries[batch] = {
    start,
    end,
  };
}

// Batch number = 1 for the last month, 2 for the month before, etc.
const recordingCache: {
  [hostId: string]: {
    [batchNumber: string]: PublishableRecordingWithInfo[],
  },
} = {}; // hostId => batchNumber => recordingPair[]

/**
 * Get recordings for a Zoom meeting
 * @author Gabe Abrams
 * @param opts object containing all arguments
 * @param opts.eventName the name of the event
 * @param opts.courseId the id of the course
 * @param opts.zoomIds the id of the zoom meetings of interest
 * @param opts.hostIds the hostIds for the hosts of the meeting
 * @param opts.numBatches the number of the batch to load
 * @returns recording pairs
 */
const listZoomRecordingPairs = async (
  opts: {
    eventName: string,
    courseId: number,
    zoomIds: number[],
    hostIds: string[],
    numBatches: number,
  },
): Promise<PublishableRecordingWithInfo[]> => {
  const {
    eventName,
    courseId,
    zoomIds,
    hostIds,
    numBatches,
  } = opts;

  // Create empty recording pairs list (all pairs will be added to this list)
  const recordingPairs: PublishableRecordingWithInfo[] = [];

  // Don't allow duplicates
  const recordingPairAlreadyAdded: {
    [key: string]: boolean,
  } = {}; // <zoomId>-<timestamp> => true if added

  // Go through hosts
  for (let i = 0; i < hostIds.length; i++) {
    const hostId = hostIds[i];
    // Add entry if cache doesn't contain it
    if (!recordingCache[hostId]) {
      recordingCache[hostId] = {};
    }

    // Loop through all relevant batch numbers
    for (let batch = 1; batch <= numBatches; batch++) {
      // Check for cached recordings
      if (!(batch in recordingCache[hostId])) {
        // Batch not cached. Load it.
        const { start, end } = batchDateBoundaries[batch];

        // Load recordings from server
        const loadedRecordingPairs: PublishableRecordingWithInfo[] = (
          await visitServerEndpoint({
            path: `/api/ttm/courses/${courseId}/hosts/${hostId}/recordings/all`,
            method: 'GET',
            params: {
              startMS: start.getTime(),
              endMS: end.getTime(),
            },
          })
        );

        // Add ids
        const loadedPairsWithIds = loadedRecordingPairs.map((pair) => {
          return {
            ...pair,
            id: `${pair.recording.zoomId}-${pair.recording.timestamp}`,
          };
        });

        // Add names to recordings that aren't matched to an event
        const loadedPairsWithIdsAndNames = loadedPairsWithIds.map((pair) => {
          return {
            ...pair,
            recording: {
              ...pair.recording,
              name: (
                pair.recording.name === 'Unknown'
                  ? eventName
                  : pair.recording.name
              ),
            },
          };
        });

        // Pre-process and store in cache
        recordingCache[hostId][batch] = (
          preprocessRecordingPairs(loadedPairsWithIdsAndNames) || []
        );
      }

      // Now we have recordings in the cache. Find the relevant ones and add
      //   them to the running list
      recordingCache[hostId][batch].forEach((recordingPair) => {
        // Make sure the zoom meeting matches
        if (zoomIds.indexOf(recordingPair.recording.zoomId) < 0) {
          return;
        }
        // Make sure the recording pair isn't already in the list
        const key = `${recordingPair.recording.zoomId}-${recordingPair.recording.timestamp}`;
        if (recordingPairAlreadyAdded[key]) {
          return;
        }
        // Add it
        recordingPairs.push(recordingPair);
        // Keep track
        recordingPairAlreadyAdded[key] = true;
      });
    }
  }

  return recordingPairs;
};

/**
 * Update the publish status of a Zoom recording
 * @author Gabe Abrams
 * @param pairId the id of the pair to update
 * @param published true if the new status for the recording is
 *   published
 */
const updateRecordingPublishStatus = (
  pairId: string,
  published: boolean,
) => {
  Object.keys(recordingCache).forEach((hostId) => {
    Object.keys(recordingCache[hostId]).forEach((batchNumber) => {
      const pairs = recordingCache[hostId][batchNumber];
      for (let i = 0; i < pairs.length; i++) {
        const { id } = pairs[i];

        // Check if this is a match
        if (id === pairId) {
          recordingCache[hostId][batchNumber][i].published = published;
          break;
        }
      }
    });
  });
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const RecordingsSubpanel: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
    event,
    onClose,
  } = props;

  // Check which page we're on
  const onPublishedRecordingsPage = (userAndCourseInfo.isLearner || !event);

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    loading: true,
    recordings: [],
    events: [],
    linkToOpenLoading: false,
    numLoadedBatches: 1,
    firstLoad: true,
    aRecordingWasPublished: false,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    loading,
    recordings,
    linkToOpen,
    linkToOpenLoading,
    numLoadedBatches,
    events,
    firstLoad,
    aRecordingWasPublished,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Load recordings
   * @author Gabe Abrams
   * @param loadNextBatch if true, load the next batch of
   *   recordings. Otherwise, just re-load the recordings
   */
  const load = async (loadNextBatch?: boolean) => {
    // Increment the number of batches (if applicable)
    let numBatches = numLoadedBatches;
    if (loadNextBatch) {
      numBatches += 1;
    }

    // Start the loading bar
    dispatch({
      type: ActionType.StartLoading,
    });

    // If we don't have the list of events yet, load it!
    let loadedEvents: CourseEvent[] = events;
    if (events.length === 0) {
      // Load list of events
      try {
        loadedEvents = await visitServerEndpoint({
          path: `/api/courses/${userAndCourseInfo.courseId}/events`,
          method: 'GET',
        });
      } catch (err) {
        return showFatalError(err);
      }
    }

    // Check which page we're on
    let loadedRecordings: PublishableRecordingWithInfo[];
    if (onPublishedRecordingsPage) {
      /* ---- Published Recordings Page --- */

      // Load just the published recordings
      try {
        const publishedRecordings: ZoomRecording[] = await visitServerEndpoint({
          path: `/api/courses/${userAndCourseInfo.courseId}/recordings/published`,
          method: 'GET',
        });

        // Turn the list of recordings into recording pairs with ids
        const recordingPairs: PublishableRecordingWithInfo[] = (
          publishedRecordings.map((recording) => {
            // We got the list of published recordings. Thus, published = true
            return {
              recording,
              id: `${recording.zoomId}-${recording.timestamp}`,
              published: true,
            };
          })
        );

        // Sort recordings by timestamp
        loadedRecordings = sortByTimestamp(recordingPairs);
      } catch (err) {
        return showFatalError(err);
      }
    } else {
      /* ---- Event-specific Recordings --- */

      // Load event recordings (the ones for a specific event)

      // Collect a list of zoomIds (including past ones) for the event
      const zoomIdSet: Set<number> = new Set([]);
      // > Add current zoomId
      if (event.currentZoomId) {
        zoomIdSet.add(event.currentZoomId);
      }
      // > Add past zoomIds
      (event.pastZoomIds || []).forEach((zoomId) => {
        zoomIdSet.add(zoomId);
      });
      const zoomIds = Array.from(zoomIdSet);

      // Collect list of hosts
      const hostIdSet: Set<string> = new Set([]);
      // > Add current host
      if (event.currentZoomHost) {
        hostIdSet.add(event.currentZoomHost);
      }
      // > Add past hosts
      (event.pastZoomHosts || []).forEach((host) => {
        hostIdSet.add(host);
      });
      const hostIds = Array.from(hostIdSet);

      // Load all recordings for the given zoomIds including unpublished
      try {
        let recordingPairs = await listZoomRecordingPairs({
          eventName: event.name,
          courseId: userAndCourseInfo.courseId,
          zoomIds,
          hostIds,
          numBatches,
        });

        // Augment recordings with ihid and names
        recordingPairs = recordingPairs.map((recordingPair) => {
          const newRecordingPair = recordingPair;
          newRecordingPair.recording.ihid = event.ihid;
          // Fall back to event name when no recording name
          newRecordingPair.recording.name = (
            newRecordingPair.recording.name
            || event.name
          );

          return newRecordingPair;
        });

        // Sort the pairs
        recordingPairs = sortByTimestamp(recordingPairs);

        // Store list of recordings
        loadedRecordings = recordingPairs;
      } catch (err) {
        return showFatalError(err);
      }
    }

    // Finish loading
    dispatch({
      type: ActionType.FinishLoading,
      recordings: loadedRecordings,
      numLoadedBatches: numBatches,
      events: loadedEvents,
    });
  };

  /**
   * Publish a recording
   * @author Gabe Abrams
   * @param recordingPair the recording pair to publish
   */
  const publish = async (recordingPair: PublishableRecordingWithInfo) => {
    // Skip if not on event page
    if (!event) {
      return;
    }

    // Deconstruct recording pair
    const { id, recording } = recordingPair;

    // Skip if no id
    if (!id) {
      return;
    }

    // Show the loading indicator
    dispatch({
      type: ActionType.StartLoading,
    });

    // Ask server to publish the recording
    try {
      await visitServerEndpoint({
        path: `/api/ttm/courses/${userAndCourseInfo.courseId}/events/${event.ihid}/recordings/published`,
        method: 'POST',
        params: {
          recording,
        },
      });

      // Log publish
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.RecordingsSubpanel,
        action: LogAction.Add,
        target: LogMetadata.Target.PublishedRecording,
        metadata: {
          recording,
        },
      });

      // Success!

      // Store update
      updateRecordingPublishStatus(id, true);

      // Show confirmation
      let whoCanWatch = 'All students';
      if (event.banDCEStudents && event.banFASStudents) {
        whoCanWatch = 'Teaching team members (but no students)';
      } else if (event.banDCEStudents) {
        whoCanWatch = 'All FAS students';
      } else if (event.banFASStudents) {
        whoCanWatch = 'All DCE students';
      }
      await alert(
        'Recording Published!',
        `${whoCanWatch} in the course can watch this recording by clicking the "Published Recordings" button on the Gather home page. To change the name of this recording, unpublish and then re-publish the recording.`,
      );

      // Re-load to get updated recordings
      load();
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Unpublish a recording
   * @author Gabe Abrams
   * @param recordingPair the recording pair to unpublish
   */
  const unpublish = async (recordingPair: PublishableRecordingWithInfo) => {
    // Deconstruct recording pair
    const { id, recording } = recordingPair;

    // Skip if no id
    if (!id) {
      return;
    }

    // Show the loading indicator
    dispatch({
      type: ActionType.StartLoading,
    });

    // Ask server to unpublish the recording
    try {
      await visitServerEndpoint({
        path: `/api/ttm/courses/${userAndCourseInfo.courseId}/events/${recording.ihid}/recordings/published`,
        method: 'DELETE',
        params: {
          recording,
        },
      });

      // Log unpublish
      logClientEvent({
        context: LogMetadata.Context.Home,
        subcontext: LogMetadata.Context.Home.RecordingsSubpanel,
        action: LogAction.Remove,
        target: LogMetadata.Target.PublishedRecording,
        metadata: {
          recording,
        },
      });

      // Success!

      // Store update
      updateRecordingPublishStatus(id, false);

      // Show confirmation
      await alert(
        'Recording Unpublished!',
        'That recording is no longer available to students.',
      );

      // Reload
      load();
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Open a Zoom recording in a new tab and save attendance
   * @author Gabe Abrams
   * @param {string} ihid - the ihid for the event being attended
   * @param {number} eventTimestamp - the timestamp for when the event occurred
   * @param {string} url - the url to visit
   */
  const openRecording = async (
    ihid: string,
    eventTimestamp: number,
    url: string,
  ) => {
    // Show the modal that takes the user to the recording
    dispatch({
      type: ActionType.StartOpeningLink,
      link: url,
    });

    // Save attendance:
    try {
      await visitServerEndpoint({
        path: `/api/courses/${userAndCourseInfo.courseId}/events/${ihid}/attendance`,
        method: 'POST',
        params: {
          eventTimestamp,
          method: AttendanceMethod.Async,
          isHost: false,
        },
      });

      // Success! Stop the loading
      dispatch({
        type: ActionType.FinishOpeningLink,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        // Log open
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.RecordingsSubpanel,
          action: LogAction.Open,
        });

        // Load
        load();
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log close
        logClientEvent({
          context: LogMetadata.Context.Home,
          subcontext: LogMetadata.Context.Home.RecordingsSubpanel,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ------------ Open Link ----------- */

  if (linkToOpen) {
    // Create modal
    modal = (
      <VisitLinkModal
        title="Play Recording"
        body="Click below to open the recording in a new tab."
        label="recording"
        buttonText="Play Recording"
        icon={faFileVideo}
        link={linkToOpen}
        loading={linkToOpenLoading}
        onClose={() => {
          // Close the visit link modal
          dispatch({
            type: ActionType.CloseLinkModal,
          });
        }}
        isOpeningRecording
      />
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  const title = (
    <>
      {/* Icon */}
      <FontAwesomeIcon
        icon={faFileVideo}
        className="d-none d-md-inline me-2"
      />

      {/* Customize based on if this is an event page */}
      {event ? 'Recordings' : 'Published Recordings'}

      {/* Context */}
      {' '}
      for
      {' '}
      {
        event
          ? event.name
          : userAndCourseInfo.courseName
      }
    </>
  );

  /* ----------- First Load ----------- */

  if (firstLoad) {
    return (
      <Modal
        key="main-modal"
        title={title}
        largeTitle
        size={ModalSize.ExtraLarge}
        onClose={() => {
          onClose(aRecordingWasPublished);
        }}
      >
        <div className="text-center">
          <LoadingSpinner />
        </div>
      </Modal>
    );
  }

  /* --------- Batch Handling --------- */

  // Check if there's another batch available
  const anotherBatchAvailable = (
    // There must be another batch available
    !!batchDateBoundaries[numLoadedBatches + 1]
    // This must be an event page
    && event
  );

  /* -------- Load More Button -------- */

  const loadMoreRecordingsButton = (
    anotherBatchAvailable
      ? (
        <button
          type="button"
          id="RecordingsPanel-load-more-recordings-button"
          className="btn btn-secondary"
          aria-label="load more recordings"
          onClick={() => {
            // Load another batch
            load(true);
          }}
          disabled={loading}
        >
          {
            loading
              ? 'Working...'
              : (
                <>
                  <FontAwesomeIcon
                    icon={faClone}
                    rotation={180}
                    className="me-2"
                  />
                  Load Older Recordings
                </>
              )
          }
        </button>
      )
      : null
  );

  /* --------- Recordings List -------- */

  // Create message representing fetch date
  const beginningOfFetch = batchDateBoundaries[numLoadedBatches].start;
  const loadStatusMessage = (
    <span>
      {/* Date message */}
      We fetched recordings made as early as
      {' '}
      {/* Date */}
      {formatDate(beginningOfFetch)}
      .
    </span>
  );

  // Create UI
  let itemsContent: React.ReactNode;
  if (recordings.length === 0) {
    // No recording pairs

    // Create message
    let message: React.ReactNode = (
      event
        ? 'This event doesn\'t have any recordings yet.'
        : 'This course doesn\'t have any published recordings yet.'
    );
    if (anotherBatchAvailable) {
      message = loadStatusMessage;
    }

    // Create UI
    itemsContent = (
      anotherBatchAvailable
        ? (
          <NothingHereNotice
            title={`No Recordings Since ${formatDate(beginningOfFetch)}`}
            subtitle={(
              <div>
                {/* Message */}
                <div id="RecordingsPanel-load-more-recordings-message">
                  {message}
                </div>

                {/* Load more button */}
                <div className="mt-4">
                  {loadMoreRecordingsButton}
                </div>
              </div>
            )}
          />
        )
        : (
          <NothingHereNotice
            title="No Recordings"
            subtitle={(
              // Subtitle depends on whether this is an event page
              event
                ? 'This event doesn\'t have any recordings yet.'
                : 'This course doesn\'t have any published recordings yet.'
            )}
          />
        )
    );
  } else {
    // At least one recording has been loaded
    itemsContent = (
      <div>
        {/* List of Recordings */}
        <div className="d-grid gap-2">
          {
            recordings
              .map((recordingPair) => {
                const { recording, published } = recordingPair;

                // Get the event type
                const { ihid } = recording;
                const matchingEvent = events.find((existingEvent) => {
                  return (ihid === existingEvent.ihid);
                });
                const eventType = (
                  matchingEvent
                    ? matchingEvent.type
                    : CourseEventType.Other
                );

                // Create a RecordingPreview
                return (
                  <RecordingPreview
                    key={`${recording.name}-${recording.timestamp}`}
                    eventType={eventType}
                    recording={recording}
                    onPublish={() => {
                      publish(recordingPair);
                    }}
                    onUnpublish={() => {
                      unpublish(recordingPair);
                    }}
                    onPlay={(url) => {
                      openRecording(
                        recording.ihid,
                        recording.timestamp,
                        url,
                      );
                    }}
                    isLearner={userAndCourseInfo.isLearner}
                    published={published}
                  />
                );
              })
          }
        </div>

        {/* Load More Recordings Message */}
        {event && (
          anotherBatchAvailable
            ? (
              <div className="text-center">
                {loadStatusMessage}

                {/* Load Button */}
                <div>
                  {loadMoreRecordingsButton}
                </div>
              </div>
            )
            : (
              <div className="text-center">
                That&apos;s it! Zoom does not allow us to load
                older recordings.
              </div>
            )
        )}
      </div>
    );
  }

  /* ------------- Full UI ------------ */

  return (
    <>
      {/* Modal (if there is one) */}
      {modal}

      {/* Main UI */}
      <Modal
        key="main-modal"
        title={title}
        largeTitle
        size={ModalSize.ExtraLarge}
        onClose={() => {
          onClose(aRecordingWasPublished);
        }}
      >
        {/* Items Content (either items, load button, or both) */}
        {itemsContent}
      </Modal>
    </>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default RecordingsSubpanel;
