/**
 * Helper for shortening term names.
 * @author Benedikt Arnarsson
 * @param term the term that we are shortening
 * @returns the shortened term string, of the form '22 Spr
 */
const shortenTerm = (term: string): string => {
  // Split on space, check whether month or Spring/Fall
  const [years, season] = term.split(' ');

  // Get year
  let year = years;
  if (years.includes('-')) {
    // Year range
    year = (
      season.toLowerCase().trim() === 'spring'
        ? years.split('-')[1]
        : years.split('-')[0]
    );
  }

  // Validate
  if (
    // Invalid year
    Number.isNaN(Number.parseInt(year, 10))
    // Invalid season
    || season.trim().length === 0
  ) {
    // Don't shorten
    return term;
  }

  const shortenedYear = `'${year.substring(2)}`;

  return `${season} ${shortenedYear}`;
};

export default shortenTerm;
