/**
 * Manage lounge bans
 * @author Gabe Abrams
 */

// Import React
import React, { useEffect, useReducer } from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faTrash,
  faPlus,
} from '@fortawesome/free-solid-svg-icons';

// Import caccl
import CanvasUser from 'caccl/types/CanvasUser';

// Import dce-reactkit
import {
  LoadingSpinner,
  visitServerEndpoint,
  onlyKeepLetters,
  showFatalError,
  confirm,
  Variant,
  logClientEvent,
  LogAction,
} from 'dce-reactkit';

// Import shared components
import NothingHereNotice from '../../../shared/NothingHereNotice';

// Import shared types
import LoungeBan from '../../../shared/types/from-server/stored/LoungeBan';
import LogMetadata from '../../../shared/types/from-server/LogMetadata';

// Import other components
import CreateLoungeBanModal from './CreateLoungeBanModal';

// Import other types
import AdminPanelFeatureProps from '../types/AdminPanelFeatureProps';

/*------------------------------------------------------------------------*/
/* -------------------------------- State ------------------------------- */
/*------------------------------------------------------------------------*/

/* -------------- Views ------------- */

enum View {
  // Loading
  Loading = 'Loading',
  // Lounge ban list
  LoungeBanList = 'LoungeBanList',
}

/* -------- State Definition -------- */

type State = (
  | {
    // Current view
    view: View.Loading,
  }
  | {
    // Current view
    view: View.LoungeBanList,
    // List of bans
    bans: LoungeBan[],
    // List of students in the course
    studentsInCourse: CanvasUser[],
    // If true, currently working on adding/removing a ban
    working: boolean,
    // If true, adding a ban
    adding: boolean,
  }
);

/* ------------- Actions ------------ */

// Types of actions
enum ActionType {
  // Finish loading
  FinishLoading = 'FinishLoading',
  // Start removing a ban
  StartRemoving = 'StartRemoving',
  // Finish removing a ban
  FinishRemoving = 'FinishRemoving',
  // Start adding a ban
  StartAdding = 'StartAdding',
  // Start saving add
  StartSavingAdd = 'StartSavingAdd',
  // Cancel adding a ban
  CancelAdding = 'CancelAdding',
  // Finish adding a ban
  FinishAdding = 'FinishAdding',
}

// Action definitions
type Action = (
  | {
    // Action type
    type: ActionType.FinishLoading,
    // List of bans
    bans: LoungeBan[],
    // List of students in the course
    studentsInCourse: CanvasUser[],
  }
  | {
    // Action type
    type: ActionType.StartRemoving,
  }
  | {
    // Action type
    type: ActionType.FinishRemoving,
    // Ban to remove
    ban: LoungeBan,
  }
  | {
    // Action type
    type: ActionType.FinishAdding,
    // Ban to add
    ban: LoungeBan,
  }
  | {
    // Action type
    type: (
      | ActionType.StartAdding
      | ActionType.StartSavingAdd
      | ActionType.CancelAdding
    ),
  }
);

/**
 * Reducer that executes actions
 * @author Gabe Abrams
 * @param state current state
 * @param action action to execute
 */
const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case ActionType.FinishLoading: {
      return {
        ...state,
        view: View.LoungeBanList,
        bans: action.bans,
        studentsInCourse: action.studentsInCourse,
        adding: false,
        working: false,
      };
    }
    case ActionType.StartAdding: {
      // Skip if in the wrong view
      if (state.view !== View.LoungeBanList) {
        return state;
      }
      return {
        ...state,
        adding: true,
      };
    }
    case ActionType.StartSavingAdd: {
      // Skip if in the wrong view
      if (state.view !== View.LoungeBanList) {
        return state;
      }
      return {
        ...state,
        working: true,
      };
    }
    case ActionType.CancelAdding: {
      // Skip if in the wrong view
      if (state.view !== View.LoungeBanList) {
        return state;
      }
      return {
        ...state,
        adding: false,
        working: false,
      };
    }
    case ActionType.FinishAdding: {
      // Skip if in the wrong view
      if (state.view !== View.LoungeBanList) {
        return state;
      }
      const { bans } = state;
      const banAlreadyInList = bans.some((ban) => {
        return (ban.userId === action.ban.userId);
      });
      if (!banAlreadyInList) {
        bans.push(action.ban);
      }
      return {
        ...state,
        bans,
        working: false,
        adding: false,
      };
    }
    case ActionType.StartRemoving: {
      // Skip if in the wrong view
      if (state.view !== View.LoungeBanList) {
        return state;
      }
      return {
        ...state,
        working: true,
      };
    }
    case ActionType.FinishRemoving: {
      // Skip if in the wrong view
      if (state.view !== View.LoungeBanList) {
        return state;
      }
      const { bans } = state;
      return {
        ...state,
        bans: bans.filter((ban) => {
          return ban.userId !== action.ban.userId;
        }),
      };
    }
    default: {
      return state;
    }
  }
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const LoungeBanManager: React.FC<AdminPanelFeatureProps> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    userAndCourseInfo,
  } = props;

  const {
    courseId,
    courseName,
  } = userAndCourseInfo;

  /* -------------- State ------------- */

  // Initial state
  const initialState: State = {
    view: View.Loading,
  };

  // Initialize state
  const [state, dispatch] = useReducer(reducer, initialState);

  // Destructure common state
  const {
    view,
  } = state;

  /*------------------------------------------------------------------------*/
  /* ------------------------- Component Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Add the ban that's currently being created
   * @author Gabe Abrams
   * @param banBeingCreated the ban being created
   */
  const addBan = async (banBeingCreated: LoungeBan) => {
    // Skip if in the wrong view
    if (view !== View.LoungeBanList) {
      return;
    }

    // Start saving indicator
    dispatch({
      type: ActionType.StartSavingAdd,
    });

    try {
      // Add the ban
      await visitServerEndpoint({
        path: `/api/admin/lounges/bans/users/${banBeingCreated.userId}`,
        method: 'POST',
        params: {
          ban: banBeingCreated,
        },
      });

      // Log ban added
      logClientEvent({
        context: LogMetadata.Context.AdminPanel,
        subcontext: LogMetadata.Context.AdminPanel.LoungeBanManager,
        action: LogAction.Create,
        metadata: {
          ban: banBeingCreated,
        },
      });

      // Update the state
      dispatch({
        type: ActionType.FinishAdding,
        ban: banBeingCreated,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /**
   * Delete the selected ban
   * @author Gabe Abrams
   * @param banBeingDeleted the ban being deleted
   */
  const deleteBan = async (banBeingDeleted: LoungeBan) => {
    // Skip if in the wrong view
    if (view !== View.LoungeBanList) {
      return;
    }

    // Confirm that the user wants to delete the ban
    const confirmed = await confirm(
      'Delete Ban?',
      `Sure you want to delete the ban for "${banBeingDeleted.userFirstName} ${banBeingDeleted.userLastName}"?`,
      {
        confirmButtonText: 'Delete Ban',
        confirmButtonVariant: Variant.Danger,
      },
    );
    if (!confirmed) {
      return;
    }

    // Start loading
    dispatch({
      type: ActionType.StartRemoving,
    });

    try {
      // Delete the ban
      await visitServerEndpoint({
        path: `/api/admin/lounges/bans/users/${banBeingDeleted.userId}`,
        method: 'DELETE',
      });

      // Log ban deleted
      logClientEvent({
        context: LogMetadata.Context.AdminPanel,
        subcontext: LogMetadata.Context.AdminPanel.LoungeBanManager,
        action: LogAction.Delete,
        metadata: {
          ban: banBeingDeleted,
        },
      });

      // Update the state
      dispatch({
        type: ActionType.FinishRemoving,
        ban: banBeingDeleted,
      });
    } catch (err) {
      return showFatalError(err);
    }
  };

  /*------------------------------------------------------------------------*/
  /* ------------------------- Lifecycle Functions ------------------------ */
  /*------------------------------------------------------------------------*/

  /**
   * Mount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      (async () => {
        try {
          // Log opening
          logClientEvent({
            context: LogMetadata.Context.AdminPanel,
            subcontext: LogMetadata.Context.AdminPanel.LoungeBanManager,
            action: LogAction.Open,
          });

          // Load bans and students
          const [
            bans,
            studentsInCourse,
          ] = await Promise.all([
            visitServerEndpoint({
              path: '/api/admin/lounges/bans',
              method: 'GET',
            }),
            visitServerEndpoint({
              path: `/api/admin/courses/${courseId}/students`,
              method: 'GET',
            }),
          ]);

          // Update the state
          dispatch({
            type: ActionType.FinishLoading,
            bans,
            studentsInCourse,
          });
        } catch (err) {
          return showFatalError(err);
        }
      })();
    },
    [],
  );

  /**
   * Unmount
   * @author Gabe Abrams
   */
  useEffect(
    () => {
      return () => {
        // Log closing
        logClientEvent({
          context: LogMetadata.Context.AdminPanel,
          subcontext: LogMetadata.Context.AdminPanel.LoungeBanManager,
          action: LogAction.Close,
        });
      };
    },
    [],
  );

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  // Determine students without bans
  let studentsWithoutBans: CanvasUser[] = [];
  if (state.view === View.LoungeBanList) {
    // Destructure state
    const {
      bans,
      studentsInCourse,
    } = state;

    // Get the list of students without bans
    studentsWithoutBans = studentsInCourse.filter((student) => {
      return bans.every((ban) => {
        return (ban.userId !== student.id);
      });
    });
  }

  /*----------------------------------------*/
  /* ---------------- Modal --------------- */
  /*----------------------------------------*/

  // Modal that may be defined
  let modal: React.ReactNode;

  /* ------------- Create ------------- */

  if (view === View.LoungeBanList && state.adding) {
    modal = (
      <CreateLoungeBanModal
        courseId={courseId}
        courseName={courseName}
        studentsWithoutBans={studentsWithoutBans}
        onCancel={() => {
          dispatch({
            type: ActionType.CancelAdding,
          });
        }}
        onStartCreation={(banBeingCreated) => {
          addBan(banBeingCreated);
        }}
      />
    );
  }

  /*----------------------------------------*/
  /* ---------------- Views --------------- */
  /*----------------------------------------*/

  // Body that will be filled with the current view
  let body: React.ReactNode;

  /* ------------- Loading ------------ */

  if (view === View.Loading) {
    // Create body
    body = (
      <LoadingSpinner />
    );
  }

  /* --------- Lounge Ban List -------- */

  if (view === View.LoungeBanList) {
    // Destructure the state
    const {
      bans,
    } = state;

    // Display each ban
    const banElems = bans.map((ban) => {
      const {
        userId,
        userFirstName,
        userLastName,
        banFromAllLounges,
        banFromCourseId,
        banFromCourseName,
      } = ban;

      return (
        <div
          key={userId}
          className="alert alert-secondary mb-2 p-2 d-flex align-items-center justify-content-start"
          role="listitem"
        >
          {/* Info */}
          <div className="flex-grow-1 text-start">
            <h3 className="m-0">
              {userFirstName}
              {' '}
              {userLastName}
            </h3>
            <div>
              Banned from
              {' '}
              {banFromAllLounges && (
                <span>
                  all lounges in all courses
                </span>
              )}
              {banFromCourseId && (
                <span>
                  all lounges in
                  {' '}
                  <a
                    href={`https://canvas.harvard.edu/courses/${banFromCourseId}`}
                    target="_blank"
                    className={`LoungeBanManager-course-link-${banFromCourseId}`}
                    rel="noopener noreferrer"
                    style={{
                      textDecoration: 'underline',
                    }}
                  >
                    {banFromCourseName}
                  </a>
                </span>
              )}
            </div>
          </div>

          {/* Buttons */}
          <div>
            <button
              type="button"
              className={`LoungeBanManager-delete-button LoungeBanManager-delete-button-named-${onlyKeepLetters(userFirstName)}-${onlyKeepLetters(userLastName)} LoungeBanManager-delete-${userId} LoungeBanManager-${banFromCourseId ? 'specific-course-ban' : 'all-courses-ban'} btn btn-dark`}
              aria-label={`delete ban for ${userFirstName} ${userLastName}`}
              id={`LoungeBanManager-delete-${userId}-${banFromCourseId || 'all'}`}
              onClick={() => {
                deleteBan(ban);
              }}
            >
              <FontAwesomeIcon
                icon={faTrash}
                className="me-2"
              />
              Delete
            </button>
          </div>
        </div>
      );
    });

    // Create body
    body = (
      <div className="alert alert-light text-dark">
        {/* Bans */}
        <div role="list">
          {banElems.length === 0 && (
            <div className="mb-3">
              {
                studentsWithoutBans.length > 0
                  ? (
                    <NothingHereNotice
                      title="No Bans Yet"
                      subtitle="Create a ban by clicking below"
                    />
                  )
                  : (
                    <NothingHereNotice
                      title="Nobody to Ban"
                      subtitle="There are no students in this course"
                    />
                  )
              }
            </div>
          )}
          {banElems}
        </div>

        {/* Create Button */}
        {studentsWithoutBans.length > 0 && (
          <div className="d-grid">
            <button
              type="button"
              id="LoungeBanManager-create-lounge-ban"
              className="btn btn-lg btn-light btn-block p-3"
              style={{ border: '0.125rem dashed black' }}
              aria-label="create a new lounge ban"
              onClick={() => {
                // Start creating
                dispatch({
                  type: ActionType.StartAdding,
                });
              }}
            >
              <h3 className="m-0">
                <FontAwesomeIcon
                  icon={faPlus}
                  className="me-2"
                />
                Create Study Lounge Ban
              </h3>
            </button>
          </div>
        )}
      </div>
    );
  }

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  return (
    <div>
      {/* Modal */}
      {modal}

      {/* Title */}
      <h2 className="text-white fw-bold mb-3">
        Lounge Bans
      </h2>

      {/* Body */}
      {body}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default LoungeBanManager;
