/**
 * Footer for batch event creation panels
 * @author Gabe Abrams
 */

// Import React
import React from 'react';

// Import FontAwesome
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconDefinition, faChevronRight } from '@fortawesome/free-solid-svg-icons';

/*------------------------------------------------------------------------*/
/* -------------------------------- Types ------------------------------- */
/*------------------------------------------------------------------------*/

// Props definition
type Props = {
  // Info on the continue button
  continueButton?: {
    // Handler to call when clicked
    onClick: () => void,
    // Label of the button
    label?: string,
    // FontAwesome icon
    icon?: IconDefinition,
  },
  // Custom footer content
  customContent?: React.ReactNode,
};

/*------------------------------------------------------------------------*/
/* ------------------------------ Component ----------------------------- */
/*------------------------------------------------------------------------*/

const BatchCreationFooter: React.FC<Props> = (props) => {
  /*------------------------------------------------------------------------*/
  /* -------------------------------- Setup ------------------------------- */
  /*------------------------------------------------------------------------*/

  /* -------------- Props ------------- */

  // Destructure all props
  const {
    continueButton,
    customContent,
  } = props;

  /*------------------------------------------------------------------------*/
  /* ------------------------------- Render ------------------------------- */
  /*------------------------------------------------------------------------*/

  /*----------------------------------------*/
  /* --------------- Main UI -------------- */
  /*----------------------------------------*/

  // List of items in the footer
  const contentItems = [];

  // Continue button
  if (continueButton) {
    const {
      onClick,
    } = continueButton;
    const label = (continueButton.label || 'Continue');

    contentItems.push(
      <div
        key="continue-button"
        className="d-flex align-items-center justify-content-center"
      >
        {/* Continue Button */}
        <div>
          <button
            type="button"
            className="btn btn-dark btn-lg"
            aria-label="continue to next step of batch creation process"
            id="BatchCreationFooter-continue-button"
            onClick={onClick}
          >
            {/* Icon before if custom icon */}
            {continueButton.icon && (
              <FontAwesomeIcon
                icon={continueButton.icon}
                className="me-2"
              />
            )}

            {/* Button Label */}
            {label}

            {/* Icon after if no custom icon */}
            {!continueButton.icon && (
              <FontAwesomeIcon
                icon={faChevronRight}
                className="ms-2"
              />
            )}
          </button>
        </div>
      </div>,
    );
  }

  // Custom content
  if (customContent) {
    contentItems.push(
      <div key="custom-content">
        {customContent}
      </div>,
    );
  }

  /* ---------------------- Assemble full UI ---------------------- */

  return (
    <div className="BatchCreationFooter-container mt-2">
      {contentItems}
    </div>
  );
};

/*------------------------------------------------------------------------*/
/* ------------------------------- Wrap Up ------------------------------ */
/*------------------------------------------------------------------------*/

// Export component
export default BatchCreationFooter;
